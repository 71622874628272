import { fetchWithDefaultHeaders, fetchJsonWithParsedError } from 'common/http';
import { ClientContextVariable, ClientContextVariableCreate } from 'common/types/clientContextVariable';
import { Revision } from 'common/types/revision';
import { Parameter, SoQLType } from 'common/types/soql';

export const createClientContextVariable = (viewId: string, newParameter: ClientContextVariableCreate): Promise<ClientContextVariableCreate> => {
  return fetchJsonWithParsedError(
    `/api/views/${viewId}/client_context`,
    { method: 'POST', body: JSON.stringify(newParameter)}
  );
};

export const replaceClientContextVariablesOnRevision = (viewId: string, revisionSeq: number, newParameters: ClientContextVariableCreate[]): Promise<ClientContextVariableCreate[]> => {
  const body = { metadata: {clientContext: {clientContextVariables: newParameters}} };
  return fetchJsonWithParsedError(
    `/api/publishing/v1/revision/${viewId}/${revisionSeq}`,
    { method: 'PUT', body: JSON.stringify(body)}
  ).then((response:  { resource: Revision }) => {
    const context = response.resource.metadata.clientContext;
    if (context) {
      return context.clientContextVariables;
    }
    return [];
  });
};

export const replaceClientContextVariablesOnView = (viewId: string, newParameters: ClientContextVariableCreate[]): Promise<ClientContextVariableCreate[]> => {
  return fetchJsonWithParsedError(
    `/api/views/${viewId}/client_context`,
    { method: 'PUT', body: JSON.stringify(newParameters)}
  );
};

export const editClientContextVariable = (viewId: string, newParameter: ClientContextVariableCreate): Promise<ClientContextVariableCreate> => {
  return fetchJsonWithParsedError(
    `/api/views/${viewId}/client_context/${newParameter.name}`,
    { method: 'PATCH', body: JSON.stringify(newParameter)}
  );
};

export const deleteClientContextVariable = (viewId: string, ccvName: string): Promise<void> => {
  const body = { name: ccvName };
  return fetchWithDefaultHeaders(
    `/api/views/${viewId}/client_context/${ccvName}`,
    { method: 'DELETE', body: JSON.stringify(body)}
  );
};

const typeToParamName = (dataType: SoQLType): string => {
  switch (dataType) {
    case SoQLType.SoQLTextT:
        return '$$client_vars_txt';
    case SoQLType.SoQLNumberT:
        return '$$client_vars_num';
    case SoQLType.SoQLFloatingTimestampT:
      return '$$client_vars_ts';
    case SoQLType.SoQLBooleanT:
      return '$$client_vars_bool';
  }
  throw new Error(`Tried to get override parameter name for client context variable type ${dataType} which has not been implemented`);
};

export const getParameterFunction = (name: string, uid: string): string => {
  return `param(@${uid}, '${name.replace("'", "''")}')`;
};

// TODO: future users will want to user the 'override' value, and maybe only include overriden things rather than sending everything
export const toTypedOverrideParams = (variables: ClientContextVariable[]): string => {
  if (variables.length === 0) {
    return '';
  }

  const makeParamForType = (dataType: SoQLType, typedVars: ClientContextVariable[]): string => {
    if (typedVars.length === 0) return '';
    const paramName = typeToParamName(dataType);
    const paramValue = encodeURIComponent(typedVars.map(tv => {
      return `${encodeURIComponent(tv.name + '.' + tv.viewId)}=${encodeURIComponent(tv.defaultValue)}`;
    }).join('&'));
    return `${paramName}=${paramValue}`;
  };

  const possibleTypes = [SoQLType.SoQLTextT, SoQLType.SoQLNumberT, SoQLType.SoQLBooleanT, SoQLType.SoQLFloatingTimestampT];

  return possibleTypes.map(t => {
    const varsOfType = variables.filter(v => v.dataType === t);
    return makeParamForType(t, varsOfType);
  }).filter(param => param !== '').join('&');
};

export const sortClientContextVariables = (vars: ClientContextVariable[], separateInherited: boolean): ClientContextVariable[] => {
  const sortFunction = (a: ClientContextVariable, b: ClientContextVariable) => {
    return (a.name).localeCompare(b.name);
  };

  if (separateInherited) {
    const inherited = vars.filter(v => v.inherited);
    const not = vars.filter(v => !v.inherited);
    return (not.sort(sortFunction)).concat(inherited.sort(sortFunction));
  } else {
    return vars.sort(sortFunction);
  }
};

export const clientContextVariableToParameter = (ccv: ClientContextVariable): Parameter => (
  {
    type: 'param',
    name: ccv.name,
    table: ccv.viewId
  }
);

// dsmapi gets unhappy if we send the extra attributes that ClientContextVariable has (like viewId)
export const clientContextVariablesToCreateOnly = (ccvs: ClientContextVariable[]): ClientContextVariableCreate[] => (
  ccvs.map(ccv => {
    return {
      name: ccv.name,
      displayName: ccv.displayName,
      dataType: ccv.dataType,
      defaultValue: ccv.defaultValue
    };
  })
);
