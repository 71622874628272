import _ from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import { ForgeRipple } from '@tylertech/forge-react';
import {
  LEFT,
  RIGHT,
  UP,
  DOWN,
  ESCAPE,
  ENTER,
  SPACE,
  isolateEventByKeys
} from 'common/dom_helpers/keycodes_deprecated';

import './index.scss';

export enum PicklistSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export interface PicklistOption {
  /** Used to render the option title. */
  title?: string;
  /** Used for value comparisons during selection. */
  value?: any;
  /**
   *  Used to visually group similar options.
   *  This value is UI text and should be human-friendly.
   */
  group?: string;
  /**
   *  Receives the relevant option and
   *  must return a DOM-renderable value.
   */
  render?: (option: PicklistOption) => JSX.Element;
  /**  Used to disable the click handler */
  disabled?: boolean;
  danger?: boolean;
  icon?: string | JSX.Element;
  symbol?: string;
  isSubCategory?: boolean;
  /**
   * @deprecated
   * Callers may choose to add other values to PicklistOptions for use
   * in selection methods, etc. Picklist won't filter out these additional keys, but avoid using it because this will not work with Forge replacements.
   * See common/components/FilterBar/AddFilter for an example.
   */
  [key: string]: any;
}

interface Props {
  /**  A top-level HTML id attribute for easier selection. Really shouldn't be optional. */
  id?: string;
  /**  Disables option selection. */
  disabled?: boolean;
  /**  Sets the initial value when provided. */
  value?: any;
  horizontal?: boolean;
  /** Defaults to 'picklist' when not provided. */
  name?: string;
  options: PicklistOption[];
  /** Calls a function after user selection. */
  onSelection: (option?: PicklistOption | null) => void;
  /** Calls a function after user navigation. */
  onChange?: (option?: PicklistOption | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onArrowNavigationFromChild?: () => void;
  /** Defaults to 'large' when not provided. */
  size?: PicklistSizes;
  picklistSizingStrategy?: 'EXPAND_TO_WIDEST_ITEM' | 'SAME_AS_DROPDOWN';
  /** Styles Picklist like Tyler Forge Select dropdown list */
  enableForgeStyle?: boolean;
  hasClickableParentCategory?: boolean;
}

interface State {
  selectedIndex: number;
  selectedOption?: PicklistOption | null;
  focused: boolean;
}

export class Picklist extends Component<Props, State> {
  picklist: HTMLDivElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedIndex: -1,
      selectedOption: null,
      focused: false
    };
  }

  UNSAFE_componentWillMount() {
    // eslint-disable-line camelcase
    this.setSelectedOptionBasedOnValue(this.props);
  }

  componentDidMount() {
    if (this.state.selectedOption) {
      const options = this.picklist.querySelectorAll('.picklist-option');
      const option = this.picklist.querySelector('.picklist-option-selected');
      const index = _.indexOf(options, option);

      if (index > 0) {
        this.setScrollPositionToOption(index);
        this.setNavigationPointer(index);
      }
    }

    // Binding this as a real event rather than passing it through
    // JSX's onKeyUp attribute allows us to stop event propagation
    // to document, which prevents an enclosing modal from closing
    // on escape.
    if (_.isFunction(this.onKeyUp)) {
      this.picklist.addEventListener('keyup', this.onKeyUp as () => void);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // eslint-disable-line camelcase
    this.setSelectedOptionBasedOnValue(nextProps);
  }

  componentWillUnmount() {
    if (_.isFunction(this.onKeyUp)) {
      this.picklist.removeEventListener('keyup', this.onKeyUp as () => void);
    }
  }

  onClickOption = (selectedOption: PicklistOption, event: React.ChangeEvent<HTMLInputElement>) => {
    const optionElements = this.picklist.querySelectorAll('.picklist-option');
    const index = _.indexOf(optionElements, event.target);

    event.stopPropagation();

    // If a consumer wants to focus something immediately after a selection
    // occurs, we shouldn't hijack its focus. To let the consumer have a
    // moment to focus whatever it wants, we delay the default focus a
    // handful of milliseconds. Do note, this will steal focus if
    // the picklist is left viewable on the page after a click.
    _.delay(() => {
      if (this.picklist) {
        this.picklist.focus({
          preventScroll: true
        });
      }
    }, 1);

    this.setNavigationPointer(index);
    this.setSelectedOption(selectedOption);
  };

  onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    isolateEventByKeys(event, [ENTER, SPACE, ESCAPE]);

    const { onSelection } = this.props;

    switch (event.keyCode) {
      case ESCAPE:
        this.picklist.blur();
        break;
      case ENTER:
      case SPACE:
        if (!_.isUndefined(this.state.selectedOption)) {
          onSelection(this.state.selectedOption);
        }
        break;
      default:
        break;
    }
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { horizontal } = this.props;

    // We need to handle UP an DOWN in onKeyDown to allow for
    // press and hold to fire multiple events.
    if (horizontal) {
      // Isolating ENTER and SPACE help prevent some side effects
      // of holding those keys down.
      isolateEventByKeys(event, [LEFT, RIGHT, ENTER, SPACE]);
      switch (event.keyCode) {
        case LEFT:
          this.move('up');
          break;
        case RIGHT:
          this.move('down');
          break;
        default:
          break;
      }
    } else {
      // Isolating ENTER and SPACE help prevent some side effects
      // of holding those keys down.
      isolateEventByKeys(event, [UP, DOWN, ENTER, SPACE]);
      switch (event.keyCode) {
        case UP:
          this.move('up');
          break;
        case DOWN:
          this.move('down');
          break;
        default:
          break;
      }
    }
  };

  onMouseDownOption = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
  };

  onFocus = () => {
    if (_.isFunction(this.props.onFocus)) {
      this.props.onFocus();
    }
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setNavigationPointer(-1);
    if (_.isFunction(this.props.onBlur)) {
      this.props.onBlur();
    }
    this.setState({ focused: false });
  };

  setNavigationPointer = (selectedIndex: number) => {
    this.setState({ selectedIndex });
  };

  setSelectedOptionBasedOnValue = (props: Props) => {
    const { options, value } = props;
    const selectedOptionIndex = _.findIndex(options, { value });

    this.setState({
      selectedOption: props.options[selectedOptionIndex],
      selectedIndex: selectedOptionIndex
    });
  };

  setSelectedOption = (selectedOption: PicklistOption) => {
    this.setState({ selectedOption });
    this.props.onSelection(selectedOption);
  };

  setChangedOption = (selectedOption?: PicklistOption | null) => {
    this.setState({ selectedOption });
    this.props.onChange && this.props.onChange(selectedOption);
  };

  // if the dropdown content is longer than the container, this will scroll the selected item into view
  scrollToSelectedItem = () => {
    setTimeout(() => {
      if (this.picklist && this.state.focused) {
        const selectedElement = this.picklist.querySelector('.picklist-option-selected');
        if (selectedElement && typeof selectedElement.scrollIntoView === 'function') {
          // API: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
          selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }
    }, 0);
  };

  setScrollPositionToOption = (picklistOptionIndex: number) => {
    const picklist = this.picklist;
    const picklistOptions = this.picklist.querySelectorAll('.picklist-option');
    const picklistOption = picklistOptions[picklistOptionIndex];
    const picklistTop = picklist.getBoundingClientRect().top - picklist.scrollTop;
    const picklistCenter = picklist.clientHeight / 2;
    const picklistOptionTop = picklistOption.getBoundingClientRect().top;

    this.picklist.scrollTop = picklistOptionTop - picklistTop - picklistCenter;
  };

  move = (upOrDown: string) => {
    let newIndex;
    let newSelectedOption;

    const { selectedOption, selectedIndex } = this.state;
    const { options, onArrowNavigationFromChild } = this.props;

    const movingUp = upOrDown === 'up';

    const indexOffset = movingUp ? -1 : 1;
    const candidateOption = options[selectedIndex + indexOffset];
    const unselectedStartPosition = movingUp ? 'last' : 'first';

    if (selectedIndex === 0 && movingUp && _.isFunction(onArrowNavigationFromChild)) {
      newIndex = selectedIndex;
      newSelectedOption = selectedOption;
      onArrowNavigationFromChild();
      return;
    }

    if (selectedIndex !== -1 && _.isPlainObject(candidateOption)) {
      newIndex = selectedIndex + indexOffset;
      newSelectedOption = candidateOption;
    } else if (selectedIndex === -1) {
      newIndex = 0;
      newSelectedOption = _[unselectedStartPosition](options);
    } else {
      newIndex = selectedIndex;
      newSelectedOption = selectedOption;
    }

    this.setNavigationPointer(newIndex);
    this.setChangedOption(newSelectedOption);
    this.scrollToSelectedItem();
    this.setScrollPositionToOption(newIndex);
  };

  renderOption = (
    name = 'picklist',
    option: PicklistOption,
    index: number,
    enableForgeStyle: boolean,
    isSubCategory?: boolean
  ): JSX.Element => {
    const { selectedOption } = this.state;
    const onClickOptionBound = this.onClickOption.bind(this, option);
    const isSelected = _.isEqual(selectedOption, option);
    const optionClasses = classNames('picklist-option', {
      'picklist-option-selected': isSelected,
      'picklist-option-disabled': !!option.disabled,
      danger: !!option.danger,
      'picklist-subcategory': isSubCategory
    });

    // This is a bit complicated - option.value is optional and it sometimes is a function. Id is also optional.
    const id = (() => {
      if (typeof option.value === 'string') {
        return `${name}-${option.value}-${index}`;
      } else if (this.props.id) {
        // If id is provided use that in the name
        return `${this.props.id}-${name}-${index}`;
      } else {
        return `${name}-${index}`;
      }
    })();

    const attributes = {
      className: optionClasses,
      onClick: option.disabled ? null : onClickOptionBound,
      onMouseDown: this.onMouseDownOption,
      key: index,
      role: 'option',
      id,
      'aria-selected': isSelected,
      'aria-label': option.title,
      title: option.title
    };

    const pickListTitleClasses = classNames('picklist-title', {
      'picklist-with-icon': !!option.icon
    });
    const content = _.isFunction(option?.render) ? (
      option.render(option)
    ) : (
      <span className={pickListTitleClasses} key={index}>
        {option.icon}
        <span className="picklist-item">{option.title}</span>
        {option.symbol && <span className="picklist-item-symbol">{option.symbol}</span>}
      </span>
    );

    return (
      <div {...attributes}>
        {content}
        {enableForgeStyle && <ForgeRipple />}
      </div>
    ) as JSX.Element;
  };

  render() {
    const renderedOptions: JSX.Element[] = [];
    const { disabled, enableForgeStyle = false, id, name, options = [], size } = this.props;
    const { focused, selectedOption, selectedIndex } = this.state;
    const activeDescendant = selectedOption
      ? `${name || 'picklist'}-${selectedOption.value}-${selectedIndex}`
      : '';
    const attributes = {
      id,
      // When "options" is an empty array and this component is rendering an empty div, it should not be focusable.
      tabIndex: options.length > 0 ? 0 : -1,
      ref: (ref: HTMLDivElement) => (this.picklist = ref),
      className: classNames('picklist', `picklist-size-${size || 'large'}`, {
        'picklist-disabled': disabled,
        'picklist-focused': focused,
        'picklist-forge': enableForgeStyle
      }),
      role: 'listbox',
      'aria-activedescendant': activeDescendant,
      'aria-disabled': disabled
    };

    if (!disabled) {
      _.merge(attributes, {
        onKeyDown: this.onKeyDown,
        onFocus: this.onFocus,
        onBlur: this.onBlur
      });
    }

    const header = (group?: string): JSX.Element => (
      <div className="picklist-group-header" key={`${group}-separator`}>
        {group}
      </div>
    );

    const separator = (group?: string): JSX.Element => (
      <div className="picklist-separator" key={`${group}-header`} />
    );

    _.forEach(options, (option, index) => {
      const { group } = option;
      const previousOption = options[index - 1];
      const differentGroup = previousOption && previousOption.group !== group;

      if (differentGroup) {
        renderedOptions.push(separator(group));
        renderedOptions.push(header(group));
      } else if (index === 0 && group) {
        renderedOptions.push(header(group));
      }

      renderedOptions.push(this.renderOption(name, option, index, enableForgeStyle, option.isSubCategory));
    });

    return (<div {...attributes}>{renderedOptions}</div>) as JSX.Element;
  }
}

export default Picklist;
