import { Expr, FunCall, isFunCall, isTypedFunCall, TypedExpr, TypedSoQLFunCall, SoQLType } from 'common/types/soql';
import { replaceAt, splice } from 'common/util';
import { initialFilter, additionalFilter } from '../../lib/soql-helpers';
import * as _ from 'lodash';
import React from 'react';
import ExpressionEditor, { AstNode, ExprProps, matchEexpr } from '../VisualExpressionEditor';
import AddFilter from '../AddFilter';
import BooleanDivider from './BooleanDivider';
import { Operator } from './Types';
import { Eexpr } from 'common/explore_grid/types';

export const zipArgs = (eexpr: Eexpr<Expr, TypedExpr>): Eexpr<Expr, TypedExpr>[] => {
  return matchEexpr(
    eexpr,
    ({ untyped: expr, typed}) => {
      if (isFunCall(expr) && isTypedFunCall(typed)) {
        return expr.args.map((arg, i) => {
          return { untyped: arg, typed: typed.args[i] };
        });
      }
      return [];
    },
    ({ untyped, error }) => {
      if (isFunCall(untyped)) {
        return untyped.args.map((arg, i) => {
          return { untyped: arg, error };
        });
      }
      return [];
    }
  );
};

interface BooleanCombinatorArgProps extends ExprProps<Expr, TypedExpr> {
  defaultOperator: Operator;
}
interface BooleanCombinatorArgState {
  addWithOperator: Operator;
}

class BooleanCombinatorArg extends React.Component<BooleanCombinatorArgProps, BooleanCombinatorArgState> {
  constructor(props: BooleanCombinatorArgProps) {
    super(props);
    this.state = {
      addWithOperator: props.defaultOperator
    };
  }

  addExpr = (newExpr: Expr, soqlType: SoQLType) => {
    this.props.update(additionalFilter(this.props.eexpr.untyped, newExpr, soqlType, this.state.addWithOperator));
  };

  render() {
    const {
      addFilterType,
      columns,
      eexpr,
      layer,
      layerCount,
      parameters,
      projectionInfo,
      querySucceeded,
      remove,
      scope,
      showAddExpr,
      showKebab,
      showRemove,
      update
    } = this.props;
    const functionName = _.get(eexpr, 'untyped.function_name', '');
    const isBooleanCombinator = (functionName === Operator.AND || functionName === Operator.OR);
    const showAddExprAfter = layerCount === 2 && layer === layerCount && !isBooleanCombinator;

    let layerClass = 'vee-no-bg';
    const hasLayerAndCount = layer !== undefined && layerCount !== undefined;
    if (isBooleanCombinator || (hasLayerAndCount && layer! <= layerCount!)) {
      layerClass = hasLayerAndCount && layer! % 2 === layerCount! % 2 ?
        'vee-gray-bg' : 'vee-white-bg';
    }

    return (<div className={`vee-expr-container vee-boolean-arg ${layerClass}`}>
      <ExpressionEditor
        eexpr={eexpr}
        update={update}
        remove={remove}
        columns={columns}
        parameters={parameters}
        scope={scope}
        isTypeAllowed={(st: SoQLType) => st === SoQLType.SoQLBooleanT}
        showAddExpr={showAddExpr}
        addFilterType={addFilterType}
        layer={layer}
        layerCount={layerCount}
        projectionInfo={projectionInfo}
        querySucceeded={querySucceeded}
        showKebab={showKebab}
        showRemove={showRemove}
      />
      {showAddExprAfter && <AddFilter
        addExpr={this.addExpr}
        className={layerClass.replace('vee', 'add-expr')}
        columns={columns}
        addFilterType={addFilterType}
        addWithOperator={this.state.addWithOperator}
        showOperatorSelector={true}
        updateOperator={(addWithOperator: Operator) => this.setState({ addWithOperator })}
        removable={true}
      />}
    </div>);
  }
}

// The goal here is to flatten out big nested conditions into a list of expressions
// that is easier for the user to read and understand.
//
// When you have something like (a = 1 AND (b = 2 AND (c = 3)))
// we want it to render as
//
// AND
//   a = 1
//   b = 2
//   c = 3
//
// rather than naively rendering it as
// AND
//   a = 1
//   AND
//     b = 2
//     c = 3
//
//
// (a = 1 AND (b = 2 AND (c = 3))) turns into
// [a = 1, b = 2, c = 3]
export const flattenArgs = (subExprs: Eexpr<Expr, TypedExpr>[], expr: Expr): Eexpr<Expr, TypedExpr>[] => {
  return _.flatMap(subExprs, subExpr => {
    if (isFunCall(subExpr.untyped) && subExpr.untyped.function_name === _.get(expr, 'function_name', '')) {
      return flattenArgs(zipArgs(subExpr), expr);
    } else {
      return [subExpr];
    }
  });
};

// [a = 1, b = 2, c = 3] turns into
// (a = 1 AND (b = 2 AND (c = 3)))
export const unflatten = (exprs: Expr[], expr: Expr): Expr => {
  if (exprs.length === 1) return exprs[0];

  const [head, ...rest] = exprs;
  const subExpr: FunCall = {
    type: 'funcall',
    function_name: _.get(expr, 'function_name', ''),
    args: [
      head,
      unflatten(rest, expr)
    ],
    window: null
  };
  return subExpr;
};

function EditBooleanCombinator(props: ExprProps<FunCall, TypedSoQLFunCall>) {
  const { update, eexpr: { untyped: expr }, layer, layerCount } = props;

  let operatorClassName;
  if (expr.function_name === Operator.AND) {
    operatorClassName = 'operator-and';
  } else if (expr.function_name === Operator.OR) {
    operatorClassName = 'operator-or';
  }

  const addExpr = (newExpr: Expr, soqlType: SoQLType) => {
    update(unflatten(splice(flatEExprs.map(eexpr => eexpr.untyped), initialFilter(newExpr, soqlType), flatEExprs.length), expr));
  };

  const updateOperator = (operator: Operator) => {
    update(unflatten(flatEExprs.map(eexpr => eexpr.untyped), { ...expr, function_name: operator }));
  };

  const flatEExprs = flattenArgs(zipArgs(props.eexpr), expr);
  const renderedExprs: JSX.Element[] = [];
  flatEExprs.forEach((subExpr, i) => {
    const subExprUpdate = (newSubExpr: Expr) => {
      update(unflatten(replaceAt(flatEExprs.map(eexpr => eexpr.untyped), newSubExpr, i), expr));
    };

    const subExprRemove = () => {
      update(unflatten(
        flatEExprs
          .filter((unused, offset) => offset !== i)
          .map(fe => fe.untyped),
        expr
      ));
    };


    renderedExprs.push(<BooleanCombinatorArg
      key={i}
      {...props}
      layer={(props.layer || 0) + 1}
      eexpr={subExpr}
      update={subExprUpdate}
      remove={subExprRemove}
      defaultOperator={expr.function_name === Operator.AND ? Operator.OR : Operator.AND}/>);

    if (i < flatEExprs.length - 1) {
      renderedExprs.push(
        <BooleanDivider
          key={i + 'divider'}
          selectedOperator={expr.function_name as Operator}
          onUpdate={updateOperator}
        />
      );
    }
  });

  const layerClass = layer !== undefined && layerCount !== undefined && layer % 2 === layerCount % 2 ?
    'add-expr-gray-bg' : 'add-expr-white-bg';
  const hideRemove = layer === 1 && (layerCount || 0) >= 2;

  return (
    <AstNode {...props} className={`funcall block-level-function-change-icon ${operatorClassName}`} removable={!hideRemove}>
      {renderedExprs}
      {props.showAddExpr && <AddFilter
        addExpr={addExpr}
        columns={props.columns}
        className={layerClass}
        addFilterType={props.addFilterType}
        addWithOperator={expr.function_name as Operator}
        showOperatorSelector={true}
        updateOperator={updateOperator}
        removable={true}
      />}
    </AstNode>
  );
}


const BooleanCombinators = ['op$AND', 'op$OR'];
const shouldRenderAsBooleanCombinator = (f: FunCall) => _.includes(BooleanCombinators, f.function_name);

export { EditBooleanCombinator, shouldRenderAsBooleanCombinator };
