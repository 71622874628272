import { assign, has } from 'lodash';
import {
  Expr, UnAnalyzedAst, UnAnalyzedJoin, JoinType,
  TableName,
  JoinByFromTable, JoinBySubSelect,
  isJoinByFromTable, isJoinBySubSelect, isJoinByJoinFunc
} from 'common/types/soql';
import { castToBinaryTree } from './binaryTree';
import { buildLiteral, renderExpr } from './expr';
import { buildQueryAsBinaryTree } from './query/build';
import { renderQuery } from './query/render';
import { renderTableName } from './tableName';
import { parens } from './util';

type DSLJoinSelect = TableName | JoinByFromTable | JoinBySubSelect;

const isTableName = (dslJoin: DSLJoinSelect): dslJoin is TableName => !has(dslJoin, 'type');

const buildJoin = (type: JoinType, joinSelect: DSLJoinSelect, on?: Expr, lateral?: boolean): UnAnalyzedJoin => {
  const from = (() => {
    if (isTableName(joinSelect)) {
      return { type: 'from_table', from_table: joinSelect } as JoinByFromTable;
    } else {
      return joinSelect;
    }
  })();

  return {
    type,
    from,
    on: on || buildLiteral(true),
    lateral: lateral || false
  };
};

type OuterJoinType = 'left' | 'right' | 'full';
const toJoinType = (arg: OuterJoinType): JoinType => {
  return {
    'left': 'LEFT OUTER JOIN',
    'right': 'RIGHT OUTER JOIN',
    'full': 'FULL OUTER JOIN'
  }[arg] as JoinType;
};

/**
 * @param joinSelect Something returned from fromTable or subSelect.
 * @param on An Expression defining the ON clause.
 */
export const buildInnerJoin = (joinSelect: DSLJoinSelect, on?: Expr) => buildJoin('JOIN', joinSelect, on);
/**
 * @param joinSelect Something returned from fromTable or subSelect.
 * @param on An Expression defining the ON clause.
 */
export const buildOuterJoin = (joinType: OuterJoinType, joinSelect: DSLJoinSelect, on?: Expr) => buildJoin(toJoinType(joinType), joinSelect, on);
export const buildLeftJoin = (joinSelect: DSLJoinSelect, on?: Expr) => buildJoin('LEFT OUTER JOIN', joinSelect, on);
export const buildRightJoin = (joinSelect: DSLJoinSelect, on?: Expr) => buildJoin('RIGHT OUTER JOIN', joinSelect, on);
export const buildFullJoin = (joinSelect: DSLJoinSelect, on?: Expr) => buildJoin('FULL OUTER JOIN', joinSelect, on);

export const decorateJoinWithOn = (join: UnAnalyzedJoin, on: Expr): UnAnalyzedJoin => ({ ...join, on });
/**
 * @param join Something returned from a join-creation function.
 */
export const decorateJoinWithLateral = (join: UnAnalyzedJoin): UnAnalyzedJoin => ({ ...join, lateral: true });

/**
 * Describe a query with which to join a dataset to this query.
 * @param parts Equivalent to composeQuery.
 * @param fromTable Response from a fromTable invocation. This will specify the FROM clause.
 */
export const buildSubSelect = (parts: Parameters<typeof buildQueryAsBinaryTree>[0], alias: string): JoinBySubSelect => ({ type: 'sub_select', sub_select: { selects: buildQueryAsBinaryTree(parts), alias }});

const renderJoinSelect = (joinSelect: UnAnalyzedJoin['from']): string => {
  if (isJoinByFromTable(joinSelect)) {
    return renderTableName(joinSelect.from_table);
  } else if (isJoinByJoinFunc(joinSelect)) {
    throw new Error('building UDF invocations is currently unsupported; please poke at the appropriate engineer');
  } else {
    const { selects, alias } = joinSelect.sub_select;
    return [
      parens(renderQuery(selects)),
      alias
    ].join(' as ');
  }
};

export const renderJoin = (join: UnAnalyzedJoin): string => {
  const joinType = join.lateral ? `${join.type} LATERAL` : join.type;
  return `${joinType} ${renderJoinSelect(join.from)} ON ${renderExpr(join.on)}`;
};
