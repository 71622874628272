import { TypedExpr, Parameter } from 'common/types/soql';
import React from 'react';
import * as _ from 'lodash';
import ParameterPicker from '../ParameterPicker';
import { AstNode, ExprProps } from '../VisualExpressionEditor';
import { Option, some } from 'ts-option';
import I18n from 'common/i18n';
import { ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';

export default function EditParameter(props: ExprProps<Parameter, TypedExpr> ) {
  const {
    parameters,
    eexpr,
    forceShowSuccess,
    isTypeAllowed,
    querySucceeded,
    showKebab,
    showRemove,
    update
  } = props;

  const selected: Option<Parameter> = some(eexpr.untyped);

  const candidates = parameters.filter(ccv => isTypeAllowed(ccv.dataType));
  const showSuccess = forceShowSuccess !== undefined && forceShowSuccess && querySucceeded;

  return (
    <AstNode
      {...props}
      className="parameter-expr"
      removable={showRemove !== undefined && showRemove}
      changeableElement={showKebab ? 1 : undefined}
      showSuccess={showSuccess}>
      <ParameterPicker
        className="btn btn-default"
        prompt={I18n.t('shared.explore_grid.edit_nodes.choose_column')}
        onSelect={update}
        selected={selected}
        parameters={candidates}
      />
      {showKebab &&
        <ForgeIconButton>
          <button
            type="button"
            data-testid="edit-param-kebab-btn"
            aria-label={I18n.t('shared.explore_grid.vee_kebab_menu.button_label')}
            className="tyler-icons">
            <ForgeIcon name="more_vert" />
          </button>
        </ForgeIconButton>
      }
    </AstNode>
  );
}
