import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { spring } from 'react-motion';

import { IconName, SocrataIcon } from '../SocrataIcon';
import ConditionTransitionMotion from '../ConditionTransitionMotion';

import './index.scss';
import { ToastType } from './ToastType';

interface TransitionAttributes {
  opacity: number;
  top: number;
}

export interface SocrataToastProps {
  className?: string;
  onDismiss?: () => void;
  positionTop?: number;
  showNotification?: boolean;
  type?: ToastType;
  customTransition?: TransitionAttributes;
}

export default class ToastNotification extends Component<SocrataToastProps> {
  static propTypes = {
    onDismiss: PropTypes.func,
    positionTop: PropTypes.number,
    showNotification: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(ToastType)),

    // Returns a set of props to pass to ConditionTransitionMotion.
    // This is optional - the default uses positionTop to make a default
    // animation.
    customTransition: PropTypes.object
  };

  static defaultProps = {
    positionTop: 55, // This needs to be at least $header_height (plus some margin) as defined by site_chrome
    showNotification: false,
    type: null
  };

  render() {
    const {
      children,
      customTransition,
      onDismiss,
      positionTop,
      showNotification,
      type
    } = this.props;

    const defaultTransition = {
      willEnter: () => ({ opacity: 0, top: -positionTop! }),
      willLeave: () => ({ opacity: spring(0), top: spring(-positionTop!) }),
      style: { opacity: spring(1), top: spring(positionTop!) }
    };

    const contentClassName = classNames(
      'alert',
      type,
      this.props.className
    );
    return (
      <ConditionTransitionMotion
        condition={!!showNotification}
        {...(customTransition || defaultTransition)}>
        {(style: TransitionAttributes) => (
          <div className="socrata-toast-notification">
            <div className={contentClassName} style={style}>
              {children}
              {onDismiss
                ? (
                  <button className="btn btn-transparent btn-dismiss" onClick={() => onDismiss()}>
                    <SocrataIcon name={IconName.Close2} />
                  </button>
                )
                : null}
            </div>
          </div>
        )}
      </ConditionTransitionMotion>
    );
  }
}

export { ToastType } from './ToastType';
