import _ from 'lodash';
import fp from 'lodash/fp';
import sift from 'common/js_utils/sift';

export function csrfToken() {
  return _.get(document.querySelector('meta[name="csrf-token"]'), 'content');
}

// Because consistency is hard.
export function appToken() {
  return sift(window,
    'CORE_SERVICE_APP_TOKEN',
    'serverConfig.appToken',
    'socrata.siteChrome.appToken',
    'blist.configuration.appToken',
    'socrata.appToken'
  ) || '';
}

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

// Define these as getter properties so we can more easily test with them
Object.defineProperties(headers, {
  'X-CSRF-Token': {
    get() {
      return csrfToken();
    },
    enumerable: true
  },
  'X-App-Token': {
    get() {
      return appToken();
    },
    enumerable: true
  }
});

export const defaultHeaders = headers;

// Used to throw errors from non-200 responses when using fetch.
export function checkStatus(response) {
  if (response.ok) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

// EN-37631: Having multiple socrata tabs can cause the csrf cookie to
// be overwritten within Storyteller session, leading to a validation error.

// This grabs the meta tag and sets it in a cookie.
// This cookie is sent along with requests to core, which validates it against either the
// `X-CSRF-Token` or `form_authenticity_token` header that is sent with the request
export function ensureCsrfToken() {
  const csrfCookieName = 'socrata-csrf-token';
  const currentCsrfToken = csrfToken();
  if (!_.isEmpty(currentCsrfToken)) {
    document.cookie = `${csrfCookieName}=${currentCsrfToken};secure;path=/`;
  }

  return currentCsrfToken;
}

// Wrapper around `document.location.reload`, mainly to make testing easier.
export function reload() {
  document.location.reload();
}

// Object mapping of the current url params
export const urlParams = () => (
  _(document.location.search.slice(1).split('&')).
    map((item) => (item ? item.split('=') : null)).
    compact().
    fromPairs().
    value()
);

export const redirectToQueryString = (queryString, wait = 0) => {
  _.delay(() => {
    document.location.search = queryString;
  }, wait);
};

export const redirectTo = (location, wait = 0) => {
  _.delay(() => {
    document.location.assign(location);
  }, wait);
};

export const fetchJson = (apiPath, options) =>
  fetch(
    apiPath,
    {
      credentials: 'same-origin',
      ...options
    }).
    then(checkStatus).
    then(response => response.json());

/**
 * Fetch with default headers and check status, but do not attempt to parse the response body as JSON
 * @param apiPath
 * @param options
 * @returns {Promise<Response | never>}
 */
export const fetchWithDefaultHeaders = (apiPath, options) =>
  fetch(
    apiPath,
    {
      credentials: 'same-origin',
      ...options,
      headers: {
        ...((options || {}).headers || {}),
        ...defaultHeaders
      }
    }).
    then(checkStatus);

export const fetchJsonWithDefaultHeaders = (apiPath, options) =>
  fetchJson(
    apiPath,
    {
      ...options,
      headers: {
        ...((options || {}).headers || {}),
        ...defaultHeaders
      }
    }
  );

export const fetchJsonWithFederation = (apiPath, options) =>
  fetchJson(
    apiPath,
    {
      ...options,
      headers: {
        'X-Socrata-Federation': 'Honey Badger',
        ...((options || {}).headers || {}),
        ...defaultHeaders
      }
    }
  );

/**
 * A wrapper around `fetchWithDefaultHeaders` with special sauce that will properly handle the situation of a successful
 * response, but an empty body that cannot be json parsed. Additionally, will parse the body on a failure
 * and attach that parsed JSON to the error
 * @param apiPath
 * @param options
 * @returns Resolved promise with a response or rejected promise with error, response, and parsed json body
 */
export const fetchJsonWithParsedError = (apiPath, options) =>
  fetchWithDefaultHeaders(apiPath, options).then(
    (response) => response.json().catch(() => Promise.resolve(response)),
    (error) => {
      if (error.response) {
        return error.response.json().then(
          (json) => {
            error.json = json;
            error.requestId = error.response.headers.get('X-Socrata-RequestId');
            throw error;
          },
          () => {
            throw error;
          }
        );
      } else {
        throw error;
      }
    }
  );

/**
 * Given a map of key/value pairs, this will construct a query
 * string, removing null/undefined values, and properly encoding values
 * @example
 * // returns 'one=1&three=hello%20world'
 * buildQueryString({ one: '1', two: null, three: 'hello world', four: undefined }
 *
 * @param {object} mapOfQueryParams Map of query params
 */
export const buildQueryString = fp.flow(
  fp.omitBy(fp.isNil),
  fp.mapValues(encodeURIComponent),
  fp.toPairs,
  fp.map(fp.join('=')),
  fp.join('&')
);
