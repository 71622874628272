import { Expr, FunCall, isStringLiteral, isTypedStringLiteral, isLiteral, TypedExpr, TypedSoQLFunCall, Scope, isParameter } from 'common/types/soql';
import { typedComponent } from '../../lib/selectors';
import * as _ from 'lodash';
import React from 'react';
import { AstNode, ExprProps } from '../VisualExpressionEditor';
import Argument from './Argument';
import ChangeFunction from './ChangeFunction';
import { EditBetween, shouldRenderAsBetween } from './EditBetween';
import { EditBooleanCombinator, shouldRenderAsBooleanCombinator } from './EditBooleanCombinator';
import { EditDateLikeLiteral, shouldRenderDateLikeLiteral } from './EditDateLikeLiteral';
import { EditInLiterals, shouldRenderInLiterals } from './EditInLiterals';
import { EditOperator, shouldRenderAsOperator } from './EditOperator';
import { EditPostfix, shouldRenderPostfix } from './EditPostfix';
import StringLiteralWithSuggestions from './StringLiteralWithSuggestions';
import FunctionBadge from './FunctionBadge';


export function renderArgs<T>(exprProps: ExprProps<FunCall, TypedSoQLFunCall>): JSX.Element[] {
  return exprProps.eexpr.untyped.args.map((argExpr, i) => (
    <Argument
      key={`arg-${i}`}
      exprProps={exprProps}
      argExpr={argExpr}
      argPosition={i} />
  ));
}

const suggestable = ['op$=', 'op$!=', 'caseless_eq'];
export function renderArgsWithSuggestions(props: ExprProps<FunCall, TypedSoQLFunCall>): JSX.Element[] {
  const typedExpr = typedComponent(props.eexpr);
  if (_.includes(suggestable, props.eexpr.untyped.function_name) && typedExpr.isDefined) {

    // The args here don't necessarily have the same type, because
    // casts may be inserted during analysis. That's why we need
    // to check both
    const [leftTyped, rightTyped] = typedExpr.get.args;
    const [left, right] = props.eexpr.untyped.args;

    // we only use the 'string with suggestions' component for expressions comparing a non literal (and non parameter) to a string (so no 'meow' = 'meow')
    const isString = (exprNode: Expr, typedExprNode: TypedExpr) => isStringLiteral(exprNode) && isTypedStringLiteral(typedExprNode);
    const supportsSuggestions = (node: Expr) => (!isLiteral(node) && !isParameter(node));

    if (isString(right, rightTyped) && supportsSuggestions(left)) {
      return [
        <Argument
          key="suggestion-target"
          exprProps={props}
          argExpr={left}
          argPosition={0} />,
        <StringLiteralWithSuggestions
          key="string-literal"
          exprProps={props}
          // @ts-expect-error
          suggestionTarget={left}
          argExpr={right}
          argPosition={1} />
      ];
    } else if (supportsSuggestions(right) && isString(left, leftTyped)) {
      return [
        <StringLiteralWithSuggestions
          key="string-literal"
          exprProps={props}
          argExpr={left}
          argPosition={0}
          // @ts-expect-error
          suggestionTarget={right} />,
        <Argument
          key="suggestion-target"
          exprProps={props}
          argExpr={right}
          argPosition={1} />
      ];
    }
  }

  return renderArgs(props);
}

const shouldRenderAggregateFunCall = (fun: FunCall, scope: Scope) => {
  const funCall = _.find(scope, (fs) => ( fs.name === fun.function_name ));
  return funCall && funCall.is_aggregate;
};
// TODO There is more to forge here specifically the pop out menu, need to create a custom component to replace the ChangeFunction component.
function EditRegularFunCall<T>(props: ExprProps<FunCall, TypedSoQLFunCall>) {
  return (
    <AstNode
      {...props}
      className="funcall block-level-change-icon regular-funcall"
      removable={props.showRemove !== undefined && props.showRemove}
      showSuccess={props.querySucceeded}>
      <ChangeFunction {...props} scope={props.scope}>
        <FunctionBadge fun={props.eexpr.untyped} formatFunctionName={props.formatFunctionName} />
      </ChangeFunction>
      {renderArgs(props)}
    </AstNode>
  );
}

function EditAggregateFunCall<T>(props: ExprProps<FunCall, TypedSoQLFunCall>) {
  return (
    <AstNode {...props} className="funcall block-level-change-icon aggregate-funcall" removable showSuccess={props.querySucceeded}>
      {renderArgs(props)}
      <ChangeFunction {...props} onlyAggregates={true}>
        <FunctionBadge fun={props.eexpr.untyped} />
      </ChangeFunction>
    </AstNode>
  );
}

export default function EditFunCall<T>(props: ExprProps<FunCall, TypedSoQLFunCall>) {

  if (shouldRenderDateLikeLiteral(props.eexpr.untyped)) return <EditDateLikeLiteral {...props} />;

  if (shouldRenderAsBooleanCombinator(props.eexpr.untyped)) return <EditBooleanCombinator {...props} />;

  if (shouldRenderAsBetween(props.eexpr.untyped)) return <EditBetween {...props} />;

  if (shouldRenderInLiterals(props.eexpr.untyped)) return <EditInLiterals {...props} />;

  if (shouldRenderAsOperator(props.eexpr.untyped, props.scope)) return <EditOperator {...props} />;

  if (shouldRenderPostfix(props.eexpr.untyped)) return <EditPostfix {...props} />;

  if (shouldRenderAggregateFunCall(props.eexpr.untyped, props.scope)) return <EditAggregateFunCall {...props} />;

  else return <EditRegularFunCall {...props} />;
}
