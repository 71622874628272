/**
 * Checks to see if an object has a property key, and causes TypeScript to retype `obj` with `prop` as a property.
 *
 * @source https://fettblog.eu/typescript-hasownproperty/
 * @example
 *  hasProperty({ first: "test"}, "first")
 */
/* eslint @typescript-eslint/ban-types: "warn" */
export function hasProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}
