import _ from 'lodash';
import moment, { Moment } from 'moment';
import $ from 'jquery';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Calendar as FullCalendar, EventApi, OptionsInput, View } from '@fullcalendar/core';
import { DateInput } from '@fullcalendar/core/datelib/env';
// @ts-expect-error
import allLocales from '@fullcalendar/core/locales-all';

import I18n from 'common/i18n';
import getLocale from 'common/js_utils/getLocale';
import BaseVisualization from './BaseVisualization';
import { DATE_FORMAT } from 'common/dates';
import { getTodayDate } from 'common/dates';
import {
  getCalendarDate,
  getEventOutlineColor,
  getEventTextColor,
  getEventBackgroundColor,
  getLockCalendarViewControl
} from 'common/visualizations/helpers/VifSelectors';
import { getFlyoutContentForCalendarEvent } from 'common/visualizations/helpers/contentFormatters';
import { isEventTitleOfUrlColumnType, getEventTitle } from 'common/visualizations/helpers/CalendarHelper';
import { Vif } from '../vif';
import { assertIsNotNil } from 'common/assertions';
import { ViewColumn } from 'common/types/viewColumn';

interface EventInfo {
  el: HTMLElement;
  event: EventApi;
  jsEvent: MouseEvent;
  view: View;
}

type EventFunction = (arg: EventInfo) => boolean | void;

interface CustomButtons {
  [name: string]: {
    text: string;
    click: () => void;
  };
}

type CalendarView = 'dayGridMonth';

interface CalendarOptions {
  customButtons: CustomButtons;
  defaultDate: DateInput | undefined;
  defaultView: CalendarView;
  events: (info: any, successCallback: (events: any[]) => void, failureCallback: () => void) => void;
  fixedWeekCount: false;
  header: {
    left: 'title';
    right: 'todayButton previousButton,nextButton';
  };
  height: 'parent';
  plugins: typeof dayGridPlugin[];
  locales: allLocales;
  locale: any;
}

interface InteractionOptions {
  eventClick: EventFunction;
  eventMouseEnter: EventFunction;
  eventMouseLeave: EventFunction;
}

interface RenderOptions {
  defaultDisplayDate?: DateInput;
  currentDisplayDate?: DateInput;
}
interface CalendarRenderProps {
  newData: any;
  newColumns: ViewColumn[] | null;
  newVif: Vif;
  renderOptions?: RenderOptions;
  newTableVif?: Vif;
}

export default class Calendar extends BaseVisualization {
  private _displayedDate: DateInput | undefined;
  private _visContainer: HTMLElement;
  private _hasOpenFlyout: boolean | undefined;
  private _currentExtendedPropsRow: any | null;
  private _data: any;
  private _calendar: FullCalendar | undefined;
  private _vif: Vif | undefined;
  private _renderOptions: RenderOptions | undefined;

  constructor(visualizationElement: JQuery, vif: Vif, options: any) {
    super(visualizationElement, vif, options);
    this._displayedDate = getCalendarDate(vif);
    assertIsNotNil(this._displayedDate);

    this._visContainer = $(visualizationElement).find('.socrata-visualization-chart-container')[0];

    $(document).on('mousedown', (event) => {
      const isClickWithinDayGridEvent = $(event.target).closest('.fc-day-grid-event').length > 0;
      const isClickWithinCalendarFlyout = $(event.target).closest('.svg-calendar-flyout-content').length > 0;

      if (!isClickWithinDayGridEvent && !isClickWithinCalendarFlyout) {
        this._hasOpenFlyout = false;
        this._currentExtendedPropsRow = null;
        this.emitEvent('SOCRATA_VISUALIZATION_CALENDAR_FLYOUT', null);
      }
    });

    const customButtons: CustomButtons = {
      nextButton: {
        text: ' ► ',
        click: () => this._onNextMonth()
      },
      previousButton: {
        text: ' ◄ ',
        click: () => this._onPreviousMonth()
      },
      todayButton: {
        text: I18n.t('shared.visualizations.charts.calendar.today'),
        click: () => this._onToday()
      }
    };
    const interactionOptions: InteractionOptions = {
      eventClick: (eventInfo: EventInfo) => this._onEventClick(eventInfo),
      eventMouseEnter: (eventInfo: EventInfo) => this._onEventMouseEnter(eventInfo),
      eventMouseLeave: (eventInfo: EventInfo) => this._onEventMouseLeave(eventInfo)
    };
    const calendarOptions: CalendarOptions = {
      customButtons,
      defaultDate: this._displayedDate,
      defaultView: 'dayGridMonth',
      events: this._getEvents,
      fixedWeekCount: false,
      header: {
        left: 'title',
        right: 'todayButton previousButton,nextButton'
      },
      height: 'parent',
      plugins: [dayGridPlugin],
      locales: allLocales,
      locale: getLocale(window)
    };

    const optionsInput: OptionsInput = _.merge(calendarOptions, interactionOptions);

    this._calendar = new FullCalendar(this._visContainer, optionsInput);
    this._vif = vif;
  }

  _onEventMouseEnter = ({ event, el }: EventInfo) => {
    if (!this._hasOpenFlyout) {
      if (isEventTitleOfUrlColumnType(this._data)) {
        $(this._visContainer).addClass('mouse-over-link');
      }

      const $content = getFlyoutContentForCalendarEvent(event, this._data);
      const payload = {
        element: el,
        content: $('<div>', { class: 'svg-calendar-flyout-content' }).append($content),
        belowTarget: false,
        rightSideHint: false,
        dark: true
      };

      this.emitEvent('SOCRATA_VISUALIZATION_CALENDAR_FLYOUT', payload);
    }
  };

  _onEventMouseLeave = ({ event, jsEvent }: EventInfo) => {
    if (!this._hasOpenFlyout && jsEvent?.relatedTarget) {
      const extendedPropsRow = _.get(event, 'extendedProps.row');

      const isMouseEnterCalendarFlyout =
        $(jsEvent.relatedTarget).closest('.svg-calendar-flyout-content').length > 0;

      if (isEventTitleOfUrlColumnType(this._data)) {
        $(this._visContainer).removeClass('mouse-over-link');
      }

      if (_.isEqual(this._currentExtendedPropsRow, extendedPropsRow) || isMouseEnterCalendarFlyout) {
        return;
      }

      this.emitEvent('SOCRATA_VISUALIZATION_CALENDAR_FLYOUT', null);
    }
  };

  _onEventClick = ({ event }: EventInfo) => {
    if (this._hasOpenFlyout) {
      this._hasOpenFlyout = false;
      this._currentExtendedPropsRow = null;
      this.emitEvent('SOCRATA_VISUALIZATION_CALENDAR_FLYOUT', null);
    } else {
      this._hasOpenFlyout = true;
      const extendedPropsRow = _.get(event, 'extendedProps.row', []);
      const eventTitle = getEventTitle(this._data, extendedPropsRow);

      if (isEventTitleOfUrlColumnType(this._data)) {
        window.open(eventTitle, '_blank');
      }
      this._currentExtendedPropsRow = _.isEqual(this._currentExtendedPropsRow, extendedPropsRow)
        ? null
        : extendedPropsRow;
    }
  };

  _onNextMonth = () => {
    const newDate = moment.utc(this._displayedDate).add(1, 'month');
    this._triggerDateChange(newDate);
  };

  _onPreviousMonth = () => {
    const newDate = moment.utc(this._displayedDate).subtract(1, 'month');
    this._triggerDateChange(newDate);
  };

  _onToday = () => {
    const newDate = moment.utc();
    this._triggerDateChange(newDate);
  };

  // Render Options
  //{ defaultDisplayDate: string, currentDisplayDate: string }
  /**
   * @param {RenderObject} - params
   * @param {Object} params.renderOptions
   * @param {string} params.renderOptions.defaultDisplayDate - The default date value, to be used when currentDisplayDate is null
   * @param {string} params.renderOptions.currentDisplayDate - The current date value, think of this as local state.
   */
  render = ({ newData, newColumns, newVif, renderOptions = {}, newTableVif }: CalendarRenderProps) => {
    this._data = newData;
    this._renderOptions = renderOptions;
    this.clearError();

    if (newColumns) {
      this.updateColumns(newColumns);
    }

    if (newVif) {
      this.updateVif(newVif);
    }

    if (newTableVif) {
      this.updateSummaryTableVif(newTableVif);
    }

    this.renderData(newVif);
  };

  renderData = (vif?: Vif | undefined) => {
    assertIsNotNil(this._calendar);

    if (vif) {
      const currentDisplayDate = vif?.configuration?.currentDisplayDate;
      // @ts-ignore
      const defaultDisplayDate = vif?.configuration?.defaultDisplayDate;

      // currentDisplayDate should be treated as the locked display date.
      if (currentDisplayDate) {
        // @ts-ignore
        this._calendar.gotoDate(currentDisplayDate);
        this._displayedDate = currentDisplayDate;
      } else if (defaultDisplayDate) {
        // TODO remove following if statement once we convert currentDisplayDate to lockedDisplayDate and do the appropriate vif migration.
        if (getLockCalendarViewControl(vif) === false) {
          // @ts-ignore
          this._calendar.gotoDate(getTodayDate());
          this._displayedDate = getTodayDate();
        } else {
          // @ts-ignore
          this._calendar.gotoDate(defaultDisplayDate);
          this._displayedDate = defaultDisplayDate;
        }
      } else {
        // @ts-ignore
        this._calendar.gotoDate(getTodayDate());
        this._displayedDate = getTodayDate();
      }
    }

    this._calendar.refetchEvents();
    this._calendar.render();
  };

  invalidateSize = () => {
    if (this._data) {
      this.renderData();
    }
  };

  destroy = () => {
    assertIsNotNil(this._calendar);
    this._calendar.destroy();
  };

  _getEvents = (info: any, successCallback: (events: any[]) => void) => {
    const rows = _.get(this._data, 'rows');
    const newVif = _.cloneDeep(this.getVif());
    const events = _.map(rows, (row) => {
      // Adding one day as full calendar is not inclusive of the end day.
      // https://fullcalendar.io/docs/v3/upgrading-from-v1
      const endDate = moment.utc(_.isEmpty(row[2]) ? row[1] : row[2]).add(1, 'day');
      const startDate = moment.utc(row[1]);

      return {
        textColor: getEventTextColor(newVif),
        backgroundColor: getEventBackgroundColor(newVif),
        borderColor: getEventOutlineColor(newVif),
        title: getEventTitle(this._data, row).replace('\n', ''),
        start: startDate.format(DATE_FORMAT),
        end: endDate.format(DATE_FORMAT),
        row
      };
    });

    successCallback(events);
  };

  _triggerDateChange = (newDateMoment: Moment) => {
    const newVif = _.cloneDeep(this.getVif());
    const newDateString = newDateMoment.format(DATE_FORMAT);

    this.emitEvent('SOCRATA_VISUALIZATION_CURRENT_DATE_CHANGED', newDateString);
    _.set(newVif, 'configuration.currentDisplayDate', newDateString);

    this.emitVifEvent(newVif);
  };
}
