import $ from 'jquery';
import _ from 'lodash';
import mapboxgl from '@socrata/mapbox-gl';

import MapHelper, { getFeaturesAroundLocation } from 'common/visualizations/helpers/MapHelper';
import {
  getFeatureDiameter,
  LAYERS as POINT_AND_STACK_LAYERS
} from '../vifOverlays/partials/PointsAndStacks';
import {
  DEFAULT_STACK_RADIUS_PADDING,
  MAP_CANVAS_CLASSNAME,
  MAP_HIGHLIGHT_CONTAINER_CLASSNAME
} from 'common/visualizations/views/mapConstants';

export const MOUSE_CURSOR_DEFAULT = 'auto';
export const MOUSE_CURSOR_HAND = 'pointer';
const CIRCLE_DIAMETER_TO_BBOX_SQUARE_RATIO = 3 / 4;
const MOUSE_MOVE_DEBOUNCE_WAIT_TIME = 100;

// Handles mouse events (mousemove, click) for points/stacks/clusters/lines/shapes displayed
// by VifPointOverlay, VifLineOverlay and VifShapeOverlay.
// Sample Feature: (Geojson object got from mapbox-gl map)
//    {
//      "type": "Feature",
//      "geometry": {
//        "type": "Point",
//        "coordinates": [-122.44754076004028,37.8044394394888]
//      },
//      "properties": {
//        "cluster": true
//        "cluster_id": 13
//        "count": 15489
//        "count_abbrev": "15k"
//        "count_group": "{\"Abandoned Vehicle\":2645,\"__$$other$$__\":6946,\"Street and Sidewalk Cleaning\":3109,\"Graffiti Private Property\":1027,\"Graffiti Public Property\":1424,\"SFHA Requests\":338}"
//        "point_count": 162
//        "point_count_abbreviated": 162
//        "__aggregate_by__": 97491
//        "__aggregate_by___abbrev": "97k"
//        "__aggregate_by___group": "{\"Abandoned Vehicle\":17386,\"__$$other$$__\":44145,\"Street and Sidewalk Cleaning\":18816,\"Graffiti Private Property\":6191,\"Graffiti Public Property\":8587,\"SFHA Requests\":2366}"
//      },
//      "layer": {
//        "id":"stack-circle",
//        "type":"circle",
//        "source":"pointVectorDataSource",
//        "source-layer":"_geojsonTileLayer",
//        "filter":["any",["has","point_count"], ...],
//        "paint":{"circle-radius":12, ...}
//      }
//    }
export default class MouseInteractionHandler {
  constructor(mouseInteractionParams = {}) {
    const {
      map,
      element,
      highlightHandler,
      popupHandler,
      persistentPopupHandler,
      spiderfyHandler,
      vif
    } = mouseInteractionParams;

    this._map = map;
    this._spiderfyHandler = spiderfyHandler;
    this._highlightHandler = highlightHandler;
    this._popupHandler = popupHandler;
    this._persistentPopupHandler = persistentPopupHandler;
    this._previousPopupDetails = null;
    this._currentlyHiddenFeatureConfigs = [];
    this._existingPersistentDetails = null;
    this._existingPopup = null;
    this._marker = null;
    this._renderOptions = {};
    this._vif = vif;
    this._registeredLayers = {};
    const debouncedMouseMove = _.debounce(this._onMouseMove, MOUSE_MOVE_DEBOUNCE_WAIT_TIME);

    // No need to unbind the event listeners on destroy. It is automatically taken care of
    // in unifiedMap using map.remove();(https://www.mapbox.com/mapbox-gl-js/api/#map#remove).
    this._map.on('mousemove', debouncedMouseMove);
    this._map.on('click', this._onMouseClick);
    this._map.on('zoomstart', () => {
      this._spiderfyHandler.unspiderfy();
      this._removeMarker();
    });

    $(document).on('mousedown', (event) => {
      const isClickWithinPopup = $(event.target).closest('.mapboxgl-popup-content').length > 0;
      const isClickWithinSpiderLeg = $(event.target).closest('.spider-leg-container').length > 0;

      if (!isClickWithinPopup && !isClickWithinSpiderLeg) {
        // We are using mousedown as this needs to happen before we show a popup on clicking
        // of a feature in the map. On clicking anywhere persistent popup should be closed.
        this._persistentPopupHandler.removeFeaturePopup();
        popupHandler.spiderPersistPopupRemove();
        this._unhidePoints();
        this._spiderfyHandler.unspiderfy();
        this._previousPopupDetails = null;
        this._removeMarker();
      }
    });
    $(element).on('mouseout', (event) => {
      this._popupHandler.removeFeaturePopup();
      this._existingPersistentDetails = null;
    });
  }

  // layerId                        : <string> Id of the layer
  // vif                            : <object> vif used to render the layer
  // renderOptions                  : <object> renderOptions used to render the layer
  // options.highlight              : <boolean> should add highlight
  // options.popupOnMouseOver       : <boolean> should show popup on hover
  // options.shouldSpiderfyOnClick  : <boolean> should spiderfy on click
  // options.shouldZoomOnClick      : <boolean> should zoom on click
  // options.renderFeatureInSpiderLeg      : <function> should render feature in spider leg
  // options.hidePointsWithId      : <function> should hide particular points with Id
  // options.unhidePoints      : <function> should unhide the points
  register(layerId, vif, renderOptions, options) {
    const existingVif = _.get(this._registeredLayers, [layerId, 'vif'], vif);

    if (options.shouldSpiderfyOnClick && hasStackingOptionsChanged(existingVif, vif)) {
      this._spiderfyHandler.unspiderfy();
      this._persistentPopupHandler.removeFeaturePopup();
    }

    this._registeredLayers[layerId] = {
      layerId,
      highlight: options.highlight,
      popupOnMouseOver: options.popupOnMouseOver,
      renderOptions,
      shouldSpiderfyOnClick: options.shouldSpiderfyOnClick,
      shouldZoomOnClick: options.shouldZoomOnClick,
      renderFeatureInSpiderLeg: options.renderFeatureInSpiderLeg,
      hidePointsWithId: options.hidePointsWithId,
      unhidePoints: options.unhidePoints,
      overlayOptions: options.overlayOptions,
      vif
    };
  }

  unregister(layerId) {
    delete this._registeredLayers[layerId];
  }

  _getFeaturesAt(point, layers) {
    const availableLayers = _.filter(layers, (layer) => this._map.getLayer(layer));

    return this._map.queryRenderedFeatures(point, { layers: availableLayers });
  }

  // Event handler, gets called when mouse is clicked in the mapboxgl map element.
  _onMouseClick = (event) => {
    let featureConfigs = [];
    const spiderfyOnClickLayerIds = this._getSpiderfyOnClickLayerIds();
    const zoomOnClickLayerIds = this._getZoomOnClickLayerIds();
    const popupOnMouseOverLayerIds = this._getPopupOnMouseOverLayerIds();
    const mouseInteractableLayerIds = spiderfyOnClickLayerIds.concat(zoomOnClickLayerIds, popupOnMouseOverLayerIds);

    if (_.isEmpty(mouseInteractableLayerIds)) {
      return;
    }

    const clickedOnFeatures = this._getFeaturesAt(event.point, _.uniq(mouseInteractableLayerIds));

    if (_.isEmpty(clickedOnFeatures)) {
      this._unhidePoints();
      this._spiderfyHandler.unspiderfy();
      this._persistentPopupHandler.removeFeaturePopup();
      return;
    }

    // If multiple stacks/clusters are displayed on the same location and
    // mouse is hovered over the common area, then on querying features at the mouse
    // location will have all those features. In that case, we take the first feature
    // and process the click event for it.
    const topMostClickedOnFeature = clickedOnFeatures[0];
    const topMostClickedOnLayerId = _.get(topMostClickedOnFeature, 'layer.id');

    if (isPointOrStackLayer(topMostClickedOnLayerId)) {
      featureConfigs = this._getFeatureConfigs(topMostClickedOnFeature, topMostClickedOnLayerId, event);
    }

    const hasMultipleFeatures = featureConfigs.length > 1;
    const properties = _.get(topMostClickedOnFeature, 'properties');
    const renderOptions = this._registeredLayers[topMostClickedOnLayerId].renderOptions;
    const isPoint = _.get(topMostClickedOnFeature, ['properties', renderOptions.countBy]) <= 1;
    const isSpiderfyLayerId = _.includes(spiderfyOnClickLayerIds, topMostClickedOnLayerId);
    const singlePointOrNotSpiderfyLayerId = !isSpiderfyLayerId || (isPoint && !hasMultipleFeatures);

    if (_.isEqual(this._existingPersistentDetails, properties)) {
      this._persistentPopupHandler.removeFeaturePopup();
      this._previousPopupDetails = null;
      this._existingPersistentDetails = null;
      return;
    }

    if (_.includes(popupOnMouseOverLayerIds, topMostClickedOnLayerId) && singlePointOrNotSpiderfyLayerId) {
      const options = {
        event: event,
        features: [topMostClickedOnFeature],
        renderOptions: [renderOptions],
        vifs: [this._registeredLayers[topMostClickedOnLayerId].vif]
      };

      this._previousPopupDetails = properties;
      this._persistentPopupHandler.showFeaturePopup(options);
      this._existingPersistentDetails = properties;
    } else {
      this._persistentPopupHandler.removeFeaturePopup();
    }

    if (isSpiderfyLayerId && (hasMultipleFeatures || !isPoint)) {
      const renderFeatureInSpiderLeg = this._registeredLayers[topMostClickedOnLayerId].renderFeatureInSpiderLeg;

      this._hidePointsBehindMouseClick(featureConfigs);
      this._spiderfyHandler.spiderfy(featureConfigs, renderFeatureInSpiderLeg);

      this._renderMarker(topMostClickedOnFeature);
      this._currentlyHiddenFeatureConfigs = featureConfigs;
    } else {
      this._spiderfyHandler.unspiderfy();
    }

    if (_.includes(zoomOnClickLayerIds, topMostClickedOnLayerId)) {
      this._map.easeTo({ center: event.lngLat, zoom: this._map.getZoom() + 2 });
    }
  }

  // Event handler, gets called when mouse is moved in the mapboxgl map element.
  // If multiple points/stacks/clusters are displayed on the same location and
  // mouse is hovered over the common area, then on querying features at the mouse
  // location will have all those features. In that case, we take the first feature
  // and show tipsy for it.
  _onMouseMove = (event) => {
    const zoomOnClickLayerIds = this._getZoomOnClickLayerIds();
    const spiderfyOnClickLayerIds = this._getSpiderfyOnClickLayerIds();
    const popupOnMouseOverLayerIds = this._getPopupOnMouseOverLayerIds();
    const highlightLayerIds = this._getHighlightLayerIds();
    const isClickWithinSpiderify = $(event.originalEvent.target).closest('.spider-leg-container').length > 0;
    const mouseInteractableLayerIds = zoomOnClickLayerIds.
      concat(spiderfyOnClickLayerIds).
      concat(highlightLayerIds).
      concat(popupOnMouseOverLayerIds);

    if (_.isEmpty(mouseInteractableLayerIds)) {
      return;
    }

    if (isClickWithinSpiderify) {
      this._highlightHandler.removeHighlight();
      return;
    }

    const hoveredOverFeatures = this._getFeaturesAt(event.point, _.uniq(mouseInteractableLayerIds));

    if (_.isEmpty(hoveredOverFeatures)) {
      this._map.getCanvas().style.cursor = MOUSE_CURSOR_DEFAULT;
      this._highlightHandler.removeHighlight();
      this._popupHandler.removeFeaturePopup();
      return;
    }

    const topMostHoverOveredFeature = hoveredOverFeatures[0];
    const topMostHoverOveredLayerId = _.get(topMostHoverOveredFeature, 'layer.id');

    this._highlightHandler.highlight({
      highlightFeature: topMostHoverOveredFeature,
      highlightLayerId: highlightLayerIds,
      renderOptions: this._registeredLayers[topMostHoverOveredLayerId].renderOptions,
      vif: this._registeredLayers[topMostHoverOveredLayerId].vif
    });

    const properties = _.get(topMostHoverOveredFeature, 'properties');
    const popupOptions = this._getPopupOptions(event, topMostHoverOveredFeature, topMostHoverOveredLayerId);
    const hasMultipleFeatures = _.get(popupOptions, 'features', []).length > 1;
    const hoverOnClickableFeature = _.includes(zoomOnClickLayerIds, topMostHoverOveredLayerId) ||
      _.includes(spiderfyOnClickLayerIds, topMostHoverOveredLayerId);

    this._map.getCanvas().style.cursor = hoverOnClickableFeature ?
      MOUSE_CURSOR_HAND :
      MOUSE_CURSOR_DEFAULT;

    if (_.isEqual(this._previousPopupDetails, properties)) {
      if (this._existingPopup) {
        this._existingPopup.removeFeaturePopup();
        return;
      }
    }

    if (_.includes(popupOnMouseOverLayerIds, topMostHoverOveredLayerId) || hasMultipleFeatures) {
      this._popupHandler.showFeaturePopup(popupOptions);
      this._existingPopup = this._popupHandler;
    } else {
      this._popupHandler.removeFeaturePopup();
    }
  }

  _getLayerIdsBy(layerProperty) {
    return _.chain(this._registeredLayers).
      filter((layerOptions) => {
        return layerOptions[layerProperty] === true;
      }).
      map('layerId').
      value();
  }

  _getZoomOnClickLayerIds() {
    return this._getLayerIdsBy('shouldZoomOnClick');
  }

  _getSpiderfyOnClickLayerIds() {
    return this._getLayerIdsBy('shouldSpiderfyOnClick');
  }

  _getPopupOnMouseOverLayerIds() {
    return this._getLayerIdsBy('popupOnMouseOver');
  }

  _getHighlightLayerIds() {
    return this._getLayerIdsBy('highlight');
  }

  _getFeatureConfigs(topMostClickedOnFeature, topMostClickedOnLayerId, event) {
    const featuresToShowPopup = this._getPointOrStackFeaturesBehind(topMostClickedOnFeature, event);

    return _.chain(featuresToShowPopup).
      map((feature) => {
        const layerId = _.get(feature, 'layer.id');

        if (isPointOrStackLayer(layerId)) {
          const featureRenderOptions = this._registeredLayers[layerId].renderOptions;

          return {
            feature,
            renderOptions: featureRenderOptions,
            vif: this._registeredLayers[layerId].vif
          };
        }

        return null;
      }).
    compact().
    value();
  }

  _getPointOrStackFeaturesBehind(feature, event) {
    const featureLayerId = _.get(feature, 'layer.id');
    // We create a bbox square which is a subset of the feature's circle and get the features
    // that overlap the bbox. Thereby getting the features(circles) within the hovered feature.
    const featureCircleDiameter = getFeatureDiameter({
      featureProperties: feature.properties,
      resizeByRange: _.get(this._registeredLayers, [featureLayerId, 'renderOptions', 'resizeByRange']),
      aggregateAndResizeBy: _.get(this._registeredLayers, [featureLayerId, 'renderOptions', 'aggregateAndResizeBy']),
      vif: _.get(this._registeredLayers, [featureLayerId, 'vif'])
    });
    const popupPointOrStackLayers = _.chain(this._registeredLayers).
      filter((registration, layerId) => {
        return isPointOrStackLayer(layerId);
      }).
      map('layerId').
      value();

    return getFeaturesAroundLocation({
      map: this._map,
      layers: popupPointOrStackLayers,
      centerLatLngCoords: [event.lngLat.lng, event.lngLat.lat],
      pixelBoundingBoxSize: featureCircleDiameter * CIRCLE_DIAMETER_TO_BBOX_SQUARE_RATIO
    });
  }

  _getPopupOptions(event, topMostHoverOveredFeature, topMostHoverOveredLayerId) {
    let featuresToShowPopup = [];
    let renderOptionsToShowPopup = [];
    let vifsToShowPopup = [];

    if (isPointOrStackLayer(_.get(topMostHoverOveredFeature, 'layer.id'))) {
      featuresToShowPopup = this._getPointOrStackFeaturesBehind(topMostHoverOveredFeature, event);

      // If hovered over a point/stack, show details of all points/stacks below the hovered point/stack.
      // While hovering the map, mapbox returns all the features below the mouse pointer. But based on
      // which pixel the user is hovering over a circle, we might get different features. Thereby different
      // details in the popup when you hover over the same point/stack but on different area of it. To avoid
      // that we take the topmost hovered feature and get all the features below it.
      _.each(featuresToShowPopup, (feature, index) => {
        const layerId = _.get(feature, 'layer.id');

        renderOptionsToShowPopup.push(this._registeredLayers[layerId].renderOptions);
        vifsToShowPopup.push(this._registeredLayers[layerId].vif);
      });
    } else {
      featuresToShowPopup.push(topMostHoverOveredFeature);
      renderOptionsToShowPopup.push(this._registeredLayers[topMostHoverOveredLayerId].renderOptions);
      vifsToShowPopup.push(this._registeredLayers[topMostHoverOveredLayerId].vif);
    }

    return {
      event,
      features: featuresToShowPopup,
      renderOptions: renderOptionsToShowPopup,
      vifs: vifsToShowPopup
    };
  }

  _unhidePoints = () => {
    if (!_.isEmpty(this._currentlyHiddenFeatureConfigs)) {
      _.each(this._currentlyHiddenFeatureConfigs, (featureConfig) => {
        const { feature, renderOptions } = featureConfig;
        const layerId = _.get(feature, 'layer.id');
        const isPoint = _.get(feature, ['properties', renderOptions.countBy]) <= 1;

        if (isPoint) {
          this._registeredLayers[layerId].unhidePoints(layerId, renderOptions.countBy);
        }
      });
      this._currentlyHiddenFeatureConfigs = [];
    }
  }

  _hidePointsBehindMouseClick = (featureConfigs) => {
    const hidePointDetails = _.chain(featureConfigs).
      map((featureConfig) => {
        const { feature, renderOptions } = featureConfig;
        const layerId = _.get(feature, 'layer.id');
        const isPoint = _.get(feature, ['properties', renderOptions.countBy]) <= 1;
        const rowId = _.get(feature, ['properties', renderOptions.idBy]);

        if (isPoint) {
          return { layerId, renderOptions, rowId };
        }

        return null;
      }).
      compact().
      value();

    if (!_.isEmpty(hidePointDetails)) {
      _.chain(hidePointDetails).
        groupBy('layerId').
        each((hidePointConfigs) => {
          const layerId = hidePointConfigs[0].layerId;
          const renderOptions = hidePointConfigs[0].renderOptions;
          const rowIds = _.map(hidePointConfigs, 'rowId');

          if (!_.isNil(layerId) && !_.isEmpty(rowIds)) {
            this._highlightHandler.hideHighlightLayer();
            this._registeredLayers[layerId].hidePointsWithId(layerId, renderOptions, rowIds);
          }
        }).
        value();
    }
  }

  _renderMarker = (topMostClickedOnFeature) => {
    const topMostClickedOnLayerId = _.get(topMostClickedOnFeature, 'layer.id');
    if (!isPointOrStackLayer(topMostClickedOnLayerId)) {
      this._removeMarker();
      return;
    }

    const vif = this._registeredLayers[topMostClickedOnLayerId].vif;
    const renderOptions = this._registeredLayers[topMostClickedOnLayerId].renderOptions;
    const overlayOptions = this._registeredLayers[topMostClickedOnLayerId].overlayOptions;
    const  coordinates = _.get(topMostClickedOnFeature, 'geometry.coordinates', []);
    const $markerElement = $('<div>', { class: 'custom-marker' });
    const stackOutlineColor = vif.getStackOutlineColor(
      renderOptions.layerStyles,
      renderOptions.colorByBuckets,
    );
    const pointAndStackRadius = vif.getPointAndStackCircleRadiusPaintProperty(
      renderOptions.resizeByRange,
      renderOptions.aggregateAndResizeBy
    );
    const stackCircleRadius = overlayOptions.hasMultiplePointMapSeries ?
      overlayOptions.stackSize + DEFAULT_STACK_RADIUS_PADDING :
      pointAndStackRadius;
    const circleDiameter = (2 * stackCircleRadius) + DEFAULT_STACK_RADIUS_PADDING;
    $markerElement.css({
      'width': `${circleDiameter}px`,
      'height': `${circleDiameter}px`,
      'border': `${renderOptions.layerStyles.STACK_BORDER_SIZE}px solid ${stackOutlineColor}`,
      'background-color': '#ffffff'
    });

    // add marker to map
    this._marker = new mapboxgl.Marker($markerElement[0]).setLngLat(coordinates).addTo(this._map);
  }

  _removeMarker = () => {
    if (this._marker) {
      this._marker.remove();
    }
    $(this._map._container).find(`.${MAP_HIGHLIGHT_CONTAINER_CLASSNAME}`).css({ 'width': '', 'height': '' });
    $(this._map._container).find(`.${MAP_CANVAS_CLASSNAME}`).css('opacity', '');
  }
}

function hasStackingOptionsChanged(existingVif, newVif) {
  const existingClusteringZoomLevel = existingVif.getMaxClusteringZoomLevel();
  const newClusteringZoomLevel = newVif.getMaxClusteringZoomLevel();
  const existingStackRadius = existingVif.getStackRadius();
  const newStackRadius = newVif.getStackRadius();
  const existingMapOptions = _.get(existingVif, 'series[0].mapOptions');
  const newMapOptions = _.get(newVif, 'series[0].mapOptions');
  const existingColorOptions = _.get(existingVif, 'series[0].color');
  const newColorOptions = _.get(newVif, 'series[0].color');
  const existingFilters = _.get(existingVif, 'series[0].dataSource.filters');
  const newFilters = _.get(newVif, 'series[0].dataSource.filters');

  return !_.isEqual(existingClusteringZoomLevel, newClusteringZoomLevel) ||
    !_.isEqual(existingStackRadius, newStackRadius) ||
    !_.isEqual(existingMapOptions, newMapOptions) ||
    !_.isEqual(existingColorOptions, newColorOptions) ||
    !_.isEqual(existingFilters, newFilters);
}

function isPointOrStackLayer(layerId) {
  const layerName = MapHelper.getName(layerId);

  return (layerName === POINT_AND_STACK_LAYERS.POINTS_CIRCLE ||
    layerName === POINT_AND_STACK_LAYERS.STACKS_CIRCLE);
}
