import { isEqual } from 'lodash';
import { useMemo, useRef } from 'react';
import { ColDef } from '@ag-grid-community/core';

import { ViewColumn } from 'common/types/viewColumn';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';
import { Hierarchy, HierarchyColumnConfig, OrderConfig } from 'common/visualizations/vif';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import computeAgColumn from '../helpers/computeAgColumn';
import { FeatureFlags } from 'common/feature_flags';

type ColDefPropertyKey = keyof ColDef;
const GRID_CONTROLLED_PROP_KEYS: ColDefPropertyKey[] = [
  'flex',
  'sort',
  'sortIndex',
  'sortable',
  'cellRenderer',
  'headerClass',
  'cellStyle',
  'tooltipValueGetter',
  'valueGetter'
];

const areColDefsEquivalent = (a: ColDef | undefined, b: ColDef | undefined) => {
  if (a === b) return true;
  const keysA = a ? Object.keys(a) : [];
  const keysB = b ? Object.keys(b) : [];

  const localSkipKeys = [...GRID_CONTROLLED_PROP_KEYS];
  if (FeatureFlags.valueOrDefault('enable_flexible_table_hierarchies', false)) localSkipKeys.push('hide');

  for (const key of keysA) {
    if (localSkipKeys.includes(key as ColDefPropertyKey)) continue;
    if (!keysB.includes(key)) return false;
    if ([null, undefined].includes(a?.[key]) && [null, undefined].includes(b?.[key])) continue;
    if (!isEqual(a?.[key], b?.[key])) return false;
  }
  return true;
};

/**
 * Determines if the column definitions should be recreated by comparing the two lists of column definitions.
 * The comparison takes into account the order of the columns and the properties of each column.
 * @param listA
 * @param listB
 */
const shouldRecreateColDefs = (listA: ColDef[], listB: ColDef[]) => {
  if (listA.length !== listB.length) return true;

  for (let i = 0; i < listA.length; i++) {
    const column = listA[i];
    const b = listB[i];
    if (!areColDefsEquivalent(column, b)) return true;
  }

  return false;
};

interface IUseColDefsParams {
  columns: ViewColumn[];
  columnFormats: { [key: string]: TableColumnFormat };
  datasetUid: string;
  displayColumnFilters?: boolean;
  domain: string;
  hierarchyConfig?: Hierarchy;
  hierarchyColumns: HierarchyColumnConfig[];
  nonStandardAggregations?: ColumnAggregation[] | null;
  removedHierarchyColumnNames: Set<string>;
  showAgGridColumnAggregations?: boolean;
  showAgGridColumnMenu?: boolean;
  useSetFilters?: boolean;
  vifOrderConfig: OrderConfig[];
}
/**
 * useColDefs will return the same object reference as long as only column properties that are controlled
 * by AG Grid have been changed. Since AG Grid will automatically rerender itself when these properties change,
 * there's no need to cause a second rerender by passing a new object to the AgGridReact component's props.
 *
 * Preventing extra rerenders allows us to preserve most ephemeral changes the user has made to the table, and
 * generally reducing rerenders improves performance a little bit.
 *
 * @see GRID_CONTROLLED_PROP_KEYS for which properties are considered socrata controlled vs AG Grid controlled.
 *
 * @param props These are the parameters to computeAgColumn, and also the reactive values for the useMemo
 * @returns an array of ColDefs for AG Grid React's columnDefs prop
 */
const useColDefs = ({
  columns,
  columnFormats,
  datasetUid,
  displayColumnFilters,
  domain,
  hierarchyConfig,
  hierarchyColumns,
  nonStandardAggregations,
  removedHierarchyColumnNames,
  showAgGridColumnAggregations,
  showAgGridColumnMenu,
  useSetFilters,
  vifOrderConfig
}: IUseColDefsParams) => {
  const columnsRef = useRef<Array<ColDef>>([]);

  return useMemo(() => {
    const agColumns = columns
      .map((column) =>
        computeAgColumn({
          column,
          columnFormats,
          datasetUid,
          displayColumnFilters,
          domain,
          hierarchyColumns,
          hierarchyConfig,
          nonStandardAggregations,
          removedHierarchyColumnNames,
          showAgGridColumnAggregations,
          showAgGridColumnMenu,
          useSetFilters,
          vifOrderConfig
        })
      )
      .filter((column): column is ColDef => column !== null);

    if (shouldRecreateColDefs(columnsRef.current, agColumns)) {
      columnsRef.current = agColumns;
    }

    return columnsRef.current;
  }, [
    columns,
    columnFormats,
    datasetUid,
    displayColumnFilters,
    domain,
    hierarchyConfig,
    hierarchyColumns,
    nonStandardAggregations,
    removedHierarchyColumnNames,
    showAgGridColumnAggregations,
    showAgGridColumnMenu,
    useSetFilters,
    vifOrderConfig
  ]);
};

export default useColDefs;
