// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';

// Project Imports
import I18n from 'common/i18n';
import SocrataIcon, { IconName } from '../SocrataIcon';
import AddFilter from './AddFilter';
import ResetFiltersButton from './ResetFiltersButton';
import FilterItem from './FilterItem';
import ParameterItem from './ParameterItem';

import { Filters, FilterBarColumn, FilterBarPendoIds, FilterDataSource } from './types';
import {
  FilterItemType,
  FilterParameterConfiguration,
  ParameterConfiguration
} from 'common/types/reportFilters';

import { SoqlFilter } from './SoqlFilter';
import * as BaseFilter from './lib/Filters/BaseFilter';

import './index.scss';
import { SoQLType } from 'common/types/soql';
import migrateFilter from './lib/migrateFilter';
import { getRelevantColumnMetadata } from './lib/Filters/BaseFilter';

// Constants
// These are approximately the dimensions set by our CSS
// This needs to in sync with '$max-filter-width' in common/components/FilterBar/css/_filter-item.scss
// This var should be width + margin
const MAX_FILTER_WIDTH = 184;
const FILTER_CONFIG_TOGGLE_WIDTH = 30;

export interface DateFilterBarColumn extends FilterBarColumn {
  renderTypeName: SoQLType.SoQLFloatingTimestampAltT;
  /** the minimum time present in the column */
  rangeMax: string;
  /** the maximum time present in the column */
  rangeMin: string;
}

/** This can also encompass specially formatted numbers like money. */
export interface NumberFilterBarColumn extends FilterBarColumn {
  renderTypeName: SoQLType.SoQLNumberT;
  /** the minimum value present in the column */
  rangeMax: number;
  /** the maximum value present in the column */
  rangeMin: number;
}

export interface FilterBarColumns {
  /** All columns from this one dataset */
  [datasetUid: string]: FilterBarColumn[];
}

export interface FilterBarProps {
  /** Whether the Add Filter button is disabled */
  addFilterDisabled?: boolean;
  className?: string;
  /** All columns from all data sources */
  columns: FilterBarColumns;
  /** All computed columns from the first data source */
  computedColumns: FilterBarColumn[];
  editMode: boolean;
  /**
   * This disables all controls in the filter bar, including the Add Filter button,
   * even if addFilterDisabled is false.
   */
  disabled?: boolean;
  /**
   * The message to display in a flyout over the Add Filter button when the FilterBar is disabled.
   * This has no effect if the FilterBar is not disabled.
   */
  disabledMessage?: string;
  /**
   * This is an array of filter and parameter items that will be rendered. Each item is
   * structured according to the VIF specification. The set of rendered controls will always
   * reflect the contents of this array.
   */
  filterParameterConfigurations?: FilterParameterConfiguration[];

  /**
   * Whether to display the filter bar's settings, including the option to add new filters and
   * individual filter settings. If this is set to true and none of the provided filters are
   * visible, the FilterBar will not render anything. Defaults to true.
   *
   * NOTE: Even if 'isReadOnly' is set to true, the parameters of individual, non-hidden filters
   * will still be changeable by users.
   */
  isReadOnly?: boolean;
  /**
   * The onUpdate prop is an optional function that will be called whenever the set of filters has
   * changed.  This may happen when a filter is added, a filter is removed, or the parameters of a
   * filter have changed.  The function is passed the new set of filters.  The consumer of this
   * component is expected to respond to the event by rerendering this component with the new
   * updated "filters" prop.  Any filters that do not have any criteria applied will have a filter
   * function of "noop".
   */
  onUpdate: (newFilters: Filters) => void;
  /**
   * onUpdateSingleFilterParameter accepts a FilterParameterConfiguration and an index. It updates
   * either a single filter or single a parameter.
   */
  onUpdateSingleFilterParameter?: (updated: FilterParameterConfiguration, index: number) => void;
  /**
   * onUpdateAllFilterParameters Updates all FilterParameterConfigurations. This is called when the
   * clear all button is clicked and all the filters are reset to their defaults. Note that all filters
   * and parameters are included in the updated array.
   */
  onUpdateAllFilterParameters: (updated: FilterParameterConfiguration[]) => void;
  /**
   * Constraints constrain filtering/autocompletion in the filter items.
   *   - geoSearch: reduces the RadiusFilter to suggest values within the given geoSearch boundary.
   * example: {
   *     geoSearch: { boundary: [0, 0, 10, 10] },
   * }
   */
  constraints?: {
    geoSearch: {
      boundary: number[];
    };
  };
  /**
   * Called whenever
   * - a FilterItem is opened/closed
   * - a filter is updated via the opened FilterItem.
   *
   * This is used to show the radius filters as a circle on the map when the user opens the
   * radius filter item in the filter bar. Also to update the circle on the map, when the user
   * updates the radius or the center of the radius filter.
   */
  onInEditFilterChange: (openFilter: SoqlFilter | null) => void;
  /**
   * Indicates whether to show all filters or to show only the first x filters that fits into one
   * row of the filterbar along with 'Show More filters' button.
   */
  showAllFilters?: boolean;
  /**
   * Indicates whether CheckboxFilter should display the null option with a display string of
   * "False", and omit the strictly false option. This is detailed in EN-32483.
   */
  showNullsAsFalse?: boolean;
  dataSource: FilterDataSource[];
  /**
   * Whether to render addFilter button
   */
  shouldRenderAddFilter?: boolean;
  /**
   * Whether to render the remove button in the filter editor
   */
  showRemoveButtonInFilterEditor?: boolean;

  /**
   * Whether to maintain the `columnName` field on filters.
   * TODO EN-63868: Remove this when removing references to `columnName`
   */
  shouldMaintainColumnName?: boolean;

  /**
   * Strings to use as the id property for certain controls.
   * These are passed in to allow callers to customize them based on context for Pendo.
   */
  pendoIds?: FilterBarPendoIds;
}

interface FilterBarState {
  isExpanded: boolean;
  maxVisibleFilters: number;
  newFilterAdded: boolean;
  maxFiltersToggleWidth: number;
  columnsMap: BaseFilter.FilterColumnsMap;
}

/**
 * FilterBar
 * The FilterBar component renders a set of controls that are intended to allow users to apply
 * customized sets of filters to datasets and visualizations.  Eventually, if the user accessing the
 * component has an admin or publisher role, the FilterBar will expose additional functionality,
 * allowing the user to create new filters and add restrictions on how they are used.
 */
export class FilterBar extends Component<FilterBarProps, FilterBarState> {
  static defaultProps = {
    addFilterDisabled: false,
    disabled: false,
    filterParameterConfigurations: [],
    constraints: {},
    className: '',
    computedColumns: {},
    isReadOnly: true,
    onUpdate: _.noop,
    onUpdateSingleFilterParameter: _.noop,
    onUpdateAllFilterParameters: _.noop,
    onParameterUpdate: _.noop,
    onInEditFilterChange: _.noop,
    showAllFilters: false,
    showNullsAsFalse: false,
    shouldRenderAddFilter: false,
    showRemoveButtonInFilterEditor: false,
    // TODO EN-63868: Remove this when removing references to `columnName`
    shouldMaintainColumnName: true
  };

  container: HTMLElement | null;
  addFilter: HTMLElement | null;
  leftSideControls: HTMLElement | null;
  filterIcon: HTMLElement | null;
  expandControl: HTMLElement | null;

  constructor(props: FilterBarProps) {
    super(props);

    this.state = {
      isExpanded: false,
      maxVisibleFilters: props.showAllFilters ? Infinity : 0,
      newFilterAdded: false,
      maxFiltersToggleWidth: 0,
      columnsMap: {}
    };

    _.bindAll(this, [
      'getContainerWidth',
      'getControlsWidth',
      'getFilterItem',
      'getParameterItem',
      'onFilterAdd',
      'onFilterRemove',
      'onFilterUpdate',
      'onParameterUpdate',
      'onToggleFilterItemControl',
      'onToggleCollapsedFilters',
      'onWindowResize',
      'renderAddFilter',
      'renderFilterCount',
      'renderFilterIcon',
      'renderExpandControl',
      'renderVisibleFilters',
      'renderCollapsedFilters',
      'setMaxVisibleFilters'
    ]);
  }

  componentDidMount() {
    this.setMaxVisibleFilters();

    window.addEventListener('resize', this.onWindowResize);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: FilterBarProps) {
    const { filterParameterConfigurations } = this.props;

    if (nextProps.isReadOnly !== this.props.isReadOnly) {
      this.setState({ isExpanded: false });
    }

    // Track if a new filter was added
    let trackNewFilterAdded = false;
    if (
      filterParameterConfigurations &&
      nextProps.filterParameterConfigurations &&
      filterParameterConfigurations.length < nextProps.filterParameterConfigurations.length
    ) {
      trackNewFilterAdded = true;
    }
    this.setState({ newFilterAdded: trackNewFilterAdded });
  }

  componentDidUpdate(prevProps: FilterBarProps) {
    this.setMaxVisibleFilters();

    const { filterParameterConfigurations, isReadOnly } = this.props;

    if (!isReadOnly) {
      if (filterParameterConfigurations && prevProps.filterParameterConfigurations) {
        // if we've added a filter, we need to focus on the new filter
        if (
          prevProps.filterParameterConfigurations.length < filterParameterConfigurations.length &&
          this.container
        ) {
          // the new filter should be the last rendered filter (there should be at least one rendered
          // filter if we get to this point)
          (_.last(this.container.querySelectorAll('.filter-control-toggle')) as HTMLElement)?.focus();

          // otherwise we should focus on the add filter button
        } else if (
          prevProps.filterParameterConfigurations.length > filterParameterConfigurations.length &&
          this.addFilter
        ) {
          // we should always have an Add Filter button when isReadOnly is false
          this.addFilter.querySelector('button')?.focus();
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  getFilterItem(filter: SoqlFilter, index: number) {
    const {
      columns,
      computedColumns,
      constraints,
      dataSource,
      disabled,
      filterParameterConfigurations,
      isReadOnly,
      pendoIds,
      showNullsAsFalse,
      showRemoveButtonInFilterEditor
    } = this.props;
    if (isReadOnly && filter.isHidden) {
      return null;
    }

    const filterColumns = getRelevantColumnMetadata(filter, columns, computedColumns);

    if (_.isEmpty(filterColumns)) {
      return null;
    }

    const migratedFilter = migrateFilter(filter, filterColumns);
    if (migratedFilter === null) {
      return null;
    }

    const allFilters: SoqlFilter[] = [];
    const allParameters: ParameterConfiguration[] = [];
    if (filterParameterConfigurations) {
      filterParameterConfigurations.forEach((item) => {
        if (item.type === FilterItemType.FILTER) {
          allFilters.push(item.config as SoqlFilter);
        } else if (item.type === FilterItemType.PARAMETER) {
          allParameters.push(item.config as ParameterConfiguration);
        }
      });
    }

    const props = {
      appliedFilter: migratedFilter,
      columns: filterColumns,
      constraints,
      disabled,
      allFilters,
      allParameters,
      isReadOnly,
      showNullsAsFalse,
      dataProvider: dataSource,
      onRemove: _.partial(this.onFilterRemove, index),
      onUpdate: _.partialRight(this.onFilterUpdate, index),
      onToggleControl: _.partial(this.onToggleFilterItemControl, migratedFilter),
      showRemoveButtonInFilterEditor: showRemoveButtonInFilterEditor,
      pendoIds
    };

    return <FilterItem key={index} {...props} />;
  }

  getParameterItem(parameter: ParameterConfiguration, index: number) {
    const { disabled, editMode, pendoIds } = this.props;

    const props = {
      editMode,
      parameter,
      disabled,
      onParameterUpdate: _.partialRight(this.onParameterUpdate, index),
      pendoIds
    };

    return <ParameterItem key={parameter.paramIds[0].datasetUid + parameter.paramIds[0].name} {...props} />;
  }

  mapFilterOrParameterObjects() {
    const { filterParameterConfigurations } = this.props;

    return _.compact(
      (filterParameterConfigurations ?? []).map(({ type, config }, index) => {
        if (type === FilterItemType.FILTER) {
          config = config as SoqlFilter;
          return this.getFilterItem(config, index);
        } else if (type === FilterItemType.PARAMETER) {
          config = config as ParameterConfiguration;
          if (config.isHidden) {
            return null;
          }
          return this.getParameterItem(config, index);
        }
      })
    );
  }

  onFilterAdd(filter: SoqlFilter) {
    const { filterParameterConfigurations, onUpdate, shouldMaintainColumnName } = this.props;
    const { maxVisibleFilters } = this.state;
    // This clone is very important. See comment in onFilterUpdate.
    const newFilters =
      _.cloneDeep(filterParameterConfigurations)?.map((item) => item.config as SoqlFilter) || [];

    // Add `columnName` so that we can reuse the same filter objects between
    // SingleSourceFilterBar and FilterBar.
    // TODO EN-63868: Remove this when removing references to `columnName`
    if (shouldMaintainColumnName && !filter.columnName) {
      filter.columnName = filter.columns[0].fieldName ?? '';
    }

    newFilters.push(filter);
    onUpdate(newFilters);

    // Number of visible filters and parameters
    const itemCount = _.size(newFilters);

    if (itemCount > maxVisibleFilters) {
      this.setState({
        isExpanded: true
      });
    }
  }

  onToggleFilterItemControl(filter: SoqlFilter, isOpened: boolean) {
    const { onInEditFilterChange } = this.props;

    onInEditFilterChange(isOpened ? filter : null);
  }

  onFilterRemove(index: number) {
    const { filterParameterConfigurations, onUpdate, onUpdateAllFilterParameters } = this.props;
    // This clone is very important. See comment in onFilterUpdate.
    const newFilters =
      _.cloneDeep(filterParameterConfigurations)?.map((item) => item.config as SoqlFilter) || [];
    const newFilterParameters = _.cloneDeep(filterParameterConfigurations);

    newFilters.splice(index, 1);
    newFilterParameters?.splice(index, 1);

    onUpdate(newFilters);
    onUpdateAllFilterParameters(newFilterParameters || []);
  }

  onFilterUpdate(filter: SoqlFilter, index: number) {
    const {
      filterParameterConfigurations,
      onInEditFilterChange,
      onUpdate,
      onUpdateSingleFilterParameter,
      shouldMaintainColumnName
    } = this.props;
    // This clone is _very important_. We obtained `filters`
    // from our parent (via props), and we don't want to mutate
    // their copy of `filters`. If we do, it makes tracking state
    // changes via Redux quite difficult.
    const newFilters =
      _.cloneDeep(filterParameterConfigurations)?.map((item) => item.config as SoqlFilter) || [];
    newFilters.splice(index, 1, filter);

    // Add `columnName` so that we can reuse the same filter objects between
    // SingleSourceFilterBar and FilterBar.
    // TODO EN-63868: Remove this when removing references to `columnName`
    if (shouldMaintainColumnName && !filter.columnName) {
      filter.columnName = filter.columns[0].fieldName ?? '';
    }

    onInEditFilterChange(filter);
    onUpdate(newFilters);

    if (onUpdateSingleFilterParameter && filter) {
      const updated: FilterParameterConfiguration = {
        type: FilterItemType.FILTER,
        config: filter
      };
      onUpdateSingleFilterParameter(updated, index);
    }
  }

  onParameterUpdate(updatedParameter: ParameterConfiguration, index: number) {
    const { onUpdateSingleFilterParameter } = this.props;

    if (onUpdateSingleFilterParameter && updatedParameter) {
      const updated: FilterParameterConfiguration = {
        type: FilterItemType.PARAMETER,
        config: updatedParameter
      };
      onUpdateSingleFilterParameter(updated, index);
    }
  }

  onToggleCollapsedFilters() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  onWindowResize() {
    this.setMaxVisibleFilters();

    if (this.state.newFilterAdded) {
      this.setState({
        newFilterAdded: false
      });
    }
  }

  getContainerWidth() {
    if (!this.container) {
      return 0;
    }

    const styles = window.getComputedStyle(this.container);
    const containerPadding = _.parseInt(styles.paddingLeft) + _.parseInt(styles.paddingRight);

    // Note that clientWidth does not include borders or margin. The FilterBar currently doesn't
    // have borders, but this could potentially throw our calculations off in the future if a
    // border is added.
    return this.container.clientWidth - containerPadding;
  }

  getControlsWidth() {
    const { maxFiltersToggleWidth } = this.state;
    const addFilterWidth = this.addFilter ? this.addFilter.offsetWidth : 0;
    const filterIconWidth = this.filterIcon ? this.filterIcon.offsetWidth : 0;
    const currentToggleWidth = this.expandControl ? this.expandControl.offsetWidth : 0;
    const leftSideControlsWidth = this.leftSideControls ? this.leftSideControls.offsetWidth : 0;

    // Keeping track of the longer word used for the toggle - 'more' or 'less' (which may vary depending on
    // locale) so that the max number of visible filters doesn't jump back and forth when you toggle visibility.
    const maxToggleWidth = _.max([currentToggleWidth, maxFiltersToggleWidth])!;

    if (currentToggleWidth > maxFiltersToggleWidth) {
      this.setState({
        maxFiltersToggleWidth: currentToggleWidth
      });
    }

    const isLeftSideInline = this.leftSideControls
      ? window.getComputedStyle(this.leftSideControls).display === 'inline-block'
      : false;

    return isLeftSideInline
      ? addFilterWidth + filterIconWidth + maxToggleWidth + leftSideControlsWidth
      : addFilterWidth + filterIconWidth + maxToggleWidth;
  }

  setMaxVisibleFilters() {
    const { isReadOnly, showAllFilters } = this.props;

    if (showAllFilters) {
      if (this.state.maxVisibleFilters === Infinity) {
        return;
      }
      return this.setState({
        maxVisibleFilters: Infinity
      });
    }

    const { maxVisibleFilters } = this.state;

    const containerWidth = this.getContainerWidth();
    const spaceLeftForFilters = containerWidth - this.getControlsWidth();

    const filterWidth = isReadOnly ? MAX_FILTER_WIDTH : MAX_FILTER_WIDTH + FILTER_CONFIG_TOGGLE_WIDTH;
    const newMaxVisibleFilters = _.floor(spaceLeftForFilters / filterWidth);

    if (containerWidth > 0 && maxVisibleFilters !== newMaxVisibleFilters) {
      this.setState({
        maxVisibleFilters: newMaxVisibleFilters
      });
    }
  }

  renderAddFilter() {
    const {
      addFilterDisabled,
      columns,
      computedColumns,
      dataSource,
      disabled,
      disabledMessage,
      filterParameterConfigurations,
      isReadOnly
    } = this.props;

    const availableColumns = _.reject(columns[dataSource[0].datasetUid], (column) => {
      return _.some(filterParameterConfigurations, (filter) => {
        return !!_.find((filter.config as SoqlFilter).columns, ['fieldName', column.fieldName]);
      });
    });

    const availableComputedColumns = _.reject(computedColumns, (column) => {
      return _.some(filterParameterConfigurations, (filter) => {
        return !!_.find((filter.config as SoqlFilter).columns, ['fieldName', column.fieldName]);
      });
    });

    const props = {
      columns: availableColumns,
      computedColumns: availableComputedColumns,
      disabled: addFilterDisabled || disabled,
      disabledMessage,
      onClickColumn: (column: FilterBarColumn) => {
        this.onFilterAdd(BaseFilter.getNoopFilter({ [dataSource[0].datasetUid]: column }));
      }
    };

    return (
      !isReadOnly && (
        <span className="add-filter-container" ref={(ref) => (this.addFilter = ref)}>
          <span className="add-filter-spacer"></span>
          <AddFilter {...props} />
        </span>
      )
    );
  }

  renderResetFiltersButton = () => {
    const {
      columns,
      isReadOnly,
      filterParameterConfigurations,
      onUpdate,
      onUpdateAllFilterParameters,
      pendoIds
    } = this.props;
    const resetFiltersButtonAttributes = {
      filterParameterConfigurations,
      columns,
      isReadOnly,
      onReset: onUpdate,
      onResetAll: onUpdateAllFilterParameters,
      pendoIds
    };

    return (
      <span className="reset-filter-container">
        <span className="reset-filter-spacer"></span>
        <ResetFiltersButton {...resetFiltersButtonAttributes} />
      </span>
    );
  };

  renderFilterIcon() {
    const { isReadOnly } = this.props;

    const icon = (
      <div className="filter-icon" ref={(ref) => (this.filterIcon = ref)}>
        <SocrataIcon name={IconName.Filter} />
      </div>
    );

    return isReadOnly ? icon : null;
  }

  renderExpandControl() {
    const { isReadOnly, filterParameterConfigurations, pendoIds } = this.props;
    const { isExpanded, maxVisibleFilters } = this.state;

    const renderableFiltersParameters = _.reject(
      filterParameterConfigurations,
      ({ config }) => isReadOnly && config.isHidden
    );

    const totalRenderableItems = _.size(renderableFiltersParameters);

    const text = isExpanded
      ? I18n.t('shared.components.filter_bar.less')
      : I18n.t('shared.components.filter_bar.more');
    const classes = classNames('btn btn-transparent btn-expand-control', {
      'is-hidden': totalRenderableItems <= maxVisibleFilters
    });

    const iconName = isExpanded ? IconName.ArrowUp : IconName.ArrowDown;

    return (
      <button
        id={pendoIds?.expandButton}
        data-testid="filter-bar-expand-button"
        aria-expanded={isExpanded ? 'true' : 'false'}
        className={classes}
        onClick={this.onToggleCollapsedFilters}
        ref={(ref) => (this.expandControl = ref)}
      >
        {text}
        <SocrataIcon name={iconName} />
      </button>
    );
  }

  renderVisibleFilters(filterItems: JSX.Element[]) {
    const { maxVisibleFilters, newFilterAdded } = this.state;
    const filters = _.take(filterItems, maxVisibleFilters);

    const filterTransitions = filters.map((filter, i) => (
      <CSSTransition
        key={i}
        in={true}
        classNames="filters"
        timeout={{ enter: 1000, exit: 1 }}
        enter={newFilterAdded}
        leave={false}
      >
        {filter}
      </CSSTransition>
    ));

    return (
      <div className="visible-filters-container">
        <TransitionGroup>{filterTransitions}</TransitionGroup>
      </div>
    );
  }

  renderCollapsedFilters(filterItems: JSX.Element[]) {
    const { maxVisibleFilters, newFilterAdded } = this.state;

    const filters = _.drop(filterItems, maxVisibleFilters);

    const filterTransitions = filters.map((filter, i) => (
      <CSSTransition
        key={i}
        in={true}
        classNames="filters"
        timeout={{ enter: 1000, exit: 1 }}
        enter={newFilterAdded}
        leave={false}
      >
        {filter}
      </CSSTransition>
    ));

    return (
      <div className="collapsed-filters-container">
        <TransitionGroup>{filterTransitions}</TransitionGroup>
      </div>
    );
  }

  renderFilterCount(filterItems: JSX.Element[]) {
    const filtersWithArgumentsCount = _.chain(filterItems)
      .filter((filterItem) => !_.isEmpty(filterItem.props?.appliedFilter?.arguments))
      .size()
      .value();

    const nonHiddenParametersCount = _.chain(filterItems)
      .filter((filterItem) => filterItem.props?.parameter?.isHidden === false)
      .size()
      .value();

    const totalCount = filtersWithArgumentsCount + nonHiddenParametersCount;
    const filtersCount = totalCount === 0 ? '' : `(${totalCount})`;

    return (
      <span className="filter-bar-filter-count">
        {I18n.t('shared.components.filter_bar.title')} {filtersCount}
      </span>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const { className, isReadOnly, shouldRenderAddFilter } = this.props;

    const allItems = this.mapFilterOrParameterObjects();
    const filterCountItems = allItems;

    if (isReadOnly && _.isEmpty(allItems)) {
      return null;
    }

    const containerProps = {
      className: classNames(
        'filter-bar-container',
        {
          'filter-bar-expanded': isExpanded,
          'filter-bar-read-only': isReadOnly
        },
        className
      ),
      ref: (ref: HTMLDivElement) => (this.container = ref)
    };

    return (
      <div {...containerProps}>
        {this.renderFilterIcon()}
        <div className="filters-controls-left-side" ref={(ref) => (this.leftSideControls = ref)}>
          {this.renderFilterCount(filterCountItems)}
          {this.renderResetFiltersButton()}
        </div>
        {shouldRenderAddFilter && <div className="filters-controls-right-side">{this.renderAddFilter()}</div>}

        {this.renderVisibleFilters(allItems)}
        {this.renderExpandControl()}
        {this.renderCollapsedFilters(allItems)}
      </div>
    );
  }
}

export default FilterBar;
