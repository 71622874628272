/**
 * This file contains type definitions used for the filter bar, including definitions for the
 * Global Filter Bar, Filters and Parameters.
 */
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
import { SoQLType } from 'common/types/soql';
import { View } from 'common/types/view';
import { ViewColumn } from 'common/types/viewColumn';
import type { ClientContextVariable } from 'common/types/clientContextVariable';

export interface SingleDataSource {
  datasetUid: string;
  parameterOverrides?: ParameterOverridesData;
}

export interface ParameterOverridesData {
  /** paramKey should be in the form `${parameter.name}.${parameter.viewId}` */
  [paramKey: string]: {
    paramDataType: string;
    paramOverrideValue: string;
  };
}

export interface ReportDataSource {
  dataSourceList: SingleDataSource[];
  filterConfigurations?: SoqlFilter[];
  dataSourceMetadata?: DataSourceMetadata;
  filterParameterConfigurations?: FilterParameterConfiguration[];
}

export interface ViewWithColumnDictionary extends Omit<View, 'columns'> {
  columns: { [fieldName: string]: ViewColumn };
}

/**
 * Stores the view for all datasources in a story, including datasources from vizualizations and
 * the Global Filter Bar. This is only used in the DataSourceReducer.
 */
export interface DataSourceMetadata {
  [datasetUid: string]: ViewWithColumnDictionary;
}

/**
 * Stores the client context variables for all datasources in a story, including datasources from
 * visualizations and the Global Filter Bar. This is only used in the DataSourceReducer.
 */
export interface DataSourceCCVs {
  [datasetUid: string]: ClientContextVariable[];
}

interface FilterParameterConfigurationBase {
  type: FilterItemType;
}

interface ParameterItem extends FilterParameterConfigurationBase {
  type: FilterItemType.PARAMETER;
  config: ParameterConfiguration;
}

export interface FilterItem extends FilterParameterConfigurationBase {
  type: FilterItemType.FILTER;
  config: SoqlFilter;
}

export type FilterParameterConfiguration = ParameterItem | FilterItem;

export enum FilterItemType {
  PARAMETER = 'parameter',
  FILTER = 'filter'
}

/** Minimum information needed to identify a client context variable from a data source. */
export interface DataSourceParameter {
  name: string;
  datasetUid: string;
  inheritedFromUid?: string;
}

/** Configuration of a parameter to be used in the FilterBar */
export interface ParameterConfiguration {
  displayName: string;
  isHidden: boolean;
  dataType: SoQLType;
  overrideValue: string;
  paramIds: DataSourceParameter[];
}
