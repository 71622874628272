import { compact, has, isEmpty } from 'lodash';
import {
  BinaryTree, Hint, Distinct, isCompound, isLeaf,
  UnAnalyzedAst, UnAnalyzedJoin
} from 'common/types/soql';
import { renderBinaryTree } from '../binaryTree';
import { renderDistinct } from '../distinct';
import { renderExpr } from '../expr';
import { renderJoin } from '../join';
import { renderUnAnalyzedSelectedExpression as renderSelection } from '../select';
import { renderOrderBy } from '../orderBy';
import { renderTableName } from '../tableName';
import { asString } from '../util';

const renderHints = (hints: Hint[]): string | null => {
  if (hints.length > 0) {
    return `HINT(${hints.join(', ')})`;
  } else {
    return null;
  }
};

const renderSimpleQuery = (ast: UnAnalyzedAst): string => {
  const query = [];

  query.push(compact([
    'SELECT',
    renderHints(ast.hints),
    renderDistinct(ast.distinct),
    ast.selection.exprs.map(renderSelection).join(', ')
  ]).join(' '));

  if (ast.from) {
    query.push(`FROM ${renderTableName(ast.from)}`);
  }

  if (ast.joins.length > 0) {
    query.push(ast.joins.map(renderJoin).join(' '));
  }

  if (ast.where) {
    query.push(`WHERE ${renderExpr(ast.where)}`);

    // to allow interop between the old string-based filter => where helpers
    if (ast.legacyWhereClause) {
      query.push('AND', ast.legacyWhereClause);
    }
  } else if (ast.legacyWhereClause) {
    query.push(`WHERE ${ast.legacyWhereClause}`);
  }

  if (!isEmpty(ast.group_bys)) {
    query.push(`GROUP BY ${ast.group_bys.map(renderExpr).join(', ')}`);
  }

  if (ast.having && !isEmpty(ast.having)) {
    query.push(`HAVING ${renderExpr(ast.having)}`);
  }

  if (ast.search) {
    query.push(`SEARCH ${asString(ast.search)}`);
  }

  if (!isEmpty(ast.order_bys)) {
    query.push(`ORDER BY ${ast.order_bys.map(renderOrderBy).join(', ')}`);
  }

  if (ast.limit) {
    query.push(`LIMIT ${ast.limit}`);
  }

  if (ast.offset) {
    query.push(`OFFSET ${ast.offset}`);
  }

  return query.join(' ');
};

const isUnanalyzedAst = (arg: UnAnalyzedAst | unknown): arg is UnAnalyzedAst => has(arg, 'selection');
export const renderQuery = (ast: UnAnalyzedAst | BinaryTree<UnAnalyzedAst>): string => renderBinaryTree<UnAnalyzedAst>(ast, isUnanalyzedAst, renderSimpleQuery);
