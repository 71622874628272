// Vendor Imports
import $ from 'jquery';
import _ from 'lodash';
import DataTypeFormatter from 'common/DataTypeFormatter';

export function getColumnFormats(columns) {
  return _.reduce(columns, (acc, column) => {
    acc[column.fieldName] = _.pick(column, [
      'fieldName', 'name', 'dataTypeName', 'renderTypeName', 'format'
    ]);
    return acc;
  }, {});
}

export function createMoneyFormatter(column, dataToRender) {
  const formatInfo = _.cloneDeep(_.get(dataToRender, `columnFormats.${column}`, {}));
  // Using fixed precision caused rounding errors, so we are letting precision
  // get determined by the general renderNumberCellHTML.
  delete formatInfo.format.precision;
  formatInfo.format.forceHumane = true;
  const formatter = (value) => {
    const formattedValue = DataTypeFormatter.renderNumberCellHTML(value, formatInfo);
    const currencySymbol = DataTypeFormatter.getCurrencySymbol(formatInfo.format);
    return `${currencySymbol}${formattedValue}`;
  };

  return formatter;
}

// Formats a value from the dataset for rendering within the chart as HTML.
export function formatValueHTML(value, columnName, dataToRender, forceHumane = false) {
  // NOTE: Seems kind of expensive to do a cloneDeep on every single format call.
  const formatInfo = _.cloneDeep(_.get(dataToRender, `columnFormats.${columnName}`, {}));
  return formatValueHTMLWithFormatInfo({
    dataToRender,
    forceHumane,
    formatInfo,
    value
  });
}

export function formatValueHTMLWithFormatInfo({
  dataToRender,
  forceHumane,
  formatInfo,
  value
}) {
  if (!formatInfo.renderTypeName) {
    formatInfo.renderTypeName = 'number';
  }

  if (forceHumane) {
    // There are certain circumstances where we want to always abbreviate
    // numbers (with the "humane" suffixes K, M, B, T, etc.); however, our
    // existing humane number formatter doesn't understand how to use column
    // formatting concepts like currency or percents. So instead, I decided to
    // add support for forcing "humane" formatting in DataTypeFormatter.
    // IMPORTANT: forceHumane is the only formatting property which is purely
    // determined by our application code (i.e. not directly set by a user).
    _.set(formatInfo, 'format.forceHumane', true);
  }

  if (dataToRender.dateDisplayFormat && (formatInfo.dataTypeName === 'calendar_date')) {
    _.set(formatInfo, 'format.formatString', dataToRender.dateDisplayFormat);
  }

  return DataTypeFormatter.renderCellHTML(value, formatInfo);
}

// Formats a value from the dataset for rendering within the chart as plain text.
export function formatValuePlainText(value, columnName, dataToRender, forceHumane = false) {
  // NOTE: Seems kind of expensive to do a cloneDeep on every single format call.
  const formatInfo = _.cloneDeep(_.get(dataToRender, `columnFormats.${columnName}`, {}));
  return formatValuePlainTextWithFormatInfo({
    dataToRender,
    forceHumane,
    formatInfo,
    value
  });
}

export function formatValuePlainTextWithFormatInfo({
  dataToRender,
  forceHumane = false,
  formatInfo,
  value
}) {
  // This is a very brain-dead solution. However, it works safely and keeps us
  // from maintaining separate plain/html renderers for each column type.
  return $('<div>').
    html(
      formatValueHTMLWithFormatInfo({
        dataToRender,
        forceHumane,
        formatInfo,
        value
      })
    ).
    text();
}

export function formatDatasetValue(cellContent, column, domain, datasetUid) {
  if (_.isNil(column)) {
    return cellContent;
  }

  return DataTypeFormatter.renderCellHTML(
    cellContent,
    column,
    domain,
    datasetUid
  );
}

export function getColumnFormatWithPrecision(columnDetails, precision) {
  const columnFormat = _.cloneDeep(columnDetails);
  if (!_.isNil(precision)) {
    _.set(columnFormat, 'format.precision', precision);
  }

  return columnFormat;
}
