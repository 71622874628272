// Vendor Imports
import _ from 'lodash';
import $ from 'jquery';

import { assert, assertInstanceOf, assertIsOneOfTypes } from 'common/assertions';
import Table from './views/Table';
import Pager from './views/Pager';

import { migrateVif } from 'common/visualizations/helpers/migrateVif';

import {
  assertCellsCountInRows,
  computePagerOptions,
  computePageSize,
  padOrTrimRowsToFitPageSize
} from 'common/visualizations/helpers/TableHelpers';

import MetadataProvider from 'common/visualizations/dataProviders/MetadataProvider';

import {
  getInlineDataRowCount,
  getSoqlDataRowCount,
  getRawSoqlRowCount,
  getSocrataViewRowCount,
  setInlineDataQuery,
  setRawSoqlDataQuery,
  setSoqlDataQuery,
  setSocrataViewDataQuery
} from 'common/visualizations/helpers/TableDataHelpers';

// Passing in locale is a temporary workaround to localize the Table & Pager
$.fn.socrataTable = function(originalVif, options) {
  originalVif = migrateVif(_.cloneDeep(originalVif));

  const $element = $(this);
  const locale = _.get(options, 'locale');
  const pagingEnabled = _.get(options, 'pagingEnabled', true);
  let visualization = $element.data('visualization');
  let pager = $element.data('pager');

  // Holds all state regarding the table's visual presentation.
  // Do _NOT_ update this directly, use setState() or updateState().
  // This is to ensure all state changes are reflected in the UI.
  let renderState = $element.data('renderState') || {
    vif: null,
    // Is the table busy?
    busy: false,
    // Did we freak out somewhere trying to get data?
    error: false,
    errorType: null,
    // Holds result of last successful data fetch, plus
    // the metadata regarding that request (start index,
    // order, etc).
    // {
    //   rows: <data from SoqlDataProvider or InlineDataProvider>,
    //   rowIds: <data from SoqlDataProvider or InlineDataProvider>,
    //   columns: <data from SoqlDataProvider or InlineDataProvider>,
    //   datasetMetadata: <data from SoqlDataProvider>,
    //   startIndex: index of first row (offset),
    //   pageSize: number of items in page (not necessarily in rows[]).
    //   order: {
    //     [ // only one element supported.
    //       {
    //         columnName: <name of column to sort by>,
    //         ascending: boolean
    //       }
    //     ]
    //   }
    // }
    fetchedData: null,
    datasetRowCount: null,
    fetchingRowCount: false,
    collocating: false,
    userChangedOrder: false
  };

  function initialize() {
    if (visualization) {
      return;
    }
    renderState.vif = originalVif;

    $element.addClass('socrata-paginated-table');

    visualization = new Table($element, originalVif, options);
    $element.data('visualization', visualization);

    // We need to instantiate and render the Pager on initialization so that we
    // can reliably determine how much space is available to fill with table
    // rows when we call computePageSize below.
    //
    // Note that the Pager will not actually modify the DOM until you actually
    // call render, so we do that as well.
    pager = new Pager($element.find('.socrata-visualization-container'), locale);
    $element.data('pager', pager);
    pager.render(computePagerOptions(renderState));

    // Note that we do this here and not just call computePageSize() as an
    // argument to setDataQuery below because computePageSize causes the DOM
    // structure of the table to be updated as a side effect, and we need to
    // ensure that happens before we render the actual table data. Things get
    // a little tricky if this happens in setDataQuery's call stack.
    const pageSize = computePageSize($element, renderState);

    // If the container is big enough to render rows, initiate a data request.
    // If it is not, then we will wait for the ...INVALIDATE_SIZE event, in
    // response to which we will initiate a data request.
    if (pageSize > 0) {
      setDataQuery(
        renderState.vif,
        0, // Offset
        pageSize,
        _.get(renderState.vif, 'configuration.order')
      ).then(() => {
        const { vif, fetchedData } = renderState;
        visualization.render(vif, fetchedData, _.get(fetchedData, 'filterableColumns'));
        updateState({ error: false });
      }).catch(function() {
        updateState({ error: true });
      });
    }
  }

  function teardown() {
    visualization.destroy();
    $element.removeData('visualization');
    $element.removeData('pager');
    $element.removeData('renderState');
    detachEvents();
  }

  function attachEvents() {
    detachEvents();

    $element.one(
      'SOCRATA_VISUALIZATION_DESTROY.socrata-table',
      teardown
    );

    $element.on(
      'SOCRATA_VISUALIZATION_INVALIDATE_SIZE.socrata-table',
      handleInvalidateSize
    );
    $element.on(
      'SOCRATA_VISUALIZATION_RENDER_VIF.socrata-table',
      handleRenderVif
    );
    $element.on(
      'SOCRATA_VISUALIZATION_COLUMN_CLICKED.socrata-table',
      handleColumnClicked
    );
    $element.on(
      'SOCRATA_VISUALIZATION_COLUMN_SORT_APPLIED.socrata-table',
      handleColumnSortApplied
    );
    $element.on(
      'SOCRATA_VISUALIZATION_COLUMN_FLYOUT.socrata-table',
      handleColumnFlyout
    );
    $element.on(
      'SOCRATA_VISUALIZATION_CELL_FLYOUT.socrata-table',
      handleCellFlyout
    );
    $element.on(
      'SOCRATA_VISUALIZATION_PAGINATION_PREVIOUS.socrata-table',
      handlePrevious
    );
    $element.on(
      'SOCRATA_VISUALIZATION_PAGINATION_NEXT.socrata-table',
      handleNext
    );
    $element.on(
      'SOCRATA_VISUALIZATION_TABLE_COLUMNS_RESIZED.socrata-table',
      handleColumnsResized
    );
  }

  function detachEvents() {
    $element.off('.socrata-table');
  }

  function render() {
    const { vif, fetchedData, error, errorType, collocating } = renderState;

    if (collocating) {
      return visualization.renderCollocationMessage();
    }

    if (error) {
      return visualization.renderError(errorType);
    }

    visualization.clearError();

    visualization.hideBusyIndicator();

    pager.render(computePagerOptions(renderState));
    visualization.render(vif, fetchedData, fetchedData.filterableColumns);
  }

  // Event handler functions

  function handleInvalidateSize() {
    const pageSize = computePageSize($element, renderState);
    const oldPageSize = _.get(renderState, 'fetchedData.pageSize', 0);

    // Canceling inflight requests is hard. If we're currently fetching data,
    // ignore the size change. The size will be rechecked once the current
    // request is complete.
    if (
      !renderState.error &&
      !renderState.busy &&
      oldPageSize !== pageSize
    ) {

      setDataQuery(
        renderState.vif,
        _.get(renderState, 'fetchedData.startIndex', 0),
        pageSize,
        _.get(renderState.vif, 'configuration.order')
      );
    }
  }

  async function handleRenderVif(event) {
    let newVif = migrateVif(
      _.cloneDeep(event.originalEvent.detail)
    );
    let newColumns = _.get(newVif, 'series[0].dataSource.dimension.columns');

    if (newColumns) {
      const datasetRowCount = _.get(renderState, 'datasetRowCount', null);

      _.each(newColumns, (column) => {
        _.unset(column, 'position');
      });

      const { userChangedOrder } = renderState;

      const newData = await setSoqlDataQuery(newVif,
        datasetRowCount,
        _.get(renderState, 'fetchedData.startIndex', 0),
        computePageSize($element, renderState),
        _.get(renderState.vif, 'configuration.order'),
        visualization.shouldDisplayFilterBar(),
        userChangedOrder);

      newVif = newData.vif;
      updateState({ error: false, errorType: null });
    }

    // If we are asked to re-render the same vif we don't need to do anything
    // at all.
    if (!_.isEqual(renderState.vif, newVif)) {
      // Note that we do this here and not just call computePageSize() as an
      // argument to setDataQuery below because computePageSize causes the DOM
      // structure of the table to be updated as a side effect, and we need to
      // ensure that happens before we render the actual table data. Things get
      // a little tricky if this happens in setDataQuery's call stack.
      const pageSize = computePageSize($element, renderState);

      if (
        !renderState.error &&
        !renderState.busy
      ) {
        setDataQuery(
          newVif,
          0,
          pageSize,
          _.get(newVif, 'configuration.order')
        );
      }
    }
  }

  function handleColumnClicked(event) {
    updateState({ userChangedOrder: true });

    const dataSourceType = _.get(renderState, 'vif.series[0].dataSource.type');

    if (dataSourceType !== 'socrata.soql') {
      return;
    }

    assertIsOneOfTypes(event.originalEvent.detail, 'string');

    if (renderState.busy) {
      return;
    }

    const columnName = event.originalEvent.detail;

    assert(_.includes(
      _.map(renderState.fetchedData.columns, 'fieldName'),
      columnName
    ), `Column name not found to sort by: ${columnName}`);

    let existingOrders = _.get(renderState.vif, 'configuration.order', []);

    const canSeeInternalIdCol = _.find(
      renderState.fetchedData.columns,
      (c) => c.name === ':id'
    );
    // If the interal :id column is hidden, remove it from the sort orders
    if (!canSeeInternalIdCol) {
      existingOrders = existingOrders.filter(o => o.columnName !== ':id');
    }

    const existingSort = _.find(
      existingOrders,
      (o) => o.columnName === columnName
    );
    let newOrders;

    if (existingSort) {
      if (!existingSort.ascending) {
        // Clear it
        newOrders = existingOrders.filter(o => o.columnName !== columnName);
      } else {
        // Set to descending
        newOrders = existingOrders.map(o => {
          if (o.columnName === columnName) {
            return {...existingSort, ascending: false };
          }
          return o;
        });
      }
    } else {
      newOrders = [...existingOrders, { columnName, ascending: true }];
    }

    _.set(renderState.vif, 'configuration.order', newOrders);

    setDataQuery(
      renderState.vif,
      0,
      renderState.fetchedData.pageSize,
      _.get(renderState.vif, 'configuration.order')
    );
  }

  function handleColumnSortApplied(event) {
    const dataSourceType = _.get(renderState, 'vif.series[0].dataSource.type');

    if (dataSourceType !== 'socrata.soql' && dataSourceType !== 'socrata.rawSoql') {
      return;
    }

    assertIsOneOfTypes(event.originalEvent.detail.columnName, 'string');
    assertIsOneOfTypes(event.originalEvent.detail.ascending, 'boolean');

    if (renderState.busy) {
      return;
    }

    const payload = event.originalEvent.detail;

    assert(_.includes(
      _.map(renderState.fetchedData.columns, 'fieldName'),
      payload.columnName
    ), `Column name not found to sort by: ${payload.columnName}`);

    const newOrder = [payload];

    _.set(renderState.vif, 'configuration.order', newOrder);

    setDataQuery(
      renderState.vif,
      0,
      renderState.fetchedData.pageSize,
      _.get(renderState.vif, 'configuration.order')
    );
  }

  function handleColumnFlyout(event) {
    const payload = event.originalEvent.detail;

    $element[0].dispatchEvent(
      new window.CustomEvent(
        'SOCRATA_VISUALIZATION_FLYOUT',
        {
          detail: payload,
          bubbles: true
        }
      )
    );
  }

  function handleCellFlyout(event) {
    const payload = event.originalEvent.detail;

    $element[0].dispatchEvent(
      new window.CustomEvent(
        'SOCRATA_VISUALIZATION_FLYOUT',
        {
          detail: payload,
          bubbles: true
        }
      )
    );
  }

  function handlePrevious() {
    const dataSourceType = _.get(renderState, 'vif.series[0].dataSource.type');

    if (renderState.busy) {
      return;
    }

    if (dataSourceType === 'socrata.view') {
      return;
    }

    setDataQuery(
      renderState.vif,
      Math.max(
        0,
        renderState.fetchedData.startIndex - renderState.fetchedData.pageSize
      ),
      renderState.fetchedData.pageSize,
      _.get(renderState.vif, 'configuration.order')
    );
  }

  function handleNext() {
    const dataSourceType = _.get(renderState, 'vif.series[0].dataSource.type');

    if (renderState.busy) {
      return;
    }

    if (dataSourceType === 'socrata.view') {
      return;
    }

    setDataQuery(
      renderState.vif,
      renderState.fetchedData.startIndex + renderState.fetchedData.pageSize,
      renderState.fetchedData.pageSize,
      _.get(renderState.vif, 'configuration.order')
    );
  }

  function handleColumnsResized(event) {
    const columnWidths = event.originalEvent.detail;

    if (renderState.busy) {
      return;
    }

    assertInstanceOf(columnWidths, Object);

    _.set(renderState.vif, 'configuration.tableColumnWidths', columnWidths);

    $element[0].dispatchEvent(
      new window.CustomEvent(
        'SOCRATA_VISUALIZATION_VIF_UPDATED',
        {
          detail: _.cloneDeep(renderState.vif),
          bubbles: true
        }
      )
    );
  }

  /**
   * Data Requests
   */

  function handleSetDataQueryError(error) {

    if (window.console && _.isFunction(window.console.error)) {

      console.error(
        'Error while fulfilling table data request:',
        error
      );
    }

    const collocating = _.get(error, 'soqlError.status') === 'in-progress';
    // There was an issue populating this table with data. Retry?
    updateState({
      busy: false,
      error: true,
      errorType: error.message,
      collocating
    });

    return Promise.reject();
  }

  function setDataQuery(
    vifForDataQuery,
    startIndex,
    pageSize,
    order
  ) {
    if (renderState.busy) {
      throw new Error(
        'Cannot call setDataQuery while a request already in progress.'
      );
    }
    $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_START');
    visualization.showBusyIndicator();
    updateState({ busy: true });

    return initiateDataQuery(vifForDataQuery, startIndex, pageSize, order).
      then((newState) => {
        $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_COMPLETE');
        visualization.hideBusyIndicator();
        assertCellsCountInRows(newState.fetchedData.rows, newState.fetchedData.columns);
        newState.fetchedData.rows = padOrTrimRowsToFitPageSize(newState.fetchedData.rows, newState.fetchedData.pageSize);
        updateState(newState);
      }).
      then(() => {
        updateState({ fetchingRowCount: true });
        pager.render(computePagerOptions(renderState)); // To show fetching spinner
        getDatasetRowCount(renderState.vif).then((response) => {
          updateState({ datasetRowCount: response, fetchingRowCount: false });
          pager.render(computePagerOptions(renderState));
        }).catch((error) => {
          console.error(`Failed to get row count: ${error}`);
          updateState({ fetchingRowCount: false });
        });
      }).
      catch((e) => {
        visualization.hideBusyIndicator();
        handleSetDataQueryError(e);
      });
  }

  function initiateDataQuery(vifForDataQuery,
    startIndex,
    pageSize,
    order
  ) {
    const dataSourceType = _.get(vifForDataQuery, 'series[0].dataSource.type');
    const datasetRowCount = _.get(renderState, 'datasetRowCount', null);
    const { userChangedOrder } = renderState;
    switch (dataSourceType) {
      case 'socrata.inline':
        return setInlineDataQuery(vifForDataQuery, datasetRowCount, startIndex, pageSize, pagingEnabled);

      case 'socrata.soql':
        return setSoqlDataQuery(vifForDataQuery,
          datasetRowCount,
          startIndex,
          pageSize,
          order,
          visualization.shouldDisplayFilterBar(),
          userChangedOrder);

      case 'socrata.rawSoql':
        return setRawSoqlDataQuery(vifForDataQuery, datasetRowCount, startIndex, pageSize, order);

      case 'socrata.view':
        return setSocrataViewDataQuery(vifForDataQuery, datasetRowCount);

      default:
        return Promise.reject(
          `Invalid data source type in vif: '${dataSourceType}'.`
        );
    }
  }

  // Rather than have each data source fetch row counts as part of their
  // set_____DataQuery() methods which fetch the actual rows, breaking
  // row count fetches into separate methods here. Each get_____RowCount()
  // method should return a promise, and the result is set to the table state under datasetRowCount.
  async function getDatasetRowCount(vifForRowCount) {
    const dataSourceType = _.get(vifForRowCount, 'series[0].dataSource.type');

    const dataProviderConfig = {
      datasetUid: _.get(vifForRowCount, 'series[0].dataSource.datasetUid'),
      domain: _.get(vifForRowCount, 'series[0].dataSource.domain'),
      parameterOverrides: _.get(vifForRowCount, 'series[0].dataSource.parameterOverrides'),
      readFromNbe: _.get(vifForRowCount, 'series[0].dataSource.readFromNbe', true)
    };

    switch (dataSourceType) {
      case 'socrata.inline':
        return getInlineDataRowCount(vifForRowCount);
      case 'socrata.soql':
        const metadataProvider = new MetadataProvider(dataProviderConfig, true);
        const datasetMetadata = await metadataProvider.getDatasetMetadata();
        return getSoqlDataRowCount(vifForRowCount, dataProviderConfig, datasetMetadata);
      case 'socrata.rawSoql':
        return getRawSoqlRowCount(vifForRowCount, dataProviderConfig);
      case 'socrata.view':
        return getSocrataViewRowCount(vifForRowCount,  dataProviderConfig);
      default:
        return Promise.reject(
          `Invalid data source type in vif: '${dataSourceType}'.`
        );
    }
  }

  // Updates only specified UI state.
  function updateState(newPartialState) {

    setState(
      _.extend(
        {},
        renderState,
        newPartialState
      )
    );
  }

  // Replaces entire UI state.
  function setState(newState) {

    if (
      !_.isEqual(renderState.vif, newState.vif) ||
      !_.isEqual(renderState, newState)
    ) {

      const becameIdle = !newState.busy && renderState.busy;
      const changedOrder = (
        // We don't want to emit the ...VIF_UPDATED event on first render.
        // The way that the state here works is that it is initialized with
        // order set to null, so we need to check that there actually is an
        // order (which gets folded into the fetchedData object from the vif
        // once the data request comes back) before checking if it has changed
        // since the last time the state was updated.
        _.get(renderState, 'fetchedData') !== null &&
        !_.isEqual(
          _.get(renderState, 'fetchedData.order'),
          _.get(newState, 'fetchedData.order')
        )
      );

      renderState = newState;

      if (changedOrder) {

        $element[0].dispatchEvent(
          new window.CustomEvent(
            'SOCRATA_VISUALIZATION_VIF_UPDATED',
            {
              detail: _.cloneDeep(renderState.vif),
              bubbles: true
            }
          )
        );
      }

      if (becameIdle) {
        render();
      }
    }
  }

  initialize();
  attachEvents();

  return this;
};

export default $.fn.socrataTable;
