import React, { useMemo } from 'react';
import { ColDef, ColumnState, IServerSideDatasource } from '@ag-grid-community/core';
import { TableColumnFormat, RowFormat, FormatStyle } from 'common/authoring_workflow/reducers/types';
import { ViewColumn } from 'common/types/viewColumn';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import { Vif, Hierarchy, OrderConfig } from 'common/visualizations/vif';
import { filter, get, isEmpty } from 'lodash';
import HierarchyTabs from './HierarchyTabs';
import Grid from './Grid';
import classNames from 'classnames';
import { ParameterOverrides } from 'common/components/SingleSourceFilterBar/types';
import { Filters } from 'common/components/FilterBar/types';
import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import { Column } from '@ag-grid-community/core';
import { isUpdatedConditionalFormattingDesignsEnabled } from 'common/visualizations/helpers/VifSelectors';
import { RowStripeStyle } from 'common/types/agGrid/rowStripe';
import {
  DEFAULT_TABLE_HEADER_TEXT_COLOR,
  DEFAULT_TABLE_HEADER_BACKGROUND_COLOR
} from 'common/authoring_workflow/constants';

export interface AgGridContainerProps {
  vif: Vif;
  columnMetadata: ViewColumn[];
  nonStandardAggregations: ColumnAggregation[] | null;
  datasource: IServerSideDatasource;
  getGrandTotalRow: (hierarchyConfig: Hierarchy) => Promise<any>;
  onColumnReorder: (columnState: ColumnState[]) => void;
  onColumnResize: (columns: Column[] | null) => void;
  onColumnRowGroupChange: (columnState: ColumnState[], columns: Column[], hierarchyId?: string) => void;
  onColumnSort: (columnState: ColumnState[], hierarchyId?: string) => void;
  onColumnValueChange: (columns: Column[], hierarchyId?: string) => void;
  onColumnVisibilityChange: (
    columnState: ColumnState[],
    columns: Column[] | null,
    hierarchyId?: string
  ) => void;
  onHierarchyTabChange: (hierarchyId: string) => void;
  onFilterChange: (newFilters: Filters) => void;
  paginationPageSize?: number;
  defaultColDefOverrides?: ColDef;
  displayColumnFilters?: boolean;
  useSetFilters?: boolean;
  showAgGridColumnMenu?: boolean;
  showAgGridColumnAggregations?: boolean;
  isIndented?: boolean;
  initializeRowStripeStyle?: () => RowStripeStyle;
  openToolPanelByDefault?: boolean;
}

const GridContainer = (props: AgGridContainerProps) => {
  const {
    vif,
    columnMetadata,
    nonStandardAggregations,
    datasource,
    getGrandTotalRow,
    onColumnReorder,
    onColumnResize,
    onColumnRowGroupChange,
    onColumnSort,
    onColumnValueChange,
    onColumnVisibilityChange,
    onHierarchyTabChange,
    onFilterChange,
    paginationPageSize,
    defaultColDefOverrides,
    displayColumnFilters,
    useSetFilters,
    showAgGridColumnMenu,
    showAgGridColumnAggregations,
    initializeRowStripeStyle,
    openToolPanelByDefault
  } = props;

  const vifHierarchies: Hierarchy[] = get(vif, 'series[0].dataSource.hierarchies', []);
  const vifFilters: Filters = get(vif, 'series[0].dataSource.filters', []);
  const columnFormat: TableColumnFormat[] = get(vif, 'series[0].columnFormat', []);
  const headerFormat: FormatStyle = get(vif, 'series[0].formatting.headerFormat', []);
  const rowFormat: RowFormat[] = get(vif, 'series[0].rowFormat', []);
  const vifParameterOverrides: ParameterOverrides = get(
    vif,
    'series[0].dataSource.parameterOverrides',
    new Map()
  );
  const vifColumns: ViewColumn[] = useMemo(() => {
    return get(vif, 'series[0].dataSource.dimension.columns', []);
  }, [vif]);
  const activeHierarchyId: string | undefined = get(vif, 'series[0].dataSource.activeHierarchyId');

  const isHierarchyActive = (hierarchy: Hierarchy) => {
    return hierarchy.id === activeHierarchyId;
  };

  let columnFormats: { [key: string]: TableColumnFormat } = {};
  if (isUpdatedConditionalFormattingDesignsEnabled()) {
    columnFormats = get(props.vif, 'series[0].formatting.columnFormat', {});
  } else {
    columnFormats = filter(columnFormat, ({ columnName }: TableColumnFormat) => !isEmpty(columnName)).reduce(
      (acc: { [key: string]: TableColumnFormat }, format: TableColumnFormat) => {
        acc[format.columnName] = format;
        return acc;
      },
      {}
    );
  }

  const commonGridProps = {
    columnMetadata,
    nonStandardAggregations,
    datasource,
    getGrandTotalRow,
    onColumnReorder,
    onColumnResize,
    onColumnRowGroupChange,
    onColumnSort,
    onColumnValueChange,
    onColumnVisibilityChange,
    onHierarchyTabChange,
    columnFormats,
    rowFormat: filter(rowFormat, ({ columnName }: RowFormat) => !isEmpty(columnName)),
    onFilterChange,
    vifFilters,
    vifParameterOverrides,
    vifColumns,
    domain: get(vif, 'series[0].dataSource.domain') || getDefaultDomain(),
    datasetUid: get(vif, 'series[0].dataSource.datasetUid'),
    searchString: get(vif, 'series[0].dataSource.searchString'),
    paginationPageSize,
    defaultColDefOverrides,
    useSetFilters,
    displayColumnFilters,
    showAgGridColumnMenu,
    showAgGridColumnAggregations,
    isIndented: get(vif, 'configuration.isIndented'),
    initializeRowStripeStyle,
    headerFormat,
    openToolPanelByDefault
  };

  const isBoldHeaderFontStyle =
    headerFormat?.fontStyle?.isBold === undefined ? true : headerFormat.fontStyle.isBold;
  const isItalicHeaderFontStyle =
    headerFormat?.fontStyle?.isItalic === undefined ? false : headerFormat.fontStyle.isItalic;
  const style = {
    '--ag-header-foreground-color': headerFormat?.textColor || DEFAULT_TABLE_HEADER_TEXT_COLOR,
    '--ag-header-background-color': headerFormat?.backgroundColor || DEFAULT_TABLE_HEADER_BACKGROUND_COLOR,
    '--ag-table-header-font-weight': isBoldHeaderFontStyle ? 'bold' : 'normal',
    '--ag-table-header-font-style': isItalicHeaderFontStyle ? 'italic' : 'normal'
  };
  const gridClasses = classNames('ag-grid-react ag-theme-material', {
    'ag-grid-columns-aggregation': showAgGridColumnAggregations ?? true
  });
  return (
    <div className="ag-grid-visualization">
      <HierarchyTabs
        activeHierarchyId={activeHierarchyId}
        onTabChange={(newTabId) => props.onHierarchyTabChange(newTabId)}
        hierarchies={vifHierarchies}
        columns={props.columnMetadata}
      />
      {vifHierarchies.map((hierarchy) => {
        const vifOrderConfig: OrderConfig[] = hierarchy?.order ?? [];
        const agGridOpenNodeLevel = hierarchy?.agGridOpenNodeLevel ?? 0;

        const classes = classNames(gridClasses, { 'ag-hidden': !isHierarchyActive(hierarchy) });

        return (
          <div style={style} className={classes} key={hierarchy.id}>
            <Grid
              {...{ ...commonGridProps, vifOrderConfig, agGridOpenNodeLevel }}
              hierarchyConfig={hierarchy}
            />
          </div>
        );
      })}
    </div>
  );
};

export default GridContainer;
