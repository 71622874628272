import _ from 'lodash';
import $ from 'jquery';
import mapboxgl from '@socrata/mapbox-gl';

import BaseVisualization from './BaseVisualization';
import {
  attachLegendBarEventHandlers,
  renderLegendBar,
  removeLegendBar
} from './BaseVisualization/LegendBarContainer';
import {
  MAP_EVENTS,
  RADIUS_FILTER_CIRCLE_PADDING,
  RADIUS_FILTER_MAP_PANNING_DURATION,
  MAP_CANVAS_CLASSNAME,
  MAP_HIGHLIGHT_CONTAINER_CLASSNAME
} from './mapConstants';
import I18n from 'common/i18n';
import MetadataProvider, { getComputedColumns } from 'common/visualizations/dataProviders/MetadataProvider';
import {
  isOutsideBounds,
  getPrimarySeriesVif,
  sanitizeVifForMaps
} from 'common/visualizations/helpers/MapHelper';

import * as vifDecorator from './map/vifDecorators/vifDecorator';
import MapFactory from './map/MapFactory';
import { getBasemapStyle } from './map/basemapStyle';
import MouseInteractionHandler from './map/handlers/MouseInteractionHandler';
import PopupHandler from './map/handlers/PopupHandler';
import HighlightHandler from './map/handlers/HighlightHandler';
import RadiusFilterCircleOverlay, {
  getBoundsForRadiusFilterCircle
} from './map/vifOverlays/RadiusFilterCircleOverlay';
import SpiderfyHandler from './map/handlers/SpiderfyHandler';
import VifBasemap from './map/VifBasemap';
import VifIndexLayers, { getIndexLayerIdAt } from './map/VifIndexLayers';
import VifMapControls from './map/VifMapControls';
import VifMapInteractionHandler from './map/VifMapInteractionHandler';
import { getOverlaysFor } from './map/vifOverlayFactory';

import { getFilteredDataTableVif } from 'common/visualizations/VisualizationCommon';
import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import { getFilters } from 'common/visualizations/helpers/VifSelectors';

// Entry point for new maps. Mostly it channels the vif as it comes from the AX to
// the child components/handlers. It orchestrates the following based on incoming Vif:
//  - basemap(VifBasemap),
//  - rendering data (Vif____Overlays),
//  - map controls(VifMapControls),
//  - zoom/pan interaction/behavior(VifMapInteractionHandler),
//  - mouseInteraction(MouseInteractionHandler-> popupHandler/spiderfyHandler)
// Whenever the map's zoom/center/pitch/bearing changes, it emits event, so
// that the vif can be updated.
export default class UnifiedMap extends BaseVisualization {
  constructor(visualizationElement, vif, options) {
    super(
      visualizationElement,
      vif,
      _.merge(options, {
        onInEditFilterChange: (event, inEditFilter) => this.onInEditFilterChange(event, inEditFilter),
        onFilterChange: (filters) => this.onFilterChange(null, { filters })
      })
    );

    this._element = visualizationElement;
    this._visualizationOptions = options;
    this._is_moving = false;

    this.invalidateSize = () => {
      if (this._map) {
        this._map.resize();
      } else {
        this._sizeInvalidatedWhileMapInitialization = true;
      }
    };

    this.onUpdateEvent = (event) => {
      if (this._is_moving) return;
      const newVif = _.get(event, 'originalEvent.detail');
      this.update(newVif);
    };

    this._element.find('.socrata-visualization-container').removeAttr('aria-hidden');

    this.renderSummaryTable = (newVif) => {
      const summaryTableVif = _.cloneDeep(newVif);
      const primaryLayer =
        _.find(summaryTableVif.series, (series) => _.get(series, 'primary', false)) ||
        // If no layer is indicated as the primary layer, just pick the first one
        _.get(summaryTableVif, 'series[0]');
      const dataSource = _.get(primaryLayer, 'dataSource.datasetUid');
      const parameterOverrides = _.get(primaryLayer, 'dataSource.parameterOverrides');
      const units = {
        one: 'Row',
        other: 'Rows'
      };
      // We always use the primary layer for filtering, so only get those filters
      const filters = _.get(primaryLayer, 'dataSource.filters');
      const newTableVif = getFilteredDataTableVif(dataSource, units, filters, parameterOverrides);
      this.updateSummaryTableVif(newTableVif);
    };
  }

  destroy() {
    // The default aria-hidden attribute on BaseVisualization that is removed for
    // UnifiedMap needs to be restored so that other visualizations retain their
    // expected behavior in the AuthoringWorkflow when switching between viz types.
    if (this._element) {
      this._element.find('.socrata-visualization-container').attr('aria-hidden', true);
    }
    if (this._map) {
      this._map.remove();
    }
  }

  initialize(rawVif) {
    if (!mapboxgl.supported()) {
      this.renderError(
        'WebGl is disabled or not supported in your browser. Please enable WebGl or upgrade your browser.'
      );
      return Promise.resolve(null);
    }

    const vif = vifDecorator.getDecoratedVif(sanitizeVifForMaps(rawVif));
    const filtersInitPromise = this._setupDatasetColumnsForFilters(getPrimarySeriesVif(rawVif));
    this._sizeInvalidatedWhileMapInitialization = false;

    const mapInitPromise = MapFactory.build(this._element, vif).then(({ map, featureBounds }) => {
      this._map = map;
      this._vifBasemap = new VifBasemap(this._map);
      this._vifBasemap.initialize(vif);

      this._vifMapControls = new VifMapControls(this._map);
      this._vifMapControls.initialize(vif);

      this._vifMapInteractionHandler = new VifMapInteractionHandler(this._map);
      this._vifIndexLayers = new VifIndexLayers(this._map);
      this._vifIndexLayers.initialize(vif);
      this._vifMapInteractionHandler.initialize(vif);

      this._radiusFilterCircleOverlay = new RadiusFilterCircleOverlay(this._map);
      this._radiusFilterCircleOverlay.initialize();

      this._popupHandler = new PopupHandler(map);
      this._persistentPopupHandler = new PopupHandler(map);
      this._spiderfyHandler = new SpiderfyHandler(map, this._popupHandler);
      this._highlightHandler = new HighlightHandler(map);
      this._highlightHandler.initialize();
      const mouseInteractionOptions = {
        map: this._map,
        element: this._element,
        highlightHandler: this._highlightHandler,
        popupHandler: this._popupHandler,
        persistentPopupHandler: this._persistentPopupHandler,
        spiderfyHandler: this._spiderfyHandler,
        vif
      };

      this._mouseInteractionHandler = new MouseInteractionHandler(mouseInteractionOptions);

      this._renderOverlays(vif);

      // Adding Map object to visualization DOM element,
      // to get mapbox gl map for manual and automated testing
      this._element[0].map = map;

      this._map.on('moveend', () => {
        // Moving the map will eventually cause a filter update. This is bad because a filter update will
        // also cause a map move. So we need to ignore the filter updates that are caused by map moves.
        this._is_moving = true;
        this._emitMapCenterAndZoomChange();
        this._emitMapPitchAndBearingChange();
        this._is_moving = false;
      });
      this._map.on('boxzoomend', (boxZoomEvent) => {
        this._emitSetGeocodeBounds(boxZoomEvent.target.getBounds());
      });
      this._map.on(MAP_EVENTS.LOADING_SPIDER_DATA, () => {
        this.showBusyIndicator();
      });
      this._map.on(MAP_EVENTS.FINISHED_SPIDER_DATA, () => {
        this.hideBusyIndicator();
      });
      this._map.on(MAP_EVENTS.TOGGLE_MAP_LAYER, (options) => {
        this._emitToggleMapLayer(options);
      });

      if (featureBounds) {
        // On map initialize with bounds based on features, set the featureBounds
        // as search bounds(geocode bounds). The user can override it using shift+click and drag.
        this._emitSetGeocodeBounds(featureBounds);
      }

      if (this._sizeInvalidatedWhileMapInitialization) {
        this.invalidateSize();
        this._sizeInvalidatedWhileMapInitialization = false;
      }

      const $divElement = $('<div>', { class: `${MAP_HIGHLIGHT_CONTAINER_CLASSNAME}` });
      const $mapCanvasContainer = $(this._map._container).find(`.${MAP_CANVAS_CLASSNAME}`);
      // We are manually inserting a role because we use a very old version of mapbox-gl.
      $mapCanvasContainer.attr('role', 'application');
      $($divElement).insertAfter($mapCanvasContainer);
    });
    this._existingVif = vif;

    return Promise.all([mapInitPromise, filtersInitPromise]);
  }

  onFilterChange = async (_event, { filters }) => {
    const vifFiltersWithArguments = _.filter(
      getFilters(this._existingVif),
      (filterItem) => !_.isEmpty(filterItem.arguments)
    );
    const filtersWithArguments = _.filter(filters, (filterItem) => !_.isEmpty(filterItem.arguments));
    const whereClauseComponent = filters
      .map(SoqlHelpers.filterToWhereClauseComponent)
      .filter(_.negate(_.isEmpty))
      .join(' and ');

    if (!_.isEqual(vifFiltersWithArguments, filtersWithArguments)) {
      const { featureBounds } = await MapFactory.getFeatureBounds(this._existingVif, whereClauseComponent);
      if (featureBounds !== null) {
        this._map.fitBounds(featureBounds, { animate: true });
      }
    }
  };

  onInEditFilterChange = (_event, inEditFilter) => {
    const currentInEditFilterCenter = _.get(inEditFilter, 'arguments[0].center');
    const currentRadiusFilterBounds = getBoundsForRadiusFilterCircle(inEditFilter);

    const hasFilterCenterChanged =
      !_.isUndefined(currentRadiusFilterBounds) &&
      !_.isEqual(this._previousInEditFilterCenter, currentRadiusFilterBounds.getCenter());

    if (isOutsideBounds(this._map.getBounds(), currentRadiusFilterBounds) || hasFilterCenterChanged) {
      this._previousInEditFilterCenter = currentRadiusFilterBounds.getCenter();
      this._map.fitBounds(currentRadiusFilterBounds, {
        animate: true,
        duration: RADIUS_FILTER_MAP_PANNING_DURATION,
        padding: RADIUS_FILTER_CIRCLE_PADDING
      });
    }

    this._radiusFilterCircleOverlay.render(inEditFilter);
  };

  async update(newRawVif) {
    if (!mapboxgl.supported()) {
      return;
    }

    const newVif = vifDecorator.getDecoratedVif(sanitizeVifForMaps(newRawVif));
    const primarySeriesVif = getPrimarySeriesVif(newRawVif);

    const filtersInitPromise = this._setupDatasetColumnsForFilters(primarySeriesVif);

    this._vifBasemap.update(newVif);
    this._vifMapControls.update(newVif);
    this._vifMapInteractionHandler.update(newVif);
    this._vifIndexLayers.update(newVif);
    this.updateVif(newVif);

    const newBasemapStyle = getBasemapStyle(newVif);
    const oldBasemapStyle = getBasemapStyle(this._existingVif);
    if (newBasemapStyle !== oldBasemapStyle) {
      this._highlightHandler.initialize();
    }

    const renderOverlaysPromise = this._renderOverlays(newVif);

    this._existingVif = newVif;
    return Promise.all([renderOverlaysPromise, filtersInitPromise]);
  }

  _renderOverlays(newVif) {
    if (_.get(newVif, 'series.length', 0) === 0) {
      const learnMoreLink =
        'https://support.socrata.com/hc/en-us/articles/360007692133-How-to-Configure-a-Georeference-Location-Column';
      const errorMessage = I18n.t(
        'shared.visualizations.charts.map.dimensions_column_required_is_not_georeferenced'
      );
      this.renderErrorWithHTML(
        `${errorMessage} <a href=${learnMoreLink} target="_blank" rel="noreferrer noopener">Learn More</a>`
      );
      return;
    } else {
      this.clearError();
    }
    const hasMultiplePointMapSeries = newVif.hasMultiplePointMapSeries();
    const stackSize = newVif.getStackSize();
    const newOverlays = getOverlaysFor({
      newVif,
      existingVif: this._existingVif,
      existingOverlays: this._currentOverlays,
      map: this._map,
      mouseInteractionHandler: this._mouseInteractionHandler
    });

    _.each(this._currentOverlays, (currentOverlay, index) => {
      if (currentOverlay !== newOverlays[index]) {
        currentOverlay.destroy();
      }
    });

    const overlayRenderPromises = _.map(newOverlays, (newOverlay, index) => {
      return newOverlay.loadVif(newVif.cloneWithSingleSeries(index), {
        renderLayersBefore: getIndexLayerIdAt(index),
        hasMultiplePointMapSeries,
        stackSize
      });
    });

    this._currentOverlays = newOverlays;
    this.renderSummaryTable(newVif);

    return Promise.all(overlayRenderPromises).then(() => {
      if (this._currentOverlays === newOverlays) {
        this._renderMetadataErrorIfRequired(this._currentOverlays);
        this._renderLegendGroups(this._currentOverlays, newVif);
      }
    });
  }

  _setupDatasetColumnsForFilters(primarySeriesVif) {
    const datasetMetadataProvider = new MetadataProvider(
      {
        domain: primarySeriesVif.getDomain(),
        datasetUid: primarySeriesVif.getDatasetUid()
      },
      true
    );

    const columnsPromise = this.shouldDisplayFilterBar()
      ? datasetMetadataProvider.getDisplayableFilterableColumns({ shouldGetColumnStats: false })
      : Promise.resolve(null);

    // Overriding old dataset columns to null if any already set. Otherwise, BaseVisualization
    // will try to render filters with the old dataset's columns and fail.
    this.updateColumns(null, null);

    return Promise.all([columnsPromise, datasetMetadataProvider.getDatasetMetadata()]).then((resolutions) => {
      const [columns, datasetMetadata] = resolutions;
      const computedColumns = getComputedColumns(datasetMetadata);
      this.updateColumns(columns, computedColumns);
    });
  }

  _renderMetadataErrorIfRequired = (overlays) => {
    const overlaysMetadataResponse = _.map(overlays, (overlay) => {
      return overlay.metadataResponse;
    });
    const isForbiddenResponse = (overlayMetadataResponse) => {
      return _.isEqual(overlayMetadataResponse, { success: false, status: 403 });
    };

    if (_.every(overlaysMetadataResponse, isForbiddenResponse)) {
      const errorMessage = I18n.t('shared.visualizations.charts.map.error_generic');
      this.renderError(errorMessage);
    }
  };

  _renderLegendGroups = (overlays, vif) => {
    const legendItemGroups = _.chain(overlays)
      .map((overlay) => overlay.getLegendItems())
      .compact()
      .value();

    if (!_.isEmpty(legendItemGroups) && vif.getShowLegendForMap()) {
      renderLegendBar(this, legendItemGroups);
      attachLegendBarEventHandlers(this.$container);
    } else {
      removeLegendBar(this.$container);
      this.invalidateSize();
    }
  };

  _emitMapCenterAndZoomChange = () => {
    const center = this._map.getCenter();
    const zoom = this._map.getZoom();
    const centerAndZoom = {
      center: {
        lat: center.lat,
        lng: center.lng
      },
      zoom: zoom
    };
    this.emitEvent('SOCRATA_VISUALIZATION_MAP_CENTER_AND_ZOOM_CHANGED', centerAndZoom);
  };

  _emitMapPitchAndBearingChange = () => {
    const bearing = this._map.getBearing();
    const pitch = this._map.getPitch();
    const pitchAndBearing = {
      pitch: pitch,
      bearing: bearing
    };
    this.emitEvent('SOCRATA_VISUALIZATION_PITCH_AND_BEARING_CHANGED', pitchAndBearing);
  };

  _emitSetGeocodeBounds = (bounds) => {
    this.emitEvent('SOCRATA_VISUALIZATION_SET_GEOCODE_BOUNDS', bounds);
  };

  _emitToggleMapLayer = (options) => {
    if (this._visualizationOptions.toggleMapLayersInternally) {
      // If toggleLayersInternally is set to true,
      // instead of storyteller update vif unifiedMap update vif locally
      //
      // If toggleLayersInternally is set to false,
      // we emit event, storyteller update vif.
      //
      // update the vif via events, we update the vif locally for toggling layers.
      // In storyteller published mode, there is no way to edit the vif and update the map.
      // Currently we use this for storyteller published mode. The same approach is already
      // taken for displaying filters in storyteller. BaseVisualization modifies the vif and
      // updates the visualization.
      const { relativeIndex, visible } = options;
      const clonedVif = _.cloneDeep(this._existingVif);

      _.set(clonedVif, `series[${relativeIndex}].visible`, visible);

      this.update(clonedVif);
    } else {
      this.emitEvent('SOCRATA_VISUALIZATION_TOGGLE_MAP_LAYER', options);
    }
  };
}
