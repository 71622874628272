import { find, get } from 'lodash';
import {
  ColDef,
  ITooltipParams,
  CellClassParams,
  ValueGetterParams,
  HeaderClassParams
} from '@ag-grid-community/core';

import { AgGridProps } from '../Grid';
import { ViewColumn } from 'common/types/viewColumn';
import {
  getAgTableHeaderName,
  getCustomCellStyle,
  getCustomHeaderStyle,
  getFieldName
} from './TableColumnFormatting';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import { GroupAndTotalRenderer } from '../GroupAndTotalRenderer';
import I18n from 'common/i18n';
import { RowStripeStyle } from 'common/types/agGrid/rowStripe';
import { NULL_GROUPING } from '../Constants';

const getColumnFormat = (columnFormats: { [key: string]: TableColumnFormat }, fieldName: string) => {
  return columnFormats[fieldName];
};

export interface AutoGroupAttributesProps {
  props: AgGridProps;
  columnMetadata: ViewColumn[];
  columnFormats: { [key: string]: TableColumnFormat };
  nonStandardAggregations: ColumnAggregation[] | null;
  domain: string;
  datasetUid: string;
}

const getHeaderValue = (
  params: any,
  props: AgGridProps,
  columnMetadata: ViewColumn[],
  columnFormats: { [key: string]: TableColumnFormat }
) => {
  let fieldName = '' as string;
  if (props.isIndented) {
    fieldName = params?.api.getRowGroupColumns()[0].getColDef().field;
  } else {
    fieldName = params?.colDef?.field || params?.colDef?.showRowGroup;
  }

  const localColumnMetadata = columnMetadata.find((c) => c.fieldName === fieldName);
  if (!localColumnMetadata) return params.colDef.headerName;
  return getAgTableHeaderName(columnFormats[fieldName], localColumnMetadata);
};

const getHeaderClass = (
  params: HeaderClassParams,
  columnMetadata: ViewColumn[],
  columnFormats: { [key: string]: TableColumnFormat }
) => {
  const fieldName = get(params, 'colDef.field', '');
  const localColumnMetadata = columnMetadata.find((c) => c.fieldName === fieldName);
  if (!localColumnMetadata) {
    return '';
  }
  const customHeaderStyle = getCustomHeaderStyle(columnFormats[fieldName]);
  return customHeaderStyle;
};

const getCellStyle = (
  params: CellClassParams,
  columnMetadata: ViewColumn[],
  nonStandardAggregations: ColumnAggregation[] | null,
  columnFormats: { [key: string]: TableColumnFormat },
  showSubTotal: boolean | undefined,
  rowStripeStyle: RowStripeStyle | undefined
) => {
  const fieldName = getFieldName(params);
  const localColumnMetadata = columnMetadata.find((c) => c.fieldName === fieldName);
  if (!localColumnMetadata || !fieldName) {
    return {};
  }
  const customCellStyle = getCustomCellStyle({
    column: localColumnMetadata,
    nonStandardAggregations: nonStandardAggregations,
    columnFormat: columnFormats[fieldName],
    params,
    showSubTotal,
    currentRowStripeStyle: rowStripeStyle
  });
  return customCellStyle;
};

const getTooltipValue = (params: ITooltipParams) => {
  if (!params.value) return undefined;
  return params.value;
};

const getValue = (params: ValueGetterParams, props: AgGridProps) => {
  // If we have a total row we want the first grouped column to have "Total" as the value
  const columnDefs: ColDef[] | undefined = params?.api?.getColumnDefs();
  if (!columnDefs) return;

  const fieldName = getFieldName(params);
  if (params.node?.isRowPinned()) {
    if (!props.isIndented) {
      const firstGroupedColumn = find(columnDefs, ['rowGroupIndex', 0]);
      const firstGroupedColumnField = firstGroupedColumn?.field;
      if (fieldName === firstGroupedColumnField) return I18n.t('shared.visualizations.charts.table.total');
    } else {
      return I18n.t('shared.visualizations.charts.table.total');
    }
  }

  const nodeField = params.node?.field;
  if (params.node?.footer && fieldName && fieldName === nodeField) {
    const noValueText = I18n.t('shared.visualizations.charts.common.summary_table.no_value');
    const value = params.getValue(fieldName);
    return value == NULL_GROUPING ? noValueText : value;
  }
};

export const getAutoGroupAttributes = ({
  props,
  columnMetadata,
  columnFormats,
  nonStandardAggregations,
  domain,
  datasetUid
}: AutoGroupAttributesProps) => {
  const showSubTotal = props.hierarchyConfig?.showSubTotal;
  const rowStripeStyle = props.initializeRowStripeStyle && props.initializeRowStripeStyle();
  const width = props.isIndented ? props.hierarchyConfig?.singleAutoColumnWidth : undefined;
  return {
    headerTooltip: 'Group',
    headerValueGetter: (params: any) => {
      return getHeaderValue(params, props, columnMetadata, columnFormats);
    },
    tooltipComponentParams: {
      domain: domain,
      datasetUid: datasetUid,
      isIndented: props.isIndented
    },
    headerClass: (params: HeaderClassParams) => {
      return getHeaderClass(params, columnMetadata, columnFormats);
    },
    cellStyle: (params: CellClassParams) => {
      return getCellStyle(
        params,
        columnMetadata,
        nonStandardAggregations,
        columnFormats,
        showSubTotal,
        rowStripeStyle
      );
    },
    tooltipValueGetter: (params: ITooltipParams) => {
      return getTooltipValue(params);
    },
    cellRendererParams: {
      innerRenderer: GroupAndTotalRenderer,
      innerRendererParams: {
        datasetUid,
        domain,
        wrapWithDivElement: false
      }
    },
    valueGetter: (params: ValueGetterParams) => {
      return getValue(params, props);
    },
    width
  };
};
