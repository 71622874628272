import { FunCall, SoQLFunCall, TypedSoQLFunCall } from 'common/types/soql';
import { translateFunCall } from '../../lib/soql-helpers';
import * as _ from 'lodash';
import React from 'react';
import { AstNode, ExprProps } from '../VisualExpressionEditor';
import ChangeFunction from './ChangeFunction';
import { renderArgs } from './EditFunction';
import FunctionBadge from './FunctionBadge';

function EditPostfix<T>(props: ExprProps<FunCall, TypedSoQLFunCall>) {
  return (
    <AstNode
      {...props}
      className="funcall block-level-change-icon regular-funcall"
      // the OR case ensure functions like "As Boolean" displays the RemoveNode element
      removable={!!props?.showRemove || props.eexpr.untyped.args.length === 1}
      >
      {renderArgs(props)}
      <ChangeFunction {...props}>
        <FunctionBadge fun={props.eexpr.untyped} />
      </ChangeFunction>
    </AstNode>
  );
}

const Postfixes = [
  SoQLFunCall.IsNotNull,
  SoQLFunCall.IsNull
];
const shouldRenderPostfix = (f: FunCall): boolean => _.includes(Postfixes, f.function_name) || f.function_name.startsWith('cast$');

export { EditPostfix, shouldRenderPostfix };
