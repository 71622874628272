import { JOIN_FUNCTION } from 'common/components/SingleSourceFilterBar/SoqlFilter';

export type AgFilter = AgTextFilter | AgNumberFilter | AgDateFilter | AgSetFilter | AgMultiFilter | AgCombineFilter;
export type SimpleAgFilter = AgTextFilter | AgNumberFilter | AgDateFilter | AgSetFilter;
export type AgGridOperation = TextFilterType | NumberFilterType;

export enum TextFilterType {
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  EQUALS = 'equals',
  NOT_EQUAL = 'notEqual',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
  BLANK = 'blank',
  NOT_BLANK = 'notBlank'
}

export enum NumberFilterType {
  EQUALS = 'equals',
  NOT_EQUAL = 'notEqual',
  LESS_THAN = 'lessThan',
  LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
  GREATER_THAN = 'greaterThan',
  GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
  IN_RANGE = 'inRange',
  BLANK = 'blank',
  NOT_BLANK = 'notBlank'
}

export type AgCombineFilter = {
  operator: JOIN_FUNCTION,
  filterType: FilterType,
  conditions: AgFilter[]
};

export type AgTextFilter = {
  filter: string,
  filterType: FilterType.TEXT,
  type: TextFilterType
};

export type AgNumberFilter = {
  filter: number,
  filterTo?: number,
  filterType: FilterType.NUMBER,
  type: NumberFilterType
};

export type AgDateFilter = {
  dateFrom: string,
  dateTo?: string,
  filterType: FilterType.DATE,
  type: NumberFilterType
};

export type AgSetFilter = {
  filterType: FilterType.SET,
  values: (string | null)[]
};

export type AgFilterOrNull = AgFilter | null;

export type AgMultiFilter = {
  filterType: FilterType.MULTI,
  filterModels: AgFilterOrNull[]
};

export enum FilterType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  SET = 'set',
  MULTI = 'multi'
}

export type AgColumnFilter = Record<string, AgFilter>;
