/**
 * Useful for debugging. Sometimes isEqual passes when you think it shouldn't.
 * This is available even within a breakpoint on window.socrata.utils.
 */

import { isObject, isEmpty, isEqual, isArray, isString, transform, merge } from 'lodash';

/**
 * Object keys are actually sorted by insertion order. This function sorts them alphabetically.
 * Doesn't matter for isEqual, but this makes them easier to read in the console.
 * https://stackoverflow.com/a/31102605/8412474
 * @param unsorted Object to sort
 * @returns Object identical to unsorted, keys sorted alphabetically
 */
export const sortKeys = (unsorted: object): object => {
  return Object.keys(unsorted)
    .sort()
    .reduce((obj, key) => {
      obj[key] = unsorted[key];
      return obj;
    }, {});
};

/**
 * Removes all empty objects, empty arrays, and whitespace-only strings from an object.
 * Warning: Mutates the input object. Clone if you need it.
 * @param messyObj Object to trim
 * @returns Object with no empty objects, empty arrays, or whitespace-only strings
 */
export const trimObject = (messyObj: object): object => {
  // I actually wrote this from scratch, so proud of myself 🤣
  function _trimObjectRecurse(o: any): object {
    for (const [k, v] of Object.entries(o)) {
      if (isObject(v))
        if (isEmpty(v)) delete o[k];
        else _trimObjectRecurse(v);
      else if (isArray(v))
        if (isEmpty(v)) delete o[k];
        else v.map(_trimObjectRecurse);
      else if (isString(v) && isEmpty(v.trim())) delete o[k];
    }
    return o;
  }
  return sortKeys(_trimObjectRecurse(messyObj));
};

/**
 * Returns an object representing the difference between two objects. More readable than isEqual.
 * Symmetric difference, so all different keys from (a, b) and (b, a) are included.
 * Value shown may be from a or b.
 * @param obj1 First object
 * @param obj2 Second object
 * @returns An object representing the difference between a and b, or a string if they are equal
 */
/*
 * WET notice: Duplicated in column/ts
 * I adapted this from there, but didn't delete that version.
 * It's not referenced anywhere but idk if it's still used somehow.
 */
export const deepDifference = (obj1: object, obj2: object): object | string => {
  if (isEqual(obj1, obj2)) return 'isEqual(a, b) is true';
  function _diffRecurse(a: object, b: object): object {
    return transform(a, function (result, value, key) {
      if (!isEqual(value, b[key])) {
        // @ts-expect-error
        result[key] = isObject(value) && isObject(b[key]) ? _diffRecurse(value, b[key]) : value;
      }
    });
  }
  return sortKeys(merge(_diffRecurse(obj1, obj2), _diffRecurse(obj1, obj2)));
};

export default { sortKeys, trimObject, deepDifference };
