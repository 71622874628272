
type AssertType = (expression: boolean, message?: string) => asserts expression is true;

const assert: AssertType = (expression: boolean, message?: string): asserts expression is true => {
  if (expression === false) {
    throw new Error(message);
  }
};

export default assert;
