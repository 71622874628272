import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Result from './Result';
import { shouldLoadMoreDataForContainer } from '../../helpers';

import './results.scss';

export const classNameScope = 'common--components--Autocomplete--autocomplete--results';

class Results extends React.Component {
  static propTypes = {
    isLoadingMore: PropTypes.bool,
    onLoadMore: PropTypes.func,
    onChooseResult: PropTypes.func,
    onResultsVisibilityChanged: PropTypes.func.isRequired,
    onResultFocusChanged: PropTypes.func.isRequired,
    results: PropTypes.array,
    visible: PropTypes.bool,
    focusedResult: PropTypes.number,
    collapsible: PropTypes.bool,
    renderResult: PropTypes.func
  };

  static defaultProps = {
    results: [],
    isLoadingMore: false
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleScrollForAutocompleteResult = () => {
    if (shouldLoadMoreDataForContainer(this.autocompleteResultsContainerRef)) {
      this.props.onLoadMore();
    }
  }

  handleKeyDown = (event) => {
    const {
      visible,
      focusedResult,
      results,
      onResultFocusChanged,
      onChooseResult,
      onResultsVisibilityChanged
    } = this.props;

    // return if results aren't even visible...
    if (!visible) {
      return;
    }

    const hasFocusedResult = !_.isUndefined(focusedResult);

    // ArrowDown scrolls down
    if (event.keyCode === 40) {
      // either focus on the first result, or increment the focused result
      onResultFocusChanged(!hasFocusedResult ? 0 : focusedResult + 1);
      event.preventDefault();
    }

    // ArrowUp scrolls up
    if (event.keyCode === 38 && hasFocusedResult) {
      // decrement the focused result; reducer handles making sure it doesn't go past the list
      onResultFocusChanged(focusedResult - 1);
      event.preventDefault();
    }

    // Enter searches for selected result
    if (event.keyCode === 13 && hasFocusedResult) {
      if (_.isEmpty(results)) {
        return;
      }

      // goto search if we have a result
      const result = results[focusedResult];
      if (!_.isUndefined(result)) {
        onResultsVisibilityChanged(false);
        onChooseResult(result.title, result);
      }
    }
  }

  renderResults = () => {
    const {
      onResultFocusChanged,
      onResultsVisibilityChanged,
      results,
      isLazyLoading,
      focusedResult,
      onChooseResult,
      renderResult
    } = this.props;

    if (!results || !results.map) {
      return;
    }

    if (_.isUndefined(renderResult) || isLazyLoading) {
      return results.map((result, index) => (
        <Result
          key={`${result.title}_${index}`}
          matchOffsets={result.matches}
          name={result.title}
          index={index}
          focused={index === focusedResult}
          onResultFocusChanged={onResultFocusChanged}
          onResultsVisibilityChanged={onResultsVisibilityChanged}
          onChooseResult={() => { onChooseResult(result.title, result); }}
        />
      ));
    } else {
      return results.map((result, index) => {
        return renderResult(
          result,
          index,
          index === focusedResult,
          (query, result) => onChooseResult(query, result),
          onResultsVisibilityChanged,
          onResultFocusChanged
        );
      });
    }
  }

  renderLoadingSpinner() {
    return (
      <div className="loading-spinner-container">
        <span className="spinner-default" />
      </div>
    );
  }

  render() {
    const { isLoadingMore, visible, results, collapsible } = this.props;

    if (!visible || results.length === 0) {
      return null;
    }

    return (
      <div
        className={`autocomplete-results-container ${classNameScope}--${collapsible ? 'results-container-collapsible' : 'results-container'}`}
        onScroll={this.handleScrollForAutocompleteResult}
        ref={(ref) => this.autocompleteResultsContainerRef = ref }
      >
        {this.renderResults()}
        {isLoadingMore && this.renderLoadingSpinner()}
      </div>
    );
  }
}

export default Results;

/** For testing purposes */
export const ResultsClass = Results;
