import React from 'react';
import { ForgeIcon, ForgeTooltip } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import SearchablePicklist from 'common/components/SingleSourceFilterBar/SearchablePicklist';
import { DropdownOption } from 'common/components/Forms/Dropdown';
import {
  Expr,
  FunCall,
  FunSpec,
  isNullLiteral,
  isTypedNullLiteral,
  nullLiteral,
  SoQLType,
  SoQLFunCall,
  TypedExpr,
  typedNullLiteral,
  TypedSoQLFunCall
} from 'common/types/soql';
import {
  emptyExprOfType,
  getFunSpec,
  isFunctionDisplayable,
  translateFunction,
  resolveConstraints,
  argSpecAtIndex
} from '../../lib/soql-helpers';
import { getOnlyAggregates } from '../../lib/selectors';
import { hasDefaultJoinConditionShape } from '../../lib/data-helpers';
import { ExprProps, isEditable, matchEexpr } from '../VisualExpressionEditor';
import { Key } from 'common/types/keyboard/key';
import * as _ from 'lodash';
import { none, Option, option, some } from 'ts-option';
import { getScrollTop } from '../../lib/scroll-helpers';
import { Eexpr } from 'common/explore_grid/types';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.change_function_picker' });

export const isFunctionApplicable =
  (call: TypedSoQLFunCall) =>
  (fs: FunSpec): boolean => {
    const constraints = resolveConstraints(call, fs);
    return call.args.reduce<boolean>((isApplicable, callArg, idx) => {
      const maybeArgSpec = argSpecAtIndex(fs, idx);
      // short circuit for error case
      if (!isApplicable) return isApplicable;

      // if there's no expression in this position for the spec, we can choose it, but we'll fill
      // the argument is as null
      // if there is no spec for this position in the expr, we'll remove it
      if (!callArg || maybeArgSpec.isEmpty) return isApplicable;
      // null works for anything, but doesn't add any type constraints
      if (isTypedNullLiteral(callArg)) return isApplicable;

      const argSpec = maybeArgSpec.get;

      if (argSpec.kind === 'fixed') {
        // this function takes an explicit type for this arg, if the argument at that position
        // doesn't match, it won't work
        if (argSpec.type !== callArg.soql_type) return false;
      } else if (argSpec.kind === 'variable') {
        return _.includes(constraints[argSpec.type] || [], callArg.soql_type);
      }

      return isApplicable;
    }, true);
  };

interface ChangeState {
  args: Expr[];
  constraints: { [name: string]: SoQLType };
}
export const changeTo = (from: Eexpr<FunCall, TypedSoQLFunCall>, to: FunSpec): FunCall => {
  const result = _.zip(from.untyped.args, to.sig).reduce(
    (acc: ChangeState, [existingArg, argSpec], idx) => {
      // variadic case.
      // duplication from above is a bummer, but it might be more obnoxious to reason
      // about if we tried to abstract the variadic case, since there's already enough
      // going on here
      if (!argSpec && !_.isEmpty(to.variadic)) {
        argSpec = to.variadic[(idx - to.sig.length) % to.variadic.length];
      }

      // if theres no specification for this position even after considering varidic args,
      // then nothing to do
      if (!argSpec) return acc;

      const existingArgTyped: TypedExpr = matchEexpr(
        from,
        (editable) => editable.typed.args[idx],
        (_unknown) => typedNullLiteral
      );

      // if there is an existing argument for this slot in the
      // spec, place it there. Note that we can't tell the difference between
      // existing args with user values and existing args with default values, so
      // there are always kept
      if (existingArg && !isNullLiteral(existingArg)) {
        const args = [...acc.args, existingArg];

        if (argSpec.kind === 'variable' && existingArgTyped.soql_type) {
          // if we're placing a non-null expr here, we
          // update the constraint map
          return {
            constraints: {
              ...acc.constraints,
              [argSpec.type]: existingArgTyped.soql_type
            },
            args
          };
        }
        return { ...acc, args };
      } else {
        // there is no expr arg for this slot in the spec,
        // example:
        // changing from `foo > 2` to `foo between 2 and 4`
        // so we needd to figure out what empty value to put there
        let emptyArg: Expr = nullLiteral;
        if (argSpec.kind === 'fixed') {
          // simple case: the function sig specifies explicitly what
          // the type needs to be at this position
          emptyArg = emptyExprOfType(argSpec.type);
        } else if (argSpec.kind === 'variable') {
          // trickier case: the function spec allows for variable types
          // at this position.
          // if an argument came before that already said what the variable
          // type needs to be, it will be in the constraint map we're building
          // if not, then we'll leave it as a nullLiteral
          // example above: `foo between 2 and 4`
          const variableTypeConstraint = acc.constraints[argSpec.type];
          if (variableTypeConstraint) {
            emptyArg = emptyExprOfType(variableTypeConstraint);
          }
        }

        return { ...acc, args: [...acc.args, emptyArg] };
      }
    },
    { args: [], constraints: {} }
  );

  let call: FunCall = {
    type: 'funcall',
    function_name: to.name,
    args: result.args,
    window: null
  };

  // special cases. We may pull this out to a separate function
  // when we add enough of these for usability, but for now it's fine
  if (call.function_name === SoQLFunCall.In) {
    // special case for #IN is it only specifies a single argument, but
    // actually fails to parse if you do `foo IN ()`. it's unclear if this
    // is a feature or a bug, so just plop an empty literal in there
    // if we hit this case
    if (call.args.length <= 1) {
      const inType = result.constraints[to.sig[0].type];
      call = {
        ...call,
        args: [...call.args, emptyExprOfType(inType)]
      };
    }
  }

  return call;
};

interface GroupedFunction {
  group: 'common' | 'complex';
  fs: FunSpec;
  precedence: Option<number>;
}
const noFunctionGrouping = (fs: FunSpec): GroupedFunction => {
  return { fs, group: t('complex'), precedence: none };
};
const functionGrouper =
  (st: SoQLType) =>
  (fs: FunSpec): GroupedFunction => {
    const get = (top: string[]): GroupedFunction => {
      const offset = _.indexOf(top, fs.name);
      const precedence = offset === -1 ? none : some(offset);

      return {
        fs,
        group: precedence.isDefined ? t('common') : t('complex'),
        precedence
      };
    };

    switch (st) {
      case SoQLType.SoQLTextT: {
        return get([
          SoQLFunCall.CaselessContains,
          SoQLFunCall.CaselessIs,
          SoQLFunCall.CaselessIsNot,
          SoQLFunCall.CaselessNotOneOf,
          SoQLFunCall.CaselessOneOf,
          SoQLFunCall.CaselessStartsWith
        ]);
      }
      case SoQLType.SoQLNumberT: {
        return get([
          SoQLFunCall.In,
          SoQLFunCall.NotIn,
          'op$=',
          'op$!=',
          'op$<',
          'op$>',
          'op$<=',
          'op$>=',
          SoQLFunCall.Between,
          SoQLFunCall.NotBetween
        ]);
      }
      case SoQLType.SoQLFixedTimestampT:
      case SoQLType.SoQLFixedTimestampAltT:
      case SoQLType.SoQLFloatingTimestampT:
      case SoQLType.SoQLFloatingTimestampAltT: {
        return get([
          'op$=',
          'op$!=',
          'op$<',
          'op$>',
          'op$<=',
          'op$>=',
          SoQLFunCall.Between,
          SoQLFunCall.NotBetween
        ]);
      }
    }
    return { fs, group: t('complex'), precedence: none };
  };

type ChangeFunctionProps = ExprProps<FunCall, TypedSoQLFunCall> & {
  onlyAggregates?: boolean;
  showPickerByDefault?: boolean;
  onBlur?: () => void;
  // allows you to override the functions name
  formatFunctionName?: (functionName: string, translatedName: string) => string;
};

interface ChangeFunctionState {
  filter: Option<string>;
  showPicker: boolean;
}
class ChangeFunction extends React.Component<ChangeFunctionProps, ChangeFunctionState> {
  functionButtonParent: HTMLDivElement;

  constructor(props: ChangeFunctionProps) {
    super(props);
    const { showPickerByDefault } = props;

    if (showPickerByDefault) {
      this.state = { filter: none, showPicker: showPickerByDefault };
    } else {
      this.state = { filter: none, showPicker: false };
    }
  }

  onBlur() {
    this.hidePicker();
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const elementToFocus =
      (this.functionButtonParent.querySelector('.btn-default') as HTMLButtonElement) ||
      this.functionButtonParent;
    switch (event.key) {
      case Key.ArrowDown:
        this.showPicker();
        break;
      case Key.ArrowUp:
      case Key.Escape:
        elementToFocus.focus();
        break;
      case Key.Enter:
        event.preventDefault();
        this.showPicker();
        break;
      default:
        break;
    }
  };

  // function is passed to children so that they can set focus back here upon Up arrow key navigation.
  onArrowNavigationFromChild = () => {
    const element =
      (this.functionButtonParent.querySelector('.btn-default') as HTMLButtonElement) ||
      this.functionButtonParent;
    element.focus();
  };

  onFilterFunctions = (value: string) => {
    if (value === '') {
      this.setState({ filter: none });
    } else {
      this.setState({ filter: some(value) });
    }
  };

  togglePicker = () => {
    this.setState({ showPicker: !this.state.showPicker });
  };

  showPicker = () => {
    this.setState({ showPicker: true });
  };

  hidePicker = () => {
    this.setState({ showPicker: false });
  };

  matchesFilter = (fs: FunSpec): boolean => {
    return this.state.filter.match({
      none: () => true,
      some: (filter) => {
        const normalized = filter.toLowerCase().trim();
        return (
          _.includes(fs.name.toLowerCase(), normalized) ||
          _.includes(translateFunction(fs).toLowerCase(), normalized)
        );
      }
    });
  };

  render() {
    const { scope, eexpr, update, children, onlyAggregates, unAnalyzedJoin, formatFunctionName } = this.props;

    let thisFunSpec: Option<FunSpec> = none;
    // if we don't have type analysis, we don't filter anything out
    let isApplicable = (_fs: FunSpec) => true;
    let doesntChangeBlockType = (_fs: FunSpec) => true;

    if (isEditable(eexpr)) {
      thisFunSpec = getFunSpec(eexpr.typed, scope);
      isApplicable = isFunctionApplicable(eexpr.typed);
      doesntChangeBlockType = (fs: FunSpec) => {
        if (fs.result.kind === 'fixed') {
          // if the candidate function spec is a fixed type, and that equals
          // the current call's type, then nothing is changing and we're good
          return _.isEqual(eexpr.typed.soql_type, fs.result.type);
        } else if (fs.result.kind === 'variable') {
          // if the candidate function spec is variable,
          // we need to figure out what the type is in this call context
          // say the typed expr is
          //  count(foo:number) -> number
          // and candidate function spec (aka fs) is
          //  sum(a) -> a
          // we need to figure out what concrete type a is. so we work backwards
          // from the result type, find an arg of that same type, and then use
          // that to resolve the concrete type.
          const argIndex = fs.sig.findIndex((argSpec) => argSpec.type === fs.result.type);
          return option(eexpr.typed.args[argIndex])
            .map((arg) => arg.soql_type)
            .map((concreteType) => concreteType === eexpr.typed.soql_type)
            .getOrElseValue(false);
        }
        // can't get here, but this appeases tsc
        return false;
      };
    }

    // we group based on what type the current expression is.
    // if we're in an untypechecked state, we need to fall back to a noop grouper.
    // some design work may be necessary to refine that state

    // TODO: is this right? we're keying off the first arg only?
    const argType: Option<SoQLType> = isEditable(eexpr)
      ? option(eexpr.typed.args[0]).flatMap((firstArg) => option(firstArg.soql_type))
      : none;
    const grouper = argType.map(functionGrouper).getOrElseValue(noFunctionGrouping);
    const [topAll, otherAll] = _.chain(scope)
      .filter(
        (fs) =>
          isFunctionDisplayable(fs) && this.matchesFilter(fs) && isApplicable(fs) && doesntChangeBlockType(fs) // EN-40960: need to fix boolean vs checkbox garbage before enabling this
      )
      .map(grouper)
      .partition((grouped) => grouped.precedence.isDefined)
      .value();

    const applicableAggregates = getOnlyAggregates(scope)
      .filter((fs) => {
        const constraints = _.flatMap(fs.constraints.a || []);
        return (
          isFunctionDisplayable(fs) &&
          this.matchesFilter(fs) &&
          argType
            .map((soqlType) => _.isEmpty(constraints) || constraints.includes(soqlType))
            .getOrElseValue(true)
        );
      })
      .map((fs) => ({ fs, group: t('complex'), precedence: none } as GroupedFunction));

    const top = onlyAggregates ? [] : topAll;
    const others = onlyAggregates ? applicableAggregates : otherAll;
    // This logic insures that the case sensitive warning is not rendered on caseless and
    // any SoQLFunCall that ignore case (#IS_NULL and #IS_NOT_NULL). It is my hope that this
    // comment will show up in a code search if anyone adds or modifies the SoQLFunCall list
    // moving forward so that this logic can be expanded to either include or exclude the new
    // function as appropriate.
    const showCaseSensitivityWarning =
      argType.nonEmpty &&
      argType.get == 'text' &&
      !this.props.eexpr.untyped.function_name.startsWith('caseless_') &&
      !this.props.eexpr.untyped.function_name.includes('NULL');

    const options: DropdownOption<FunCall>[] = top
      .sort((a, b) => (a.precedence.get > b.precedence.get ? 1 : -1))
      .concat(others.sort((a, b) => (translateFunction(a.fs) > translateFunction(b.fs) ? 1 : -1)))
      .map(({ fs, group }) => {
        const render = () => (
          <div className="column-header-dropdown-item">
            {formatFunctionName ? formatFunctionName(fs.name, translateFunction(fs)) : translateFunction(fs)}
          </div>
        );

        return {
          render,
          group: top.length === 0 ? t('common') : group,
          value: changeTo(eexpr, fs)
        };
      });

    return (
      <div
        className="function-name-selector"
        data-testid="function-name-selector"
        onKeyDown={this.onKeyDown}
        ref={(div: HTMLDivElement) => {
          this.functionButtonParent = div;
        }}
      >
        {hasDefaultJoinConditionShape(unAnalyzedJoin) && <div className="function-arg-blank" />}
        <div className="toggle-function-picker" onClick={this.togglePicker}>
          {children}
        </div>
        {this.state.showPicker ? (
          <div className="function-picker" onBlur={() => this.onBlur()}>
            <SearchablePicklist
              options={options}
              canAddSearchTerm={() => Promise.resolve(true)}
              hideExactMatchPrompt={true}
              hideSearchInput={false}
              size={'large'}
              value={this.state.filter.getOrElseValue('')}
              onBlur={() => this.onBlur()}
              onChangeSearchTerm={this.onFilterFunctions}
              onClickSelectedOption={_.noop}
              onSelection={(op: DropdownOption<FunCall>) => {
                update(op.value);
                this.hidePicker();
              }}
              onArrowNavigationFromChild={this.onArrowNavigationFromChild}
              shouldReposition={true}
              getRepositionScrollOffset={getScrollTop}
            />
          </div>
        ) : null}{' '}
        {showCaseSensitivityWarning ? (
          <div>
            <ForgeIcon name="warning"></ForgeIcon>
            <ForgeTooltip>{t('case_sensitivity')}</ForgeTooltip>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ChangeFunction;
