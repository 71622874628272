import _ from 'lodash';
import { assert, assertHasProperty, assertIsNotNil, assertIsOneOfTypes } from 'common/assertions';
import DataProvider, { DataProviderConfig } from './DataProvider';
import SoqlDataProvider from './SoqlDataProvider';

import { fetchJsonWithDefaultHeaders } from 'common/http';
import { appToken } from 'common/http';
import { POINT_COLUMN_TYPES } from 'common/authoring_workflow/constants';
import { View } from 'common/types/view';
import { ColumnFormat, ViewColumn } from 'common/types/viewColumn';
import { SoQLType } from 'common/types/soql';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';

import { FeatureFlags } from 'common/feature_flags';
import { getParameterOverrides } from '../helpers/VifSelectors';

export interface DatasetMetadataAndFederationStatus {
  metadata: View;
  federatedFrom?: string | null;
}

export interface ColumnAggregation {
  fieldName: string;
  aggregationType: string;
  dataTypeName: SoQLType;
  format: ColumnFormat;
  soqlExpression: string;
}

export interface MetadataProviderConfig extends DataProviderConfig {
  domain?: string;
  readFromNbe?: boolean;
  datasetUid: string;
}

/*
 * Static helper functions.
 */

export const isSystemColumn = (fieldName: string) => {
  return fieldName[0] === ':';
};

// EN-13453: Don't try to pass along hidden columns.
// If a logged in user has write access to a view, the request for metadata will return hidden
// columns decorated with a nice hidden flag, instead of omitting the hidden columns completely.
export const isHiddenColumn = (flags: ViewColumn['flags']) => {
  return flags ? _.includes(flags, 'hidden') : false;
};

/*
 * CORE-4645 OBE datasets can have columns that have sub-columns. When converted to the NBE, these
 * sub-columns become their own columns. This function uses heuristics to figure out if a
 * column is likely to be a subcolumn (so not guaranteed to be 100% accurate!).
 *
 * This code is lifted from frontend: lib/common_metadata_methods.rb.
 */
export const isSubcolumn = (fieldName: string, datasetMetadata: View) => {
  assertIsOneOfTypes(fieldName, 'string');

  let returnValue = false;
  const columns = datasetMetadata.columns;
  const fieldNameByName = {};

  const fieldNameWithoutCollisionSuffix = fieldName.replace(/_\d+$/g, '');

  // EN-17640 - If we want pretty URL columns for NBE datasets, we need to let
  // the _description subcolumn through to reconstruct OBE-like URL columns
  const hasExplodedSuffix = /_(address|city|state|zip|type)$/.test(fieldNameWithoutCollisionSuffix);
  const matchedColumn = _.find(columns, _.matches({ fieldName: fieldName }));

  assertIsNotNil(matchedColumn, `could not find column ${fieldName} in dataset ${datasetMetadata.id}`);
  assertIsNotNil(
    matchedColumn.name,
    `column ${fieldName} in dataset ${datasetMetadata.id} does not hae a name`
  );

  // The naming convention is that child column names are the parent column name, followed by the
  // child column name in parentheses. Remove the parentheses to get the parent column's name.
  const parentColumnName = matchedColumn.name.replace(/(\w) +\(.+\)$/, '$1');

  /*
   * CORE-6925: Fairly brittle, but with no other clear option, it seems that
   * we can and should only flag a column as a subcolumn if it follows the
   * naming conventions associated with "exploding" location, URL, and phone
   * number columns, which is an OBE-to-NBE occurrence. Robert Macomber has
   * verified the closed set of suffixes in Slack PM:
   *
   *   _type for the type subcolumn on phones (the number has no suffix)
   *   _description for the description subcolumn on urls (the url itself has no suffix)
   *   _address, _city, _state, _zip for location columns (the point has no suffix)
   *
   * See also https://socrata.slack.com/archives/engineering/p1442959713000621
   * for an unfortunately lengthy conversation on this topic.
   *
   * Complicating this matter... there is no strict guarantee that any suffix
   * for collision prevention (e.g. `_1`) will belong to a user-given column
   * or an exploded column consistently. It's possible that a user will have
   * a column ending in a number. Given that we're already restricting the
   * columns that we're willing to mark as subcolumns based on the closed set
   * of (non-numeric) suffixes, and the low probability of this very specific
   * type of column name similarity, we'll strip numeric parts off the end of
   * the column name *before* checking the closed set. This leaves us with a
   * very low (but non-zero) probability that a user-provided column will be
   * marked as an exploded subcolumn.
   */
  if (parentColumnName !== matchedColumn.name && hasExplodedSuffix) {
    _.each(columns, (column) => {
      if (!column.name) return;
      fieldNameByName[column.name] = fieldNameByName[column.name] || [];
      fieldNameByName[column.name].push(column.fieldName);
    });

    // Look for the parent column
    // There are columns that have the same name as this one, sans parenthetical.
    // Its field_name naming convention should also match, for us to infer it's a subcolumn.
    returnValue = (fieldNameByName[parentColumnName] || []).some((parentFieldName: string) => {
      return parentFieldName + '_' === fieldName.substring(0, parentFieldName.length + 1);
    });
  }

  return returnValue;
};

// Given a dataset metadata object (see .getDatasetMetadata()),
// returns an array of the computed columns.
//
// @return {Object[]}
export const getComputedColumns = (datasetMetadata: View) => {
  assertHasProperty(datasetMetadata, 'columns');

  return _.chain(datasetMetadata.columns)
    .filter((column) => _.has(column, 'computationStrategy.parameters.region'))
    .map((column) =>
      _.extend({}, column, {
        fieldName: column.fieldName,
        name: column.name,
        // since we've filtered to only columns that have this property, it's safe to assert it
        uid: column.computationStrategy!.parameters!.region!.slice(1)
      })
    )
    .sortBy('name')
    .value();
};

// Given a dataset metadata object (see .getDatasetMetadata()),
// returns an array of the columns which are suitable for
// display to the user (all columns minus system and subcolumns).
//
// @return {Object[]}
export const getDisplayableColumns = (datasetMetadata: View) => {
  assertHasProperty(datasetMetadata, 'columns');

  return _.reject(datasetMetadata.columns, (column) => {
    return (
      isSystemColumn(column.fieldName) ||
      isSubcolumn(column.fieldName, datasetMetadata) ||
      isHiddenColumn(column.flags)
    );
  });
};

/**
 * Returns columns that are support by our filtering experience.
 * These columns include numbers (with column stats), text and
 * calendar dates.
 */
export const getFilterableColumns = (datasetMetadata: { columns: View['columns'] }) => {
  assertHasProperty(datasetMetadata, 'columns');

  return _.filter(datasetMetadata.columns, (column) => {
    const dataTypeName = column.dataTypeName;

    return _.includes(
      ['money', 'number', 'text', 'calendar_date', 'date', 'checkbox', ...POINT_COLUMN_TYPES],
      dataTypeName
    );
  });
};

/**
 * `MetadataProvider` is an implememntation of `DataProvider` that enables users
 * to get information about a dataset
 *
 * @param {Object} config
 *        Information about your dataset, `domain` and `datasetUid` are required
 * @param {bool|false} useCache
 *        Whether or not to use the cached instance of this "class". The cache
 *        will last the lifetime of this page view, and will prevent identical
 *        network requests from being made. For most cases, it's fine to pass true.
 * @return {MetaDataProvider}
 */
class MetadataProvider extends DataProvider {
  cachedMetadataResponses: { [cacheKey: string]: Promise<View> };
  useCache: boolean;
  soqlDataProvider: any;

  constructor(config: MetadataProviderConfig, useCache = false) {
    super({ ...config, domain: config.domain || getDefaultDomain() } as MetadataProviderConfig);

    assertHasProperty(this.config, 'domain');
    assertHasProperty(this.config, 'datasetUid');

    assertIsOneOfTypes(this.config.domain, 'string');
    assertIsOneOfTypes(this.config.datasetUid, 'string');

    this.useCache = useCache;
    this.cachedMetadataResponses = {};

    if (useCache) {
      const cached = this.cachedInstance('MetadataProvider');
      if (cached) {
        return cached as MetadataProvider;
      }
    }

    this.soqlDataProvider = new SoqlDataProvider(
      {
        domain: this.getConfigurationProperty('domain'),
        datasetUid: this.getConfigurationProperty('datasetUid')
      },
      true
    );
  }

  /**
   * Gets aggregation suggestions defined for a dataset's columns, e.g. percent aggregations
   * The returned list of suggestions is structured as follows:
   * [{
   *   fieldName,
   *   dataTypeName,
   *   aggregationType,
   *   format,
   *   soqlExpression
   * }, ...]
   *
   * The `soqlExpression` field is a string that can be directly placed inside
   * a SoQL query and represents the aggregated value of the column.
   *
   * When removing enable_flexible_table_hierarchies: also remove this comment
   * When a visualization is configured to use one of the returned aggregations, we set
   * the `nonStandardAggregation` flag on the VIF (`series[n].dataSource.nonStandardAggregation`).
   * When this flag is set, the viz will load the suggested aggregations using this method,
   * and then use the appropriate `soqlExpression` when generating and executing the queries.
   * When rendering the returned data, the viz will use the `format` property to format the
   * column values.
   */
  async getAggregationSuggestions(): Promise<ColumnAggregation[]> {
    const datasetUid = this.getConfigurationProperty('datasetUid');
    const readFromNbe = this.getOptionalConfigurationProperty('readFromNbe', true);

    let url = `api/views/${datasetUid}.json/aggregation_suggestions`;

    if (readFromNbe) {
      url = url + '?read_from_nbe=true&version=2.1';
    }

    try {
      const aggregations = await this.makeMetadataRequest(url, { federation: false, redirect: 'error' });
      return aggregations;
    } catch (e) {
      const aggregations = await this.makeMetadataRequest(url, { federation: true });
      return aggregations;
    }
  }

  /**
   * Gets dataset metadata from /api/views/4x4.json.
   *
   * NOTE:
   * Columns are structured in an Array.
   * (See: https://localhost/api/docs/types#View)
   */
  async getDatasetMetadata(): Promise<View> {
    return (await this.getDatasetMetadataAndFederationStatus()).metadata;
  }

  /**
   * Gets dataset metadata from /api/views/4x4.json.
   *
   * Returns:
   * {
   *   metadata: Object,
   *   federatedFrom: null or home domain primary cname
   * }
   *
   * NOTE:
   * Columns are structured in an Array.
   * (See: https://localhost/api/docs/types#View)
   */
  async getDatasetMetadataAndFederationStatus(): Promise<DatasetMetadataAndFederationStatus> {
    const datasetUid = this.getConfigurationProperty('datasetUid');
    const readFromNbe = this.getOptionalConfigurationProperty('readFromNbe', true);
    let url = `api/views/${datasetUid}.json`;

    if (readFromNbe) {
      url = url + '?read_from_nbe=true&version=2.1';
    }

    // TODO EN-39798: can we just use sourceDomainCName instead? If we pass X-Socrata-Federation header,
    // then we should get that field back for all federated views (contingent on the user's right to know
    // that the view is federated), empty for unfederated views.
    // ex: federatedFrom = metadata.sourceDomainCName ? metadata.domainCName : null
    try {
      const metadata = await this.makeMetadataRequest(url, { federation: false, redirect: 'error' });
      return {
        metadata,
        federatedFrom: null // Did not redirect, so no federation.
      };
    } catch (e) {
      // Dataset may be federated - browsers don't tell us if it's a 3xx or not, so we have to guess.
      // We care that the dataset is federated so we may figure out what domain to use for attributionDomain,
      // see comments in getAttributionDomain.
      // Note that the `domain` field in the metadata response is always provided if the federation header
      // is sent. We can't simply compare `domain` with `window.location.hostname` because `domain` is always
      // set to the primary cname, and we may be on an alias!
      const metadata = await this.makeMetadataRequest(url, { federation: true });
      return {
        metadata,
        federatedFrom: metadata.domainCName // Since we *know* we're federated, this field is for sure the home domain primary cname.
      };
    }
  }

  /**
   * Gets output columns for a given soql query
   *
   * Output is an array of column details
   */
  getOutputColumnsForQuery(soqlQuery: string) {
    const datasetUid = this.getConfigurationProperty('datasetUid');
    const readFromNbe = this.getOptionalConfigurationProperty('readFromNbe', true);
    let url =
      `api/views/${datasetUid}.json?` +
      `method=getColumnsForViewWithQuery&query=${encodeURIComponent(soqlQuery)}`;

    if (readFromNbe) {
      url = url + '&read_from_nbe=true&version=2.1&';
    }

    return this.makeMetadataRequest(url);
  }

  getCuratedRegions() {
    return this.makeMetadataRequest('api/curated_regions');
  }

  // Gets the domain to use for the attribution ("view source data") domain.
  // Returns null if the asset should not be attributed to at all.
  async getAttributionDomain(
    datasetMetadataAndFederationStatus: DatasetMetadataAndFederationStatus | null = null
  ) {
    datasetMetadataAndFederationStatus = await (datasetMetadataAndFederationStatus ||
      this.getDatasetMetadataAndFederationStatus());

    return await this.getDataSourceDomain(datasetMetadataAndFederationStatus);
  }

  // Gets the domain to use for data requests.
  async getDataSourceDomain(
    datasetMetadataAndFederationStatus: DatasetMetadataAndFederationStatus | null = null
  ) {
    // This is tricky because this depends on the federation status of the dataset (which our API doesn't make plain,
    // see getDatasetMetadata).
    // 1. If the dataset is not federated:
    //   1a. Use the VIF domain if specified, or
    //   1b. Use the page domain.
    // 2. If the dataset is federated:
    //   2a. Use the `domain` field of the metadata response [Not implemented, but would be nice: if the dataset is accessible to the user].
    //   2b. Use the page domain.
    const { federatedFrom } = await (datasetMetadataAndFederationStatus ||
      this.getDatasetMetadataAndFederationStatus());

    return federatedFrom || this.getConfigurationProperty('domain');
  }

  // In short, a view's base view is the view which supplies a view's metadata blob.
  // I'm sorry about how complicated this is. We're in a split world where
  //   A) We place important things into the view metadata (i.e. rowLabel),
  //   B) AX and the visualizations primarily work with NBE replicas,
  //   C) We have an OBE->NBE sync which does _not_ sync over the view metadata field,
  //   D) We're given any one of these as datasetUid:
  //    1. UID of NBE-only dataset.
  //    2. UID of NBE replica of OBE dataset.
  //    3. UID of a derived view (they don't have separate NBE UIDs).
  //      3a. The derived view may have a modifyingViewUid.
  // This means that we must always
  //   1) Check to see which of the 3 types of UIDs we've been given.
  //   2) If derived view, find parent view.
  //   3) Read metadata from OBE replica, if it exists.
  //      If it does not exist, read from base view.
  //      If that too does not exist, read from datasetUid.
  //
  // Important note: This method may fail due to permissions issues. If it does,
  // the recommended course of action is to fall back to the plain dataset metadata
  // from getDatasetMetadata - it is likely impossible for the user to obtain access
  // to the base view (especially when it comes to modifyingViewUid, which exists
  // purely to allow users to create derived views on datasets they have access to only
  // through a redacted derived view).
  async getBaseViewMetadata() {
    const domain = this.getConfigurationProperty('domain');

    // Lack of migration means we're either A) NBE-only, B) derived
    // In either case, we can call core to getDefaultView.
    // That should traverse through modifyingViewUid, but I'm not 100%
    // sure. If the view still has a modifyingViewUid, grab its metadata.
    const defaultView = await this.getDefaultView();

    if (defaultView.modifyingViewUid) {
      return new MetadataProvider({
        domain,
        datasetUid: defaultView.modifyingViewUid
      }).getDatasetMetadata();
    }

    return defaultView;
  }

  async getDefaultView() {
    const datasetUid = this.getConfigurationProperty('datasetUid');
    const url = `api/views/${datasetUid}.json?method=getDefaultView&accessType=WEBSITE`;

    return this.makeMetadataRequest(url);
  }

  async getDatasetMigrationMetadata() {
    const datasetUid = this.getConfigurationProperty('datasetUid');
    const url = `api/migrations/${datasetUid}.json`;

    return this.makeMetadataRequest(url);
  }

  async getShapefileMetadata() {
    const datasetUid = this.getConfigurationProperty('datasetUid');

    const curatedRegionsUrl = `api/curated_regions?method=getByViewUid&viewUid=${datasetUid}`;
    const curatedRegionsRequest = this.makeMetadataRequest(curatedRegionsUrl);

    return curatedRegionsRequest
      .then((curatedRegionsResponse) => {
        const curatedRegionsGeometryLabel = _.get(curatedRegionsResponse, 'geometryLabel', null);
        const geometryLabel = curatedRegionsGeometryLabel;

        const curatedRegionsFeaturePk = _.get(curatedRegionsResponse, 'featurePk', null);
        const featurePk = curatedRegionsFeaturePk || '_feature_id';

        return {
          geometryLabel: geometryLabel,
          featurePk: featurePk
        };
      })
      .catch(() => ({
        geometryLabel: null,
        featurePk: null
      }));
  }

  /**
   * Returns the result of getDisplayableColumns and getFilterableColumns.
   * Also augments the column metadata with column stats for historical (bad?)
   * reasons.
   * @param datasetMetadata A metadata object or a Promise for a metadata object
   *                        (optional; will fetch fresh metadata if omitted)
   */
  async getDisplayableFilterableColumns({
    datasetMetadata = this.getDatasetMetadata(),
    shouldGetColumnStats = true
  }: {
    datasetMetadata?: Promise<View> | View;
    shouldGetColumnStats?: boolean;
  }) {
    const metadataPromise =
      datasetMetadata instanceof Promise ? datasetMetadata : Promise.resolve(_.cloneDeep(datasetMetadata));

    const _datasetMetadata = await metadataPromise;
    const displayableColumns = getDisplayableColumns(_datasetMetadata);
    let columns = displayableColumns;

    if (shouldGetColumnStats) {
      const columnStats = await this.soqlDataProvider.getColumnStats(displayableColumns);
      // Since we merge by index, column stats array should line up perfectly with displayable columns.
      assert(
        columnStats.length === displayableColumns.length,
        'column stats length and displayable columns length do not match'
      );
      columns = _.merge([], columnStats, displayableColumns);
    }

    return getFilterableColumns({ columns });
  }

  /**
   * Gets the component data for a platform measure.
   * Assumes datasetUid is for a platform measure, with no handling.
   * This is only used for platform measures embedded in a story,
   * as normal platform measures come from Ruby.
   */
  async getPlatformMeasure() {
    const datasetUid = this.getConfigurationProperty('datasetUid');
    const url = `/api/measures_v1/${datasetUid}.json`;
    return this.makeMetadataRequest(url);
  }

  resetCache() {
    this.cachedMetadataResponses = {};
  }

  /**
   * Calls Core API with the current dataset uid,
   * or returns a cached response if available
   * GET /api/views/<uid>/client_context
   * ref: https://socrata.atlassian.net/wiki/spaces/PD/pages/2137587828/User+Context+Variables+API+Notes
   */
  async getAvailableParameters(): Promise<ClientContextVariable[]> {
    const datasetUid = this.getConfigurationProperty('datasetUid');
    const apiPath = `/api/views/${datasetUid}/client_context`;

    try {
      const parameters = await this.makeMetadataRequest(apiPath);

      return _.cloneDeep(parameters);
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  private async makeMetadataRequest(path: string, options = {}) {
    const domain = this.getConfigurationProperty('domain');
    const url = `https://${domain}/${path}`;
    const federation = _.get(options, 'federation', true);
    const redirect = _.get(options, 'redirect', null);

    const cacheKey = `${domain}-${url}-${federation}`;
    const cachedPromise = this.cachedMetadataResponses[cacheKey];
    if (cachedPromise && this.useCache) {
      return cachedPromise;
    }

    const fetchOptions = {
      credentials: 'same-origin',
      ...(redirect && { redirect }),
      headers: { ...(federation && { 'X-Socrata-Federation': 'Honey Badger' }) }
    };

    fetchOptions.headers['X-App-Token'] = appToken();

    const metadataRequestPromise = fetchJsonWithDefaultHeaders(url, fetchOptions);

    this.cachedMetadataResponses[cacheKey] = metadataRequestPromise;

    return metadataRequestPromise;
  }
}

export default MetadataProvider;
