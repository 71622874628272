import { compact, isUndefined, reduce } from 'lodash';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import { BinaryTree, Expr } from 'common/types/soql';
import { buildLiteral } from './expr';
import functions, { wfOver } from './function';
import { buildSelect, buildLiteralSoqlSelect } from './select';
import { buildDistinctOn } from './distinct';
import { buildOrderBy } from './orderBy';
import {
  buildInnerJoin, buildOuterJoin,
  buildLeftJoin, buildRightJoin, buildFullJoin,
  buildSubSelect, decorateJoinWithLateral
} from './join';
import { QueryParts, buildQuery }  from './query/build';
import compounds, { connectQueries }  from './query/compound';
import { renderQuery } from './query/render';
import { buildTableName } from './tableName';
import { buildQualifiedViewColumn, encodeStringAsColumnRef } from './util';
import { buildWindowFunction } from './windowFunction';
import { MAX_URL_SIZE } from 'common/utilities/Constants';

const connectExprs = (
  connector: typeof functions.and,
  clauses: Expr[]
) => reduce(clauses.slice(1), (nested, clause) => connector(nested, clause), clauses[0]);
/**
 * Concatenates Expressions with ANDs; easier than doing this manually.
 * @param clauses Expressions that you wish to AND together, given as arguments.
 */
const buildWhereAnd = (...clauses: (Expr | undefined)[]) => connectExprs(functions.and, compact(clauses));
/**
 * Concatenates Expressions with ORs; easier than doing this manually.
 * @param clauses Expressions that you wish to OR together, given as arguments.
 */
const buildWhereOr = (...clauses: (Expr | undefined)[]) => connectExprs(functions.or, compact(clauses));

/**
 * Construct a SoQL query string from objects defined by the SoQL Builder DSL.
 * @param parts.selects An array of SELECT objects to include. Required.
 * @param parts.from An object with a fourfour name and an alias.
 * @param parts.distinct {boolean} Whether or not to include the DISTINCT keyword. {@link https://dev.socrata.com/docs/functions/distinct.html}
 * @param parts.joins An array of JOIN objects to include.
 * @param parts.where An Expression to use as the WHERE clause.
 * @param parts.groups An array of GROUP BY objects to include.
 * @param parts.having An Expression to use as the WHERE clause.
 * @param parts.orders An array of ORDER BY objects to include.
 * @param parts.search {string} A string after the SEARCH keyword, for full-text search. Somewhat vestigial.
 * @param parts.limit {number} The number after the LIMIT keyword.
 * @param parts.offset {number} The number after the OFFSET keyword.
 * @see https://github.com/socrata/platform-ui/tree/main/common/soql_builder/
 */
export const composeQuery = (parts: QueryParts | BinaryTree<QueryParts>): string => renderQuery(buildQuery(parts));
export const chainQueries = (...queries: string[]): string => queries.join(' |> ');

/**
 * Concatenates Queries with UNIONs; easier than doing this manually.
 * @param queries Query Parts that you wish to UNION together, given as arguments.
 */
const buildUnion = (...queries: (QueryParts | undefined)[]) => connectQueries(compounds.union, compact(queries));
/**
 * Concatenates Queries with UNION ALLs; easier than doing this manually.
 * @param queries Query Parts that you wish to UNION ALL together, given as arguments.
 */
const buildUnionAll = (...queries: (QueryParts | undefined)[]) => connectQueries(compounds.unionAll, compact(queries));
/**
 * Concatenates Queries with INTERSECTs; easier than doing this manually.
 * @param queries Query Parts that you wish to INTERSECT together, given as arguments.
 */
const buildIntersect = (...queries: (QueryParts | undefined)[]) => connectQueries(compounds.intersect, compact(queries));
/**
 * Concatenates Queries with MINUSs; easier than doing this manually.
 * @param queries Query Parts that you wish to MINUS together, given as arguments.
 */
const buildMinus = (...queries: (QueryParts | undefined)[]) => connectQueries(compounds.minus, compact(queries));
/**
 * Concatenates Queries with PIPEs; easier than doing this manually.
 * @param queries Query Parts that you wish to PIPE together, given as arguments.
 */
const buildChain = (...queries: (QueryParts | undefined)[]) => connectQueries(compounds.chain, compact(queries));


export type ResourceURI = string;
const uriRegex = /(?:https:\/\/.*?)?\/(?:api\/id|resource)\/(?<uid>\w{4}-\w{4}).json/;
const isResourceURI = (uri: string): uri is ResourceURI => uriRegex.test(uri);

/**
 * A parameterized SoQL query is a function which accepts bindings as an array
 * and outputs the result of composeQuery or chainQueries.
 */
export type ParameterizedSoqlQuery = (...params: any[]) => string;
/**
 * Fetch a JSON response from the Socrata API according to the prepared SoQL query.
 * @param resourceUri A string in the shape of 'https://some.domain/resource/four-four.json'.
 * @param preparedStatement A function that returns the result of composeQuery or chainQueries.
 * @param parameters An arbitrary array of arguments to pass into the preparedStatement.
 * @param soqlVersion Assumed 2.0 but prefer 2.1
 * @param clientContextURIEncoded An optional string of key/value client context variable override pairs
 */
export const invoke = <T>(
  resourceUri: ResourceURI,
  preparedStatement: ParameterizedSoqlQuery,
  parameters: any[],
  soqlVersion = 2.0,
  clientContextURIEncoded?: string
): Promise<T> => {
  const regexMatches = resourceUri.match(uriRegex);
  if (isResourceURI(resourceUri) && regexMatches) {
    const queryUid = regexMatches[1];
    const query = preparedStatement(...parameters);
    const version = soqlVersion === 2.0 ? undefined : soqlVersion;

    const makeQuery = (queryParams: object) => {
      return reduce(queryParams, (result, value, key) => {
        if (!isUndefined(value)) {
          result.push(`${key}=${encodeURIComponent(value)}`);
        }
        return result;
      }, [] as string[]).join('&');
    };

    let urlQuery = makeQuery({ '$query': query, '$$version': version });

    if (clientContextURIEncoded) {
      urlQuery = urlQuery + clientContextURIEncoded;
    }

    const fullURL = `${resourceUri}?${urlQuery}`;
    if (fullURL.length > MAX_URL_SIZE) {
      return fetchJsonWithDefaultHeaders(
        `/api/query/${queryUid}.json?${makeQuery({'$$version': version})}`, {
        method: 'POST',
        body: JSON.stringify({query})
      });
    } else {
      return fetchJsonWithDefaultHeaders(fullURL);
    }
  } else {
    throw new Error('Given resourceUri is not a resource uri.');
  }
};

export {
  buildSelect as select,
  buildLiteralSoqlSelect as selectLiteral,
  buildDistinctOn as distinctOn,
  buildInnerJoin as innerJoin, buildOuterJoin as outerJoin,
  buildLeftJoin as leftJoin, buildRightJoin as rightJoin, buildFullJoin as fullJoin,
  buildTableName as tableName, buildSubSelect as subSelect,
  decorateJoinWithLateral as lateral,
  buildOrderBy as orderBy,
  buildWindowFunction as windowFunc, wfOver as over,

  functions as f, buildWhereAnd as and, buildWhereOr as or,
  compounds as c,
  buildUnion as union, buildUnionAll as unionAll, buildIntersect as intersect,
  buildMinus as minus, buildChain as chain, buildChain as pipe,

  buildLiteral as literal,
  encodeStringAsColumnRef as ref,
  buildQualifiedViewColumn as qualify
};
