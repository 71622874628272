import _ from 'lodash';
import circle from '@turf/circle';
import mapboxgl from '@socrata/mapbox-gl';


import MapHelper from 'common/visualizations/helpers/MapHelper';
import {
  RADIUS_FILTER_CIRCLE_COLOR,
  TO_METERS_CONVERSION_FACTORS
} from 'common/visualizations/views/mapConstants';

const LAYERS = Object.freeze({
  RADIUS_FILTER_CIRCLE: 'radius-filter-circle'
});

const SOURCES = Object.freeze({
  RADIUS_FILTER_CIRCLE: 'radius-filter-circle-source'
});

export default class RadiusFilterCircleOverlay {
  constructor(map) {
    this._map = map;
  }

  initialize = async () => {
    await MapHelper.afterMapLoad(this._map);

    this._map.addSource(SOURCES.RADIUS_FILTER_CIRCLE, {
      type: 'geojson',
      data: getRadiusFilterCircleGeojson()
    });

    this._map.addLayer({
      'id': LAYERS.RADIUS_FILTER_CIRCLE,
      'type': 'line',
      'source': SOURCES.RADIUS_FILTER_CIRCLE,
      'paint': {
        'line-color': RADIUS_FILTER_CIRCLE_COLOR,
        'line-width': 1
      }
    });
  }

  render = async (filter) => {
    this._currentFilter = filter;
    await MapHelper.afterMapLoad(this._map);

    if (this._currentFilter !== filter) {
      // Render method has been called multiple times and it should be sufficient to draw the
      // filter that was sent as argument to the last render method call.
      return;
    }

    this._map.getSource(SOURCES.RADIUS_FILTER_CIRCLE).
      setData(getRadiusFilterCircleGeojson(filter));
  }

  destroy = () => {
    _.each(LAYERS, (_name, layerId) => {
      this._map.removeLayer(layerId);
    });
    _.each(SOURCES, (_name, sourceId) => {
      this._map.removeSource(sourceId);
    });
  }
}

function getRadiusFilterCircleGeojson(filter) {
  let circleFeatures = [];

  const filterFunction = _.get(filter, 'function');
  const humanReadableLocation = _.get(filter, 'arguments[0].humanReadableLocation');
  const lat = _.get(filter, 'arguments[0].center[1]');
  const lng = _.get(filter, 'arguments[0].center[0]');
  const radius = _.get(filter, 'arguments[0].radius');
  const units = _.get(filter, 'arguments[0].units');

  if (isValidRadiusFilter(filter)) {
    circleFeatures = [circle([lng, lat], radius, { units, properties: { humanReadableLocation } })];
  } else {
    circleFeatures = [];
  }

  return {
    type: 'FeatureCollection',
    features: circleFeatures
  };
}

export function getBoundsForRadiusFilterCircle(filter) {
  const { center, radius, units } = _.get(filter, 'arguments[0]', {});
  const filterFunction = _.get(filter, 'function');

  if (!isValidRadiusFilter(filter)) {
    return;
  }
  const radiusInMeters = radius * TO_METERS_CONVERSION_FACTORS[units];

  return new mapboxgl.LngLat(center[0], center[1]).toBounds(radiusInMeters);
}

function isValidRadiusFilter(filter) {
  const filterFunction = _.get(filter, 'function');

  const {
    center,
    radius,
    units
  } = _.get(filter, 'arguments[0]', { center: [] });

  if (filterFunction !== 'withinCircle') {
    return false;
  } else if (_.isUndefined(center[0]) || _.isUndefined(center[1]) || _.isUndefined(radius) || _.isUndefined(units)) {
    return false;
  }
  return true;
}
