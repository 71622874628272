import _ from 'lodash';
import mapboxgl from '@socrata/mapbox-gl';
import turfConvex from 'turf-convex';

import * as vifDecorator from 'common/visualizations/views/map/vifDecorators/vifDecorator';
import {
  DEFAULT_PRIMARY_SERIES_INDEX,
  QUERY_TIMEOUT_SECONDS,
  SIMPLIFICATION_CONFIGURATION
} from '../views/mapConstants';
import { COLOR_BY_BUCKETS_COUNT, VIF_CONSTANTS } from 'common/authoring_workflow/constants';
import { getCharmSvgSrc } from 'common/resources/charms';
import { appToken } from 'common/http';

const LAYER_NAME_SERIES_ID_SEPERATOR = '|';

export default class MapHelper {
  // Returns a promise that resolves
  // when mapbox-gl basemap and style have loaded and are ready for
  // custom data sources/layers to be added. When we update a basemap style
  // (from dark to light or ...), the map will load the new basemap styles,
  // during which we have to wait to add our custom data sources/layers.
  static afterMapLoad(map) {
    return new Promise((resolve, reject) => {
      if (map.loaded()) {
        resolve();
      } else {

        map.once('render', () => {
          MapHelper.afterMapLoad(map).then(resolve);
        });
      }
    });
  }

  static isLineOrBoundaryMap(mapType) {
    return _.includes(['boundaryMap', 'lineMap'], mapType);
  }

  // Given a tile url template: https://a.domain.com/tiles/{z}/{x}/{y}
  // mapbox will substitute {z},{x},{y} with zoom,x,y
  // then call the supplied transformRequest on the url
  // then fetch tiles and display them on map.
  //
  // If substituteSoqlParams will be given as options.transformRequest to mapbox,
  // it will infer the tiles z,x,y based on 'substituteSoqlParams_tileParams={z}|{x}|{y}'
  // and it will replace the below in each tile url.
  // * {{'point' column condition}} : intersects(point, 'POLYGON((90.00 20.00, .....))')
  // * snapPrecision : snapPrecision value for tile's zoom
  // * simplifyPrecision : simplifyPrecision value for tile's zoom
  static substituteSoqlParams(tileUrl) {
    // The socrata icon fonts have been added to the socrata account in mapbox. The socrata account
    // has the font icons and all the other default fonts(used by place/poi labels in the map) provided
    // by mapbox. The styles that we use are mapbox's styles and by default, those styles load fonts
    // from mapbox/v1/fonts. We are replacing font request urls with 'fonts/v1/socrata' so that font
    // files get loaded from socrata account.
    tileUrl = tileUrl.replace('api.mapbox.com/fonts/v1/mapbox', 'api.mapbox.com/fonts/v1/socrata');

    const tileParams = getTileParams(tileUrl);
    const simplificationLevel = getSimplificationLevel(tileUrl);

    if (tileParams === null) {
      return tileUrl;
    }

    const tilePolygonWKT = tilePolygonString(tileParams);
    const transformedUrl = tileUrl
      // {{'point' column condition}}
      .replace(/%7B%7B'(.*)'%20column%20condition%7D%7D/g, function(entireMatch, columnId) {
        return `intersects(${columnId}, '${tilePolygonWKT}')`;
      })
      // {snap_precision}
      .replace(/%7Bsnap_precision%7D/g, getSnapPrecision(tileParams, simplificationLevel))
      // {simplify_precision}
      .replace(/%7Bsimplify_precision%7D/g, getSnapPrecision(tileParams, simplificationLevel));

    return transformedUrl;
  }

  // Params:
  //    map         : mapboxgl map instance
  //    lngLat : [lng, lat]
  // Returns:
  //    the tile canonnicalTileId(x, y, z) of the tile in which the
  //    given lngLat fall for the current zoom level.
  static getTileIdForLatLng(map, lngLat) {
    const mercatorCoordinate = map.transform.locationCoordinate(lngLat);
    const zoom = Math.floor(map.getZoom());
    const totalTilesForZoom = Math.pow(2, zoom);
    return {
      x: Math.floor(mercatorCoordinate.x * totalTilesForZoom),
      y: Math.floor(mercatorCoordinate.y * totalTilesForZoom),
      z: zoom,
    };
  }

  // Params:
  //    names           : <array of string> An array of layer names or source names
  //    uniqueId        : <string|number>unique value that gets prepended to the names to form an id
  // Example:
  //    getNameToIdMap(['points', 'stacks'], '23')
  //    => { points: 'points|23', stacks: 'stacks|23' }
  static getNameToIdMap(names, uniqueId) {
    return _.reduce(names, (acc, name) => {
      acc[name] = MapHelper.getId(name, uniqueId);
      return acc;
    }, {});
  }

  static getId(name, uniqueId) {
    return `${name}${LAYER_NAME_SERIES_ID_SEPERATOR}${uniqueId}`;
  }

  static getName(id) {
    return id.toString().split(LAYER_NAME_SERIES_ID_SEPERATOR)[0];
  }

  // Given a set of features, it returns a polygon feature, that encompases all the points.
  static getBoundingPolygonFor(pointFeatures) {
    if (pointFeatures.length < 3) {
      return null;
    }

    return turfConvex({
      type: 'FeatureCollection',
      features: pointFeatures
    });
  }
}

// Returns true if targetBounds is within sourceBounds
// sourceBounds   : mapboxgl.LngLat
// targetBounds   : mapboxgl.LngLat
export function isOutsideBounds(sourceBounds, targetBounds) {
  if (!sourceBounds || !targetBounds || !sourceBounds.getWest || !targetBounds.getWest) {
    return false;
  }

  return targetBounds.getNorth() > sourceBounds.getNorth() ||
    targetBounds.getEast() > sourceBounds.getEast() ||
    targetBounds.getSouth() < sourceBounds.getSouth() ||
    targetBounds.getWest() < sourceBounds.getWest();
}

// For the maps current zoom/location, it returns the lat/lng range that each pixel takes.
// For instance on very wide zoom level (like when viewing the whole world) each pixel
// would almost take 1-2 degrees.
export function getPerPixelLatLngRange(map) {
  return {
    lngDiff: map.unproject([1, 0]).lng - map.unproject([0, 0]).lng,
    latDiff: map.unproject([0, 1]).lat - map.unproject([0, 0]).lat
  };
}

function getTileParams(tileUrl) {
  const match = tileUrl.match(/#substituteSoqlParams_tileParams=(\d+)\|(\d+)\|(\d+)/);
  if (match) {
    return {
      z: Number(match[1]),
      x: Number(match[2]),
      y: Number(match[3])
    };
  } else {
    return null;
  }
}

function getSimplificationLevel(tileUrl) {
  const match = tileUrl.match(/&simplificationLevel=(\d+)/);

  if (match && _.has(SIMPLIFICATION_CONFIGURATION, match[1])) {
    return match[1];
  } else {
    return VIF_CONSTANTS.SIMPLIFICATION_LEVEL.DEFAULT;
  }
}

function getSnapPrecision(tileParams, simplificationLevel) {
  return SIMPLIFICATION_CONFIGURATION[simplificationLevel].snapPrecision[tileParams.z];
}

function getSimplifyPrecision(tileParams, simplificationLevel) {
  return SIMPLIFICATION_CONFIGURATION[simplificationLevel].simplifyPrecision[tileParams.z];
}

// Params after '#' will not be sent in the soql call. They are used to create soql call
// for each tile. See `substituteSoqlParams` function for more details.
export function getTileUrl(domain, datasetUid, query, simplificationLevel) {
  return `https://${domain}/resource/${datasetUid}.geojson?$query=` +
     encodeURIComponent(query) +
    `&$$query_timeout_seconds=${QUERY_TIMEOUT_SECONDS}` +
    '&$$read_from_nbe=true&$$version=2.1' +
    `&$$app_token=${appToken()}` +
    '#substituteSoqlParams_tileParams={z}|{x}|{y}' +
    `&simplificationLevel=${simplificationLevel}`;
}

function tileParamsToBounds(tileParams) {
  const nwPoint = { x: tileParams.x, y: tileParams.y };
  const sePoint = { x: nwPoint.x + 1, y: nwPoint.y + 1 };

  const northWest = unproject(nwPoint, tileParams.z);
  const southEast = unproject(sePoint, tileParams.z);

  // northWest, southEast --> northEast, southWest
  const neLngLat = { lng: southEast.lng, lat: northWest.lat };
  const swLngLat = { lng: northWest.lng, lat: southEast.lat };

  return new mapboxgl.LngLatBounds(
    swLngLat,
    neLngLat
  );
}

function tilePolygonString(tileParams) {
  const tileLatLngBounds = tileParamsToBounds(tileParams);
  return 'POLYGON(( ' +
    `${tileLatLngBounds.getWest()} ${tileLatLngBounds.getSouth()} ,` +
    `${tileLatLngBounds.getWest()} ${tileLatLngBounds.getNorth()} ,` +
    `${tileLatLngBounds.getEast()} ${tileLatLngBounds.getNorth()} ,` +
    `${tileLatLngBounds.getEast()} ${tileLatLngBounds.getSouth()} ,` +
    `${tileLatLngBounds.getWest()} ${tileLatLngBounds.getSouth()} ))`;
}

export const getFeaturesAroundLocation = function({ map, layers, centerLatLngCoords, pixelBoundingBoxSize }) {
  const centerPixelCoords = map.project(centerLatLngCoords);
  const pixelBoundingBox = [
    [
      centerPixelCoords.x - pixelBoundingBoxSize / 2,
      centerPixelCoords.y - pixelBoundingBoxSize / 2
    ],
    [
      centerPixelCoords.x + pixelBoundingBoxSize / 2,
      centerPixelCoords.y + pixelBoundingBoxSize / 2
    ]
  ];

  return map.queryRenderedFeatures(pixelBoundingBox, { layers });
};

export const getPrimarySeriesIndex = function(vif) {
  const series = _.get(vif, 'series', []);
  const primarySeriesIndex = _.findIndex(series, (seriesItem) => seriesItem.primary);

  return (primarySeriesIndex === -1) ?
    DEFAULT_PRIMARY_SERIES_INDEX :
    primarySeriesIndex;
};

export const getPrimarySeriesVif = function(vif) {
  const primarySeriesIndex = getPrimarySeriesIndex(vif);
  return vifDecorator.getDecoratedVif(vif).cloneWithSingleSeries(primarySeriesIndex);
};

export const sanitizeVifForMaps = function(vif) {
  const sanitizers = [
    removeSeriesWithoutMapType
  ];

  let sanitizedVif = _.cloneDeep(vif);

  _.each(sanitizers, (sanitizer) => {
    sanitizer(sanitizedVif);
  });

  return sanitizedVif;
};

const removeSeriesWithoutMapType = (vif) => {
  const series = _.get(vif, 'series', []);

  const filteredSeries = _.filter(series, (seriesItem) => {
    const mapType = _.get(seriesItem, 'mapOptions.mapType');

    return _.isString(mapType);
  });

  _.set(vif, 'series', filteredSeries);
};

/**
* Unprojects a Google XYZ tile coord to lng/lat coords.
*/
function unproject(point, zoom) {
  return {
    lng: xLng(point.x, zoom),
    lat: yLat(point.y, zoom)
  };
}

/**
* Transform tile-x coordinate (Google XYZ) to a longitude value.
* @param  {number} x  -
* @param  {number} zoom  -
* @return {number} -
*/
function xLng(x, zoom) {
  return x * 360 / Math.pow(2, zoom) - 180;
}

/**
* Transform tile-y coordinate (Google XYZ) to a latitude value.
* @param  {number} y  -
* @param  {number} zoom  -
* @return {number} -
*/
function yLat(y, zoom) {
  const y2 = 180 - y * 360 / Math.pow(2, zoom);
  return 360 / Math.PI * Math.atan(Math.exp(y2 * Math.PI / 180)) - 90;
}
