import { ColumnRef, Expr, TypedExpr, isColumnEqualIgnoringPosition, isExpressionEqualIgnoringPosition, isTypedColumnRef, AnalyzedSelectedExpression, UnAnalyzedSelectedExpression } from 'common/types/soql';
import React from 'react';
import * as _ from 'lodash';
import ColumnPicker from '../ColumnPicker';
import { AstNode, ExprProps, matchEexpr } from '../VisualExpressionEditor';
import { Option, option, none, some } from 'ts-option';
import { getColumnSubset } from '../../lib/filter-helpers';
import { zipSelection } from '../../lib/soql-helpers';
import { ProjectionInfo, ViewColumnColumnRef } from '../../lib/selectors';
import { PickableColumn, ProjectionExpr, Selected, matchPicked } from '../../lib/column-picker-helpers';
import I18n from 'common/i18n';
import { ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';

const toViewColumnColumnRef = (columnRef: ColumnRef, columns: ViewColumnColumnRef[]): Option<ViewColumnColumnRef> => (
  option(_.find(columns, (c) => isColumnEqualIgnoringPosition(c.ref, columnRef)))
);

interface SelectedExpression {
  unanalyzed: UnAnalyzedSelectedExpression,
  analyzed: AnalyzedSelectedExpression
}

const aliasColumnRefToViewColumnColumnRef = (columnRef: ColumnRef, typedExpr: Expr, columns: ViewColumnColumnRef[], projectionInfo?: ProjectionInfo): Option<ViewColumnColumnRef> => {
  return findMatchingExprFromAlias(columnRef, typedExpr, projectionInfo).flatMap(({analyzed}) => {
    if (isTypedColumnRef(analyzed.expr)) {
      return toViewColumnColumnRef(analyzed.expr, columns);
    } else {
      return none;
    }
  });
};

const findMatchingExprFromAlias = (columnRef: ColumnRef, typedExpr: Expr, projectionInfo?: ProjectionInfo): Option<SelectedExpression> => {
  if (_.isUndefined(projectionInfo)) { return none; }
  return projectionInfo.flatMap(({ tableAliases, viewContext, unanalyzed, analyzed }) => {

    const selectedExpressions = _.zip(
      zipSelection(viewContext, tableAliases, unanalyzed, analyzed).exprs,
      analyzed
    ).map(([unanalyzedSelExpr, analyzedSelExpr]) => {
      if (!_.isUndefined(unanalyzedSelExpr) && !_.isUndefined(analyzedSelExpr)) {
        return some({unanalyzed: unanalyzedSelExpr, analyzed: analyzedSelExpr});
      } else {
        return none;
      }
    }).filter(x => x.nonEmpty).map(x => x.get);

    return option(_.find(selectedExpressions, ({ analyzed: analyzedSelExpr }) => {
      return columnRef.value === analyzedSelExpr.name && isExpressionEqualIgnoringPosition(typedExpr, analyzedSelExpr.expr);
    }));
  });
};

/* Given column ref, typed expr, and projection info, find the analyzed selected expression and translate it into a ProjectionExpr. */
const toProjectionExpr = (columnRef: ColumnRef, typedExpr: Expr, projectionInfo?: ProjectionInfo): Option<ProjectionExpr> => {
  return findMatchingExprFromAlias(columnRef, typedExpr, projectionInfo).flatMap(({analyzed, unanalyzed}) => {
    return some({
      expr: unanalyzed.expr,
      typedExpr: analyzed.expr,
      name: analyzed.name,
      ref: (unanalyzed.name) ? some(columnRef) : none
    } as ProjectionExpr);
  });
};

export default function EditColumnRef(props: ExprProps<ColumnRef, TypedExpr>) {
  const {
    addFilterType,
    columns,
    eexpr,
    forceShowSuccess,
    hasGroupOrAggregate,
    isTypeAllowed,
    projectionInfo,
    querySucceeded,
    showKebab,
    showRemove,
    update
  } = props;

  const onSelectColumn = (picked: PickableColumn) => {
    matchPicked(
      picked,
      (vccr: ViewColumnColumnRef) => update(vccr.ref),
      (pexpr: ProjectionExpr) => update(pexpr.ref.getOrElseValue(pexpr.expr as any) as Expr)
    );
  };

  let selected: Option<Selected> = toViewColumnColumnRef(eexpr.untyped, columns);

  matchEexpr(
    eexpr,
    (editable) => {
      if (isTypedColumnRef(editable.typed) && selected.isEmpty) {
        // we're dealing with an aliased Column Ref, translate into the actual column ref
        selected = aliasColumnRefToViewColumnColumnRef(editable.untyped, editable.typed, columns, projectionInfo);
      }
      if (!isTypedColumnRef(editable.typed)) { // calculated or aggregated column
        // find the analyzed selected expression so that we know the current alias of the query column
        selected = toProjectionExpr(editable.untyped, editable.typed, projectionInfo);
      }
    },
    (_uneditable) => _.noop()
  );

  const candidates = columns.filter(c => isTypeAllowed(c.typedRef.soql_type));
  const showSuccess = forceShowSuccess !== undefined && forceShowSuccess && querySucceeded;

  return (
    <AstNode
      {...props}
      className="column-ref"
      removable={showRemove !== undefined && showRemove}
      changeableElement={showKebab ? 1 : undefined}
      showSuccess={showSuccess}>
      <ColumnPicker
        className="btn btn-default"
        prompt={I18n.t('shared.explore_grid.edit_nodes.choose_column')}
        onSelect={onSelectColumn}
        projectionInfo={projectionInfo || none}
        selected={selected}
        columns={candidates}
        columnSubset={getColumnSubset(addFilterType, hasGroupOrAggregate)} />
      {showKebab &&
        <ForgeIconButton>
          <button
            type="button"
            data-testid="edit-column-ref-kebab-btn"
            aria-label={I18n.t('shared.explore_grid.vee_kebab_menu.button_label')}
            className="tyler-icons">
            <ForgeIcon name="more_vert" />
          </button>
        </ForgeIconButton>
      }
    </AstNode>
  );
}
