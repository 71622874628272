import _ from 'lodash';

import MetadataProvider, {
  ColumnAggregation,
  getDisplayableColumns
} from 'common/visualizations/dataProviders/MetadataProvider';
import InlineDataProvider from 'common/visualizations/dataProviders/InlineDataProvider';
import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import SocrataViewDataProvider from 'common/visualizations/dataProviders/SocrataViewDataProvider';

import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import { assertHasProperties, assertIsArray } from 'common/assertions';
import { orderBy, select, selectLiteral, f, and, composeQuery } from 'common/soql_builder';

import { MAX_COLUMN_COUNT, ERROR_COLUMN_MISMATCH } from 'common/visualizations/helpers/TableHelpers';
import { SERIES_TYPE_AG_GRID_TABLE } from 'common/visualizations/views/SvgConstants';

import { Vif, OrderConfig, Hierarchy } from '../vif';
import { ViewColumn } from 'common/types/viewColumn';
import { ColumnVO, IServerSideGetRowsRequest } from '@ag-grid-community/core';
import { Select } from 'common/soql_builder/select';
import { Expr } from 'common/types/soql';
import { GROUP_COLUMN_PREFIX, NULL_GROUPING } from '../views/agGridReact/Constants';
import { ViewFlag } from 'common/types/view';

interface DataProviderConfig {
  datasetUid: string;
  domain: string;
  readFromNbe?: boolean;
}

/**
 * EN-17640 - URL column handling
 *
 * Attempts to identify NBE columns that were converted from OBE URL columns to regular
 * text columns. This is not foolproof as we can only guess by looking for related columns
 * based on 'name' and 'fieldName', which a user can control
 *
 * @param {Array} columnData - the unaltered column data
 * @returns {Array} - a list of columns that we think came from an OBE URL column.
 *                    This list only returns the 'url' half of the url + description pair
 */
export function findNbeUrlCols(columnData: Array<any>): Array<any> {
  assertIsArray(columnData);

  return _.filter(columnData, ({ name, fieldName }) => {
    return _.find(
      columnData,
      _.matches({
        name: `${name} (description)`,
        fieldName: `${fieldName}_description`
      })
    );
  });
}

/**
 * EN-17640 - URL column handling
 *
 * Looks for columns that resemble exploded URL columns like 'link' & 'link_description'
 * and combines them into an OBE-like URL column
 *
 * @param {Array} columnData - the unaltered column data
 * @param {Array} urlCols - list of columns that _probably_ came from OBE URL columns
 * @returns {Array} a list of columns where exploded NBE URL columns are molded back into OBE URL columns
 */
export function reconstructColumnsWithObeUrls(columnData: Array<any>, urlCols: Array<any>): Array<any> {
  assertIsArray(columnData);
  assertIsArray(urlCols);

  return (
    _.chain(columnData)
      .map((col) => {
        const colCopy = _.clone(col);
        _.forEach(urlCols, (urlCol) => {
          if (colCopy.fieldName === urlCol.fieldName) {
            colCopy.renderTypeName = 'url';
          }
        });
        return colCopy;
      })
      // remove _description sub-column since all URL data will be combined into a single column
      .reject((col) => {
        return _.some(urlCols, (urlCol) => {
          return col.fieldName === `${urlCol.fieldName}_description`;
        });
      })
      .value()
  );
}

/**
 * EN-17640 - URL column handling
 *
 * Combines row data for likely URL columns (e.g. 'link', 'link_description') into a single
 * row item that looks like { description: 'Google', url: 'www.google.com' }
 *
 * @param {Array} rowData - the unaltered row data
 * @param {Array} columnData - the unaltered column data
 * @param {Array} urlCols - list of columns that _probably_ came from OBE URL columns
 * @returns {Array} row data where individual urls and url descriptions are combined
 */
export function reconstructRowsWithObeUrls(
  rowData: Array<any>,
  columnData: Array<any>,
  urlCols: Array<any>
): Array<any> {
  assertIsArray(rowData);
  assertIsArray(columnData);
  assertIsArray(urlCols);

  return _.map(rowData, (row) => {
    const rowCopy = _.clone(row);
    // Row can be null here since we intentionally pad rowData with nulls in
    // Table.js #setInlineDataQuery if there aren't enough rows
    if (rowCopy === null) {
      return null;
    }

    _.forEach(urlCols, (urlCol) => {
      const urlColIndex = _.findIndex(columnData, (col) => _.isEqual(urlCol, col));
      const urlColDescriptionIndex = _.findIndex(columnData, (col) => {
        return col.fieldName === `${urlCol.fieldName}_description`;
      });

      const description = row[urlColDescriptionIndex];
      const url = row[urlColIndex];

      // is it possible to have just a description w/o a url?
      if (url || description) {
        rowCopy[urlColIndex] = { description, url };
      }

      // Remove the _description data from the row since it is part of the new fake OBE url col
      _.pullAt(rowCopy, urlColDescriptionIndex);
    });

    return rowCopy;
  });
}

/**
 * Functions for getting row counts for different types of data providers
 */

/**
 * Utilizes the InlineDataProvider, ie: summary tables, SOQL query editor.
 * @param {Object} vifForRowCount - Vif of the given table.
 * @returns {Promise} Row count.
 */
export function getInlineDataRowCount(vifWithInlineData: Vif): Promise<any> {
  const inlineDataProvider = new InlineDataProvider(vifWithInlineData);
  return Promise.resolve(inlineDataProvider.getTotalRowCount());
}

/**
 * @param {Object} vifForRowCount - Vif of the given table.
 * @param {Object} dataProviderConfig - An object of values required by the data providers.
 * @param {string} dataProviderConfig.datasetUid - Uid of the given table.
 * @param {string} dataProviderConfig.domain - The domain on which the table lives. If federated, this will be the source
 * @param {Boolean} dataProviderConfig.readFromNbe: _.get(vifForRowCount, 'series[0].dataSource.readFromNbe', true)
 * @param {Object} datasetMetadata - metadata of source dataset
 * @returns {Promise} Row count.
 */
export function getSoqlDataRowCount(
  vifForRowCount: Vif,
  dataProviderConfig: DataProviderConfig,
  datasetMetadata: any
): Promise<any> {
  const soqlDataProvider = new SoqlDataProvider(dataProviderConfig, true);
  const whereClauseComponents = SoqlHelpers.whereClauseFilteringOwnColumn(vifForRowCount, 0);

  // This is stored as a variable and not a function since we need to capture
  // the output of _.memoize.
  const getMemoizedRowCount = _.memoize(
    function (_soqlDataProvider, _whereClauseComponents, lastUpdate) {
      return _soqlDataProvider.getRowCount(_whereClauseComponents);
    },
    function (_soqlDataProvider, _whereClauseComponents, lastUpdate) {
      const datasetUid = _soqlDataProvider.getConfigurationProperty('datasetUid');

      return `${datasetUid}_${_whereClauseComponents}_${lastUpdate}`;
    }
  );
  return getMemoizedRowCount(soqlDataProvider, whereClauseComponents, datasetMetadata.rowsUpdatedAt);
}

/**
 * @param {Object} vifForRowCount - Vif of the given table.
 * @param {Object} dataProviderConfig - An object of values required by the data providers.
 * @param {string} dataProviderConfig.datasetUid - Uid of the given table.
 * @param {string} dataProviderConfig.domain - The domain on which the table lives. If federated, this will be the source
 * @param {Boolean} dataProviderConfig.readFromNbe: _.get(vifForRowCount, 'series[0].dataSource.readFromNbe', true)
 * @param {Object} datasetMetadata - metadata of source dataset
 * @param {string} resourceURI - Location of source dataset
 * @param {Object} displayableColumns - all possible displayable columns of underlying dataset, used for generating the row count query
 * @param {string} whereClause - Where clause generated from applying filtes to dataset, used for the row count query
 * @param {Request} agRequest - The request being made by agGrid to get the rows, shows if grouping is being done or not
 * @param {string} searchString - An optional search string used by Primer
 * @returns {Promise} Row count.
 */
export function getAgSoqlDataRowCount(
  dataProviderConfig: DataProviderConfig,
  datasetMetadata: any,
  resourceURI: string,
  displayableColumns: ViewColumn[],
  whereClause: string,
  agRequest: IServerSideGetRowsRequest,
  searchString?: string
): Promise<any> {
  const soqlDataProvider = new SoqlDataProvider(dataProviderConfig, true);

  // This is stored as a variable and not a function since we need to capture
  // the output of _.memoize.
  const getRowCount = _.memoize(
    async function (_soqlDataProvider, _whereClause, _agRequest, lastUpdate) {
      const rowCountResult: any = await soqlDataProvider.invokeSoqlQuery(resourceURI, tableRowCountQuery, [
        displayableColumns,
        _whereClause,
        _agRequest,
        searchString
      ]);
      const rowCount = parseInt(_.get(rowCountResult, [0, SoqlHelpers.countAlias()], '0'), 10);
      if (isDoingGrouping(_agRequest.rowGroupCols, _agRequest.groupKeys)) {
        const nullGroupedRowCount: any = await soqlDataProvider.invokeSoqlQuery(
          resourceURI,
          tableNullGroupingCountQuery,
          [displayableColumns, _whereClause, _agRequest]
        );
        const groupHasNulls =
          parseInt(_.get(nullGroupedRowCount, [0, SoqlHelpers.countAlias()], '0'), 10) > 0;
        return rowCount + (groupHasNulls ? 1 : 0);
      }
      return rowCount;
    },
    function (_soqlDataProvider, _whereClause, _agRequest, lastUpdate) {
      const datasetUid = _soqlDataProvider.getConfigurationProperty('datasetUid');

      return `${datasetUid}_${_whereClause}_${_agRequest}_${lastUpdate}`;
    }
  );

  return getRowCount(soqlDataProvider, whereClause, agRequest, datasetMetadata.rowsUpdatedAt);
}

/**
 * Utlizes the SoqlDataProvider, but expects raw Soql.
 * @param {Object} vifForRowCount - Vif of the given table.
 * @param {Object} dataProviderConfig - An object of values required by the data providers.
 * @param {string} dataProviderConfig.datasetUid - Uid of the given table.
 * @param {string} dataProviderConfig.domain - The domain on which the table lives. If federated, this will be the source
 * @param {Boolean} dataProviderConfig.readFromNbe: _.get(vifForRowCount, 'series[0].dataSource.readFromNbe', true)
 * @returns {Promise} Row count.
 */
export function getRawSoqlRowCount(
  vifForRowCount: Vif,
  dataProviderConfig: DataProviderConfig
): Promise<number> {
  const soqlQuery = _.get(vifForRowCount, 'series[0].dataSource.rawSoqlQuery');
  const soqlDataProvider = new SoqlDataProvider(dataProviderConfig, true);
  return soqlDataProvider.getRowCountForQuery(soqlQuery);
}

/**
 * Utlizes the SocrataViewDataProvider, ie: the /data view, Socrata Data Player embeds
 * @param {Object} vifForRowCount - Vif of the given table.
 * @param {Object} dataProviderConfig - An object of values required by the data providers.
 * @param {string} dataProviderConfig.datasetUid - Uid of the given table.
 * @param {string} dataProviderConfig.domain - The domain on which the table lives. If federated, this will be the source
 * @param {Boolean} dataProviderConfig.readFromNbe: _.get(vifForRowCount, 'series[0].dataSource.readFromNbe', true)
 * @returns {Promise} Row count.
 */
export function getSocrataViewRowCount(
  vifForRowCount: Vif,
  dataProviderConfig: DataProviderConfig
): Promise<number> {
  // limit and offset below are both 0 because they are only used for getSODA1RowCount()
  // that method returns the total row count info in the metadata field on the response
  // so we don't actually need any row data.
  const queryConfig = {
    currentView: _.get(vifForRowCount, 'series[0].dataSource.currentView', {}),
    savedView: _.get(vifForRowCount, 'series[0].dataSource.savedView', null),
    parentView: _.get(vifForRowCount, 'series[0].dataSource.parentView', null),
    offset: 0,
    limit: 0
  };

  const socrataViewDataProvider = new SocrataViewDataProvider({
    domain: _.get(vifForRowCount, 'series[0].dataSource.domain')
  });
  const soqlDataProvider = new SoqlDataProvider(dataProviderConfig, true);
  const query = socrataViewDataProvider.generateQuery(queryConfig);
  const viewId = socrataViewDataProvider.getViewId(queryConfig);
  const currentViewIsNBE = _.get(queryConfig, 'currentView.newBackend', false);

  if (currentViewIsNBE) {
    return soqlDataProvider.getRowCountForQuery(query, viewId);
  } else {
    return socrataViewDataProvider.getSODA1RowCount(queryConfig);
  }
}

/**
 * Functions for setting queries for different types of data providers
 */

/**
 * Utilizes the InlineDataProvider, ie: summary tables, SOQL query editor.
 * @param {Object} vifWithInlineData - Vif of the given table.
 * @param {Number} datasetRowCount - Row count of the table, generated from the corresponding get_____RowCount() function.
 * @param {Number} startIndex - Index of first row (offset).
 * @param {Number} pageSize - Number of items in page (not necessarily in rows[]).
 * @param {Boolean} pagingEnabled - If paging is enabled for the given table.
 */
export function setInlineDataQuery(
  vifWithInlineData: Vif,
  datasetRowCount: number | null,
  startIndex: number,
  pageSize: number,
  pagingEnabled: boolean
) {
  const inlineDataProvider = new InlineDataProvider(vifWithInlineData);
  // Fetched data returns number of rows per pageSize from startIndex
  // All rows are fetched if paging is disabled
  const newState = {
    busy: false,
    datasetRowCount: datasetRowCount,
    error: false,
    fetchedData: {
      columns: inlineDataProvider.getColumns(),
      order: null,
      pageSize: pagingEnabled ? pageSize : inlineDataProvider.getRowCount(),
      rows: pagingEnabled
        ? inlineDataProvider.getTableRows(startIndex, pageSize)
        : inlineDataProvider.getRows(),
      rowIds: pagingEnabled
        ? inlineDataProvider.getTableRowIds(startIndex, pageSize)
        : inlineDataProvider.getRowIds(),
      startIndex: pagingEnabled ? startIndex : inlineDataProvider.getStartIndex()
    },
    vif: vifWithInlineData
  };

  return Promise.resolve(newState);
}

/**
 * Utlizes the SoqlDataProvider, but expects raw Soql, ie: notifications and alerts, which can accept a user input rawSoqlQuery to generate an alert.
 * @param {Object} vifForDataQuery - Vif of the given table.
 * @param {Number} datasetRowCount - Row count of the table, generated from the corresponding get_____RowCount() function.
 * @param {Number} startIndex - Index of first row (offset).
 * @param {Number} pageSize - Number of items in page (not necessarily in rows[]).
 * @param {Object} order - Sort order. Only one element supported.
 * @param {string} order.columnName - Name of the column to sort by.
 * @param {Boolean} order.ascending - If the order is ascending or not (false = descending).
 */

export async function setRawSoqlDataQuery(
  vifForDataQuery: Vif,
  datasetRowCount: number | null,
  startIndex: number,
  pageSize: number,
  order: OrderConfig[]
) {
  const soqlQuery = _.get(vifForDataQuery, 'series[0].dataSource.rawSoqlQuery');
  const dataProviderConfig = {
    datasetUid: _.get(vifForDataQuery, 'series[0].dataSource.datasetUid'),
    domain: _.get(vifForDataQuery, 'series[0].dataSource.domain'),
    parameterOverrides: _.get(vifForDataQuery, 'series[0].dataSource.parameterOverrides'),
    readFromNbe: _.get(vifForDataQuery, 'series[0].dataSource.readFromNbe', true)
  };

  const metadataProvider = new MetadataProvider(dataProviderConfig, true);
  const soqlDataProvider = new SoqlDataProvider(dataProviderConfig, true);

  const columns = await metadataProvider.getOutputColumnsForQuery(soqlQuery);
  const columnNames = columns.map((column: ViewColumn) => column.fieldName);

  let soqlQueryWithPaginationAndSorting = `${soqlQuery} |> select ${columnNames.join(',')}`;

  if (!_.isEmpty(order)) {
    const orderByColumnName = _.get(order, '[0].columnName');
    const orderDirection = _.get(order, '[0].ascending') ? 'asc' : 'desc';

    soqlQueryWithPaginationAndSorting =
      soqlQueryWithPaginationAndSorting + ` order by ${orderByColumnName} ${orderDirection}`;
  }

  soqlQueryWithPaginationAndSorting =
    soqlQueryWithPaginationAndSorting + ` limit ${pageSize} offset ${startIndex}`;

  const { rows } = SoqlHelpers.mapSoqlRowsResponseToTable(
    columnNames,
    await soqlDataProvider.rawQuery(soqlQueryWithPaginationAndSorting)
  );

  return {
    busy: false,
    datasetRowCount: datasetRowCount,
    error: false,
    fetchedData: {
      columns,
      order,
      pageSize,
      rows,
      rowIds: [],
      startIndex
    },
    vif: vifForDataQuery
  };
}

/**
 * Utlizes the SoqlDataProvider, ie: old storyteller tables (non-Ag Grid), primer table, AX table, Viz Canvas table.
 * @param {Object} vifForDataQuery - Vif of the given table.
 * @param {Number} datasetRowCount - Row count of the table, generated from the corresponding get_____RowCount() function.
 * @param {Number} startIndex - Index of first row (offset).
 * @param {Number} pageSize - Number of items in page (not necessarily in rows[]).
 * @param {Object} order - Sort order. Only one element supported.
 * @param {string} order.columnName - Name of the column to sort by.
 * @param {Boolean} order.ascending - If the order is ascending or not (false = descending).
 * @param {Boolean} shouldDisplayFilterBar - Determines if the filter bar should be displayed on the table
 */
export async function setSoqlDataQuery(
  vifForDataQuery: Vif,
  datasetRowCount: number,
  startIndex: number,
  pageSize: number,
  order: OrderConfig[],
  shouldDisplayFilterBar: boolean,
  userChangedOrder: boolean,
  { doNotMutateVif = false } = {}
) {
  // TODO: Remove if's specific to AgGrid tables as this is now moved to setAgSoqlDataQuery
  assertHasProperties(vifForDataQuery, 'series[0].dataSource.datasetUid');

  const datasetUid = _.get(vifForDataQuery, 'series[0].dataSource.datasetUid');

  const dataProviderConfig = {
    datasetUid,
    domain: _.get(vifForDataQuery, 'series[0].dataSource.domain'),
    parameterOverrides: _.get(vifForDataQuery, 'series[0].dataSource.parameterOverrides'),
    readFromNbe: _.get(vifForDataQuery, 'series[0].dataSource.readFromNbe', true)
  };

  const metadataProvider = new MetadataProvider(dataProviderConfig, true);
  const soqlDataProvider = new SoqlDataProvider(dataProviderConfig, true);
  const whereClauseComponents = SoqlHelpers.whereClauseFilteringOwnColumn(vifForDataQuery, 0);

  const datasetMetadata = await metadataProvider.getDatasetMetadata();

  // New columns will only be set if the table is generated from the authoring experience interactive table visualization. If the user has selected a subset of columns, only use the subset, rather than the full list of available columns.
  let newColumns = _.get(vifForDataQuery, 'series[0].dataSource.dimension.columns');
  const allDatasetColumns = getDisplayableColumns(datasetMetadata);
  const allDatasetColumnsCount = allDatasetColumns.length;
  const hasNewColumns = !!newColumns;

  const updatedVif = _.cloneDeep(vifForDataQuery);

  // If we have newColumns (set in a Table Viz) this will run
  // If it returns true, this function will throw an error and return early
  checkForColumnMismatch(allDatasetColumns, newColumns);

  if (hasNewColumns) {
    newColumns = getColumnUpdates(allDatasetColumns, newColumns);
  }

  const displayableColumns = newColumns ? newColumns : allDatasetColumns.slice(0, MAX_COLUMN_COUNT);
  if (doNotMutateVif) {
    _.set(updatedVif, 'series[0].dataSource.dimension.columns', displayableColumns);
  } else {
    _.set(vifForDataQuery, 'series[0].dataSource.dimension.columns', displayableColumns);
  }

  // If the order in the VIF is undefined, we need to find a column to sort the table by
  if (_.isUndefined(order) || _.isEmpty(order)) {
    let defaultSortOrder;
    // If the user has manually cleared the sort, fall back to sorting by system id or no sort. Otherwise we'll just
    // call out to dsmapi again and reset the sort to the default one
    if (userChangedOrder) {
      // :id is only valid to sort by if view is not grouped, and we can't know that without parsing the soql
      defaultSortOrder = _.includes(datasetMetadata.flags, ViewFlag.Default)
        ? [
            {
              ascending: true,
              columnName: ':id'
            }
          ]
        : [];
    } else {
      defaultSortOrder = (await getDefaultSortOrder(datasetMetadata, displayableColumns, datasetUid)) || [];
    }

    order = defaultSortOrder;
    // Update order in vifForDataQuery so we can visually indicate which column the table is
    // being sorted by
    if (doNotMutateVif) {
      _.set(updatedVif, 'configuration.order', order);
    } else {
      _.set(vifForDataQuery, 'configuration.order', order);
    }
  }
  const tableType = _.get(vifForDataQuery, 'series[0].type');

  const soqlDataPromise = soqlDataProvider.getTableData(
    _.map(displayableColumns, 'fieldName'),
    order,
    startIndex,
    pageSize,
    whereClauseComponents,
    tableType
  );

  const filterableColumnsPromise = shouldDisplayFilterBar
    ? metadataProvider.getDisplayableFilterableColumns({
        datasetMetadata: Promise.resolve(datasetMetadata),
        shouldGetColumnStats: false
      })
    : null;

  const [soqlData, filterableColumns] = await Promise.all([soqlDataPromise, filterableColumnsPromise]);
  const newState = {
    busy: false,
    datasetRowCount: datasetRowCount,
    error: false,
    fetchedData: {
      rows: soqlData.rows,
      rowIds: soqlData.rowIds,
      columns: displayableColumns,
      filterableColumns,
      startIndex,
      pageSize,
      order,
      whereClauseComponents,
      allDatasetColumnsCount,
      hasNewColumns
    },
    vif: doNotMutateVif ? updatedVif : vifForDataQuery
  };

  return newState;
}

/**
 * Utlizes the Soql Query Builder or SoqlDataProvider depending on if there are hierarchies, ie: Ag Grid tables.
 * @param {Object} vifForDataQuery - Vif of the given table.

 */
export async function setGrandTotalDataQuery(
  vifForDataQuery: Vif,
  hierarchyConfig: Hierarchy,
  shouldFetchAggregationSuggestions: boolean
) {
  assertHasProperties(vifForDataQuery, 'series[0].dataSource.datasetUid');
  const showGrandTotal = hierarchyConfig.showGrandTotal;
  const hasAggregationColumn = _.some(
    hierarchyConfig.columnConfigurations,
    (h) => h.aggregation !== null && !h.hidden
  );

  if (!showGrandTotal || !hasAggregationColumn) {
    return [];
  }

  const dataProviderConfig = {
    datasetUid: _.get(vifForDataQuery, 'series[0].dataSource.datasetUid'),
    domain: _.get(vifForDataQuery, 'series[0].dataSource.domain'),
    parameterOverrides: _.get(vifForDataQuery, 'series[0].dataSource.parameterOverrides'),
    readFromNbe: _.get(vifForDataQuery, 'series[0].dataSource.readFromNbe', true)
  };
  const metadataProvider = new MetadataProvider(dataProviderConfig, true);
  const soqlDataProvider = new SoqlDataProvider(dataProviderConfig, true);
  const datasetMetadata = await metadataProvider.getDatasetMetadata();
  const displayableColumns = getDisplayableColumns(datasetMetadata);
  const whereClause = SoqlHelpers.whereClauseFilteringOwnColumn(vifForDataQuery, 0);
  const nonStandardAggregations = shouldFetchAggregationSuggestions
    ? await metadataProvider.getAggregationSuggestions()
    : null;
  const resourceURI = `/resource/${dataProviderConfig.datasetUid}.json`;
  const aggregatedColumns = _.filter(hierarchyConfig.columnConfigurations, { isGrouping: false });

  const valueCols = _.map(aggregatedColumns, (column) => {
    return {
      aggFunc: column.aggregation,
      id: column.columnName
    };
  });

  const columnData = { valueCols: valueCols };

  const grandTotalRow: any = await soqlDataProvider.invokeSoqlQuery(resourceURI, grandTotalQuery, [
    displayableColumns,
    whereClause,
    columnData,
    nonStandardAggregations
  ]);

  const aggAliases = columnData.valueCols
    .filter((col) => !!col.aggFunc)
    .map((col, idx) => [col.id, SoqlHelpers.measureAlias(idx)])
    .reduce((aliases, [colId, alias]) => {
      return { ...aliases, [alias]: colId };
    }, {});
  const unaliasedGrandTotal = unaliasRowData(grandTotalRow, { ...aggAliases });
  return unaliasedGrandTotal;
}

/**
 * Utlizes the Soql Query Builder or SoqlDataProvider depending on if there are hierarchies, ie: Ag Grid tables.
 */
export async function setAgSoqlDataQuery({
  agRequest,
  order,
  pageSize,
  previousOrder,
  searchString,
  shouldFetchAggregationSuggestions,
  startIndex,
  vifForDataQuery
}: {
  /** Data request info made by Ag Grid. Needs to be passed through to tableDataQuery. */
  agRequest: IServerSideGetRowsRequest;
  order: OrderConfig[];
  /** Number of items in page (not necessarily in rows[]). */
  pageSize: number;
  previousOrder?: OrderConfig[];
  searchString?: string;
  shouldFetchAggregationSuggestions: boolean;
  startIndex: number;
  vifForDataQuery: Vif;
  /** Index of the first row to be requested (aka the offset) */
}) {
  assertHasProperties(vifForDataQuery, 'series[0].dataSource.datasetUid');
  const datasetUid = _.get(vifForDataQuery, 'series[0].dataSource.datasetUid');
  const dataProviderConfig = {
    datasetUid,
    domain: _.get(vifForDataQuery, 'series[0].dataSource.domain'),
    parameterOverrides: _.get(vifForDataQuery, 'series[0].dataSource.parameterOverrides'),
    readFromNbe: _.get(vifForDataQuery, 'series[0].dataSource.readFromNbe', true)
  };

  const metadataProvider = new MetadataProvider(dataProviderConfig, true);
  const soqlDataProvider = new SoqlDataProvider(dataProviderConfig, true);
  const whereClause = SoqlHelpers.whereClauseFilteringOwnColumn(vifForDataQuery, 0);
  const datasetMetadata = await metadataProvider.getDatasetMetadata();
  const nonStandardAggregations = shouldFetchAggregationSuggestions
    ? await metadataProvider.getAggregationSuggestions()
    : null;

  const displayableColumns = getDisplayableColumns(datasetMetadata);

  const updatedVif = _.cloneDeep(vifForDataQuery);

  // If the order in the VIF is undefined, we need to find a column to sort the table by
  // TODO: There is a behavior difference between this and setSoqlDataQuery
  // setSoqlDataQuery tracks "userChangedOrder", and if that is true and order by is empty
  // it issues the query with no order (bad for pagination) or with order by :id, whereas this returns to the original default order
  // (from a users perspective, they've toggled off the order and then it regenerates)
  if (_.isUndefined(order) || _.isEmpty(order)) {
    const defaultSortOrder =
      (await getDefaultSortOrder(datasetMetadata, displayableColumns, datasetUid, previousOrder)) || [];

    order = defaultSortOrder;
    // Update order in vifForDataQuery so we can visually indicate which column the table is
    // being sorted by

    // for soql-views we generate basically an all-column sort,
    // which does a best-possible job of creating a unique order for pagination
    // but looks yucky to display, so we won't
    if (!_.has(datasetMetadata, 'queryString')) {
      _.set(updatedVif, 'configuration.order', order);
    }
  }

  const resourceURI = `/resource/${dataProviderConfig.datasetUid}.json`;
  const tableData: any = await soqlDataProvider.invokeSoqlQuery(resourceURI, tableDataQuery, [
    displayableColumns,
    whereClause,
    agRequest,
    pageSize,
    startIndex,
    order,
    nonStandardAggregations,
    searchString
  ]);

  const selectAliases = createSelectAliasMap(agRequest.rowGroupCols, agRequest.groupKeys, displayableColumns);
  const aggAliases = createMeasureAliasMap(agRequest);
  const unaliasedTableData = unaliasRowData(tableData, { ...aggAliases, ..._.invert(selectAliases) });

  const soqlData: { columns: ViewColumn[]; rows: any[]; rowIds: any[] } =
    SoqlHelpers.mapSoqlRowsResponseToTable(displayableColumns, unaliasedTableData, SERIES_TYPE_AG_GRID_TABLE);

  const rowCount = await getDatasetRowCount(
    vifForDataQuery,
    resourceURI,
    displayableColumns,
    whereClause,
    agRequest,
    searchString
  );

  return {
    vif: updatedVif,
    rows: soqlData.rows,
    datasetRowCount: rowCount
  };
}

// Returns a ViewColumn (if found) given a partial ag-grid ColumnVO object
function findCorrespondingColumn(
  columns: ViewColumn[],
  columnToFind: Partial<ColumnVO>
): ViewColumn | undefined {
  return _.find(columns, (c) => c.fieldName === columnToFind.id);
}

function generateWhereQuery(
  columns: ViewColumn[],
  groupKeys: (string | null)[],
  rowGroupCols: ColumnVO[]
): Expr | undefined {
  const whereParts: Expr[] = [];

  if (groupKeys) {
    groupKeys.forEach(function (key, i) {
      const whereColumn = findCorrespondingColumn(columns, rowGroupCols[i]);
      if (typeof whereColumn !== 'undefined') {
        if (key === null) {
          whereParts.push(f.isNull(whereColumn));
        } else {
          whereParts.push(f.eq(whereColumn, key));
        }
      }
    });
  }

  if (whereParts.length > 1) {
    return and(...whereParts);
  }

  return !_.isEmpty(whereParts) ? whereParts[0] : undefined;
}

/**
 *
 * @param {ViewColumn []} columns - All of the columns being used in the table visualization.
 * @param {string} legacyWhereClause - The output of SoqlHelpers.whereClauseFilteringOwnColumn
 * @param {Object} agRequest - The data request information from Ag Grid.
 * @param {string} searchString - An optional search string used on Primer
 * @returns - a query to be used in the Soql Query Builder, invokeSoql function.
 */
export const tableDataQuery = (
  columns: ViewColumn[],
  legacyWhereClause: string,
  agRequest: IServerSideGetRowsRequest,
  pageSize?: number,
  startIndex?: number,
  order?: OrderConfig[],
  nonStandardAggregations?: ColumnAggregation[] | null,
  searchString?: string
) => {
  const { groupKeys: agGroupKeys, rowGroupCols, valueCols } = agRequest;
  const groupKeys = agGroupKeys.map((key) => (key === NULL_GROUPING || key == undefined ? null : key));

  const selectQuery = () => {
    let selectQueries: Select[] = [];
    const selectAliasMap: { [key: string]: string } = createSelectAliasMap(
      rowGroupCols,
      agGroupKeys,
      columns
    );

    if (isDoingGrouping(rowGroupCols, groupKeys)) {
      // We want to select only the column we are grouping
      const selectCol = rowGroupCols[groupKeys.length];
      const colId = findCorrespondingColumn(columns, selectCol);
      if (typeof colId !== 'undefined') {
        selectQueries = [select(colId, selectAliasMap[selectCol.id])];
      }

      const aggregationSelects = getSelectAggregationsQuery(valueCols, columns, nonStandardAggregations);
      selectQueries = selectQueries.concat(aggregationSelects);
    } else {
      // We are at the lowest level so we want to see all the raw data for the columns
      // we've added to the table
      const ungroupedColumns = _.differenceWith(columns, rowGroupCols, (c, r) => c.fieldName === r.id);
      _.each(ungroupedColumns, (col) => {
        selectQueries.push(select(col, selectAliasMap[col.fieldName]));
      });
    }
    return selectQueries;
  };

  const groupQuery = () => {
    if (isDoingGrouping(rowGroupCols, groupKeys)) {
      const rowGroupCol = rowGroupCols[groupKeys.length];
      return [findCorrespondingColumn(columns, rowGroupCol)];
    } else {
      return [];
    }
  };

  const orderByQuery = () => {
    if (_.isUndefined(order)) {
      return [];
    }
    const rowGroupCol = rowGroupCols[groupKeys.length];

    return order
      .map((o: OrderConfig) => {
        const col = _.find(
          columns,
          (c) => c.fieldName === _.replace(o.columnName, `${GROUP_COLUMN_PREFIX}-`, '')
        );
        const ordering = o.ascending === true ? 'ascending' : 'descending';

        if (_.isUndefined(col) || col.fieldName === ':id') {
          return;
        }

        if (isDoingGrouping(rowGroupCols, groupKeys)) {
          // Table with groupings
          if (col.fieldName === rowGroupCol.id) {
            // OrderBy: Grouping column
            return orderBy(col, ordering);
          } else {
            const measureAliasMap = createMeasureAliasMap(agRequest);
            const measureAlias = _.findKey(measureAliasMap, (fieldName) => fieldName === col.fieldName);
            if (_.isUndefined(measureAlias)) {
              // OrderBy: Non Aggregated/Crouping Column, so ignored
              return;
            }
            // OrderBy: Aggregated column
            return orderBy(measureAlias, ordering);
          }
        } else {
          // Table without groupings
          return orderBy(col, ordering);
        }
      })
      .filter((orderForSoql) => !_.isUndefined(orderForSoql));
  };

  const groupedColumn = groupQuery();
  const whereParts = generateWhereQuery(columns, groupKeys, rowGroupCols);
  const selectParts = selectQuery();
  const orderParts = orderByQuery();
  const searchParts = searchString;

  const dataQuery = composeQuery({
    selects: selectParts,
    where: whereParts,
    groups: groupedColumn,
    legacyWhereClause,
    orders: orderParts,
    limit: pageSize,
    offset: startIndex,
    search: searchParts
  });

  return dataQuery;
};

export const grandTotalQuery = (
  columns: ViewColumn[],
  legacyWhereClause: string,
  agRequest: IServerSideGetRowsRequest,
  nonStandardAggregations?: ColumnAggregation[] | null
) => {
  //The grand total query is the same as the table data query except that we don't want to group.
  // We also include the where clause to handle filtering on the grand total.
  const { valueCols } = agRequest;

  // For grand totals all we need is to SELECT the columns we are aggregating on
  const selectParts: Select[] = getSelectAggregationsQuery(valueCols, columns, nonStandardAggregations);

  const dataQuery = composeQuery({
    selects: selectParts,
    legacyWhereClause
  });
  return dataQuery;
};

/**
 *
 * @param {ViewColumn []} columns - All of the columns being used in the table visualization.
 * @param {string} legacyWhereClause - The output of SoqlHelpers.whereClauseFilteringOwnColumn
 * @param {Object} agRequest - The data request information from Ag Grid.
 * @param {string} searchString - An optional search string used on the Primer
 * @returns - a query to be used in the Soql Query Builder, invokeSoql function.
 */
export const tableRowCountQuery = (
  columns: ViewColumn[],
  legacyWhereClause: string,
  agRequest: IServerSideGetRowsRequest,
  searchString?: string
) => {
  const { groupKeys: agGroupKeys, rowGroupCols } = agRequest;
  const groupKeys = agGroupKeys.map((key) => (key === NULL_GROUPING || key == undefined ? null : key));

  const countSelectQuery = () => {
    let selectQueries: Select[] = [];
    if (isDoingGrouping(rowGroupCols, groupKeys)) {
      const selectCol = rowGroupCols[groupKeys.length];

      const colId = findCorrespondingColumn(columns, selectCol);
      if (typeof colId !== 'undefined') {
        selectQueries = [select(f.countDistinct(colId), SoqlHelpers.countAlias())];
      }
    } else {
      selectQueries = [select(f.count('*'), SoqlHelpers.countAlias())];
    }

    return selectQueries;
  };

  const rowCountQuery = composeQuery({
    selects: countSelectQuery(),
    where: generateWhereQuery(columns, groupKeys, rowGroupCols),
    search: searchString,
    legacyWhereClause
  });

  return rowCountQuery;
};

/**
 * Used to determine if the grouping column contains a null value
 * @param {ViewColumn []} columns - All of the columns being used in the table visualization.
 * @param {string} legacyWhereClause - The output of SoqlHelpers.whereClauseFilteringOwnColumn
 * @param {Object} agRequest - The data request information from Ag Grid.
 * @returns - a query to be used in the Soql Query Builder, invokeSoql function.
 */
const tableNullGroupingCountQuery = (
  columns: ViewColumn[],
  legacyWhereClause: string,
  agRequest: IServerSideGetRowsRequest
) => {
  const { rowGroupCols, groupKeys: agGroupKeys } = agRequest;
  const groupKeys = agGroupKeys.map((key) => (key === NULL_GROUPING || key == undefined ? null : key));

  // if we're grouping, we need to check if the grouping column contains a null value;
  // if so, countDistinct will have ignored it and the resulting row count will be off by one
  // the below where clause will use the group keys as usual, but will also add a final
  // `<column> IS NULL`, <column> being the last opened column (or the top level unopened column)
  return composeQuery({
    selects: [select(f.count('*'), SoqlHelpers.countAlias())],
    where: generateWhereQuery(columns, [...groupKeys, null], rowGroupCols),
    legacyWhereClause
  });
};

// Rather than have each data source fetch row counts as part of their
// set_____DataQuery() methods which fetch the actual rows, breaking
// row count fetches into separate methods here. Each get_____RowCount()
// method should return a promise which resolves to the row count
async function getDatasetRowCount(
  vifForRowCount: Vif,
  resourceURI: string,
  displayableColumns: ViewColumn[],
  whereClause: string,
  agRequest: IServerSideGetRowsRequest,
  searchString?: string
) {
  const dataSourceType = _.get(vifForRowCount, 'series[0].dataSource.type');

  const dataProviderConfig = {
    datasetUid: _.get(vifForRowCount, 'series[0].dataSource.datasetUid'),
    domain: _.get(vifForRowCount, 'series[0].dataSource.domain'),
    parameterOverrides: _.get(vifForRowCount, 'series[0].dataSource.parameterOverrides'),
    readFromNbe: _.get(vifForRowCount, 'series[0].dataSource.readFromNbe', true)
  };

  switch (dataSourceType) {
    case 'socrata.inline':
      return getInlineDataRowCount(vifForRowCount);
    case 'socrata.soql':
      const metadataProvider = new MetadataProvider(dataProviderConfig, true);
      const datasetMetadata = await metadataProvider.getDatasetMetadata();
      return getAgSoqlDataRowCount(
        dataProviderConfig,
        datasetMetadata,
        resourceURI,
        displayableColumns,
        whereClause,
        agRequest,
        searchString
      );
    case 'socrata.rawSoql':
      return getRawSoqlRowCount(vifForRowCount, dataProviderConfig);
    case 'socrata.view':
      return getSocrataViewRowCount(vifForRowCount, dataProviderConfig);
    default:
      return Promise.reject(`Invalid data source type in vif: '${dataSourceType}'.`);
  }
}

/**
 * Utlizes the SocrataViewDataProvider, ie: the /data view, Socrata Data Player embeds.
 * @param {Object} vifWithView - Vif of the given table.
 * @param {Number} datasetRowCount - Row count of the table, generated from the corresponding get_____RowCount() function.
 */
export function setSocrataViewDataQuery(vifWithView: Vif, datasetRowCount: number | null) {
  const dataProviderConfig = {
    domain: _.get(vifWithView, 'series[0].dataSource.domain'),
    inDatasetSearchQueryTimeoutSeconds: _.get(
      window,
      'blist.configuration.inDatasetSearchQueryTimeoutSeconds',
      30
    )
  };
  const socrataViewDataProvider = new SocrataViewDataProvider(dataProviderConfig);
  const offset = _.get(vifWithView, 'series[0].dataSource.offset', 0);
  const limit = _.get(vifWithView, 'series[0].dataSource.limit', 5000);
  const queryConfig = {
    currentView: _.get(vifWithView, 'series[0].dataSource.currentView', {}),
    savedView: _.get(vifWithView, 'series[0].dataSource.savedView', null),
    parentView: _.get(vifWithView, 'series[0].dataSource.parentView', null),
    offset,
    limit
  };

  const currentQuery = _.get(queryConfig, 'currentView.query', {});
  const currentQueryHasGroupBys = _.get(currentQuery, 'groupBys', []).length > 0;
  const currentQueryHasOrderBys = _.get(currentQuery, 'orderBys', []).length > 0;
  // EN-29606 - Grid view incorrectly believing view is not the saved view
  //
  // In the case where we are querying a view that is already saved, we should
  // be doing a 'select *' query instead of trying to recreate the actual SoQL
  // based on the query defined in the view.
  //
  // The code below that removes invalid order by clauses based on the presence
  // or absence of grouping was incorrectly assigning an empty orderBys array
  // to the view when the saved version did not even have an orderBys property.
  //
  // This caused downstream code to believe that the current view was not also
  // the saved view, which caused it to try to reconstruct the SoQL query that
  // the query defined, even though the backend was already generating that
  // SoQL itself.

  if (currentQueryHasGroupBys && currentQueryHasOrderBys) {
    // OrderBys that are not in the groupBys or are not being aggregated
    // are invalid if groupBys or aggregations exist.
    const aggregationColumns = _.get(queryConfig, 'currentView.columns', [])
      .filter((column: ViewColumn) => {
        return (
          !_.isUndefined(_.get(column, 'format.grouping_aggregate')) ||
          !_.isUndefined(_.get(column, 'format.group_function'))
        );
      })
      .map((column: ViewColumn) => {
        return {
          columnId: column.id,
          type: 'column'
        };
      });
    const groupBysAndAggregations = currentQuery.groupBys.concat(aggregationColumns);
    const validOrderBys = _.intersectionWith(
      currentQuery.orderBys,
      groupBysAndAggregations,
      function (ob: any, gb: any) {
        return ob.expression.columnId == gb.columnId;
      }
    );

    // Remove any orderBys that are invalid (that are not in groupBys and are
    // not being aggregated).
    currentQuery.orderBys = validOrderBys;
  }

  return socrataViewDataProvider
    .query(queryConfig)
    .then(function (response: { columns: any; rows: any; rowIds: any }) {
      const newState = {
        busy: false,
        datasetRowCount: datasetRowCount,
        error: false,
        fetchedData: {
          columns: socrataViewDataProvider.getColumnsForQuery(queryConfig),
          order: _.get(vifWithView, 'configuration.order', null),
          pageSize: limit,
          rows: response.rows,
          rowIds: response.rowIds,
          startIndex: offset
        },
        vif: vifWithView
      };
      return newState;
    });
}

export function getColumnUpdates(allColumns: ViewColumn[], newColumns: ViewColumn[]) {
  // Get any column updates from the source:
  // Returns the columns from the source that are included in the table visualization
  const intersection = _.intersectionWith(allColumns, newColumns, (column, newCol) => {
    return column.fieldName === newCol.fieldName;
  });

  const updatedNewColumns = _.map(newColumns, (newCol) => {
    const originalColumn = intersection.find((col) => col.fieldName === newCol.fieldName)!;
    delete originalColumn.position;

    return originalColumn;
  });

  return updatedNewColumns;
}

export const getDefaultSortOrder = async (
  datasetMetadata: any,
  displayableColumns: ViewColumn[],
  fourfour: string,
  previousOrder?: OrderConfig[]
): Promise<OrderConfig[] | null> => {
  const isDefault = _.includes(datasetMetadata.flags, 'default');
  const isGrouped = _.has(datasetMetadata, 'query.groupBys');
  // if vif has no ordering and it's a soql view, call to dsmapi and get it
  const isSoqlView = _.has(datasetMetadata, 'queryString');

  if (isSoqlView) {
    const ordering = await SoqlHelpers.fetchOrderingForVIF(fourfour);
    return ordering;
  }

  // Get the default sort from the query property (note that if this is the NBE copy, this
  // query might not be the same as the query in the OBE copy)
  const defaultSort: OrderConfig[] = _.chain(_.get(datasetMetadata, 'query.orderBys', []))
    .map((columnOrder: any) => {
      const columnId = _.get(columnOrder, 'expression.columnId');
      const column = _.find(datasetMetadata.columns, (col) => col.id === columnId);

      if (_.has(column, 'fieldName') && isNotGeoColumn(column)) {
        return {
          ascending: columnOrder.ascending as boolean,
          columnName: column.fieldName as string
        };
      } else {
        return null;
      }
    })
    .compact()
    .value();

  // If we do have a default sort, use it. However, if the previous sort order was *already* the default sort, this
  // will just end up getting us stuck in a loop. Skip over the default sort in this case.
  if (!_.isEmpty(defaultSort) && !_.isEqual(defaultSort, previousOrder)) {
    return defaultSort;
  } else if (isDefault || !isGrouped) {
    // if this is a default view or a derived view that does not have group bys, we can safely
    // use the system id to sort if there isn't a sort order in the VIF.
    return [
      {
        ascending: true,
        columnName: ':id'
      }
    ];
  } else {
    // if this is a grouped view that doesn't have a sort order in the VIF, let's take an
    // educated guess at which column we should sort by.
    const sortableColumn = _.find(displayableColumns, (column) => {
      return _.has(column, 'fieldName') && isNotGeoColumn(column);
    });

    if (!_.isObject(sortableColumn)) {
      return null;
    }

    return [
      {
        ascending: true,
        columnName: sortableColumn.fieldName
      }
    ];
  }
};

function isNotGeoColumn(column: any) {
  return !column.dataTypeName.match(/(location|point|polygon|line)$/i);
}

function checkForColumnMismatch(allColumns: ViewColumn[], newColumns: ViewColumn[]) {
  if (!_.isEmpty(newColumns)) {
    const fieldNames = _.map(allColumns, 'fieldName');
    const hasValidColumns = _.every(newColumns, (newColumn) => {
      // Check to see if the fieldName is still in dataset columns
      return _.includes(fieldNames, newColumn.fieldName);
    });

    if (!hasValidColumns) {
      throw new Error(ERROR_COLUMN_MISMATCH);
    }
  }
}

function isDoingGrouping(rowGroupCols: ColumnVO[], groupKeys: (string | null)[]) {
  // We do not want to group at the lowest level
  return rowGroupCols.length > groupKeys.length;
}

function getSelectAggregationsQuery(
  valueCols: any[],
  columns: ViewColumn[],
  nonStandardAggregations?: ColumnAggregation[] | null
): Select[] {
  const selectQueries: Select[] = [];
  const aggregations = valueCols.filter((col) => !!col.aggFunc);

  if (aggregations.length) {
    const aggregationSelects = aggregations
      .map(({ id, aggFunc }, idx) => {
        if (typeof aggFunc !== 'undefined' && typeof f[aggFunc] === 'function') {
          const viewCol = findCorrespondingColumn(columns, { id });

          // Postgresql does not allow for min or max of boolean columns,
          // so we need to use this work around.
          if (viewCol?.dataTypeName === 'checkbox' && (aggFunc === 'max' || aggFunc === 'min')) {
            return selectLiteral(
              `${aggFunc}(iif(${viewCol.fieldName}, 1, 0)) == 1`,
              SoqlHelpers.measureAlias(idx)
            );
          } else if (viewCol?.dataTypeName === 'url' && (aggFunc === 'max' || aggFunc === 'min')) {
            return selectLiteral(
              `url(${aggFunc}(${viewCol.fieldName}.url), null)`,
              SoqlHelpers.measureAlias(idx)
            );
          }

          return select(f[aggFunc](viewCol), SoqlHelpers.measureAlias(idx));
        } else if (nonStandardAggregations) {
          // in the case of a non-standard aggregation, we need to use the nonStandardAggregations
          // list to find the correct SoQL expression for this column/aggregationType
          const aggregationDefinition = nonStandardAggregations.find(
            (agg) => agg.fieldName === id && agg.aggregationType === aggFunc
          );
          if (aggregationDefinition) {
            return selectLiteral(aggregationDefinition.soqlExpression, SoqlHelpers.measureAlias(idx));
          }
        }
      })
      .filter((selectConfig): selectConfig is Select => !!selectConfig);
    selectQueries.push(...aggregationSelects);
  }
  return selectQueries;
}

// The purpose of this function is to create a map of column names to safe aliases for use in queries.
// We want to have a safe alias for each column, since SoqlBuilder requires an alias, and the field name
// may be a reserved Soql word (e.g. `group`).
function createSelectAliasMap(
  rowGroupCols: IServerSideGetRowsRequest['rowGroupCols'],
  groupKeys: IServerSideGetRowsRequest['groupKeys'],
  columns: ViewColumn[]
): { [key: string]: string } {
  // if we are making a grouping query, it's of the form SELECT a, AGG(b), AGG(c) ... WHERE ... GROUP BY a;
  if (isDoingGrouping(rowGroupCols, groupKeys)) {
    const selectCol = rowGroupCols[groupKeys.length];
    return {
      [selectCol.id]: selectAlias()
    };
  }

  // if we aren't grouping, we're at the lowest level, and all non-grouped columns will be part of the select
  // e.g. SELECT a, b, c, ... WHERE ...;
  const ungroupedColumns = _.differenceWith(columns, rowGroupCols, (c, r) => c.fieldName === r.id);
  return ungroupedColumns
    .map((col, idx) => [col.fieldName, selectAlias(idx)])
    .reduce((aliases, [colId, alias]) => ({ ...aliases, [colId]: alias }), {});
}

function createMeasureAliasMap(agRequest: IServerSideGetRowsRequest): { [key: string]: string } {
  return agRequest.valueCols
    .filter((col) => !!col.aggFunc)
    .map((col, idx) => [col.id, SoqlHelpers.measureAlias(idx)])
    .reduce((aliases, [colId, alias]) => {
      return { ...aliases, [alias]: colId };
    }, {});
}

function unaliasRowData(data: any[], aliasMap: { [key: string]: string }): any[] {
  return Object.keys(aliasMap).length
    ? data.map((row) => {
        const unaliasedRow = {};
        for (const colName in row) {
          if (aliasMap[colName]) {
            unaliasedRow[aliasMap[colName]] = row[colName];
          } else {
            unaliasedRow[colName] = row[colName];
          }
        }
        return unaliasedRow;
      })
    : data;
}

function selectAlias(idx?: number) {
  return `__select_alias${idx || ''}__`;
}
