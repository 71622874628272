import { chain, each, get, includes, isEmpty, isEqual, keys, isNil, trim, isString } from 'lodash';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  HeaderClassParams,
  ICellRendererParams,
  ITooltipParams,
  RowClassParams,
  ValueGetterParams
} from '@ag-grid-community/core';
import { isGeospatial, SoQLType } from 'common/types/soql';
import { ViewColumn } from 'common/types/viewColumn';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import DataTypeFormatter, { TIME_FORMATS } from 'common/DataTypeFormatter';
import {
  CHECKBOX_COLUMN_TYPE,
  COLOR_PALETTE_VALUES_FOR_MAPS,
  HIERARCHY_AGGREGATION_TYPES,
  agConditionalTypeOptions,
  agAlignType
} from 'common/authoring_workflow/constants';
import {
  LOCATION_AGGREGATION_TYPES,
  NON_NUMERICAL_HIERARCHY_AGGREGATION_TYPES,
  NULL_GROUPING,
  NUMERICAL_HIERARCHY_AGGREGATION_TYPES
} from '../Constants';
import I18n from 'common/i18n';
import {
  TableColumnFormat,
  Expression,
  FormatStyle,
  RowFormat,
  ConditionalConfig
} from 'common/authoring_workflow/reducers/types';
import { FILTER_FUNCTION, OPERATOR } from 'common/components/FilterBar/SoqlFilter';
import { getLinearBuckets } from 'common/visualizations/helpers/RangeHelper.js';
import { getRangeBucket } from 'common/visualizations/helpers/BucketHelper.js';
import {
  getSiteAppearanceColors,
  isColumnStylingInTablesEnabled,
  isTableRowStripeStyleEnabled,
  isUpdatedConditionalFormattingDesignsEnabled
} from 'common/visualizations/helpers/VifSelectors';
import { isSiteAppearanceColorPalette } from 'common/visualizations/helpers/SiteAppearanceColors';
import moment from 'moment';
import { RowStripeStyle } from 'common/types/agGrid/rowStripe';
import { isUndefined } from 'lodash';
import { defaultConditionalConfig } from 'common/authoring_workflow/components/panes/PresentationPane/AgGridUpdated/AgGridColumnFormatting/helpers';

const noValueText = I18n.t('shared.visualizations.charts.common.summary_table.no_value');
const DEFAULT_AG_TABLE_BUCKET_COUNT = 5;

function toColDef(colDef: ColDef | ColGroupDef | null | undefined): ColDef | undefined {
  if ((<ColDef>colDef).field !== undefined) {
    return <ColDef>colDef;
  }
  return undefined;
}

export function getFieldName(
  params: CellClassParams | HeaderClassParams | ICellRendererParams | ITooltipParams | ValueGetterParams
): string | undefined {
  const colDef = params?.colDef;
  let fieldName = undefined;
  if (colDef && 'field' in colDef) fieldName = colDef?.field;
  if (!fieldName && colDef && 'showRowGroup' in colDef && typeof colDef?.showRowGroup === 'string')
    fieldName = colDef?.showRowGroup;
  if (!fieldName && 'data' in params && params?.data) fieldName = keys(params.data)[0];
  if (!fieldName && 'node' in params && typeof params?.node?.field == 'string')
    fieldName = params?.node?.field;

  return fieldName;
}

export function shouldCellRenderAsNumber(
  cellParams: CellClassParams | ICellRendererParams | ITooltipParams
): boolean {
  const cellIsInGroup = cellParams.node?.group === true;
  const cellIsPinned = cellParams.node?.rowPinned === 'bottom';
  const colDef = toColDef(cellParams.colDef);
  const cellIsCountAggregated = colDef?.aggFunc === 'count';
  return (cellIsInGroup || cellIsPinned) && cellIsCountAggregated;
}

export function getColumnAggregationDefinition(
  params: CellClassParams | ICellRendererParams | ITooltipParams,
  nonStandardAggregations: ColumnAggregation[] | null
) {
  // if this is a child cell in the column, it's not aggregated
  if (!params.node?.group) return undefined;

  const colDef = toColDef(params?.colDef);
  const fieldName = getFieldName(params);

  // verify that the column in question isn't using a standard aggregation function
  if (HIERARCHY_AGGREGATION_TYPES.some(({ type }) => type === colDef?.aggFunc)) return undefined;

  const aggregation = nonStandardAggregations?.find(
    (agg) => agg.fieldName === fieldName && agg.aggregationType === colDef?.aggFunc
  );
  return aggregation;
}

export function getAllowedColumnAggregationFunctions(
  column: ViewColumn,
  /** Only include aggregations applicable to this column */
  nonStandardAggregations: ColumnAggregation[] = []
): string[] {
  const additionalAggregations = nonStandardAggregations.map(({ aggregationType }) => aggregationType);
  if (column.dataTypeName == SoQLType.SoQLNumberT) {
    return [...NUMERICAL_HIERARCHY_AGGREGATION_TYPES, ...additionalAggregations];
  } else if (isGeospatial(column.dataTypeName)) {
    return [...LOCATION_AGGREGATION_TYPES, ...additionalAggregations];
  } else {
    return [...NON_NUMERICAL_HIERARCHY_AGGREGATION_TYPES, ...additionalAggregations];
  }
}

// returns the input column metadata, or adjusts it if the column is used in a non-standard aggregation
export function getAdjustedColumnMetadata(
  columnMetadata: ViewColumn | undefined,
  aggregationDefinition?: ColumnAggregation | false | null
): ViewColumn | null {
  if (!columnMetadata) return null;
  if (!aggregationDefinition) return columnMetadata;

  return {
    ...columnMetadata,
    renderTypeName: aggregationDefinition.dataTypeName,
    dataTypeName: aggregationDefinition.dataTypeName,
    format: aggregationDefinition.format
  };
}

export function getColumnAsNumber(column?: ViewColumn): ViewColumn | null {
  if (!column) return null;
  return {
    ...column,
    dataTypeName: SoQLType.SoQLNumberT,
    renderTypeName: SoQLType.SoQLNumberT,
    format: { align: 'right' }
  };
}

export function getColumnAsText(column?: ViewColumn): ViewColumn | null {
  if (!column) return null;
  return {
    ...column,
    dataTypeName: SoQLType.SoQLTextT,
    renderTypeName: SoQLType.SoQLTextT,
    format: { align: 'left' }
  };
}

export function plainTextDataFormatter(
  allColumnMetadata: ViewColumn[],
  nonStandardAggregations: ColumnAggregation[] | null,
  params: ITooltipParams,
  domain: string,
  datasetUid: string,
  columnFormats: { [key: string]: TableColumnFormat }
) {
  const fieldName = getFieldName(params);

  const columnMetadata = allColumnMetadata.find((c) => c.fieldName === fieldName);
  const valueIsNull = params.value === NULL_GROUPING;

  let metadata;
  if (valueIsNull) {
    metadata = getColumnAsText(columnMetadata);
  } else if (shouldCellRenderAsNumber(params)) {
    metadata = getColumnAsNumber(columnMetadata);
  } else {
    const columnNonStandardAggregation = getColumnAggregationDefinition(params, nonStandardAggregations);
    metadata = getAdjustedColumnMetadata(columnMetadata, columnNonStandardAggregation);
  }
  let format = {};
  if (isUpdatedConditionalFormattingDesignsEnabled()) {
    const matchedColumnFormat = columnFormats?.[fieldName ?? ''];
    format = get(matchedColumnFormat, 'format', {});

    // Pass noCommas attribute if there is no groupSeparator configured yet
    if (columnMetadata?.format?.hasOwnProperty('noCommas') && !format.hasOwnProperty('groupSeparator')) {
      format = { ...format, noCommas: columnMetadata.format.noCommas };
    }
  }

  return DataTypeFormatter.renderCellHTML(
    valueIsNull ? noValueText : params.value,
    isEmpty(format) ? metadata : { ...metadata, format },
    domain,
    datasetUid
  );
}

export function agGridDataFormatter(
  domain: string,
  datasetUid: string,
  allColumnMetadata: ViewColumn[],
  nonStandardAggregations: ColumnAggregation[] | null,
  params: ICellRendererParams,
  columnFormats: { [key: string]: TableColumnFormat },
  isIndented?: boolean
) {
  const fieldName = getFieldName(params);
  let format = {};
  const columnMetadata = allColumnMetadata.find((c) => c.fieldName === fieldName);

  if (isUpdatedConditionalFormattingDesignsEnabled()) {
    const matchedColumnFormat = columnFormats?.[fieldName ?? ''];
    format = get(matchedColumnFormat, 'format', {});
    // Pass noCommas attribute if there is no groupSeparator configured yet
    if (columnMetadata?.format?.hasOwnProperty('noCommas') && !format.hasOwnProperty('groupSeparator')) {
      format = { ...format, noCommas: columnMetadata.format.noCommas };
    }
  }

  const columnNonStandardAggregation = getColumnAggregationDefinition(params, nonStandardAggregations);

  const valueIsNull = params.value === NULL_GROUPING;
  const valueIsTotal = params.value === I18n.t('shared.visualizations.charts.table.total');
  const valueIsSubTotal = params.value === I18n.t('shared.visualizations.charts.table.subtotal');

  let metadata;
  if (valueIsNull || valueIsTotal || valueIsSubTotal) {
    metadata = getColumnAsText(columnMetadata);
  } else if (shouldCellRenderAsNumber(params)) {
    metadata = getColumnAsNumber(columnMetadata);
  } else {
    metadata = getAdjustedColumnMetadata(columnMetadata, columnNonStandardAggregation);
  }

  return DataTypeFormatter.renderCellHTML(
    valueIsNull ? noValueText : params.value,
    isEmpty(format) ? metadata : { ...metadata, format },
    domain,
    datasetUid
  );
}

/**
 * Order of precedence when it comes to styling configurations:
 *    1. Conditional formatting
 *    2. Column formatting
 *    3. Row Striping
 *    4. Base Styles
 *
 * Configurations with higher precedence should override those with lower precedence.
 */

export const getAgTableRowStyle = (
  params: RowClassParams,
  rowFormat: RowFormat[],
  columnMetadata: ViewColumn[],
  initializeRowStripeStyle?: () => RowStripeStyle
) => {
  let style = {};
  let rowStripeStyle: RowStripeStyle | undefined = undefined;
  if (initializeRowStripeStyle) rowStripeStyle = initializeRowStripeStyle();

  // Base Style config for totals and subtotals
  if (params.node.isRowPinned() || params.node.footer) style['fontWeight'] = 'bold';

  // Row Striping config
  if (
    isTableRowStripeStyleEnabled() &&
    rowStripeStyle?.base &&
    rowStripeStyle?.alternate &&
    params.node.rowIndex !== null
  ) {
    if (params.node.rowIndex % 2 !== 0) {
      style['color'] = rowStripeStyle.alternate.text;
      style['backgroundColor'] = rowStripeStyle.alternate.fill;
    } else {
      style['color'] = rowStripeStyle.base.text;
      style['backgroundColor'] = rowStripeStyle.base.fill;
    }
  }

  // Conditional Formatting and Column Formatting config
  if (isUpdatedConditionalFormattingDesignsEnabled()) {
    each(rowFormat, (formatItem: RowFormat) => {
      const value = get(params, ['data', formatItem.columnName]);
      const column = columnMetadata.find((c) => c.fieldName === formatItem.columnName);
      if (!column) {
        style = {};
      }
      const newStyle = getCellStyleConfig(
        formatItem,
        value,
        column,
        params.node.isRowPinned(),
        params.node.footer
      );
      if (newStyle) {
        style = newStyle;
      }
    });
  }

  return style;
};

export interface CustomCellStyleProps {
  column: ViewColumn;
  nonStandardAggregations: ColumnAggregation[] | null;
  columnFormat: TableColumnFormat;
  params: CellClassParams;
  showSubTotal?: boolean;
  currentRowStripeStyle?: RowStripeStyle | undefined;
}

const isNormalCell = (params: CellClassParams) => {
  return !params.node.isRowPinned() && !params.node.footer;
};

const isSubtotalCell = (params: CellClassParams) => {
  return params.node.footer;
};

const isSubtotalHeader = (params: CellClassParams, showSubTotal: boolean) => {
  if (!params.node.group) return false;
  const colId = params.column.getColId();
  // The structure of params.data for the Subtotal Header is as follows: params.data = { groupedColumn: 'Grouped Column', aggregatedColumn: '1' }.
  const rowData = params.data[colId];
  // In this context, params.value is undefined when the row is expanded and showSubTotal is toggled ON.
  const headerExpandedCell = params.value === undefined && showSubTotal;
  // Examining the structure of params.data, when params.aggregatedColumn is equal to the current cell value, this indicates a subtotal header.
  const isSubtotalHeaderValue = rowData === params.value && !!params.column.getAggFunc();

  return !!rowData && (headerExpandedCell || isSubtotalHeaderValue);
};

const isTotalCell = (params: CellClassParams) => {
  return params.node.isRowPinned();
};

export const getCustomCellStyle = ({
  column,
  nonStandardAggregations,
  columnFormat,
  params,
  showSubTotal = false,
  currentRowStripeStyle
}: CustomCellStyleProps) => {
  let overrideStyle = {};
  let align = '';
  let isAlign = true;

  align = get(columnFormat, 'format.align');
  const fieldValue = get(params, ['data', column.fieldName], '');

  if (isUpdatedConditionalFormattingDesignsEnabled()) {
    const undefinedCellUnderGrouping = isUndefined(params.value) && params.node?.parent?.group;
    const isCellGrandTotalLabel = params.value === I18n.t('shared.visualizations.charts.table.total');
    const isCellNullValue = params.value === NULL_GROUPING;
    overrideStyle =
      columnFormat &&
      getCellStyleConfig(
        columnFormat,
        fieldValue,
        column,
        params.node.isRowPinned(),
        isSubtotalCell(params) || isSubtotalHeader(params, showSubTotal),
        undefinedCellUnderGrouping,
        isCellGrandTotalLabel,
        isCellNullValue,
        currentRowStripeStyle
      );
    if (isNormalCell(params)) isAlign = get(columnFormat, 'isFormatValue', true);
    else if (isTotalCell(params)) isAlign = get(columnFormat, 'isFormatTotal', false);
    else if (isSubtotalCell(params) || isSubtotalHeader(params, showSubTotal))
      isAlign = get(columnFormat, 'isFormatSubtotal', false);
  }

  const textAlign = DataTypeFormatter.getCellAlignment(
    getAdjustedColumnMetadata(column, getColumnAggregationDefinition(params, nonStandardAggregations))
  );

  return {
    textAlign: isAlign ? align || textAlign : textAlign,
    ...overrideStyle
  };
};

export const getCustomHeaderStyle = (matchedColumnFormat: TableColumnFormat) => {
  if (isUpdatedConditionalFormattingDesignsEnabled()) {
    const isAlign = get(matchedColumnFormat, 'isAlignHeader');
    const align = get(matchedColumnFormat, 'format.align');
    const alignHeader = isAlign ? getAlignStyle(align) : '';
    return alignHeader;
  }

  return '';
};

const applyBaseCellStyle = (
  columnFormatItem: TableColumnFormat | RowFormat,
  isCellRowTotal?: boolean,
  isCellRowSubtotal?: boolean,
  undefinedCellInGroup?: boolean,
  isCellGrandTotalLabel?: boolean
) => {
  if (columnFormatItem.style && !undefinedCellInGroup) {
    const baseStyle = {
      color: getCellTextColor(columnFormatItem.style),
      backgroundColor: getCellBackgroundColor(columnFormatItem.style),
      ...getCellTextStyle(columnFormatItem.style)
    };

    if (
      !isCellGrandTotalLabel &&
      ((get(columnFormatItem, 'isFormatTotal', false) && isCellRowTotal) ||
        (get(columnFormatItem, 'isFormatSubtotal', false) && isCellRowSubtotal) ||
        (get(columnFormatItem, 'isFormatValue', true) && !isCellRowTotal && !isCellRowSubtotal))
    ) {
      return baseStyle;
    }
  }
  return {};
};

const applyConditionalFormatting = (
  columnFormatItem: TableColumnFormat | RowFormat,
  expression: Expression,
  column: ViewColumn | undefined,
  style: FormatStyle,
  value: string | number,
  isCellRowTotal?: boolean,
  isCellRowSubtotal?: boolean,
  undefinedCellInGroup?: boolean,
  isCellNullValue?: boolean
) => {
  const expressionFunction = get(expression, 'function', '');
  const conditionalType: string = get(columnFormatItem, 'conditionalType');
  const colorPalette: string = get(columnFormatItem, 'colorPalette');
  const columnType = get(column, 'dataTypeName');
  const isBooleanType = includes([CHECKBOX_COLUMN_TYPE], columnType);
  const isNumberType = isEqual(SoQLType.SoQLNumberT, columnType);

  let cellStyleConfig = {};

  // return a parsable number
  if (isNumberType) {
    value = DataTypeFormatter.renderNumberCellHTML(value, columnFormatItem, {
      returnParsableNumber: true
    });
  }

  // Conditional Formatting
  if (isBooleanType && expressionFunction === value?.toString() && !isCellRowSubtotal) {
    // Boolean formatting
    cellStyleConfig = {
      color: getCellTextColor(style),
      backgroundColor: getCellBackgroundColor(style),
      ...getCellTextStyle(style)
    };
  } else if (conditionalType === agConditionalTypeOptions.LINEAR) {
    // Linear gradient formatting
    const min = Number(get(column, 'rangeMax'));
    const max = Number(get(column, 'rangeMin'));
    const rangeIntervals = getLinearBuckets(min, max, DEFAULT_AG_TABLE_BUCKET_COUNT, 0);
    const colors = isSiteAppearanceColorPalette(colorPalette)
      ? getSiteAppearanceColors(colorPalette)
      : COLOR_PALETTE_VALUES_FOR_MAPS[colorPalette](DEFAULT_AG_TABLE_BUCKET_COUNT);
    const backgroundColor = getLinearBackgroundColor(rangeIntervals, colors, value);

    style = columnFormatItem.style;
    cellStyleConfig = {
      color: getCellTextColor(style),
      backgroundColor: backgroundColor,
      ...getCellTextStyle(style)
    };
  } else if (
    isConditionMeet(expression, value, isNumberType) &&
    !(isCellRowSubtotal || isCellRowTotal || undefinedCellInGroup || isCellNullValue)
  ) {
    // Number, Text, and Date formatting
    cellStyleConfig = {
      color: getCellTextColor(style),
      backgroundColor: getCellBackgroundColor(style),
      ...getCellTextStyle(style)
    };
  }

  return cellStyleConfig;
};

const getCellStyleConfig = (
  columnFormatItem: TableColumnFormat | RowFormat,
  value: string | number,
  column: ViewColumn | undefined,
  isCellRowTotal?: boolean,
  isCellRowSubtotal?: boolean,
  undefinedCellInGroup?: boolean,
  isCellGrandTotalLabel?: boolean,
  isCellNullValue?: boolean,
  currentRowStripeStyle?: RowStripeStyle | undefined
) => {
  if (isCellRowTotal === undefined) isCellRowTotal = false;
  if (isCellRowSubtotal === undefined) isCellRowSubtotal = false;

  if (!isUpdatedConditionalFormattingDesignsEnabled()) return {};

  // new logic used when updated conditional formatting design is enabled
  const conditionalConfig: ConditionalConfig[] = get(columnFormatItem, 'conditionalConfig', [
    defaultConditionalConfig()
  ]);

  const shouldUseRowStriping =
    isTableRowStripeStyleEnabled() && !isEmpty(currentRowStripeStyle) && !isColumnStylingInTablesEnabled();
  let cellStyleConfig = shouldUseRowStriping
    ? {}
    : applyBaseCellStyle(
        columnFormatItem,
        isCellRowTotal,
        isCellRowSubtotal,
        undefinedCellInGroup,
        isCellGrandTotalLabel
      );

  // We traverse the condition configs in reverse since in the UI, the config with index of 0
  // has a higher priority as a configuration.
  for (let i = conditionalConfig.length - 1; i >= 0; i--) {
    const config = conditionalConfig[i];
    const expression = config.expression;
    const style = config.style;
    cellStyleConfig = {
      ...cellStyleConfig,
      ...applyConditionalFormatting(
        columnFormatItem,
        expression,
        column,
        style,
        value,
        isCellRowTotal,
        isCellRowSubtotal,
        undefinedCellInGroup,
        isCellNullValue
      )
    };
  }

  return !isEmpty(cellStyleConfig) ? cellStyleConfig : false;
};

export const getAgTableHeaderName = (matchedColumnFormat: TableColumnFormat, columnMetadata: ViewColumn) => {
  return get(matchedColumnFormat, 'displayName') || columnMetadata.name;
};

const isConditionMeet = (expression: Expression, value: string | number, isNumberType = false) => {
  const expressionFunction = get(expression, 'function', '');
  const expressionValue = get(expression, 'arguments.value', '');
  const start = get(expression, 'arguments.start', '');
  const end = get(expression, 'arguments.end', '');

  if (FILTER_FUNCTION.EQUALS === expressionFunction) {
    if (isNumberType)
      return isNullOrIsBlank(expressionValue) ? false : Number(expressionValue) === Number(value);
    return expressionValue.toString().toLowerCase() === value.toString().toLowerCase();
  } else if (FILTER_FUNCTION.NOT_EQUAL === expressionFunction) {
    if (isNumberType)
      return isNullOrIsBlank(expressionValue) ? false : Number(expressionValue) !== Number(value);
    return expressionValue.toString().toLowerCase() !== value.toString().toLowerCase();
  } else if (FILTER_FUNCTION.GREATER_THAN === expressionFunction) {
    if (isNumberType && isNullOrIsBlank(expressionValue)) return false;
    return Number(value) > Number(expressionValue);
  } else if (FILTER_FUNCTION.GREATER_THAN_EQUAL_TO === expressionFunction) {
    if (isNumberType && isNullOrIsBlank(expressionValue)) return false;
    return Number(value) >= Number(expressionValue);
  } else if (FILTER_FUNCTION.LESS_THAN === expressionFunction) {
    if (isNumberType && isNullOrIsBlank(expressionValue)) return false;
    return Number(value) < Number(expressionValue);
  } else if (FILTER_FUNCTION.LESS_THAN_EQUAL_TO === expressionFunction) {
    if (isNumberType && isNullOrIsBlank(expressionValue)) return false;
    return Number(value) <= Number(expressionValue);
  } else if (OPERATOR.STARTS_WITH === expressionFunction) {
    return (value || '').toString().toLowerCase().startsWith(expressionValue.toLowerCase());
  } else if (OPERATOR.CONTAINS === expressionFunction) {
    return (value || '').toString().toLowerCase().includes(expressionValue.toLowerCase());
  } else if (OPERATOR.DOES_NOT_CONTAIN === expressionFunction) {
    return !(value || '').toString().toLowerCase().includes(expressionValue.toLowerCase());
  } else if (FILTER_FUNCTION.RANGE_EXCLUSIVE === expressionFunction) {
    if (isNumberType && (isNullOrIsBlank(start) || isNullOrIsBlank(end))) return false;
    return Number(start) < Number(value) && Number(end) > Number(value);
  } else if (FILTER_FUNCTION.RANGE_INCLUSIVE === expressionFunction) {
    if (isNumberType && (isNullOrIsBlank(start) || isNullOrIsBlank(end))) return false;
    return Number(start) <= Number(value) && Number(end) >= Number(value);
  } else if (FILTER_FUNCTION.TIME_RANGE === expressionFunction) {
    const startDate = moment(start, TIME_FORMATS.date);
    const endDate = moment(end, TIME_FORMATS.date);
    const endDateEndOfDay = endDate.endOf('day');
    const valueDate = moment(value);

    return (
      valueDate.isBetween(startDate, endDateEndOfDay) ||
      startDate.isSame(valueDate) ||
      endDate.isSame(valueDate)
    );
  }
};

const isNullOrIsBlank = (value: string | number) => {
  return isNil(value) || (isString(value) && isEmpty(trim(value)));
};

const getCellTextColor = (style: FormatStyle) => {
  return get(style, 'textColor');
};

const getCellBackgroundColor = (style: FormatStyle) => {
  return get(style, 'backgroundColor');
};

const getCellTextStyle = (style: FormatStyle) => {
  const textFont = get(style, 'textFont');
  let textStyle = {};

  if (textFont === 'bold') {
    textStyle = { fontWeight: 'bold' };
  } else if (textFont === 'italic') {
    textStyle = { fontStyle: 'italic' };
  } else if (textFont === 'semi_bold') {
    textStyle = { fontWeight: '500' };
  }

  if (isUpdatedConditionalFormattingDesignsEnabled()) {
    const fontStyle = get(style, 'fontStyle');
    textStyle = {
      fontWeight: !!fontStyle?.isBold && 'bold',
      fontStyle: !!fontStyle?.isItalic && 'italic'
    };
  }

  return textStyle;
};

const getLinearBackgroundColor = (rangeIntervals: number[], colors: string[], value: string | number) => {
  const buckets = chain(0)
    .range(rangeIntervals.length - 1, 1)
    .map((index) => {
      const start = rangeIntervals[index];
      const end = rangeIntervals[index + 1];
      return {
        color: colors[index],
        end,
        id: rangeIntervals[index],
        start
      };
    })
    .value();
  const rangeBucket = getRangeBucket({
    buckets,
    value: Number(value),
    bucketCount: buckets.length
  });
  return get(rangeBucket, 'color');
};

const getAlignStyle = (align: string) => {
  if (align === agAlignType.RIGHT) {
    return 'ag-grid-header-right';
  } else if (align === agAlignType.CENTER) {
    return 'ag-grid-header-center';
  } else {
    return 'ag-grid-header-left';
  }
};
