import { isNull, isPlainObject } from 'lodash';
import {
  Distinct, DistinctOn,
  Indistinct, FullyDistinct,
  Expr, Literal
} from 'common/types/soql';
import { ViewColumn } from 'common/types/viewColumn';
import { buildColumnRef, buildLiteral, buildSoqlLiteral, renderExpr } from './expr';
import {
  isString, isNumber, isViewColumn,
  QualifiedViewColumn, isQualifiedViewColumn
} from './util';

type BuildableIntoExpr = QualifiedViewColumn | ViewColumn | Expr | Literal['value'] | number;
type DistinctOnDSL = { distinct_on: BuildableIntoExpr[] };

/**
 * Construct a DistinctOn node.
 *
 * @param distinct_on A list of expressions.
 */
export const buildDistinctOn = (distinct_on: BuildableIntoExpr[]): DistinctOnDSL => ({ distinct_on });

const convertDistinctOn = ({ distinct_on }: DistinctOnDSL): DistinctOn => ({
  distinct_on: distinct_on.map((expr) => {
    if (isQualifiedViewColumn(expr)) {
      return buildColumnRef(expr.column, expr.qualifier);
    } else if (isViewColumn(expr)) {
      return buildColumnRef(expr);
    } else if (isString(expr) || isNumber(expr) || true === expr || false === expr || isNull(expr)) {
      return buildLiteral(expr);
    } else {
      throw new Error('Unhandled Case.');
    }
  })
});

export const convertDistinct = (distinct: DistinctOnDSL | boolean | Indistinct | FullyDistinct): Distinct => {
  if (typeof distinct === 'boolean') {
    return distinct ? 'fully_distinct' : 'indistinct';
  } else if (typeof distinct === 'string') {
    return distinct;
  } else {
    return convertDistinctOn(distinct);
  }
};

export const renderDistinct = (distinct: Distinct): string | null => {
  if (typeof distinct === 'boolean') {
    return distinct ? 'DISTINCT' : null;
  } else if (typeof distinct === 'string') {
    if (distinct === 'indistinct') {
      return null;
    } else if (distinct === 'fully_distinct') {
      return 'DISTINCT';
    }
  } else if ('distinct_on' in distinct) {
    return `DISTINCT ON (${distinct.distinct_on.map(renderExpr).join(', ')})`;
  }
  throw new Error(`distinct ${distinct} invalid`);
};
