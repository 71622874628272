import _ from 'lodash';
import $ from 'jquery';

import { Annotation, LegendGroup } from './types';
import * as Legend from './Legend';
import { LEGEND_POSITION_LEFT, LEGEND_POSITION_RIGHT } from '../SvgConstants';

interface LegendPaneOptions {
  annotations: Annotation[];
  groups: any;
  position: any;
}

/**
 * The legend pane is the "inline legend" option in AX. It shows up as a side bar
 * next to the chart.
 */

export const template = (): JQuery => $('<div>', { class: 'socrata-visualization-legend-pane-container' });

export const renderLegendPane = (
  $container: JQuery,
  { annotations, groups, position }: LegendPaneOptions
) => {
  // Add container marker
  if (position === LEGEND_POSITION_RIGHT) {
    $container.removeClass('socrata-visualization-legend-pane-left');
    $container.addClass('socrata-visualization-legend-pane-right');
  } else if (position === LEGEND_POSITION_LEFT) {
    $container.addClass('socrata-visualization-legend-pane-left');
    $container.removeClass('socrata-visualization-legend-pane-right');
  } else {
    return;
  }

  // Empty legend pane
  const $legendContainer = $container.find('.socrata-visualization-legend-pane-container');
  $legendContainer.empty();

  // Inner container
  const $innerContainer = $('<div>', { class: 'socrata-visualization-legend-bar-inner-container' });
  $legendContainer.append($innerContainer);

  // Menu
  const $legendMenu = $('<div>', { class: 'socrata-legend-menu' });

  groups.forEach((group: LegendGroup) => {
    Legend._renderLegendGroup(group, $legendMenu, annotations);
  });

  // Added a checker if VerticalFilterBar is open and legend is left side of the component.
  // We wanted to avoid legend overlap with filter bar. Overlap issue occured when we apply new filter.
  const zIndex = updatedZIndex($legendContainer);
  if (position === 'right') $legendMenu.css('z-index', zIndex);

  $innerContainer.append($legendMenu);
};

export const removeLegendPane = ($container: JQuery) => {
  $container.removeClass('socrata-visualization-legend-pane-left');
  $container.removeClass('socrata-visualization-legend-pane-right');
  $container.find('.socrata-visualization-legend-pane-container').empty();
};

const updatedZIndex = (legendContainer: JQuery<HTMLElement>) => {
  const parentComponent = legendContainer.parent();
  const componentContainer = parentComponent.closest('.component-container');
  const componentContent = parentComponent.closest('.component-content');
  const componentReference = _.size(componentContainer) > 0 ? componentContainer : componentContent;
  const extendedVerticalBar = componentReference.find('.filter-bar-expanded');

  // legend z-index to return
  return _.size(extendedVerticalBar) === 0 ? '1' : '-1';
};
