import isObject from 'lodash/isObject';

import sift from './sift.js';

/**
 *  Tries to find the current locale in several key paths listed according to precedence.
 */
export default (global = window): string => {
  let localePrefix = '';

  if (isObject(window)) {
    localePrefix = sift(
      global,
      'socrata.localePrefix',
      'serverConfig.localePrefix'
    ) || '';
  }

  return localePrefix;
};
