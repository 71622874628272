import _ from 'lodash';

import {
  assert,
  assertHasProperties,
  assertHasProperty,
  assertInstanceOf,
  assertIsOneOfTypes
} from 'common/assertions';
import formatString from 'common/js_utils/formatString';

import serializeFloatingTimestamp from 'common/js_utils/serializeFloatingTimestamp';
import moment from 'moment';
import { TO_METERS_CONVERSION_FACTORS } from 'common/visualizations/views/mapConstants';
import {
  DATE_FORMAT,
  RELATIVE_FILTERS,
  SINGLE_SELECT_BY,
  getFiscalYearStartMoment
} from 'common/dates';
import { SERIES_TYPE_AG_GRID_TABLE } from '../views/SvgConstants';
import { checkStatus, defaultHeaders } from 'common/http';

const VALID_BINARY_OPERATORS = ['=', '!=', '<', '<=', '>', '>=', 'IS NULL', 'IS NOT NULL', 'contains', 'does_not_contain', 'starts_with', 'ends_with'];
const GET_REQUEST_PROMISE_CACHE = {}; // {[key: string]: Promise<any>}

export function fetchOrderingForVIF(fourfour) {
  const cacheKey = `/api/publishing/v1/default_query/${fourfour}/order_bys`;
  if (GET_REQUEST_PROMISE_CACHE[cacheKey]) {
    return GET_REQUEST_PROMISE_CACHE[cacheKey];
  }
  const promise = fetch(cacheKey, {
    method: 'GET',
    credentials: 'same-origin',
    headers: defaultHeaders
  })
    .then(checkStatus)
    .then(r => r.json())
    .then(body => body.ordering.map(o => ({
      ascending: o.ascending,
      columnName: o.expr.value
    })))
    .catch(() => []);

  GET_REQUEST_PROMISE_CACHE[cacheKey] = promise;
  return promise;
}

/**
 * 'Public' methods
 */

/**
 * @param {Object} vif
 * @param {Number} seriesIndex
 *
 * Note: Only works with VIF versions >= 2
 */
export function dimension(vif, seriesIndex, { defaultAggregationFunction } = {}) {
  let aggregationFunction = _.get(
    vif.series[seriesIndex],
    'dataSource.dimension.aggregationFunction'
  );

  if (_.isNil(aggregationFunction) && !_.isNil(defaultAggregationFunction)) {
    aggregationFunction = defaultAggregationFunction;
  }

  const columnName = getCurrentColumnName(vif, seriesIndex);

  switch (aggregationFunction) {

    case 'sum':
      return `SUM(\`${columnName}\`)`;

    case 'count':
      return 'COUNT(*)';

    case 'avg':
      return `AVG(\`${columnName}\`)`;

    case 'max':
      return `MAX(\`${columnName}\`)`;

    case 'median':
      return `MEDIAN(\`${columnName}\`)`;

    case 'min':
      return `MIN(\`${columnName}\`)`;

    default:
      return `\`${columnName}\``;
  }
}

/**
 * Returns a safe alias for all dimension SoQL references.
 */
export function dimensionAlias() {
  return '__dimension_alias__';
}

/**
 * @param {Object} vif
 * @param {Number} seriesIndex
 *
 * Note: Only works with VIF versions >= 2
 */
export function grouping(vif, seriesIndex) {
  const columnName = _.get(
    vif.series[seriesIndex],
    'dataSource.dimension.grouping.columnName'
  );
  return `\`${columnName}\``;
}

/**
 * Returns a safe alias for all grouping SoQL references.
 */
export function groupingAlias() {
  return '__grouping_alias__';
}

export function escapeColumnName(columnName) {
  return `\`${columnName}\``;
}

/**
 * @param {Object} vif
 * @param {Number} seriesIndex
 *
 * Note: Only works with VIF versions >= 2
 */
export function measure(vif, seriesIndex, { defaultAggregationFunction } = {}) {
  let aggregationFunction = _.get(
    vif.series[seriesIndex],
    'dataSource.measure.aggregationFunction'
  );

  if (_.isNil(aggregationFunction) && !_.isNil(defaultAggregationFunction)) {
    aggregationFunction = defaultAggregationFunction;
  }

  const columnName = _.get(
    vif.series[seriesIndex],
    'dataSource.measure.columnName'
  );

  switch (aggregationFunction) {

    case 'sum':
      return `SUM(\`${columnName}\`)`;

    case 'count':
      return 'COUNT(*)';

    case 'avg':
      return `AVG(\`${columnName}\`)`;

    case 'max':
      return `MAX(\`${columnName}\`)`;

    case 'median':
      return `MEDIAN(\`${columnName}\`)`;

    case 'min':
      return `MIN(\`${columnName}\`)`;

    default:
      return `\`${columnName}\``;
  }
}

/**
 * Returns a safe alias for all measure SoQL references.
 */
export function measureAlias(measureIndex) {
  return `__measure_alias${measureIndex || ''}__`;
}

export function countAlias() {
  return '__count_alias__';
}

/**
 * Returns a safe alias for all error bars lower bound SoQL references.
 */
export function errorBarsLowerAlias() {
  return '__error_bars_lower_alias__';
}

/**
 * Returns a safe alias for all error bars upper bound SoQL references.
 */
export function errorBarsUpperAlias() {
  return '__error_bars_upper_alias__';
}

/**
 * @param {Object} vif
 * @param {Number} seriesIndex
 *
 * Note: Only works with VIF versions >= 2
 */
export function errorBarsLower(vif, seriesIndex) {
  const columnName = _.get(vif.series[seriesIndex], 'errorBars.lowerBoundColumnName');
  const aggregationFunction = _.get(vif.series[seriesIndex], 'dataSource.measure.aggregationFunction');

  return errorBarsForColumnAndAggregation(columnName, aggregationFunction);
}

/**
 * @param {Object} vif
 * @param {Number} seriesIndex
 *
 * Note: Only works with VIF versions >= 2
 */
export function errorBarsUpper(vif, seriesIndex) {
  const columnName = _.get(vif.series[seriesIndex], 'errorBars.upperBoundColumnName');
  const aggregationFunction = _.get(vif.series[seriesIndex], 'dataSource.measure.aggregationFunction');

  return errorBarsForColumnAndAggregation(columnName, aggregationFunction);
}

function errorBarsForColumnAndAggregation(columnName, aggregationFunction) {

  if (_.isEmpty(columnName)) {
    return null;
  }

  switch (aggregationFunction) {

    case 'sum':
      return `SUM(\`${columnName}\`)`;

    case 'count':
      return `COUNT(\`${columnName}\`)`;

    case 'avg':
      return `AVG(\`${columnName}\`)`;

    case 'max':
      return `MAX(\`${columnName}\`)`;

    case 'median':
      return `MEDIAN(\`${columnName}\`)`;

    case 'min':
      return `MIN(\`${columnName}\`)`;

    default:
      return `\`${columnName}\``;
  }
}

/**
 * @param {Object} vif
 * @param {Number} seriesIndex
 * @param {String} dimensionOrMeasure
 *
 * If using with a version 1 VIF, only the first argument is required.
 */
export function aggregationClause(vif, seriesIndex, dimensionOrMeasure) {
  const version = parseInt(_.get(vif, 'format.version', 1), 10);
  let aggregationFunction;
  let columnName;

  if (version === 1) {

    aggregationFunction = _.get(vif, 'aggregation.function');
    columnName = _.get(vif, 'aggregation.field');

    switch (aggregationFunction) {

      case 'sum':
        return `SUM(\`${columnName}\`)`;

      case 'avg':
        return `AVG(\`${columnName}\`)`;

      case 'max':
        return `MAX(\`${columnName}\`)`;

      case 'median':
        return `MEDIAN(\`${columnName}\`)`;

      case 'min':
        return `MIN(\`${columnName}\`)`;

      case 'count':
      default:
        return 'COUNT(*)';
    }
  } else {

    assert(
      dimensionOrMeasure === 'dimension' || dimensionOrMeasure === 'measure',
      `dimensionOrMeasure must be "dimension" or "measure", but was: ${dimensionOrMeasure}`
    );
    assertIsOneOfTypes(seriesIndex, 'number', 'money');

    aggregationFunction = _.get(
      vif.series[seriesIndex],
      `dataSource.${dimensionOrMeasure}.aggregationFunction`
    );
    columnName = getCurrentColumnName(vif, seriesIndex, dimensionOrMeasure);

    switch (aggregationFunction) {

      case 'sum':
        return `SUM(\`${columnName}\`)`;

      case 'count':
        return 'COUNT(*)';

      case 'avg':
        return `AVG(\`${columnName}\`)`;

      case 'max':
        return `MAX(\`${columnName}\`)`;

      case 'median':
        return `MEDIAN(\`${columnName}\`)`;

      case 'min':
        return `MIN(\`${columnName}\`)`;

      default:
        return `\`${columnName}\``;
    }
  }
}

/**
 * @param {Object} vif
 * @param {Number} seriesIndex
 * @param {String} dimensionOrMeasure
 *
 * If using with a version 1 VIF, only the first argument is required.
 */
export function groupByClause(vif, seriesIndex, dimensionOrMeasure) {
  const version = parseInt(_.get(vif, 'format.version', 1), 10);

  if (version === 1) {

    return dimensionAlias();

  } else {

    const aggregationFunction = _.get(
      vif.series[seriesIndex],
      `dataSource.${dimensionOrMeasure}.aggregationFunction`
    );

    // If there is no measure aggregation function, the measure column name
    // is placed in the SELECT fields list (See aggregationClause above). If
    // this is so, we need to include the measureAlias in the GROUP BY
    // clause.
    return (aggregationFunction === null) ?
      `${dimensionAlias()},${measureAlias()}` :
      dimensionAlias();
  }
}

/**
 * @param {Object} vif
 * @param {Number} seriesIndex
 *
 * If using with a version 1 VIF, only the first argument is required.
 */
export function whereClauseNotFilteringOwnColumn(vif, seriesIndex) {
  var version = parseInt(_.get(vif, 'format.version', 1), 10);
  var whereClauseComponents;

  if (version === 1) {
    whereClauseComponents = whereClauseFromVif(vif, false);
  } else {
    whereClauseComponents = whereClauseFromSeries(vif, seriesIndex, false);
  }

  return (whereClauseComponents) ? whereClauseComponents : '';
}

/**
 * @param {Object} vif
 * @param {Number} seriesIndex
 *
 * If using with a version 1 VIF, only the first argument is required.
 */
export function whereClauseFilteringOwnColumn(vif, seriesIndex) {
  var version = parseInt(_.get(vif, 'format.version', 1), 10);
  var whereClauseComponents;

  if (version === 1) {
    whereClauseComponents = whereClauseFromVif(vif, true);
  } else {
    whereClauseComponents = whereClauseFromSeries(vif, seriesIndex, true);
  }

  return (whereClauseComponents) ? whereClauseComponents : '';
}

/**
 * @param {object} filter
 *
 * Returns a where clause component (string) representing the individual vif filter.
 */
export function filterToWhereClauseComponent(filter) {
  assertHasProperties(filter, 'columns', 'function', 'arguments');

  switch (filter.function) {
    case 'OR':
    case 'AND':
      return andOrWhereClauseComponent(filter);
    case 'binaryOperator':
      return binaryOperatorWhereClauseComponent(filter);
    case 'binaryComputedGeoregionOperator':
      return binaryComputedGeoregionOperatorWhereClauseComponent(filter);
    case 'isNull':
      return isNullWhereClauseComponent(filter);
    case 'timeRange':
      return timeRangeWhereClauseComponent(filter);
    case 'relativeDateRange':
      return relativeDateRangeWhereClauseComponent(filter);
    case 'valueRange':
      return valueRangeWhereClauseComponent(filter);
    case 'withinCircle':
      return withinCircleWhereClauseComponent(filter);
    case 'rangeInclusive':
      return rangeInclusiveWhereClauseComponent(filter);
    case 'rangeExclusive':
      return rangeExclusiveWhereClauseComponent(filter);
    case 'excludeNull':
      return excludeNullWhereClauseComponent(filter);
    case '=':
      return comparisonWhereClauseComponent(filter);
    case '!=':
      return comparisonWhereClauseComponent(filter);
    case '<':
      return comparisonWhereClauseComponent(filter);
    case '<=':
      return comparisonWhereClauseComponent(filter);
    case '>':
      return comparisonWhereClauseComponent(filter);
    case '>=':
      return comparisonWhereClauseComponent(filter);
    case 'in':
      return inWhereClauseComponent(filter);
    case 'not_in':
    case 'not in':
      return notInWhereClauseComponent(filter);
    case 'noop':
      return noopWhereClauseComponent(filter);
    default:
      throw new Error(`Invalid filter function: \`${filter.function}\`.`);
  }
}

/**
 * @param {object} value
 *
 * Encodes a value in a format suitable for SoQL queries
 */
export function soqlEncodeValue(value) {
  // Note: These conditionals will fall through.
  if (_.isString(value)) {
    return soqlEncodeString(value);
  }

  if (_.isDate(value)) {
    return soqlEncodeDate(value);
  }

  if (_.isNumber(value) || _.isBoolean(value)) {
    return value;
  }

  throw new Error(
    `Cannot soql-encode value of type: ${typeof value}`
  );
}

/**
 * 'Private' methods
 */

function whereClauseFromVif(vif, filterOwnColumn) {
  var filters = vif.filters || [];
  var isTable = false;

  assertHasProperties(vif, 'type', 'filters');

  if (vif.type === 'table') {
    isTable = true;
  } else {

    assertHasProperties(vif, 'columnName');
    assertIsOneOfTypes(vif.columnName, 'string');
  }

  assertInstanceOf(filters, Array);

  return filters.
    filter(
      function (filter) {
        return (isTable) ?
          true :
          filterOwnColumn || (getColumnFieldName(filter) !== vif.columnName);
      }
    ).
    map(filterToWhereClauseComponent).
    join(' AND ');
}

function whereClauseFromSeries(vif, seriesIndex, filterOwnColumn) {
  var series;
  var filters;
  var isTable = false;

  assertHasProperty(vif, 'series');
  assert(
    vif.series.length && vif.series.length >= seriesIndex,
    '`vif.series` is not an array or seriesIndex is out of bounds.'
  );

  series = vif.series[seriesIndex];

  assertHasProperty(series, 'dataSource');
  assertHasProperties(series.dataSource, 'dimension', 'filters', 'type');

  if (series.type === 'table') {
    isTable = true;
  } else if (!filterOwnColumn) {
    assertHasProperty(series.dataSource.dimension, 'columnName');
    assertIsOneOfTypes(series.dataSource.dimension.columnName, 'string');
  }

  assertInstanceOf(series.dataSource.filters, Array);

  filters = series.dataSource.filters;

  return filters.
    filter(
      function (filter) {
        return (isTable) ?
          true :
          (
            filterOwnColumn ||
            (getColumnFieldName(filter) !== series.dataSource.dimension.columnName)
          );
      }
    ).
    map(filterToWhereClauseComponent).
    filter(_.negate(_.isEmpty)).
    join(' AND ');
}

export function orderByClauseFromSeries(vif, seriesIndex) {
  const series = _.get(vif, `series[${seriesIndex}]`);
  const orderBy = _.get(
    series,
    'dataSource.orderBy',
    {
      parameter: 'measure',
      sort: 'desc'
    }
  );

  assertIsOneOfTypes(orderBy.parameter, 'string');
  assertIsOneOfTypes(orderBy.sort, 'string');

  assert(
    _.includes(['dimension', 'measure'], _.lowerCase(orderBy.parameter)),
    'The key parameter must have a value of "dimension" or "measure".'
  );

  assert(
    _.includes(['asc', 'desc'], _.lowerCase(orderBy.sort)),
    'The key sort must have a value of "asc" or "desc"'
  );

  const sort = _.lowerCase(orderBy.sort) === 'asc' ? 'ASC' : 'DESC';
  const parameter = _.lowerCase(orderBy.parameter) === 'dimension' ?
    dimensionAlias() :
    measureAlias();

  return `${parameter} ${sort}`;
}

export function soqlEncodeColumnName(columnName) {
  assertIsOneOfTypes(columnName, 'string');

  const sanitizedColumnName = columnName.replace(/\-/g, '_');
  return `\`${sanitizedColumnName}\``;
}

function soqlEncodeString(value) {
  const sanitizedValue = value.replace(/'/g, "''");
  return `'${sanitizedValue}'`;
}

function soqlEncodeDate(value) {
  return soqlEncodeString(
    serializeFloatingTimestamp(
      value
    )
  );
}

function filterArgumentRequiresOperand(filterArgument) {

  return (
    filterArgument.operator !== 'IS NULL' &&
    filterArgument.operator !== 'IS NOT NULL'
  );
}

function andOrWhereClauseComponent(filter) {
  const subclause = filter.arguments.map(arg =>
    filterToWhereClauseComponent(arg)
  ).join(` ${filter.function} `);
  return `(${subclause})`;
}

function binaryOperatorWhereClauseComponent(filter) {
  assertHasProperties(filter, 'columns', 'arguments');

  const getColumn = (argument, columnName) => {
    switch (argument.operator) {
      case 'contains':
      case 'does_not_contain':
      case 'starts_with':
      case 'ends_with':
        return `upper(${soqlEncodeColumnName(columnName)})`;
      default: return soqlEncodeColumnName(columnName);
    }
  };

  const getOperator = (argument) => {
    switch (argument.operator) {
      case 'does_not_contain':
        return 'NOT LIKE';
      case 'contains':
      case 'starts_with':
      case 'ends_with':
        return 'LIKE';
      default:
        return argument.operator;
    }
  };

  const getOperand = (argument) => {
    if (!filterArgumentRequiresOperand(argument)) {
      return '';
    }

    switch (argument.operator) {
      case 'contains': return soqlEncodeValue(`%${argument.operand.toUpperCase()}%`);
      case 'does_not_contain': return soqlEncodeValue(`%${argument.operand.toUpperCase()}%`);
      case 'starts_with': return soqlEncodeValue(`${argument.operand.toUpperCase()}%`);
      case 'ends_with': return soqlEncodeValue(`%${argument.operand.toUpperCase()}`);
      default: return soqlEncodeValue(argument.operand);
    }
  };
  const isNotEqualOperator = (filterArgument) => {
    return _.includes(['!='], filterArgument.operator);
  };

  // If `arguments` is an array, that means that we want multiple binary
  // operators to be joined with an 'OR'.
  if (_.isArray(filter.arguments)) {

    const joinOperator = _.get(filter, 'joinOn', 'OR');

    assert(
      joinOperator === 'OR' || joinOperator === 'AND',
      `Invalid binary operator: joinOn property must be either "OR" or "AND", was: ${joinOperator}`
    );

    filter.arguments.forEach((argument) => {

      if (filterArgumentRequiresOperand(argument)) {

        assertHasProperties(argument, 'operand');
      }

      assert(
        VALID_BINARY_OPERATORS.indexOf(argument.operator) > -1,
        `Invalid binary operator: \`${argument.operator}\``
      );
    });

    const mappedArguments = filter.arguments.map((argument) => {
      const encodedColumnName = getColumn(argument, getColumnFieldName(filter));
      const operator = getOperator(argument);
      const encodedOperand = getOperand(argument);

      return `${encodedColumnName} ${operator} ${encodedOperand}`;
    });

    // Note the whitespace on either side of joinOperator!
    const joinedArguments = mappedArguments.join(` ${joinOperator} `);
    if (_.every(filter.arguments, isNotEqualOperator)) {
      return `((${joinedArguments}) OR ${soqlEncodeColumnName(getColumnFieldName(filter))} IS NULL )`;
    }

    return `(${joinedArguments})`;
    // If `arguments` is an object, that means that we want this binary
    // operator to exist on its own (as if arguments were an array with one
    // element.
  } else {

    if (filterArgumentRequiresOperand(filter.arguments)) {

      assertHasProperties(filter, 'arguments.operand');
    }

    assert(
      VALID_BINARY_OPERATORS.indexOf(filter.arguments.operator) > -1,
      `Invalid binary operator: \`${filter.arguments.operator}\``
    );

    const encodedColumnName = getColumn(filter.arguments, getColumnFieldName(filter));
    const operator = getOperator(filter.arguments);
    const encodedOperand = getOperand(filter.arguments);

    return `${encodedColumnName} ${operator} ${encodedOperand}`;
  }
}

function binaryComputedGeoregionOperatorWhereClauseComponent(filter) {
  assertHasProperties(
    filter,
    'columns',
    'arguments',
    'arguments.computedColumnName',
    'arguments.operator',
    'arguments.operand'
  );
  const operator = filter.arguments.operator;
  assert(
    VALID_BINARY_OPERATORS.indexOf(operator) > -1,
    `Invalid binary operator: \`${operator}\``
  );

  const computedColumnName = soqlEncodeColumnName(filter.arguments.computedColumnName);
  const operand = soqlEncodeValue(filter.arguments.operand);
  return `${computedColumnName} ${operator} ${operand}`;
}

function isNullWhereClauseComponent(filter) {
  assertHasProperties(filter, 'columns', 'arguments', 'arguments.isNull');

  const columnName = soqlEncodeColumnName(getColumnFieldName(filter));
  const nullClause = filter.arguments.isNull ? 'IS NULL' : 'IS NOT NULL';
  return `${columnName} ${nullClause}`;
}

function excludeNullWhereClauseComponent(filter) {
  assertHasProperties(filter, 'columns');

  return `${soqlEncodeColumnName(getColumnFieldName(filter))} IS NOT NULL`;
}

function timeRangeWhereClauseComponent(filter) {
  assertHasProperties(filter, 'columns', 'arguments', 'arguments.start', 'arguments.end');

  const singleSelectBy = _.get(filter, 'singleSelect.by');
  const argumentsStart = _.get(filter, 'arguments.start');
  const argumentsEnd = _.get(filter, 'arguments.end');

  let isRelativeStart = false;
  let startMoment;
  let endMoment;

  if (argumentsStart === RELATIVE_FILTERS.TODAY) {
    startMoment = moment().startOf('day');
    isRelativeStart = true;
  } else if (argumentsStart === RELATIVE_FILTERS.YESTERDAY) {
    startMoment = moment().startOf('day').subtract(1, 'days');
    isRelativeStart = true;
  } else {
    startMoment = moment(argumentsStart);
  }

  if (argumentsEnd === RELATIVE_FILTERS.TODAY) {
    // Set end to the start of tomorrow so all of today is included
    endMoment = moment().startOf('day').add(1, 'days');
  } else if (argumentsEnd === RELATIVE_FILTERS.YESTERDAY) {
    // Set end to the start of today so all of yesterday is included
    endMoment = moment().startOf('day');
  } else {
    endMoment = moment(argumentsEnd).add(1, 'day').startOf('day');
  }

  // If we have a relative start argument of 'today' or 'yesterday' and is it
  // single select by day, adjust the end date appropriately.
  if (isRelativeStart && (singleSelectBy === SINGLE_SELECT_BY.DAY)) {
    endMoment = startMoment.clone().add(1, 'days');
  }

  const start = startMoment.format(DATE_FORMAT);
  const end = endMoment.format(DATE_FORMAT);

  const columnName = soqlEncodeColumnName(getColumnFieldName(filter));
  return `${columnName} >= ${soqlEncodeValue(start)} AND ${columnName} < ${soqlEncodeValue(end)}`;
}

function relativeDateRangeWhereClauseComponent(filter) {
  assertHasProperties(
    filter,
    'columns',
    'arguments',
    'arguments.period',
    'arguments.value',
    'arguments.type'
  );
  const { startDate, endDate } = getRelativeDateRange(filter);

  const columnName = soqlEncodeColumnName(getColumnFieldName(filter));
  return `${columnName} >= ${soqlEncodeValue(startDate)} AND ${columnName} < ${soqlEncodeValue(endDate)}`;
}

function getRelativeDateRange(filter) {
  const { period, type, value } = filter.arguments;
  const todayDate = moment().startOf('day').format(DATE_FORMAT);

  if (type === RELATIVE_FILTERS.TODAY) {
    return {
      startDate: todayDate,
      endDate: moment(todayDate).add(1, period).startOf('day').format(DATE_FORMAT)
    };
  } else if (type === RELATIVE_FILTERS.YESTERDAY) {
    const startDate = moment(todayDate).subtract(value, period).startOf('day').format(DATE_FORMAT);
    return {
      startDate,
      endDate: todayDate
    };
  } else if (_.includes([
    RELATIVE_FILTERS.LAST_WEEK,
    RELATIVE_FILTERS.LAST_MONTH,
    RELATIVE_FILTERS.CUSTOM
  ], type)) {
    if (period === 'fiscal_year') {
      return getFiscalYearLastPeriod(period, value, todayDate);
    }
    // coerce "calendar_year" period string to "year" so that moment can understand it
    const periodString = period === 'calendar_year' ? 'year' : period;
    return getStartAndEndDateForTrailingPeriod({ period: periodString, value, todayDate });
  } else if (_.includes([
    RELATIVE_FILTERS.THIS_WEEK,
    RELATIVE_FILTERS.THIS_MONTH,
    RELATIVE_FILTERS.THIS_QUARTER,
    RELATIVE_FILTERS.THIS_YEAR,
    RELATIVE_FILTERS.THIS_CALENDAR_YEAR
  ], type)) {
    return getStartAndEndDateForCurrentPeriod({ period, value, todayDate });
  } else if (type === RELATIVE_FILTERS.THIS_FISCAL_YEAR) {
    return getThisFiscalYear(todayDate);
  }
}

function getStartAndEndDateForTrailingPeriod({ period, value, todayDate }) {
  const startDate = moment(todayDate).subtract(value, period).startOf(period).format(DATE_FORMAT);
  const endDate = moment(todayDate).startOf(period).format(DATE_FORMAT);

  return { startDate, endDate };
}

function getStartAndEndDateForCurrentPeriod({ period, value, todayDate }) {
  const startMoment = moment(todayDate).subtract(value, period).startOf(period);
  const endMoment = moment(todayDate).subtract(value, period).startOf(period).add(1, period);

  return {
    startDate: startMoment.format(DATE_FORMAT),
    endDate: endMoment.format(DATE_FORMAT)
  };
}

function getFiscalYearLastPeriod(period, value, todayDate) {
  const today = moment(todayDate);
  let endYear = today.year();

  // If today occurs after the FY month, the last fiscal year ends in the current year. Subtract 1 otherwise.
  // eg. Government fiscal year runs October to September.
  // If today is 2018-11-15, then the end of the last fiscal year is 2018-09-30.
  endYear += today.dayOfYear() >= getFiscalYearStartMoment().dayOfYear() ? 0 : -1;

  const endDate = getFiscalYearStartMoment().year(endYear).format(DATE_FORMAT);
  const startDate = getFiscalYearStartMoment().year(endYear).subtract(value, 'year').format(DATE_FORMAT);
  return { startDate, endDate };
}

function getThisFiscalYear(todayDate) {
  const today = moment(todayDate);
  let startYear = today.year();

  // If today occurs before the FY month, the start of this fiscal year is actually last year, so subtract 1.
  // eg. Government fiscal year runs October to September.
  // If today is 2018-08-15, then the start of this govt fiscal year is 2017-10-01.
  startYear += today.dayOfYear() >= getFiscalYearStartMoment().dayOfYear() ? 0 : -1;

  const startDate = getFiscalYearStartMoment().year(startYear).format(DATE_FORMAT);
  const endDate = getFiscalYearStartMoment().year(startYear + 1).format(DATE_FORMAT);
  return { startDate, endDate };
}

function comparisonWhereClauseComponent(filter) {
  assertHasProperties(filter, 'columns', 'arguments.value');

  const includeNullValuesTemplate = _.get(filter, 'arguments.includeNullValues', true) ?
    'OR {0} IS NULL' :
    'AND {0} IS NOT NULL';

  const columnName = soqlEncodeColumnName(getColumnFieldName(filter));
  const includeNullValuesQuery = formatString(includeNullValuesTemplate, columnName);
  const value = soqlEncodeValue(filter.arguments.value);
  return `((${columnName} ${filter.function} ${value}) ${includeNullValuesQuery})`;
}

function rangeInclusiveWhereClauseComponent(filter) {
  assertHasProperties(filter, 'columns', 'arguments', 'arguments.start', 'arguments.end');
  const columnName = soqlEncodeColumnName(getColumnFieldName(filter));
  const includeNullValuesQuery = _.get(filter, 'arguments.includeNullValues', true) ?
    `OR ${columnName} IS NULL` :
    `AND ${columnName} IS NOT NULL`;
  const start = soqlEncodeValue(filter.arguments.start);
  const end = soqlEncodeValue(filter.arguments.end);

  return `((${columnName} >= ${start} AND ${columnName} <= ${end}) ${includeNullValuesQuery})`;
}

function rangeExclusiveWhereClauseComponent(filter) {
  assertHasProperties(filter, 'columns', 'arguments', 'arguments.start', 'arguments.end');

  const columnName = soqlEncodeColumnName(getColumnFieldName(filter));
  const includeNullValuesQuery = _.get(filter, 'arguments.includeNullValues', true) ?
    `OR ${columnName} IS NULL` :
    `AND ${columnName} IS NOT NULL`;
  const start = soqlEncodeValue(filter.arguments.start);
  const end = soqlEncodeValue(filter.arguments.end);
  return `((${columnName} > ${start} AND ${columnName} < ${end}) ${includeNullValuesQuery})`;
}

function valueRangeWhereClauseComponent(filter) {
  assertHasProperties(filter, 'columns', 'arguments', 'arguments.start', 'arguments.end');
  const columnName = soqlEncodeColumnName(getColumnFieldName(filter));
  const includeNullValuesQuery = _.get(filter, 'arguments.includeNullValues', true) ?
    `OR ${columnName} IS NULL` :
    `AND ${columnName} IS NOT NULL`;

  const start = soqlEncodeValue(filter.arguments.start);
  const end = soqlEncodeValue(filter.arguments.end);
  return `((${columnName} >= ${start} AND ${columnName} < ${end}) ${includeNullValuesQuery})`;
}

function withinCircleWhereClauseComponent(filter) {
  assertHasProperties(filter, 'columns', 'arguments');

  const lng = _.get(filter, 'arguments[0].center[0]');
  const lat = _.get(filter, 'arguments[0].center[1]');
  const radius = _.get(filter, 'arguments[0].radius');
  const units = _.get(filter, 'arguments[0].units');

  if (_.isUndefined(lng) || _.isUndefined(lat) || _.isUndefined(radius) || _.isUndefined(units)) {
    return;
  }

  if (!_.has(TO_METERS_CONVERSION_FACTORS, units)) {
    throw new Error(`Invalid radius units: \`${units}\`.`);
  }

  const toMetersConversionFactor = TO_METERS_CONVERSION_FACTORS[units];

  const columnName = soqlEncodeColumnName(getColumnFieldName(filter));
  const radiusInMeters = soqlEncodeValue(radius * toMetersConversionFactor);
  return `within_circle(${columnName}, ${soqlEncodeValue(lat)}, ${soqlEncodeValue(lng)}, ${radiusInMeters})`;
}

function inWhereClauseComponent(filter) {
  assertHasProperties(filter, 'columns', 'arguments');
  const mappedValues = filter.arguments.map((arg) => { return soqlEncodeValue(arg); }).join(', ');
  return `${soqlEncodeColumnName(getColumnFieldName(filter))} IN (${mappedValues})`;
}

function notInWhereClauseComponent(filter) {
  assertHasProperties(filter, 'columns', 'arguments');
  const mappedValues = filter.arguments.map((arg) => { return soqlEncodeValue(arg); }).join(', ');
  return `${soqlEncodeColumnName(getColumnFieldName(filter))} NOT IN (${mappedValues})`;
}

function noopWhereClauseComponent() {
  return '';
}

function getCurrentColumnName(vif, seriesIndex, dimensionOrMeasure = 'dimension') {
  let columnName = _.get(vif.series[seriesIndex], `dataSource.${dimensionOrMeasure}.columnName`);
  const drilldownDimensions = _.get(vif.series[seriesIndex], 'dataSource.dimension.drilldowns');
  const groupingColumnName = _.get(vif.series[seriesIndex], 'dataSource.dimension.grouping.columnName', null);
  const isGrouping = !_.isNull(groupingColumnName);

  if (
    !isGrouping &&
    !_.isEmpty(drilldownDimensions) &&
    dimensionOrMeasure === 'dimension'
  ) {
    const currentDrilldownDimensionColumnName = _.get(
      vif.series[seriesIndex],
      'dataSource.dimension.currentDrilldownColumnName'
    );

    columnName = _.isNil(currentDrilldownDimensionColumnName) ?
      columnName :
      currentDrilldownDimensionColumnName;
  }

  return columnName;
}

/**
 * Transforms a raw row request result into a 'table' object.
 *
 * @param {String[]} columnNames - The list of columns to process.
 * @param {Object[]} data - The row request result, which is an array of
 *    objects with keys equal to the column name and values equal to the
 *    row value for each respective column.
 *
 * @return {Object}
 *   @property {String[]} columns - An ordered list of the column aliases
 *     present in the query.
 *   @property {[][]} rows - An array of rows returned by the query.
 *
 * The columns array is of the format:
 *
 *   [<first column name>, <second column name>, ...]
 *
 * Accordingly, each row in the rows array is of the format:
 *
 *   [
 *     <first column value>,
 *     <second column value>,
 *     ...
 *   ]
 *
 * Each row in the errorBars array is of the format:
 *
 *   [
 *     <first column value>,
 *     <second column value>,
 *     ...
 *   ]
 */

function lowercaseKeys(obj) {
  return _.mapKeys(obj, (val, key) => { return key.toLowerCase().replace('-', '_'); });
}

export function getColumnFieldName(filter) {
  return filter.columns[0].fieldName;
}

export function mapSoqlRowsResponseToTable(columnNames, data, tableType, errorBarColumnNames) {
  const nonNullColumnNames = _.without(columnNames, null);
  const table = {
    columns: nonNullColumnNames,
    rows: _.isEqual(tableType, SERIES_TYPE_AG_GRID_TABLE) ? data : _.map(data, (datum) => _.at(lowercaseKeys(datum), nonNullColumnNames)),
    // NOTE: The ':id' property will only exist for queries against NBE
    // datasets. Therefore, we can only use rowIds for the row double click
    // event when displaying NBE datasets.
    rowIds: _.map(data, (datum) => String(lowercaseKeys(datum)[':id'] || 'null'))
  };

  if (!_.isUndefined(errorBarColumnNames)) {

    table.errorBars = data.map((datum) => {
      const row = [];

      for (let i = 0; i < errorBarColumnNames.length; i++) {
        const column = errorBarColumnNames[i];
        const value = datum.hasOwnProperty(column) ? datum[column] : undefined;
        row.push(value);
      }

      return row;
    });
  }

  return table;
}

export default {
  countAlias,
  dimension,
  dimensionAlias,
  escapeColumnName,
  grouping,
  groupingAlias,
  measure,
  measureAlias,
  errorBarsLower,
  errorBarsLowerAlias,
  errorBarsUpper,
  errorBarsUpperAlias,
  aggregationClause,
  groupByClause,
  orderByClauseFromSeries,
  whereClauseNotFilteringOwnColumn,
  whereClauseFilteringOwnColumn,
  filterToWhereClauseComponent,
  soqlEncodeValue,
  soqlEncodeColumnName,
  mapSoqlRowsResponseToTable,
  fetchOrderingForVIF
};
