import {
  FunCall,
  Expr,
  isFunCall,
  isNullLiteral,
  isNumberLiteral,
  isStringLiteral,
  nullLiteral,
  SoQLFunCall,
  SoQLNumberLiteral,
  SoQLStringLiteral,
  SoQLType,
  TypedSoQLFunCall
} from 'common/types/soql';
import * as _ from 'lodash';
import React from 'react';
import { some } from 'ts-option';
import { AstNode, ExprProps } from '../VisualExpressionEditor';
import Argument from './Argument';
import ChangeFunction from './ChangeFunction';
import CompilerError, { getCompilerError } from './CompilerError';
import VariadicMultiSelect from './VariadicMultiSelect';
import FunctionBadge from './FunctionBadge';

const isCast = (function_name: string | undefined): boolean => !!function_name && function_name.startsWith('cast$');
const isCastOfLiteral = (x: Expr): boolean =>
  isFunCall(x) && isCast(x.function_name) && (isStringLiteral(x.args[0]) || isNullLiteral(x.args[0]) || isNumberLiteral(x.args[0]));

function EditInLiterals(props: ExprProps<FunCall, TypedSoQLFunCall>) {
  const { untyped: expr } = props.eexpr;
  const [left, ...args] = props.eexpr.untyped.args;

  const literals: string[] = args.flatMap(arg => {
    if (isStringLiteral(arg)) return [arg.value];
    if (isNumberLiteral(arg)) return [arg.value];
    if (isNullLiteral(arg)) return [];
    if (isFunCall(arg) && isCast(arg.function_name)) {
      if (isStringLiteral(arg.args[0])) return [arg.args[0].value];
      if (isNumberLiteral(arg.args[0])) return [arg.args[0].value];
      if (isNullLiteral(arg.args[0])) return [];
    }
    throw new Error('Attempt to use EditInLiterals component with non-literal arglist!');
  });

  // we default to text, because implicit casts will be inserted by the compiler
  // to attempt to make things work.
  let soqlT: SoQLType = SoQLType.SoQLTextT;
  if (_.every(args, isNumberLiteral)) {
    soqlT = SoQLType.SoQLNumberT;
  }

  const updateLiterals = (newLiterals: string[]) => {
    if (_.isEmpty(newLiterals)) {
      props.update({
        ...expr,
        args: [
          left,
          nullLiteral
        ]
      });
    } else {
      const soqlLiterals: SoQLStringLiteral[] | SoQLNumberLiteral[] = (
        soqlT === SoQLType.SoQLNumberT ?
          newLiterals.map(value => ({ value, type: 'number_literal' })) :
          // everything is represented as a string literal
          // soql compiler will insert the required to cast to make it work
          // if it's not castable (ex: 'foo'::floating_timestamp) then it fails
          // to compile.
          newLiterals.map(value => ({ value, type: 'string_literal'}))
      );
      props.update({
        ...expr,
        args: [
          left,
          ...soqlLiterals
        ]
      });
    }
  };

  const subExprCompilerError = _.first(args.flatMap((arg, i) => (
    // if any of the subexprs that we're going to render
    // as a multiselect have a compilation issue, we want to show the warning
    getCompilerError(props.eexpr).map(ce => (
      [<CompilerError key={i} error={ce} />]
    )).getOrElseValue([])
  ))) || null;

  return (
    <AstNode {...props} className="funcall block-level-change-icon operator" removable showSuccess={props.querySucceeded}>
      <Argument exprProps={props} argExpr={left} argPosition={0} />
      <ChangeFunction {...props} scope={props.scope}>
        <FunctionBadge fun={expr} />
      </ChangeFunction>

      <VariadicMultiSelect
        update={updateLiterals}
        literals={literals}
        soqlT={some(soqlT)}
        suggestionTarget={left}
        scope={props.scope}/>
      {subExprCompilerError}
    </AstNode>
  );
}

const shouldRenderInLiterals = (f: FunCall) => (f.function_name === SoQLFunCall.In || f.function_name === SoQLFunCall.NotIn || f.function_name === SoQLFunCall.CaselessOneOf || f.function_name === SoQLFunCall.CaselessNotOneOf) &&
      f.args.slice(1).every(a => isStringLiteral(a) || isNullLiteral(a) || isNumberLiteral(a) || isCastOfLiteral(a)) && f.args.length > 0;

export { EditInLiterals, shouldRenderInLiterals };
