import I18n from 'common/i18n';
import { assign, get, isNil, isUndefined, maxBy, minBy, set, values } from 'lodash';
import moment from 'moment';
import formatString from 'common/js_utils/formatString';
import {
  DATE_FORMAT,
  FILTER_TYPES,
  RELATIVE_FILTER_TYPES,
  RELATIVE_FILTER_VALUES,
  RELATIVE_FILTERS,
  SINGLE_SELECT_BY,
  formatDate,
  getFiscalEndYear,
  shouldOverrideFiscalYear
} from 'common/dates';
import {
  DateFilterArgument,
  FILTER_FUNCTION,
  RelativeDateFilter,
  TimeRangeFilter,
  NoopFilter
} from '../../SoqlFilter';
import * as BaseFilter from './BaseFilter';
import { FilterBarColumn } from '../../types';
import { FilterColumnsMap } from './BaseFilter';

export type CalendarDateFilterConfig = TimeRangeFilter | RelativeDateFilter | NoopFilter;

export function migrateFilter(filterConfig: CalendarDateFilterConfig) {
  filterConfig = changeLastWeekOrLastMonthToCustom(filterConfig);
  return fiscalYearToYearOverride(filterConfig);
}

/**
 * Reconciles filter configurations based on whether fiscal year is enabled or not.
 */
function fiscalYearToYearOverride(filterConfig: CalendarDateFilterConfig) {
  const filterType = get(filterConfig, 'arguments.type');
  if (
    (filterType === RELATIVE_FILTERS.THIS_FISCAL_YEAR ||
      filterType === RELATIVE_FILTERS.THIS_CALENDAR_YEAR) &&
    shouldOverrideFiscalYear()
  ) {
    set(filterConfig, 'arguments.type', RELATIVE_FILTERS.THIS_YEAR);
  }

  const filterPeriod = get(filterConfig, 'arguments.period');
  if (
    filterType === RELATIVE_FILTERS.CUSTOM &&
    (filterPeriod === 'calendar_year' || filterPeriod === 'fiscal_year') &&
    shouldOverrideFiscalYear()
  ) {
    set(filterConfig, 'arguments.period', 'year');
  }

  // Override fiscal year option to normal year if FYs have been disabled
  if (get(filterConfig, 'singleSelect.by') === SINGLE_SELECT_BY.FISCAL_YEAR && shouldOverrideFiscalYear()) {
    set(filterConfig, 'singleSelect.by', SINGLE_SELECT_BY.YEAR);
  }
  return filterConfig;
}

/**
 * If we have a filter period type of 'last_week' or 'last_month', we want to migrate this to
 * 'custom' as these filter types are now only supported as custom ranges.
 */
function changeLastWeekOrLastMonthToCustom(filterConfig: CalendarDateFilterConfig) {
  const filterType = get(filterConfig, 'arguments.type');
  if (filterType === RELATIVE_FILTERS.LAST_WEEK || filterType === RELATIVE_FILTERS.LAST_MONTH) {
    set(filterConfig, 'arguments.type', RELATIVE_FILTERS.CUSTOM);
  }
  return filterConfig;
}

export function getMinValue(columns: BaseFilter.FilterColumnsMap) {
  const minColumn = minBy(values(columns), 'rangeMin');
  return ((minColumn as FilterBarColumn).rangeMin ?? '') as string;
}

export function getMaxValue(columns: BaseFilter.FilterColumnsMap) {
  const maxColumn = maxBy(values(columns), 'rangeMax');
  return ((maxColumn as FilterBarColumn).rangeMax ?? '') as string;
}

export function getRangeMinStartDay(columns: BaseFilter.FilterColumnsMap) {
  return isNil(getMinValue(columns)) ? '' : getDate(getMinValue(columns));
}

export function getRangeMaxEndDay(columns: BaseFilter.FilterColumnsMap) {
  return isNil(getMaxValue(columns)) ? '' : moment(getMaxValue(columns)).format(DATE_FORMAT);
}

export function getDate(date?: string | number) {
  // Check for a valid value of 'today' or 'yesterday'
  if (isTodayOrYesterday(date)) {
    return date as RELATIVE_FILTER_TYPES;
  }

  // Checking if undefined because;
  //   moment(null).isValid() === false
  //   moment(undefined).isValid() === true
  return !isUndefined(date) && moment(date).isValid()
    ? moment(date as string).format(DATE_FORMAT)
    : moment().format(DATE_FORMAT);
}

export function getCalendarDateRangeFilter(
  filterConfig: CalendarDateFilterConfig,
  {
    type = filterConfig.arguments?.type,
    period = filterConfig.arguments?.period,
    value = filterConfig.arguments?.value,
    start = filterConfig.arguments?.start,
    end = filterConfig.arguments?.end,
    calendarDateFilterType = filterConfig.arguments?.calendarDateFilterType
  }
) {
  const singleSelectBy = typeof filterConfig.singleSelect === 'object' && filterConfig.singleSelect.by;

  const filterArgs: DateFilterArgument = {
    calendarDateFilterType: calendarDateFilterType ?? FILTER_TYPES.RANGE
  };

  let filterFunction = FILTER_FUNCTION.TIME_RANGE;

  if (singleSelectBy && singleSelectBy !== SINGLE_SELECT_BY.DAY) {
    // Single select (except for day), should always be of type range
    filterArgs.calendarDateFilterType = FILTER_TYPES.RANGE;
    filterArgs.start = start;
    filterArgs.end = end;
  } else if (type === RELATIVE_FILTERS.DATE_TO_TODAY) {
    // "Date to today" filter is typed as Relative, but has start/end dates as if it were a range filter.
    // Note: Order does matter here, as single select should take precedence over DATE_TO_TODAY
    filterArgs.calendarDateFilterType = FILTER_TYPES.RANGE;
    filterArgs.start = start;
    filterArgs.end = end;
    filterArgs.type = type;
  } else if (filterArgs.calendarDateFilterType === FILTER_TYPES.RANGE) {
    filterArgs.start = start;
    filterArgs.end = end;
  } else {
    filterFunction = FILTER_FUNCTION.RELATIVE_DATE_RANGE;

    // For relative filters, default all of the arguments if they're not set
    const relativeType = (type || filterConfig.arguments?.type || RELATIVE_FILTERS.TODAY) as string;
    filterArgs.type = relativeType;

    // Changing to a new relative type should update related arguments to defaults for that type
    if (type !== filterConfig.arguments?.type || !type) {
      const datePeriod = get(RELATIVE_FILTER_VALUES, relativeType);
      filterArgs.period = datePeriod.period;
      // TODO: Check if value should actually be a string or a number
      // @ts-ignore
      filterArgs.value = datePeriod.value;
    } else {
      filterArgs.period = period;
      filterArgs.value = value;
    }
  }

  const updatedConfig = assign({}, filterConfig, {
    function: filterFunction,
    arguments: {
      ...filterArgs
    }
  });

  return updatedConfig;
}

export function getSingleSelectDateFilterText(filter: TimeRangeFilter, columns: FilterColumnsMap): string {
  const { start, end } = filter.arguments;
  const singleSelectBy = filter.singleSelect?.by;
  const scope = 'shared.components.filter_bar.calendar_date_filter.relative_periods';

  if (start) {
    if (isTodayOrYesterday(start)) {
      return I18n.t(start, { scope });
    } else if (singleSelectBy === SINGLE_SELECT_BY.YEAR) {
      return formatDate(start, 'YYYY');
    } else if (singleSelectBy === SINGLE_SELECT_BY.FISCAL_YEAR) {
      return formatDate(getFiscalEndYear(end), 'YYYY');
    } else if (singleSelectBy === SINGLE_SELECT_BY.MONTH) {
      return formatDate(start, 'MMMM YYYY');
    } else {
      // default to include the day
      return formatDate(start, 'l');
    }
  } else {
    return BaseFilter.getFilterName(filter, columns);
  }
}

export function getDateRangeFilterText(filter: TimeRangeFilter, columns: FilterColumnsMap): string {
  const { start, end } = filter.arguments;
  const scope = 'shared.components.filter_bar.calendar_date_filter.relative_periods';

  if (start && end) {
    const startLabel = isTodayOrYesterday(start) ? I18n.t(start, { scope }) : formatDate(start, 'l');
    const endLabel = isTodayOrYesterday(end) ? I18n.t(end, { scope }) : formatDate(end, 'l');

    return formatString(
      I18n.t('shared.components.filter_bar.range_filter.range_label'),
      startLabel,
      endLabel
    );
  } else {
    return BaseFilter.getFilterName(filter, columns);
  }
}

export function getRelativeDateFilterText(filter: RelativeDateFilter): string {
  const { type, period, value } = filter.arguments as DateFilterArgument;
  const relativeDateFilterScope = 'shared.components.filter_bar.calendar_date_filter';
  const lastFieldLabel = I18n.t(`${relativeDateFilterScope}.last_field_label`);

  if (type === RELATIVE_FILTERS.CUSTOM) {
    const unit = value === '1' ? 'singular' : 'plural';
    const customPeriod = I18n.t(`${relativeDateFilterScope}.custom_periods.${period}.${unit}`);
    return `${lastFieldLabel} ${value} ${customPeriod}`;
  } else {
    return I18n.t(`${relativeDateFilterScope}.relative_periods.${type}`);
  }
}

export function isTodayOrYesterday(value: any) {
  return value === RELATIVE_FILTERS.TODAY || value === RELATIVE_FILTERS.YESTERDAY;
}

export function isRelative(filter: CalendarDateFilterConfig) {
  return (
    // Date to today is treated as relative by the UI, but has RANGE calendarDateFilterType
    filter.arguments?.type === RELATIVE_FILTERS.DATE_TO_TODAY ||
    filter.arguments?.calendarDateFilterType === FILTER_TYPES.RELATIVE
  );
}
