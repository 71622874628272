import { orderBy } from 'lodash';
import {
  emptyTableAliases,
  OutputColumn,
  QueryAnalysisResult, QueryAnalysisSucceeded, QueryAnalysisFailed,
  QueryCompilationResult, QueryCompilationSucceeded, QueryCompilationFailed,
  TableAliases, ViewContext, CompilationStatus
} from 'common/types/compiler';
import {
  AnalyzedAst,
  AnalyzedSelectedExpression,
  ColumnRef,
  Expr,
  isColumnRef,
  isTypedColumnRef,
  UnAnalyzedJoin,
  isJoinByFromTable,
  TypedSoQLColumnRef,
  UnAnalyzedAst,
  UnAnalyzedSelection,
  UnAnalyzedSelectedExpression,
  Scope,
  BinaryTree,
  isLeaf,
  isCompound,
  TableName,
  reservedTableNames,
  SoQLType,
  TypedExpr,
  TypedSelect
} from 'common/types/soql';
import { View } from 'common/types/view';
import { ViewColumn } from 'common/types/viewColumn';
import { Query, QueryResult, QueryFailure, QuerySuccess, QueryMeta, QueryMetaSuccess, VQEColumn } from '../redux/store';
import { EditableExpression, Eexpr, QueryStatus } from 'common/explore_grid/types';
import * as _ from 'lodash';
import { none, Option, option, some } from 'ts-option';
import { traverseExpr, zipSelection } from './soql-helpers';
import { isEditable, } from '../components/VisualExpressionEditor';

export const viewContextFromQuery = (query: Query): Option<ViewContext> =>
  compilationSuccess(query.compilationResult).map(cr => cr.views)
    .orElse(() => compilationFailure(query.compilationResult).flatMap(cr => cr.views))
    .orElse(() => querySuccess(query.queryResult).map(qr => qr.compiled.views));

export const getTableAliases = (query: Query): TableAliases => {
  return query.compilationResult.flatMap((compilation: QueryCompilationResult) => {
    switch (compilation.type) {
      case CompilationStatus.Staged:
        return some(compilation.tableAliases);
      case CompilationStatus.Succeeded:
        return some(compilation.tableAliases);
      case CompilationStatus.Failed:
        return some(compilation.tableAliases);
      default:
        return querySuccess(query.queryResult).map(qr => qr.compiled.tableAliases);
    }
  }).getOrElseValue(emptyTableAliases());
};

// if the selection is a direct column reference of a column on a parent view, this returns that column from the parent view
export const getSourceColumnFromSelection = (
  selection: AnalyzedSelectedExpression,
  compilationResult: QueryCompilationSucceeded
): Option<ViewColumn> => {
  const expr = selection.expr;
  let sourceColumn: Option<ViewColumn> = none;

  if (isTypedColumnRef(expr)) {
    const qualifier = expr.qualifier ? compilationResult.tableAliases.realTables[expr.qualifier] : '_';
    const view: View = compilationResult.views[qualifier];
    sourceColumn = view ? findViewColumnCaseInsensitive(view.columns, expr) : none;
  }
  return sourceColumn;
};
export const getSourceColumnFromSelectionNA = (
  schemaEntry: OutputColumn,
  analysis: QueryAnalysisSucceeded
): Option<ViewColumn> => {
  const namedExprs = lastInChain(analysis.ast).selection.exprs;
  const selectedExprs = namedExprs.map(nExpr => nExpr.expr);
  const exprOpt = option(selectedExprs.filter(isTypedColumnRef).find(expr => expr.value === schemaEntry.name));
  return exprOpt.flatMap(expr => {
    const qualifier = expr.qualifier ? analysis.tableAliases.realTables[expr.qualifier] : '_';
    const view = analysis.views[qualifier];
    return option(view).flatMap(v => findViewColumnCaseInsensitive(v.columns, expr));
  });
};

export const viewColumnToVQEColumn = (viewColumns: ViewColumn[]): VQEColumn[] => {
  return orderBy(viewColumns, 'position').map(
    // saving id and tableColumnId to state can then end up on a revision, which rightly upsets core when you apply the revision
    ({id, renderTypeName, tableColumnId, computationStrategy, cachedContents, ...col}, idx) =>
      ({position: idx + 1, ...col})
  );
};

export const findVQEColumnCaseInsensitive = (columns: VQEColumn[], selection: AnalyzedSelectedExpression): Option<VQEColumn> => {
  // Core only allows for fieldnames with lowercase (English) letters, digits and underscores so we need to do a
  // case insensitive search in the case that the user has set an alias with a capital letters
  return option(columns.find(col => col.fieldName === selection.name.toLowerCase()));
};
export const findVQEColumnCaseInsensitiveNA = (columns: VQEColumn[], selection: OutputColumn): Option<VQEColumn> => {
  return option(columns.find(col => col.fieldName === selection.name.toLowerCase()));
};

export const findViewColumnCaseInsensitive = (columns: ViewColumn[], expr: ColumnRef): Option<ViewColumn> => {
  // Core only allows for fieldnames with lowercase (English) letters, digits and underscores so we need to do a
  // case insensitive search in the case that the user has set an alias with a capital letters
  return option(columns.find((vc) => vc.fieldName === expr.value.toLowerCase()));
};

export function partialViewColumnToVQEColumn(partialViewColumns: Partial<ViewColumn>[]): VQEColumn[] {
  return orderBy(partialViewColumns, 'position').map<VQEColumn>((vc, i) => {
    const column: VQEColumn = {
      fieldName: vc.fieldName || '',
      description: vc.description || '',
      name: vc.name || vc.fieldName,
      flags: vc.flags || [],
      format: vc.format || {},
      position: i + 1,
      dataTypeName: vc.dataTypeName || SoQLType.SoQLTextT
    };
    if (vc.width) {
      return {...column, width: vc.width};
    }

    return column;
  });
}

export const getColumnForMetadataRepresentation = (selection: AnalyzedSelectedExpression, stateColumns: VQEColumn[], success: QueryCompilationSucceeded): Option<VQEColumn> => {
  const sourceColumn = getSourceColumnFromSelection(selection, success);
  const maybeMetadataCol = findVQEColumnCaseInsensitive(stateColumns, selection);
  return maybeMetadataCol.isDefined ? maybeMetadataCol : sourceColumn.map(sc => viewColumnToVQEColumn([sc])[0]);
};
export const getColumnForMetadataRepresentationNA = (selection: OutputColumn, stateColumns: VQEColumn[], success: QueryAnalysisSucceeded): Option<VQEColumn> => {
  const sourceColumn = getSourceColumnFromSelectionNA(selection, success);
  const maybeMetadataCol = findVQEColumnCaseInsensitiveNA(stateColumns, selection);
  return maybeMetadataCol.isDefined ? maybeMetadataCol : sourceColumn.map(sc => viewColumnToVQEColumn([sc])[0]);
};

export interface ViewColumnColumnRef {
  view: View;
  column: ViewColumn;
  ref: ColumnRef;
  typedRef: TypedSoQLColumnRef;
}

function viewColumnRefsHelper(alias: Option<string>, view: View): ViewColumnColumnRef[] {
  return (view.columns
    .filter(column => !(column.flags || []).includes('hidden'))
    .map(column => {
      const ref: ColumnRef = {
        type: 'column_ref',
        value: column.fieldName,
        qualifier: alias.orNull
      };

      return {
        view,
        column,
        ref,
        typedRef: {
          ...ref,
          soql_type: column.dataTypeName
        }
      };
    }));
}

export const viewColumnRefs = (vc: ViewContext, ta: TableAliases): ViewColumnColumnRef[] => {
  const viewToAliases: Record<string, string[]> = makeViewToAliasesMap(ta.realTables);
  return _.flatMap(vc, (view, ident) => {
    // a 4x4 can have more than one alias, so for each alias, we get the view columns,
    // setting the column-ref qualifier to the alias
    const aliases = viewToAliases[ident];
    if (aliases) {
      return _.flatMap(aliases, alias => viewColumnRefsHelper(some(alias), view));
    } else {
      return viewColumnRefsHelper(none, view);
    }
  });
};

export function lastInChain<T>(tree: BinaryTree<T>): T {
  if (isLeaf(tree)) return tree.value;
  if (isCompound(tree)) {
    return lastInChain(tree.right);
  }
  throw new Error('Not a BinaryTree!');
}

export function replaceLastInChain<T>(tree: BinaryTree<T>, newItem: T): BinaryTree<T> {
  if (isLeaf(tree)) return { ...tree, value: newItem };
  if (isCompound(tree)) {
    return { ...tree, right: replaceLastInChain(tree.right, newItem) };
  }
  throw new Error('Not a BinaryTree!');
}


export function downcastOption<S extends { type: string }, T extends S>(type: string) {
  const guard = (m2: Option<S>): m2 is Option<T> => m2.filter(m => m.type === type).isDefined;
  return (maybe: Option<S>): Option<T> => {
    if (guard(maybe)) {
      return maybe;
    }
    return none;
  };
}
export const analysisSuccess = downcastOption<QueryAnalysisResult, QueryAnalysisSucceeded>(CompilationStatus.Succeeded);
export const analysisFailure = downcastOption<QueryAnalysisResult, QueryAnalysisFailed>(CompilationStatus.Failed);
export const compilationSuccess = downcastOption<QueryCompilationResult, QueryCompilationSucceeded>(CompilationStatus.Succeeded);
export const compilationFailure = downcastOption<QueryCompilationResult, QueryCompilationFailed>(CompilationStatus.Failed);
export const isCompiling = (q: Query) => q.compilationResult.map(cr => cr.type === CompilationStatus.Started).getOrElseValue(false);

export const querySuccess = downcastOption<QueryResult, QuerySuccess>(QueryStatus.QUERY_SUCCESS);
export const queryFailure = downcastOption<QueryResult, QueryFailure>(QueryStatus.QUERY_FAILURE);
const verifyQueryMetaSuccess = downcastOption<QueryMeta, QueryMetaSuccess>(QueryStatus.QUERY_META_SUCCESS);
export function queryMetaSuccess(qr: Option<QueryResult>): Option<QueryMetaSuccess> {
  return verifyQueryMetaSuccess(querySuccess(qr).map(s => s.meta));
}

// TODO: ask someone about the _ for table specifiers. Is this an OK assumption to make? It seems bad.
export const tableSpecifierToFourFour = (ts: string): string => ts.slice(1);
export const fourfourToTableSpecifier = (ff: string): string => `_${ff}`;

export const defaultTableAliasName = (name: string): string => `_${name.replace(/[^a-zA-Z]/ig, '').toLowerCase()}`;

export function exprContainsRef(expr: Expr, joins: UnAnalyzedJoin[], fourfour: string): boolean {
  const aliasMap = joins.reduce((acc, j) => {
    if (isJoinByFromTable(j.from)) {
      return { ...acc, [j.from.from_table.alias || j.from.from_table.name]: j.from.from_table.name };
    } else {
      return acc;
    }
  }, {});
  return traverseExpr(expr, false, (subExpr: Expr | null, acc: boolean) => {
    if (subExpr === null) {
      return acc;
    }

    if (isColumnRef(subExpr) && subExpr.qualifier) {
      return acc || tableSpecifierToFourFour(aliasMap[subExpr.qualifier]) === fourfour;
    }
    return acc;
  });
}

export function getAstFromAnalysis(query: Query): Option<BinaryTree<TypedSelect>> {
  return query.analysisResult.flatMap((analysis: QueryAnalysisResult) => {
    switch (analysis.type) {
      case CompilationStatus.Succeeded:
        return some(analysis.ast);
      case CompilationStatus.Failed:
        return none;
    }
  });
}

export const getRightmostLeafFromAnalysis = (query: Query): Option<TypedSelect> => getAstFromAnalysis(query).map(lastInChain);

export function getUnAnalyzedAst(query: Query): Option<BinaryTree<UnAnalyzedAst>> {
  // if compilation failed, we may still have an AST to work with
  return query.compilationResult.flatMap((compilation: QueryCompilationResult) => {
    switch (compilation.type) {
      case CompilationStatus.Staged:
        return some(compilation.ast);
      case CompilationStatus.Started:
        return compilation.ast;
      case CompilationStatus.Succeeded:
        return some(compilation.unanalyzed);
      case CompilationStatus.Failed:
        return compilation.unanalyzed;
      default:
        return none;
    }
  });
}

export function getLastUnAnalyzedAst(query: Query): Option<UnAnalyzedAst> {
  return getUnAnalyzedAst(query).map(lastInChain);
}

export function getLastAnalyzedAst(query: Query): Option<AnalyzedAst> {
  return compilationSuccess(query.compilationResult).map(r => r.analyzed).map(lastInChain);
}

export const getGroupBys = (query: Query): Expr[] => {
  return getLastUnAnalyzedAst(query)
    .map((ast) => {
      return ast.group_bys;
    })
    .getOrElseValue([]);
};

export type ProjectionInfo = Option<{
  tableAliases: TableAliases;
  viewContext: ViewContext;
  analyzed: AnalyzedSelectedExpression[];
  unanalyzed: UnAnalyzedSelection;
}>;

/* Creates ProjectionInfo using compilationResult, which is info on the last compiled query. */
export function getCompilationProjectionInfo(query: Query): ProjectionInfo {
  return compilationSuccess(query.compilationResult).map(cs => (
    {
      tableAliases: cs.tableAliases,
      viewContext: cs.views,
      analyzed: lastInChain(cs.analyzed).selection,
      unanalyzed: lastInChain(cs.unanalyzed).selection
    }
  ));
}

/* Creates ProjectionInfo using queryResult, which is info on the last applied query. */
export function getQueryProjectionInfo(query: Query): ProjectionInfo {
  return querySuccess(query.queryResult).map(qs => (
    {
      tableAliases: qs.compiled.tableAliases,
      viewContext: qs.compiled.views,
      analyzed: lastInChain(qs.compiled.analyzed).selection,
      unanalyzed: lastInChain(qs.compiled.unanalyzed).selection
    }
  ));
}

function makeViewToAliasesMap(ta: TableAliases['realTables']): Record<string, string[]> {
  const viewToAliasMap = {};
  _.each(ta, (viewIdentifier, alias) => {
    if (_.has(viewToAliasMap, viewIdentifier)) {
      viewToAliasMap[viewIdentifier].push(alias);
    } else {
      viewToAliasMap[viewIdentifier] = [alias];
    }
  });
  return viewToAliasMap;
}

export const getColumns = (q: Query): ViewColumnColumnRef[] => (
  viewContextFromQuery(q).map(ctx => viewColumnRefs(ctx, getTableAliases(q))).getOrElseValue([])
);
export const getColumnsNA = (q: Query): ViewColumnColumnRef[] => {
  const viewContext = analysisSuccess(q.analysisResult).map(ar => ar.views)
    //.orElse(() => analysisFailure(q.analysisResult).flatMap(ar => ar.views)) // Do we need this?
    .orElse(() => querySuccess(q.queryResult).map(ar =>ar.compiled.views));
  return viewContext.map(ctx => viewColumnRefs(ctx, getTableAliases(q))).getOrElseValue([]);
};

function eexprFromCompilationSuccess<U, A>(
  compilation: QueryCompilationSucceeded,
  getUnanalyzed: (unanalyzed: UnAnalyzedAst) => Option<U>,
  getAnalyzed: (analyzed: AnalyzedAst) => Option<A>
): Option<Eexpr<U, A>> {
  const unanalyzedClause = getUnanalyzed(lastInChain(compilation.unanalyzed));
  const analyzedClause = getAnalyzed(lastInChain(compilation.analyzed));

  return unanalyzedClause.flatMap(un => (
    analyzedClause.map(an => (
      { untyped: un, typed: an }
    ))
  ));
}

function eexprFromCompilationFailure<U, A>(
  compilation: QueryCompilationFailed,
  getUnanalyzed: (unanalyzed: UnAnalyzedAst) => Option<U>,
): Option<Eexpr<U, A>> {
  return compilation.unanalyzed.flatMap((un: BinaryTree<UnAnalyzedAst>) => {
    // the compilation result was a type error; ie: we have the untyped parsetree,
    // but typechecking failed
    return getUnanalyzed(lastInChain(un)).map(unanalyzedClause => (
      {
        untyped: unanalyzedClause,
        error: compilation
      }
    ));
  });
}

export function editableExpression<U, A>(
  cr: Option<QueryCompilationResult>,
  getUnanalyzed: (unanalyzed: UnAnalyzedAst) => Option<U>,
  getAnalyzed: (analyzed: AnalyzedAst) => Option<A>
): Option<Eexpr<U, A>> {
  return cr.flatMap((compilation: QueryCompilationResult): Option<Eexpr<U, A>> => {
    if (compilation.type === CompilationStatus.Succeeded) {
      return eexprFromCompilationSuccess<U, A>(compilation, getUnanalyzed, getAnalyzed);
    } else if (compilation.type === CompilationStatus.Failed) {
      return eexprFromCompilationFailure<U, A>(compilation, getUnanalyzed);
    }

    // otherwise it's in progress, so we won't be editing it
    return none;
  });
}

export function typedComponent<U, T>(eexpr: Eexpr<U, T>): Option<T> {
  if (isEditable(eexpr)) {
    return option(eexpr.typed);
  }
  return none;
}

export function hasQuerySucceeded(query: Query): boolean {
  return query.queryResult.match({
    some: (result) => {
      if (result.type === QueryStatus.QUERY_SUCCESS) {
        return query.compilationResult.match({
          some: (compResult) => _.get(compResult, 'runnable', '') === _.get(result, 'compiled.runnable', ''),
          none: () => true
        });
      }

      return false;
    },
    none: () => false
  });
}

export function getEditableSelectedExpressions(query: Query): Option<EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[]> {
  return viewContextFromQuery(query).flatMap(viewContext => {
    return compilationSuccess(query.compilationResult).map(cs => {
      return doGetEditableSelections(cs);
    });
  });
}

export function getEditableSelectedExpressionsFromQuery(query: Query): Option<EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[]> {
  return viewContextFromQuery(query).flatMap(viewContext => {
    return querySuccess(query.queryResult).map(qs => {
      return doGetEditableSelections(qs.compiled);
    });
  });
}

function doGetEditableSelections(cs: QueryCompilationSucceeded): EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[] {
  const analyzedSelection = lastInChain(cs.analyzed).selection;
  const expandedUnanalyzedSelection = zipSelection(
    cs.views,
    cs.tableAliases,
    lastInChain(cs.unanalyzed).selection,
    analyzedSelection
  );

  return _.zip(expandedUnanalyzedSelection.exprs, analyzedSelection).flatMap(([untyped, typed]) => {
    if (!untyped || !typed) return [];
    return [{ untyped, typed }];
  });
}

export function getOnlyAggregates(scope: Scope): Scope {
  return scope.filter(funspec => funspec.is_aggregate);
}

export function collectJoinViews(tree: BinaryTree<AnalyzedAst>): string[] {
  if (isCompound(tree)) {
    return [collectJoinViews(tree.left), collectJoinViews(tree.right)].flat().sort();
  } else {
    const joinViews = [] as string[];
    const nonReservedTableName = (tn: TableName): string | null => {
      if (!reservedTableNames.includes(tn.name)) {
        return tn.name;
      }
      return null;
    };

    if (tree.value.from) {
      const selectFrom = nonReservedTableName(tree.value.from);
      if (selectFrom) joinViews.push(selectFrom);
    }

    tree.value.joins.forEach(join => {
      if (join.from.type === 'from_table') {
        const joinFrom = nonReservedTableName(join.from.from_table);
        if (joinFrom) joinViews.push(joinFrom);
      } else if (join.from.type === 'sub_analysis') {
        joinViews.concat(collectJoinViews(join.from.sub_analysis.analyses));
      }
    });

    return joinViews.flat().sort();
  }
}
