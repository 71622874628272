import {
  assign,
  chain,
  first,
  find,
  includes,
  isEmpty,
  isString,
  isNull,
  get,
  map,
  size,
  toLower,
  flatten,
  uniqBy
} from 'lodash';

import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';
import * as BaseFilter from './BaseFilter';

import {
  BinaryOperator,
  FilterValue,
  FILTER_FUNCTION,
  OPERATOR,
  NoopFilter,
  SoqlFilter
} from '../../SoqlFilter';
import { DataProvider } from '../../types';
import { ParameterConfiguration } from 'common/types/reportFilters';
import { ViewColumn } from 'common/types/viewColumn';
import SoqlDataProvider, {
  SoqlDataProviderConfig
} from 'common/visualizations/dataProviders/SoqlDataProvider';
import { FILTER_SORTING_TYPES } from 'common/authoring_workflow/constants';
import {
  isEqualityOperator,
  isEqualityFilter,
  getOperator,
  groupAllFiltersByDataset,
  groupAllParameterOverridesByDataset
} from './index';

export interface FetchedRawResult {
  [key: string]: string;
}

interface ResultByValue {
  value: string;
}

interface ResultByCount {
  value: string;
  __count_alias__: number;
}

const SHOW_RESULTS_COUNT = 20;
const COUNT_ALIAS = '__count_alias__';
export type TextSoqlFilter = BinaryOperator | NoopFilter;

// This function matches getOperands for ComputedColumnFilters except
// it checks for null values and returns only the operand value.
export function getOperands(filter: TextSoqlFilter) {
  if (filter.function === FILTER_FUNCTION.BINARY_OPERATOR) {
    return ((filter as BinaryOperator).arguments || []).map((argument) => {
      const { operator, operand } = argument;
      if (operator === OPERATOR.NULL || operator === OPERATOR.NOT_NULL) {
        return null;
      }
      return operand;
    });
  } else {
    return [];
  }
}

export function hasOperands(filter: TextSoqlFilter) {
  return isEqualityFilter(filter) ? getOperands(filter).length > 0 : !isEmpty(getOperands(filter)[0]);
}

export function setOperator(filter: TextSoqlFilter, operator: OPERATOR): TextSoqlFilter {
  if (isEqualityFilter(filter) !== isEqualityOperator(operator)) {
    // If we're switching between suggestions vs user input values, then
    // also reset the selected values.
    return isEqualityOperator(operator)
      ? getEqualityTextFilter(filter, { operator, values: [] })
      : getContainsTextFilter(filter, { operator, operand: '' });
  }

  return isEqualityOperator(operator)
    ? getEqualityTextFilter(filter, { operator, values: getOperands(filter) })
    : getContainsTextFilter(filter, { operator, operand: getOperands(filter)[0] ?? '' });
}

export function getEqualityTextFilter(
  filter: TextSoqlFilter,
  {
    operator = getOperator(filter),
    values
  }: {
    operator?: OPERATOR;
    values: FilterValue[];
  }
): TextSoqlFilter {
  const isOperatorNegated = operator === OPERATOR.NOT_EQUAL;
  const isSingleSelect = filter.singleSelect;
  const isNegated = !isSingleSelect && isOperatorNegated;
  const toArgument = (value: FilterValue) => {
    if (isNull(value)) {
      return {
        operator: isNegated ? OPERATOR.NOT_NULL : OPERATOR.NULL
      };
    } else {
      return {
        operator: isNegated ? OPERATOR.NOT_EQUAL : OPERATOR.EQUALS,
        operand: value
      };
    }
  };

  const updatedConfig = assign({}, filter, {
    function: FILTER_FUNCTION.BINARY_OPERATOR,
    joinOn: isNegated ? 'AND' : 'OR',
    arguments: map(values, toArgument)
  });
  return updatedConfig;
}

export function getContainsTextFilter(
  filter: TextSoqlFilter,
  {
    operator = getOperator(filter),
    operand
  }: {
    operator?: OPERATOR;
    operand: FilterValue;
  }
): TextSoqlFilter {
  const updatedConfig = chain({})
    .assign(filter, {
      function: FILTER_FUNCTION.BINARY_OPERATOR,
      arguments: [{ operator, operand }]
    })
    .omit('joinOn')
    .value();

  return updatedConfig as TextSoqlFilter;
}

export async function getTopXOptionsRespectingFilters({
  filter,
  allFilters,
  allParameters,
  offset,
  limit = SHOW_RESULTS_COUNT,
  dataProviderConfigs = []
}: {
  filter: TextSoqlFilter;
  allFilters: SoqlFilter[];
  allParameters: ParameterConfiguration[];
  offset: number;
  limit?: number;
  dataProviderConfigs: DataProvider[];
}) {
  const allFiltersByDataset = groupAllFiltersByDataset(allFilters);
  const allParametersByDataset = groupAllParameterOverridesByDataset(allParameters);

  const getTopOptionsPromises: Promise<ResultByCount[] | ResultByValue[]>[] = filter.columns.map(
    async (column) => {
      const { datasetUid, fieldName } = column;
      const dataProviderConfig: SoqlDataProviderConfig = find(dataProviderConfigs, { datasetUid }) ?? {
        datasetUid
      };
      dataProviderConfig.parameterOverrides = allParametersByDataset[datasetUid] ?? new Map();

      const dataProvider = new SoqlDataProvider(dataProviderConfig, true);
      const topValuesOptions = {
        allFilters: allFiltersByDataset[datasetUid] || [],
        // Coerced to ViewColumn, only "fieldName" is needed in .getTopXOptionsInColumn.
        column: { fieldName } as any as ViewColumn,
        filter: filter as SoqlFilter,
        offset,
        limit
      };

      return dataProvider
        .getTopXOptionsInColumn(topValuesOptions, datasetUid)
        .then((topXResults: FetchedRawResult[]) => {
          // Transform results slightly so that a common label is used for key rather than individual column name.
          return topXResults.map((result: FetchedRawResult) => {
            const transformedResult: ResultByCount | ResultByValue = { value: result[fieldName] };
            // Result may contain __count_alias__ if ordered by most common.
            // If so, convert it to a number since we will need to sum them later.
            if (result.hasOwnProperty(COUNT_ALIAS)) {
              (transformedResult as ResultByCount)[COUNT_ALIAS] = Number.parseInt(result[COUNT_ALIAS], 10);
            }
            return transformedResult;
          });
        });
    }
  );

  const allOptions = await Promise.all(getTopOptionsPromises);
  let mergedOptions: ResultByCount[] | ResultByValue[];
  if (isOrderByAlpha(filter)) {
    mergedOptions = mergeValuesByAlpha(isDescending(filter), allOptions as ResultByValue[][], limit);
  } else {
    mergedOptions = mergeValuesByCount(isDescending(filter), allOptions as ResultByCount[][], limit);
  }

  return {
    topXOptions: mergedOptions,
    picklistOffset: offset + limit
  };
}

export async function getSuggestions({
  filter,
  searchTerm,
  allFilters,
  allParameters,
  limit = SHOW_RESULTS_COUNT,
  dataProviderConfigs = []
}: {
  filter: TextSoqlFilter;
  searchTerm: string;
  allFilters: SoqlFilter[];
  allParameters: ParameterConfiguration[];
  limit?: number;
  dataProviderConfigs?: DataProvider[];
}) {
  if (isEmpty(searchTerm)) {
    return { results: [] };
  }

  const allFiltersByDataset = groupAllFiltersByDataset(allFilters);
  const allParametersByDataset = groupAllParameterOverridesByDataset(allParameters);

  const allSuggestionsPromises: Promise<string[]>[] = filter.columns.map(async (column) => {
    const { datasetUid, fieldName } = column;
    const dataProviderConfig: SoqlDataProviderConfig = find(dataProviderConfigs, { datasetUid }) ?? {
      datasetUid
    };
    dataProviderConfig.parameterOverrides = allParametersByDataset[datasetUid] ?? new Map();
    const dataProvider = new SoqlDataProvider(dataProviderConfig, true);
    const searchOptions = {
      columnName: fieldName,
      filters: allFiltersByDataset[datasetUid] || [],
      limit,
      isLazyLoading: false,
      searchTerm
    };

    return dataProvider.searchInColumn(searchOptions) as Promise<string[]>;
  });

  const allSuggestions = await Promise.all(allSuggestionsPromises);

  // Suggestions are actually mildly "ranked" within .searchInColumn method,
  // but ranking itself is not returned by this method. It is a simple rank that places
  // suggestions that start with the search term at the top of the list.
  // While flattening array of promises, we are placing suggestions that start with the search term first.

  const suggestionsRankedHigher: string[] = [];
  const suggestionsRankedLower: string[] = [];

  allSuggestions.forEach((suggestions) => {
    suggestions.forEach((suggestion) => {
      // Do not include selected values.
      if (getOperands(filter).indexOf(suggestion) === -1) {
        // If suggestion starts with the search term, place it in the higher ranked array.
        if (suggestion.toLowerCase().startsWith(searchTerm.toLowerCase())) {
          // Only if it is not already in the higher ranked array.
          if (suggestionsRankedHigher.indexOf(suggestion) === -1) {
            suggestionsRankedHigher.push(suggestion);
          }
        } else {
          if (suggestionsRankedLower.indexOf(suggestion) === -1) {
            suggestionsRankedLower.push(suggestion);
          }
        }
      }
    });
  });

  // We need top "limit" number of results. Trying to be a little more efficient than merging two suggestions first.
  let suggestions: string[] = [];
  suggestionsRankedHigher.sort((a, b) => a.localeCompare(b));

  if (suggestionsRankedHigher.length >= limit) {
    suggestions = suggestionsRankedHigher.slice(0, limit);
  } else {
    // Since there weren't enough suggestions in ranked higher array, we need to merge those ranked lower as well.
    suggestionsRankedLower.sort((a, b) => a.localeCompare(b));
    suggestions = suggestionsRankedHigher.concat(
      suggestionsRankedLower.slice(0, limit - suggestionsRankedHigher.length)
    );
  }

  // Transform suggestions into format that TextFilterEditor expects.
  const results = suggestions.map((suggestion) => ({ title: suggestion, matches: [] }));

  return { results };
}

export function migrateFilter(filter: TextSoqlFilter, columns: BaseFilter.FilterColumnsMap) {
  if (filter.singleSelect && filter.arguments) {
    const validSingleSelectOperators = [OPERATOR.EQUALS, OPERATOR.NULL];
    if (
      (filter.function === FILTER_FUNCTION.BINARY_OPERATOR &&
        (filter as BinaryOperator).arguments.length > 1) ||
      !includes(validSingleSelectOperators, getOperator(filter))
    ) {
      return BaseFilter.reset(filter, columns);
    }
  }
  return filter;
}

function isOrderByAlpha(filter: TextSoqlFilter) {
  const { orderBy } = filter;
  return get(orderBy, 'parameter') === FILTER_SORTING_TYPES.alphabetical.title;
}

function isDescending(filter: TextSoqlFilter) {
  const { orderBy } = filter;
  return get(orderBy, 'sort', 'desc') === 'desc';
}

function mergeValuesByAlpha(
  sortDescending: boolean,
  values: ResultByValue[][],
  limit: number = SHOW_RESULTS_COUNT
): ResultByValue[] {
  // flatten array of arrays and remove undefined values
  const flattenedValues = flatten(values).filter((option) => option.value);
  // remove duplicates
  const uniqueValues = uniqBy(flattenedValues, 'value');
  // sort alphabetically and return "limit" number of values
  if (sortDescending) {
    return uniqueValues
      .sort((a, b) => a.value.localeCompare(b.value))
      .reverse()
      .slice(0, limit);
  } else {
    return uniqueValues.sort((a, b) => a.value.localeCompare(b.value)).slice(0, limit);
  }
}

function mergeValuesByCount(
  sortDescending: boolean,
  values: ResultByCount[][],
  limit: number = SHOW_RESULTS_COUNT
): ResultByCount[] {
  // flatten array of arrays and remove undefined values
  const flattenedValues = flatten(values).filter((option) => option.value);
  // remove duplicates but add counts
  // temporarily creating a map/dictionary to merge counts
  const uniqueValues: { [key: string]: number } = flattenedValues.reduce((acc, value) => {
    if (acc[value.value]) {
      acc[value.value] += value.__count_alias__;
    } else {
      acc[value.value] = value.__count_alias__;
    }
    return acc;
  }, {} as { [key: string]: number });
  // convert it back to an array, sort by count, and return "limit" number of results
  if (sortDescending) {
    return Object.keys(uniqueValues)
      .map((key) => ({
        value: key,
        __count_alias__: uniqueValues[key]
      }))
      .sort((a, b) => b.__count_alias__ - a.__count_alias__)
      .slice(0, limit);
  } else {
    return Object.keys(uniqueValues)
      .map((key) => ({
        value: key,
        __count_alias__: uniqueValues[key]
      }))
      .sort((a, b) => a.__count_alias__ - b.__count_alias__)
      .slice(0, limit);
  }
}

export function getTextFilterHumanText(filter: BinaryOperator): string {
  const valueCount = size(filter.arguments);
  const firstArgument = first(filter.arguments);
  const firstValue = firstArgument?.operandLabel || firstArgument?.operand;
  const operators = map(filter.arguments, 'operator');
  const firstOperator = first(operators);
  const isNegated = toLower(filter.joinOn) === 'and';
  const scope = 'shared.components.filter_bar.text_filter';

  if (isNegated) {
    if (valueCount > 1) {
      return formatString(I18n.t('n_values_negated', { scope }), valueCount);
    } else if (isString(firstValue)) {
      return formatString(I18n.t('single_value_negated', { scope }), firstValue);
    } else {
      return I18n.t('no_value_negated', { scope });
    }
  } else if (valueCount > 1) {
    return formatString(I18n.t('n_values', { scope }), valueCount);
  } else if (isString(firstValue)) {
    if (firstOperator === 'contains') {
      return formatString(I18n.t('contains_value', { scope }), firstValue);
    } else if (firstOperator === 'does_not_contain') {
      return formatString(I18n.t('does_not_contain_value', { scope }), firstValue);
    } else if (firstOperator === 'starts_with') {
      return formatString(I18n.t('starts_with_value', { scope }), firstValue);
    } else {
      return firstValue;
    }
  } else {
    return I18n.t('no_value', { scope });
  }
}
