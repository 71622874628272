import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import { SocrataIcon } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';

import './search-box.scss';

const classNameScope = 'common--components--Autocomplete--autocomplete--search-box';

class SearchBox extends Component {
  // focused starts out undefined to prevent an animation on page load
  state = {
    autocomplete: {
      focused: undefined
    }
  };

  componentDidMount() {
    this.domNode.addEventListener('keydown', this.handleKeyDown);

    // if we're collapsible, this component gets mounted when it expands
    if (this.props.collapsible) {
      this.domNode.focus();
    }
  }

  componentWillUnmount() {
    this.domNode.removeEventListener('keydown', this.handleKeyDown);
  }

  getIconStyleName = () => {
    const { collapsible, animate, mobile } = this.props;
    const { focused } = this.state;

    if (animate === false) {
      if (mobile === true) {
        return 'icon-container-static-mobile';
      } else {
        return 'icon-container-static';
      }
    } else if (_.isUndefined(focused)) {
      return 'icon-container-base';
    } else if (collapsible) {
      return 'icon-container-collapsible';
    } else if (focused === true) {
      return 'icon-container-focused';
    } else {
      return 'icon-container-unfocused';
    }
  }

  getInputStyleName = () => {
    const { collapsible, animate, mobile } = this.props;
    const { focused } = this.state;

    if (animate === false) {
      if (mobile === true) {
        return 'search-box-static-mobile';
      } else {
        return 'search-box-static';
      }
    } else if (_.isUndefined(focused)) {
      return 'search-box-base';
    } else if (collapsible) {
      return 'search-box-collapsible';
    } else if (focused === true) {
      return 'search-box-focused';
    } else {
      return 'search-box-unfocused';
    }
  }

  handleKeyDown = () => {
    if (this.state.focused) {
      this.props.onResultVisibilityChanged(true);
    }
  }

  handleFormSubmit = (event) => {
    const { focusedResult, onChooseResult, query } = this.props;

    event.preventDefault();

    // goto the search URL if we DON'T have a focused result
    if (_.isUndefined(focusedResult)) {
      onChooseResult(query, focusedResult);

      // Collapse any search results that might be there.
      this.domNode.blur();
      this.setState({ focused: false });
    }
  }

  handleChange = (event) => {
    const { onClearSearch, onSearchBoxChanged } = this.props;
    const query = event.target.value;

    if (query.length <= 0) {
      onSearchBoxChanged('');
      onClearSearch();
    } else {
      onSearchBoxChanged(query);
    }

    // update state to reflect new textbox
    onSearchBoxChanged(query);
  }

  handleFocusChanged = (focused) => {
    const { query, retainFocus, setRetainFocus } = this.props;
    const originalQuery = query || '';

    if (originalQuery !== this.props.query) {
      this.props.onSearchBoxChanged(originalQuery);
    }
    if (this.props.onSearchBoxFocused) {
      this.props.onSearchBoxFocused(focused);
    }

    // Maintain focus if appropriate
    if (retainFocus) {
      this.domNode.focus();
      setRetainFocus(false); // reset the retainFocus flag
      this.setState({ focused: true });
      return;
    }

    // keep "focused" state if the current search isn't empty...
    if (!_.isEmpty(query)) {
      this.setState({ focused: true });
    } else {
      this.setState({ focused });
    }
  }

  render() {
    const { collapsible, query, showLoadingSpinner, placeholder, disabled } = this.props;
    const autocompleteSearchInputId = `autocomplete-search-input-${_.random(32768)}`;

    const inputClassName = classNames(
      { 'autocomplete-input': true, 'hide-webkit-cancel-btn': showLoadingSpinner },
      `${classNameScope}--${classNames(this.getInputStyleName())}`
    );

    return (
      <form
        className={collapsible ? `${classNameScope}--form-collapsible` : `${classNameScope}--form`}
        onSubmit={this.handleFormSubmit}>
        <div
          className={`${classNameScope}--${classNames(this.getIconStyleName())}`}
          onClick={() => this.domNode.focus()}>
          <SocrataIcon name="search" />
        </div>
        <div className={`${classNameScope}--loading-spinner-container`}>
          {showLoadingSpinner ? <SocrataIcon name="processing" /> : null}
        </div>
        <label htmlFor={autocompleteSearchInputId} className={`${classNameScope}--aria-not-displayed`}>
          {I18n.t('shared.site_chrome.header.search')}
        </label>
        <input
          autoComplete="off"
          className={inputClassName}
          disabled={disabled}
          id={autocompleteSearchInputId}
          onBlur={() => this.handleFocusChanged(false)}
          onChange={this.handleChange}
          onFocus={() => this.handleFocusChanged(true)}
          placeholder={placeholder}
          ref={(domNode) => this.domNode = domNode}
          role="searchbox"
          type="search"
          name="search"
          value={query || ''} />
      </form>
    );
  }
}

SearchBox.propTypes = {
  /* Callbacks */
  onClearSearch: PropTypes.func,
  onSearchBoxChanged: PropTypes.func.isRequired,
  onResultVisibilityChanged: PropTypes.func.isRequired,

  /* Search config*/
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChooseResult: PropTypes.func.isRequired,
  showLoadingSpinner: PropTypes.bool,
  anonymous: PropTypes.bool,

  retainFocus: PropTypes.bool,
  setRetainFocus: PropTypes.func,

  /* State */
  collapsible: PropTypes.bool,
  animate: PropTypes.bool,
  mobile: PropTypes.bool,

  // need to know this since if it's undefined, it means on form submission and
  // we search for what's in the textbox instead of for the selected result
  focusedResult: PropTypes.number
};

SearchBox.defaultProps = {
  placeholder: '',
  onClearSearch: _.noop
};

export default SearchBox;
