import { compact } from 'lodash';
import {
  Expr,
  ColumnRef, isColumnRef,
  FunCall, isFunCall,
  Literal, isLiteral,
  SoQLStringLiteral, isStringLiteral,
  isNullLiteral, nullLiteral, isSoqlLiteral,
  isURL
} from 'common/types/soql';
import { ViewColumn } from 'common/types/viewColumn';
import { isString, isNumber } from './util';

import { renderStrategyFor } from './function/render';

const renderQualifier = (arg: ColumnRef['qualifier']): string | null => {
  if (arg === null) { return arg; }
  return arg.startsWith('@') ? arg : `@${arg}`;
};
const renderColumnRef = (expr: ColumnRef): string => compact([renderQualifier(expr.qualifier), `\`${expr.value}\``]).join('.');
const renderFunCall = (expr: FunCall): string => renderStrategyFor(expr.function_name)(expr);
const renderLiteral = (expr: Literal): string => {
  if (isStringLiteral(expr)) {
    return `'${(expr as SoQLStringLiteral).value.replace("'", "''")}'`;
  } else if (isNullLiteral(expr)) {
    return 'null';
  } else if (isSoqlLiteral(expr)) {
    return `(${expr.value.toString()})`;
  } else {
    return expr.value.toString();
  }
};
const renderURL = (expr: Expr): string => {
  if ('url' in expr && expr['url']) {
    return `'${expr['url']}'`;
  }
  return '';
};

export const buildColumnRef = (arg: ViewColumn, qualifier?: string): ColumnRef => ({ type: 'column_ref', value: arg.fieldName, qualifier: qualifier || null });
export const buildLiteral = (arg: string | number | boolean | null): Literal => {
  if (isString(arg)) {
    return { type: 'string_literal', value: arg };
  } else if (isNumber(arg)) {
    return { type: 'number_literal', value: arg.toString() };
  } else if (true === arg || false === arg) {
    return { type: 'boolean_literal', value: arg };
  } else {
    return nullLiteral;
  }
};
export const buildSoqlLiteral = (arg: string): Literal => ({ type: 'soql_literal', value: arg });

export const renderExpr = (expr: Expr): string => {
  if (isColumnRef(expr)) {
    return renderColumnRef(expr);
  } else if (isFunCall(expr)) {
    return renderFunCall(expr);
  } else if (isLiteral(expr)) {
    return renderLiteral(expr);
  } else if (isURL(expr)) {
    return renderURL(expr);
  } else {
    throw new Error('Unhandled Case.');
  }
};
