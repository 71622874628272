import { FunCall, Scope, SoQLFunCall, TypedSoQLFunCall } from 'common/types/soql';
import React from 'react';
import { option } from 'ts-option';
import { AstNode, ExprProps } from '../VisualExpressionEditor';
import ChangeFunction from './ChangeFunction';
import { renderArgsWithSuggestions } from './EditFunction';
import FunctionBadge from './FunctionBadge';

function EditOperator(props: ExprProps<FunCall, TypedSoQLFunCall>) {
  const { untyped: expr } = props.eexpr;
  const [left, right] = renderArgsWithSuggestions(props);

  return (
    <AstNode {...props} className="funcall block-level-change-icon operator" removable showSuccess={props.querySucceeded}>
      {left}
      <ChangeFunction {...props} scope={props.scope}>
        <FunctionBadge fun={expr} />
      </ChangeFunction>
      {right}
    </AstNode>
  );
}

const shouldRenderAsOperator = (f: FunCall, scope: Scope) => isOperator(f, scope) || [
  SoQLFunCall.StartsWith as string,
  SoQLFunCall.Contains as string,
  SoQLFunCall.Like as string,
  SoQLFunCall.NotLike as string,
  SoQLFunCall.CaselessContains as string,
  SoQLFunCall.CaselessIs as string,
  SoQLFunCall.CaselessIsNot as string,
  SoQLFunCall.CaselessNotOneOf as string,
  SoQLFunCall.CaselessOneOf as string,
  SoQLFunCall.CaselessStartsWith as string
].includes(f.function_name);


const isOperator = (f: FunCall, scope: Scope): boolean => {
  return option(scope.find(fs => fs.name === f.function_name))
    .map(fs => fs.operator)
    .getOrElseValue(false);
};

export { EditOperator, shouldRenderAsOperator };
