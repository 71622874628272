import _ from 'lodash';

import MapHelper from 'common/visualizations/helpers/MapHelper';
import { DROP_PIN } from 'common/visualizations/views/mapConstants';
import { getCharmCode, MAPBOX_GL_CHARMS_FONT_NAME } from 'common/resources/charms';

export const LAYERS = Object.freeze({ DROP_PIN: 'drop-pin-layer' });
export const SOURCES = Object.freeze({ DROP_PIN: 'drop-pin-source' });

export default class DropPin {
  constructor(map, seriesId) {
    this._map = map;

    this.layerIds = MapHelper.getNameToIdMap(_.values(LAYERS), seriesId);
    this.sourceIds = MapHelper.getNameToIdMap(_.values(SOURCES), seriesId);
  }

  alreadySetup() {
    return !_.isNil(this._map.getSource(this.sourceIds[SOURCES.DROP_PIN]));
  }

  initialize = async () => {
    await MapHelper.afterMapLoad(this._map);

    if (this.alreadySetup()) {
      return;
    }

    this._map.addSource(this.sourceIds[SOURCES.DROP_PIN], {
      type: 'geojson',
      data: getGeojson()
    });

    this._map.addLayer({
      'id': this.layerIds[LAYERS.DROP_PIN],
      'type': 'symbol',
      'source': this.sourceIds[SOURCES.DROP_PIN],
      'layout': {
        'text-font': [MAPBOX_GL_CHARMS_FONT_NAME],
        'text-field': getCharmCode(DROP_PIN.TEXT_FIELD),
        'text-ignore-placement': true,
        'text-size': DROP_PIN.TEXT_SIZE
      },
      'paint': {
        'text-color': DROP_PIN.TEXT_COLOR
      }
    });
  }

  update = async (feature = null) => {
    await MapHelper.afterMapLoad(this._map);
    const source = this._map.getSource(this.sourceIds[SOURCES.DROP_PIN]);

    if (!_.isUndefined(source)) {
      source.setData(getGeojson(feature));
    }
  }
}

function getGeojson(feature = null) {
  let geojsonFeatures = [];
  if (!_.isNil(feature)) {
    geojsonFeatures = [{
      type: 'Feature',
      geometry: feature.geometry
    }];
  }

  return {
    type: 'FeatureCollection',
    features: geojsonFeatures
  };
}
