import { compact, filter, get, includes, isEqual, last, merge, set } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ForgeIcon, ForgeIconButton, ForgeTooltip } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import uuid from 'uuid';
import DOMPurify from 'dompurify';

import { TableColumnFormat, RowFormat } from 'common/authoring_workflow/reducers/types';
import { Hierarchy, HierarchyColumnConfig } from 'common/visualizations/vif';
import { FeatureFlags } from 'common/feature_flags';
import {
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnState,
  GridApi,
  GridReadyEvent,
  IServerSideDatasource,
  FirstDataRenderedEvent,
  SortChangedEvent,
  ColumnVisibleEvent,
  ColumnRowGroupChangedEvent,
  ColumnValueChangedEvent,
  Column,
  RowClassParams,
  MenuItemDef,
  GetMainMenuItemsParams
} from '@ag-grid-community/core';
import { ParameterOverrides } from 'common/components/SingleSourceFilterBar/types';
import { Filters } from 'common/components/FilterBar/types';
import { ViewColumn } from 'common/types/viewColumn';
import { OrderConfig } from 'common/visualizations/vif';
import { getAgTableRowStyle } from './helpers/TableColumnFormatting';

import ReactDOM from 'react-dom';
import { useGroupStateRestore } from './customHooks/useGroupStateRestore';
import useColDefs from './customHooks/useColDefs';
import SocrataTooltip from './SocrataTooltip';
import './index.scss';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import { agGridFilterToVifFilter } from 'common/visualizations/helpers/AgGridHelpers';
import { RowStripeStyle } from 'common/types/agGrid/rowStripe';
import {
  isTableRowStripeStyleEnabled,
  isUpdatedConditionalFormattingDesignsEnabled
} from 'common/visualizations/helpers/VifSelectors';
import NoRowsAgGridOverlay from 'common/components/NoRowsAgGridOverlay';
import { isLargeDesktop } from 'common/visualizations/helpers/MediaQueryHelper';
import {
  getColumnSortState,
  getGroupedColumnsToResetSort,
  getNonGroupedColumnsToResetSort,
  updateColIdForIndentedLayout
} from './helpers/SortHelpers';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
import { getAutoGroupAttributes } from './helpers/AutoGroupAttributes';
import { GROUP_COLUMN_PREFIX } from './Constants';
import { CustomAgGridContext } from 'common/types/agGrid/context';
import { useDeepCompareEffect } from 'common/visualizations/views/agGridReact/customHooks/useDeepCompareEffect';

// It's ok for this license key to be exposed (https://www.ag-grid.com/javascript-data-grid/licensing/#setting-the-license-key)
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-058914}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Tyler_Technologies}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Socrata}_only_for_{23}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Socrata}_need_to_be_licensed___{Socrata}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_July_2025}____[v3]_[01]_MTc1MjQ0NzYwMDAwMA==8816a8a04da5df11a9ea29a90c73164a'
);

const defaultColDef = {
  sortable: true,
  resizable: true,
  minWidth: 80,
  maxWidth: 512,
  tooltipComponent: 'customTooltip'
};

export interface AgGridProps {
  // columnMetadata: This is the same as vifColumns, but has all the columns in the dataset.
  // TODO: This is redundant. I dont know why we need this.
  columnMetadata: ViewColumn[];
  nonStandardAggregations: ColumnAggregation[] | null;
  datasource: IServerSideDatasource;
  getGrandTotalRow: (hierarchyConfig: Hierarchy) => Promise<any>;
  onColumnReorder: (columnState: ColumnState[]) => void;
  onColumnResize: (columns: Column[] | null) => void;
  onColumnRowGroupChange: (columnState: ColumnState[], columns: Column[], hierarchyId?: string) => void;
  onColumnSort: (columnState: ColumnState[], hierarchyId?: string) => void;
  onColumnValueChange: (columns: Column[], hierarchyId?: string) => void;
  onColumnVisibilityChange: (
    columnState: ColumnState[],
    columns: Column[] | null,
    hierarchyId?: string
  ) => void;

  onFilterChange: (newFilter: Filters) => void;
  datasetUid: string;
  domain: string;
  columnFormats: { [key: string]: TableColumnFormat };
  rowFormat: RowFormat[];
  hierarchyConfig?: Hierarchy;
  vifFilters: Filters;
  vifColumns: ViewColumn[];
  vifOrderConfig: OrderConfig[];
  vifParameterOverrides: ParameterOverrides;
  agGridOpenNodeLevel: number;
  searchString?: string;
  paginationPageSize?: number;
  defaultColDefOverrides?: ColDef;
  useSetFilters?: boolean;
  displayColumnFilters?: boolean;
  showAgGridColumnMenu?: boolean;
  showAgGridColumnAggregations?: boolean;
  isIndented?: boolean;
  initializeRowStripeStyle?: () => RowStripeStyle;
  openToolPanelByDefault?: boolean;
}

const Grid = (props: AgGridProps) => {
  const { datasource, useSetFilters, showAgGridColumnMenu, openToolPanelByDefault } = props;
  const flexibleHierarchiesEnabled = FeatureFlags.valueOrDefault('enable_flexible_table_hierarchies', false);
  const showAgGridColumnAggregations =
    flexibleHierarchiesEnabled && (props.showAgGridColumnAggregations ?? true);

  const prevHierarchyConfig = useRef<Hierarchy>();
  const agGridContext = useRef<CustomAgGridContext>({} as CustomAgGridContext);
  const [filters, setFilters] = useState<Filters>([]);
  const [parameterOverrides, setParameterOverrides] = useState<ParameterOverrides>(new Map());
  const [columns, setColumns] = useState<ViewColumn[]>([]);
  const [vifColumnFormat, setVifColumnFormat] = useState<{ [key: string]: TableColumnFormat }>({});
  const [vifRowFormat, setVifRowFormat] = useState<RowFormat[]>([]);
  const [hierarchyColumns, setHierarchyColumns] = useState<HierarchyColumnConfig[]>([]);
  const [removedHierarchyColumnNames, setRemovedHierarchyColumnNames] = useState<Set<string>>(new Set());
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [grandTotalData, setGrandTotalData] = useState<any[]>([]);
  const rowFormatRef = useRef<any>();
  const { isServerSideGroupOpenByDefault, onRowGroupOpened } = useGroupStateRestore();
  const [currentRowStripeStyle, setCurrentRowStripeStyle] = useState<RowStripeStyle | undefined>({});
  const [currentSortState, setCurrentSortState] = useState<OrderConfig[] | []>([]);
  const shouldOpenToolPanel = !!(flexibleHierarchiesEnabled && openToolPanelByDefault);
  const sideBarConfig = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnExpandAll: true,
          suppressColumnMove: false
        }
      }
    ],
    // Set this value to the `id` of a panel you want opened by default.
    // Comment this out, or set `hiddenByDefault: true` to hide the tool panel by default.
    defaultToolPanel: (shouldOpenToolPanel && 'columns') || undefined
  };

  const refreshGridData = (purge?: boolean) => {
    if (gridApi) {
      gridApi.refreshServerSide({ purge });
    }
  };

  // useLayoutEffect runs synchronously after render which helps avoid some ag grid spinning / loading issues
  // don't put anything in here that is async, like data fetching
  useLayoutEffect(() => {
    let shouldRefreshGridData = false;
    let hardRefresh = false;

    if (
      props.hierarchyConfig &&
      props.hierarchyConfig.columnConfigurations &&
      !isEqual(hierarchyColumns, props.hierarchyConfig.columnConfigurations)
    ) {
      const removedColumnNames = hierarchyColumns
        .filter((h) => {
          const vifColumnConfig = props.hierarchyConfig?.columnConfigurations.find(
            (c) => c.columnName === h.columnName
          );
          const columnRemoved = !vifColumnConfig;
          const columnNoLongerGrouping = h.isGrouping && !vifColumnConfig?.isGrouping;
          return columnRemoved || columnNoLongerGrouping;
        })
        .map(({ columnName }) => columnName);
      setHierarchyColumns(props.hierarchyConfig.columnConfigurations);
      setRemovedHierarchyColumnNames(new Set(removedColumnNames));
    }

    // the grid itself doesn't use the vif filters at all (they are used during query construction in TableDataHelpers)
    // but we keep a copy of them in state so that we can detect when they change, and refresh the grid.
    if (!isEqual(filters, props.vifFilters)) {
      if (!fromAddFilter()) {
        shouldRefreshGridData = true;
        // the grid may need a full refresh if the previous filters returned 0 rows
        hardRefresh = true;
      }
      setFilters(props.vifFilters as Filters);
    }

    // Similar to tracking state of vif filters, we also have a copy of vif parameterOverrides
    // to detect changes and refresh the grid when any values change
    if (!isEqual(parameterOverrides, props.vifParameterOverrides)) {
      setParameterOverrides(props.vifParameterOverrides);
      shouldRefreshGridData = true;
      hardRefresh = true;
    }

    if (!isEqual(vifColumnFormat, props.columnFormats)) {
      setVifColumnFormat(props.columnFormats);

      if (!isUpdatedConditionalFormattingDesignsEnabled()) {
        shouldRefreshGridData = true;
      } else {
        // Removing focus from the currently focused cell helps avoid UI issues
        // such as conditional input fields losing focus with every keypress.
        // This will only apply when updated_conditional_formatting_designs is TRUE
        // to keep other formatting designs as is.
        gridApi?.clearFocusedCell();
      }
    }

    if (!isEqual(vifRowFormat, props.rowFormat)) {
      setVifRowFormat(props.rowFormat);
      rowFormatRef.current = props.rowFormat;
      shouldRefreshGridData = true;
    }

    if (!isEqual(columns, props.vifColumns)) {
      // if a new column has been added, tell ag-grid to refresh its data
      if (props.vifColumns.length > columns.length) {
        shouldRefreshGridData = true;
      }
      // if a column was deleted and a new one took its place, we should also refresh
      const vifColumnNames = props.vifColumns.map((col) => col.fieldName);
      const columnNames = columns.map((col) => col.fieldName);

      if (vifColumnNames.some((colName) => !columnNames.includes(colName))) {
        // we don't ask ag-grid to refresh in all cases, since closing the open groups can be a disruptive experience
        // and isn't necessary if a column is removed
        shouldRefreshGridData = true;
      }

      setColumns(props.vifColumns);
    }

    // Update the width of grouped columns for non-indented layout
    if (gridApi) {
      updateGroupedColumnsWidth(gridApi);
    }

    // if a grouped column was sorted on the AX (which is a different grid instance than the story version),
    // the map of vif columns to agColumn defs below will not pick that sort up, because the grouped columns are
    // auto-created by AG Grid and therefore the mapping function never touches them.
    // here we manually compare the vif's view of grouped column sorts with ag-grid's view of grouped column sorts,
    // and if they don't match, we update ag-grid's column state
    if (gridApi && props.hierarchyConfig?.order) {
      const agColumnState = gridApi.getColumnState();
      const agGroupedColumnSortState = agColumnState
        .filter((col) => col.colId && col.sort && col.colId.startsWith(GROUP_COLUMN_PREFIX))
        .map(({ colId, sort, sortIndex }) => ({ colId, sort, sortIndex }));

      const vifGroupedColumnSortState = props.hierarchyConfig.order
        .map((order, orderIndex) => ({
          sort: order.ascending ? 'asc' : 'desc',
          sortIndex: orderIndex,
          colId: order.columnName
        }))
        .filter(({ colId }) => colId.startsWith(GROUP_COLUMN_PREFIX));
      if (!isEqual(agGroupedColumnSortState, vifGroupedColumnSortState)) {
        setCurrentSortState(props.vifOrderConfig);
        updateColumnsSort(gridApi);
      }
    }

    if (props.initializeRowStripeStyle && !isEqual(currentRowStripeStyle, props.initializeRowStripeStyle())) {
      setCurrentRowStripeStyle(props.initializeRowStripeStyle());
      shouldRefreshGridData = true;
    }

    if (gridApi && !isEqual(props.vifOrderConfig, currentSortState)) {
      setCurrentSortState(props.vifOrderConfig);
      updateColumnsSort(gridApi);
    }

    if (shouldRefreshGridData) refreshGridData(hardRefresh);
  });

  const fromAddFilter = () => {
    const vifFilters = props.vifFilters;
    const emptyFilterAdded = !last(vifFilters)?.arguments && filters.length < vifFilters.length;
    const emptyArguments = emptyFilterAdded || emptyFilterRemoved();

    return emptyArguments;
  };

  const emptyFilterRemoved = () => {
    for (let i = 0; i < filters.length; i++) {
      // if removing a filter column with an empty argument, then do not reload
      if (
        !filters[i].arguments &&
        !props.vifFilters.find((vFilter) => isEqual(vFilter.columns, (filters[i] as SoqlFilter).columns))
      ) {
        return true;
      }
    }
    return false;
  };

  const resetGranTotalData = useCallback(
    async (grandTotalRow: any, hierarchyConfig: Hierarchy, apiColumns: Column<any>[] | null) => {
      // If column is grouped, setting the total is handled in the autogroup attributes config
      const columnDefs = apiColumns?.map((column) => column.getColDef()) ?? [];
      const firstColumns = columnDefs.filter((column) => column?.rowGroupIndex != null);
      let firstColumn = firstColumns[0];
      if (!firstColumn) {
        firstColumn = columnDefs[0];
        const field = firstColumn?.field ?? '';
        if (
          hierarchyConfig?.showGrandTotal &&
          !get(grandTotalRow, [0, field]) &&
          hierarchyConfig?.columnConfigurations?.filter((v) => v?.aggregation)?.length > 0
        )
          set(grandTotalRow, [0, field], I18n.t('shared.visualizations.charts.table.total'));
      }
      if (!isEqual(grandTotalData, grandTotalRow)) setGrandTotalData(grandTotalRow);
    },
    []
  );

  const getGrandTotalRowRef = props.getGrandTotalRow;
  const refreshGrandTotalData = useCallback(
    async (hierarchyConfig: Hierarchy, apiColumns: Column<any>[] | null) => {
      const grandTotalRow = await getGrandTotalRowRef(hierarchyConfig);
      resetGranTotalData(grandTotalRow, hierarchyConfig, apiColumns);
    },
    [getGrandTotalRowRef, resetGranTotalData]
  );

  const hierarchy = props.hierarchyConfig;
  useEffect(() => {
    if (hierarchy && gridApi && hierarchy !== prevHierarchyConfig.current) {
      prevHierarchyConfig.current = hierarchy;
      refreshGrandTotalData(hierarchy, gridApi.getColumns());
    }
  }, [hierarchy]);

  useEffect(() => {
    if (gridApi) {
      // We are creating a GridReadyEvent that is defined in events.d.ts found
      // in the ag-grid-community node_module. This extends AgGridEvent which
      // extends AgEvent which has a required attribute "type" of type string.
      // Since I am not using it at all and have no actual event to pass, I
      // added an arbitrary string.
      handleGridReady({ type: 'gridReady', api: gridApi, context: undefined });
    }
  }, [props.searchString]);

  useEffect(() => {
    refreshGridData();
  }, [props.hierarchyConfig?.showSubTotal, props.isIndented]);

  const openNodeLevel = props.agGridOpenNodeLevel;
  useEffect(() => {
    if (gridApi) {
      if (!openNodeLevel) gridApi?.collapseAll();
      else {
        gridApi?.forEachNode(function (node) {
          if (node.group && !node.expanded && node.level < openNodeLevel) node.setExpanded(true);
        });
      }
    }
  }, [openNodeLevel, gridApi]);

  const {
    domain,
    datasetUid,
    columnMetadata,
    vifOrderConfig,
    hierarchyConfig,
    displayColumnFilters,
    nonStandardAggregations,
    columnFormats,
    getGrandTotalRow,
    onColumnVisibilityChange
  } = props;

  const agColumns = useColDefs({
    columns,
    columnFormats,
    datasetUid,
    displayColumnFilters,
    domain,
    hierarchyConfig,
    hierarchyColumns,
    nonStandardAggregations,
    removedHierarchyColumnNames,
    showAgGridColumnAggregations,
    showAgGridColumnMenu,
    useSetFilters,
    vifOrderConfig
  });

  function handleColumnReorder({ api }: ColumnMovedEvent) {
    props.onColumnReorder(api.getColumnState());
  }

  function handleGridReady({ api }: GridReadyEvent) {
    setGridApi(api);
    api.setGridOption('serverSideDatasource', datasource);
    if (hierarchy) refreshGrandTotalData(hierarchy, api.getColumns());
  }

  function handleFirstDataRendered({ api }: FirstDataRenderedEvent) {
    updateColumnsSort(api);
  }

  // This function updates width of grouped columns when non-indented layout is used.
  // This is necessary because we do not store the actual width of the grouped columns in columnDefs.
  function updateGroupedColumnsWidth(api: GridApi) {
    const groupedColumns = filter(api.getColumnState(), (col) =>
      includes(col.colId, GROUP_COLUMN_PREFIX)
    ).map((col) => col.colId);
    const newWidths = compact(
      groupedColumns.map((colId) => {
        // Get the width from the columns
        const fieldName = colId.replace(`${GROUP_COLUMN_PREFIX}-`, '');
        const originalColumn = filter(columns, (col) => col.fieldName === fieldName)?.[0];
        const width = originalColumn?.width;
        const agGridColumn = api.getColumn(colId);
        if (agGridColumn && width && width !== agGridColumn.getActualWidth()) {
          return {
            key: colId,
            newWidth: width
          };
        }
      })
    );

    if (newWidths.length > 0) {
      api.setColumnWidths(newWidths);
    }
  }

  function updateColumnsSort(api: GridApi) {
    let columnSortState = getColumnSortState(props.vifOrderConfig);
    const agColumnState = api.getColumnState();
    const groupedColumnsToResetSort = getGroupedColumnsToResetSort({
      columnState: agColumnState,
      vifOrderConfig: props.vifOrderConfig,
      isIndented: props.isIndented
    });
    const nonGroupedColumnsToResetSort = getNonGroupedColumnsToResetSort(agColumnState, vifOrderConfig);

    columnSortState.push(...groupedColumnsToResetSort);
    columnSortState.push(...nonGroupedColumnsToResetSort);

    // Sample column state:
    // [
    //   {
    //     colId: "ag-Grid-AutoColumn-fieldname",
    //     sort: "desc",
    //     sortIndex: 0
    //   }
    // ]
    // We have to manipulate the colId if props.isIndented is TRUE
    // since in agGrid, it is expected to have colId: ag-Grid-AutoColumn (without fieldname)
    if (props.isIndented) columnSortState = updateColIdForIndentedLayout(columnSortState);
    api.applyColumnState({ state: columnSortState });
  }

  // TODO: This should be a custom hook, don't pass in the whole props
  const autoGroupAttributes = useMemo<ColDef>(() => {
    return getAutoGroupAttributes({
      props,
      columnMetadata,
      columnFormats,
      nonStandardAggregations,
      domain,
      datasetUid
    });
  }, [columnFormats, columnMetadata, datasetUid, domain, nonStandardAggregations, hierarchyConfig]);

  function handleColumnResize({ columns: newColumns, finished }: ColumnResizedEvent) {
    if (finished) {
      props.onColumnResize(newColumns);
    }
  }

  async function handleColumnVisibilityChange(visChangedEvent: ColumnVisibleEvent) {
    const { api, columns: eventColumns } = visChangedEvent;
    onColumnVisibilityChange(api.getColumnState(), eventColumns, hierarchyConfig?.id);
    if (hierarchyConfig) {
      const grandTotalRow = await getGrandTotalRow(hierarchyConfig);
      resetGranTotalData(grandTotalRow, hierarchyConfig, api?.getColumns());
    }
  }

  function handleColumnRowGroupChanged(event: ColumnRowGroupChangedEvent) {
    const { api } = event;

    if (!event.columns || !flexibleHierarchiesEnabled) {
      return;
    }
    props.onColumnRowGroupChange(api.getColumnState(), event.columns, hierarchyConfig?.id);
  }

  function handleColumnValueChanged(event: ColumnValueChangedEvent) {
    const { api } = event;

    if (!event.columns || !flexibleHierarchiesEnabled) {
      return;
    }
    props.onColumnValueChange(event.columns, hierarchyConfig?.id);
  }

  function handleColumnSort({ api }: SortChangedEvent) {
    props.onColumnSort(api.getColumnState(), props.hierarchyConfig?.id);
  }

  const getRowStyle = (params: RowClassParams) => {
    return getAgTableRowStyle(
      params,
      rowFormatRef.current,
      props.columnMetadata,
      props.initializeRowStripeStyle
    );
  };

  const headerSortIconHTMLElement = (icon: React.ReactElement) => {
    const headerSortIcon = document.createElement('div');
    ReactDOM.render(
      <ForgeIconButton className="sort-icon">
        <ForgeIcon external external-type="standard" name={icon.props.name} />
        <ForgeTooltip position="bottom" className="sort-tooltip">
          {I18n.t('shared.visualizations.charts.table.header_multisort_helper_text')}
        </ForgeTooltip>
      </ForgeIconButton>,
      headerSortIcon
    );

    return headerSortIcon;
  };

  const columnsSidebarIconHTMLElement = () => {
    const columnsIcon = document.createElement('div');
    ReactDOM.render(<ForgeIcon name="view_column_outline" />, columnsIcon);

    return columnsIcon;
  };

  const headerMenuIconHTMLElement = () => {
    const headerMenuIcon = document.createElement('div');
    ReactDOM.render(
      <ForgeIcon className="agGrid-menu-icon" name="menu" data-testid="ag-header-menu" />,
      headerMenuIcon
    );

    return headerMenuIcon;
  };

  const headerFilterIconHTMLElement = () => {
    const headerFilterIcon = document.createElement('div');
    ReactDOM.render(
      <ForgeIcon className="agGrid-menu-icon" name="filter_menu" data-testid="ag-filter-menu" />,
      headerFilterIcon
    );

    return headerFilterIcon;
  };

  const iconsConfig: { [key: string]: string | '() => HTMLDivElement' } = {
    columns: columnsSidebarIconHTMLElement.bind(this),
    sortAscending: headerSortIconHTMLElement.bind(
      this,
      <ForgeIcon external external-type="standard" name="arrow_upward" />
    ),
    sortDescending: headerSortIconHTMLElement.bind(
      this,
      <ForgeIcon external external-type="standard" name="arrow_downward" />
    )
  };

  if (showAgGridColumnMenu) {
    iconsConfig.menu = headerMenuIconHTMLElement.bind(this);
    iconsConfig.filter = headerFilterIconHTMLElement.bind(this);
  }

  const onAgGridFilterChange = (e: any) => {
    const filterModel = e.api.getFilterModel();
    const f = agGridFilterToVifFilter(filterModel, e.api, props.datasetUid);
    setFilters(f);
    props.onFilterChange(f);
  };

  const paginationAutoPageSize = !props.paginationPageSize;

  const getDefaultColDef = props.defaultColDefOverrides
    ? merge(defaultColDef, props.defaultColDefOverrides)
    : defaultColDef;

  const getMainMenuItems = useCallback((menuItemsParams: GetMainMenuItemsParams): MenuItemDef[] => {
    const columnId = menuItemsParams.column.getColId();
    const metadataForColumn = columnMetadata.find(({ fieldName }) => fieldName === columnId);

    const description = DOMPurify.sanitize(
      metadataForColumn?.description ||
        I18n.t('shared.visualizations.charts.table.column_menu.no_description'),
      { ALLOWED_TAGS: [] }
    );

    // Yes, this is gross. AgGrid's devs didn't expect anyone to want to put their own custom elements
    // in the dropdown menu, so we have to sneak 'em in via a menu option's name prop.
    return [
      {
        name: `<b class="column-description-title">${I18n.t(
          'shared.visualizations.charts.table.column_menu.description_label'
        )}</b> ${description}`
      }
    ];
  }, []);

  const noRowsOverlay = useMemo(() => {
    return function noRowsOverlayWithProps() {
      return (
        <NoRowsAgGridOverlay
          imageClassName="agGrid-no-rows-overlay-icon"
          textSectionClassName="agGrid-no-rows-overlay-text"
        />
      );
    };
  }, []);

  const agGridModules = [
    ServerSideRowModelModule,
    RowGroupingModule,
    RangeSelectionModule,
    ClipboardModule,
    SideBarModule,
    ColumnsToolPanelModule,
    SetFilterModule,
    MultiFilterModule
  ];

  if (showAgGridColumnMenu) {
    agGridModules.push(MenuModule);
  }

  const mobileView = !isLargeDesktop();

  // We should only create a new instance of the sidebar configuration when the showAgGridAggregations prop changes
  // Otherwise the ag-grid component will re-render unnecessarily
  const sidebarConfiguration = useMemo(() => {
    const result = set(
      sideBarConfig,
      'toolPanels.0.toolPanelParams.suppressRowGroups',
      !showAgGridColumnAggregations
    );
    return set(result, 'toolPanels.0.toolPanelParams.suppressValues', !showAgGridColumnAggregations);
  }, [showAgGridColumnAggregations]);

  const showSubTotal = props.hierarchyConfig?.showSubTotal ?? false;
  const groupedColumns = hierarchyColumns.filter((col) => col.isGrouping).map((col) => col.columnName);

  useDeepCompareEffect(() => {
    agGridContext.current.nonStandardAggregations = nonStandardAggregations;
    agGridContext.current.showSubTotal = showSubTotal;
    agGridContext.current.currentRowStripeStyle = currentRowStripeStyle;
    agGridContext.current.groupedColumns = groupedColumns;
    agGridContext.current.columnFormats = columnFormats;
    agGridContext.current.columnMetadata = columnMetadata;

    gridApi?.refreshCells({ force: true });
    gridApi?.refreshHeader();
  }, [
    columnMetadata,
    columnFormats,
    nonStandardAggregations,
    showSubTotal,
    currentRowStripeStyle,
    groupedColumns
  ]);

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50];
  }, []);

  return (
    <AgGridReact
      defaultColDef={getDefaultColDef}
      enableRangeSelection={true}
      headerHeight={48}
      rowSelection={'multiple'}
      isServerSideGroupOpenByDefault={(p) =>
        p?.rowNode?.level < openNodeLevel || isServerSideGroupOpenByDefault(p)
      }
      context={agGridContext.current}
      modules={agGridModules}
      getRowId={() => {
        return uuid();
      }}
      rowModelType="serverSide"
      serverSideDatasource={datasource}
      columnDefs={agColumns}
      components={{
        customTooltip: SocrataTooltip
      }}
      onColumnMoved={handleColumnReorder}
      onColumnVisible={handleColumnVisibilityChange}
      onColumnRowGroupChanged={handleColumnRowGroupChanged}
      onColumnValueChanged={handleColumnValueChanged}
      onGridReady={handleGridReady}
      onColumnResized={handleColumnResize}
      onFirstDataRendered={handleFirstDataRendered}
      onRowGroupOpened={onRowGroupOpened}
      onSortChanged={handleColumnSort}
      suppressAggFuncInHeader
      tooltipShowDelay={0}
      multiSortKey={'ctrl'}
      getRowStyle={getRowStyle}
      autoGroupColumnDef={autoGroupAttributes}
      pagination
      paginationAutoPageSize={paginationAutoPageSize}
      paginationPageSize={props.paginationPageSize}
      pinnedBottomRowData={hierarchy?.showGrandTotal ? grandTotalData : undefined}
      groupDisplayType={props.isIndented ? 'singleColumn' : 'multipleColumns'}
      getMainMenuItems={showAgGridColumnMenu ? getMainMenuItems : undefined}
      suppressDragLeaveHidesColumns
      icons={iconsConfig}
      sideBar={sidebarConfiguration}
      suppressMenuHide={mobileView}
      onFilterChanged={onAgGridFilterChange}
      getLocaleText={(key) => {
        if (key.key == 'group' && props.isIndented) {
          const rowGroups = key.api.getRowGroupColumns();
          return rowGroups[0].getColDef().headerName;
        }
        return I18n.t(key.key, { scope: 'common.ag_grid_react' });
      }}
      groupTotalRow={props.hierarchyConfig?.showSubTotal ? 'bottom' : undefined}
      suppressRowHoverHighlight={isTableRowStripeStyleEnabled()}
      noRowsOverlayComponent={noRowsOverlay}
      paginationPageSizeSelector={paginationPageSizeSelector}
      columnMenu={'legacy'}
    />
  );
};
export default Grid;
