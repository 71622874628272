import React from 'react';
import I18n from 'common/i18n';

// components
import { picklistSizingStrategy } from 'common/components/Dropdown/picklistSizingStrategy';
import { IconName, SocrataIcon } from 'common/components/SocrataIcon';
import Dropdown, { DropdownSize } from 'common/components/Forms/Dropdown';

import FeatureFlags from 'common/feature_flags';
import classNames from 'classnames';

// project constants
const scope = 'shared.visualizations.panes.data';

interface TableHierarchyGroupingKebabMenuProps {
  duplicationAllowed: boolean;
  removeAllowed: boolean;
  handleOnDuplicateHierarchy: () => void;
  handleOnResetHierarchy: () => void;
  handleOnRemoveHierarchy: () => void;
}

enum HierarchyMenuOption {
  DUPLICATE,
  RESET,
  REMOVE
}

interface HierarchyMenuSelection {
  value: HierarchyMenuOption;
}

const HierarchyKebabMenu = (props: TableHierarchyGroupingKebabMenuProps) => {
  const {
    handleOnResetHierarchy,
    handleOnRemoveHierarchy,
    handleOnDuplicateHierarchy,
    removeAllowed,
    duplicationAllowed
  } = props;

  const enableForgeTabs = FeatureFlags.valueOrDefault('enable_forge_authoring_tabs', false);
  const flexibleHierarchiesEnabled = FeatureFlags.valueOrDefault('enable_flexible_table_hierarchies', false);

  const handleSelection = (selection: HierarchyMenuSelection) => {
    switch (selection.value) {
      case HierarchyMenuOption.DUPLICATE: {
        if (duplicationAllowed) {
          handleOnDuplicateHierarchy();
        }
        break;
      }
      case HierarchyMenuOption.RESET: {
        if (removeAllowed) {
          handleOnResetHierarchy();
        }
        break;
      }
      case HierarchyMenuOption.REMOVE: {
        handleOnRemoveHierarchy();
        break;
      }
    }

    return false;
  };

  const options = [
    {
      render: () => (
        <div role="button" data-testid="duplicate-hierarchy-button">
          <SocrataIcon name={IconName.CopyDocument} />
          <span>{I18n.t('fields.table_hierarchies.menu.duplicate', { scope })}</span>
        </div>
      ),
      value: HierarchyMenuOption.DUPLICATE,
      disabled: !duplicationAllowed
    },
    {
      render: () => (
        <div role="button" className="remove-hierarchy-button" data-testid="remove-hierarchy-button">
          <SocrataIcon name={IconName.Remove} />
          <span>{I18n.t('fields.table_hierarchies.menu.remove', { scope })}</span>
        </div>
      ),
      value: HierarchyMenuOption.REMOVE,
      disabled: !removeAllowed
    }
  ];

  const resetOption = {
    render: () => (
      <div role="button" data-testid="reset-hierarchy-button">
        <SocrataIcon name={IconName.Refresh} />
        <span>{I18n.t('fields.table_hierarchies.menu.reset', { scope })}</span>
      </div>
    ),
    value: HierarchyMenuOption.RESET
  };

  const optionsWithReset = [...options.slice(0, 1), resetOption, ...options.slice(1)];

  const dropdownProps = {
    handleSelection: handleSelection,
    picklistSizingStrategy: picklistSizingStrategy.EXPAND_TO_WIDEST_ITEM,
    options: flexibleHierarchiesEnabled ? options : optionsWithReset,
    size: DropdownSize.SMALL,
    placeholder: () => (
      <button
        className="dropdown-btn socrata-icon-kebab"
        aria-label={I18n.t('fields.table_hierarchies.menu.title', { scope })}
      />
    )
  };

  return (
    <div className={classNames('hierarchy-actions-menu', !enableForgeTabs && 'legacy')}>
      <Dropdown {...dropdownProps} />
    </div>
  );
};

export default HierarchyKebabMenu;
