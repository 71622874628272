import { compact, isNull } from 'lodash';
import {
  UnAnalyzedSelectedExpression,
  Expr, Literal,
} from 'common/types/soql';
import { ViewColumn } from 'common/types/viewColumn';
import { buildColumnRef, buildLiteral, buildSoqlLiteral, renderExpr } from './expr';
import {
  isString, isNumber, isViewColumn,
  QualifiedViewColumn, isQualifiedViewColumn, isSelectLiteral
} from './util';

export interface Select {
  expr: QualifiedViewColumn | ViewColumn | Expr | Literal['value'] | SelectLiteral | number;
  name: string;
}

export interface SelectLiteral {
  soqlExpression: string;
}

/**
 * Construct a single SELECT object, expected to become a member of an array.
 *
 * @remarks
 * You should not try to do a SELECT *; it's a smell in custom-designed queries.
 *
 * @param expr An expression you wish to select.
 * @param name The alias for your selection.
 */
export const buildSelect = (expr: Select['expr'], name: string): Select => ({ expr, name });

export const buildLiteralSoqlSelect = (soqlExpression: string, name: string): Select => ({
  expr: { soqlExpression },
  name
});

export const convertSelect = (select: Select): UnAnalyzedSelectedExpression => {
  const name = { name: select.name, position: { line: 0, column: 0 } };
  let expr;

  if (isQualifiedViewColumn(select.expr)) {
    expr = buildColumnRef(select.expr.column, select.expr.qualifier);
  } else if (isSelectLiteral(select.expr)) {
    expr = buildSoqlLiteral(select.expr.soqlExpression);
  } else if (isViewColumn(select.expr)) {
    expr = buildColumnRef(select.expr);
  } else if (isString(select.expr) || isNumber(select.expr) || true === select.expr || false === select.expr || isNull(select.expr)) {
    expr = buildLiteral(select.expr);
  } else {
    expr = select.expr;
  }

  return { expr, name };
};

export const renderUnAnalyzedSelectedExpression = (expr: UnAnalyzedSelectedExpression): string => {
  const rendered = renderExpr(expr.expr);
  const alias = expr.name?.name;

  return compact([rendered, alias]).join(' as ');
};

