import { compact, has } from 'lodash';
import { BinaryTree, UnAnalyzedAst, UnAnalyzedJoin, Expr, Hint, TableName, Distinct } from 'common/types/soql';
import { isBinaryTree, buildLeaf, mapOverBinaryTree } from '../binaryTree';
import { convertDistinct } from '../distinct';
import { buildLiteral } from '../expr';
import { convertGroupBy } from '../groupBy';
import { convertOrderBy } from '../orderBy';
import { convertSelect } from '../select';

export interface QueryParts {
  selects: (Parameters<typeof convertSelect>[0] | undefined)[];
  hints?: Hint[];
  from?: TableName;
  distinct?: Parameters<typeof convertDistinct>[0];
  joins?: UnAnalyzedJoin[];
  where?: Expr;
  groups?: (Parameters<typeof convertGroupBy>[0] | undefined)[];
  orders?: (Parameters<typeof convertOrderBy>[0] | undefined)[];
  having?: Expr;
  search?: string;
  limit?: number;
  offset?: number;
  legacyWhereClause?: string
}

export const isQueryParts = (parts: unknown): parts is QueryParts => has(parts, 'selects');
const buildSimpleQuery = (parts: QueryParts): UnAnalyzedAst => {
  const selection = {
    exprs: compact(parts.selects || []).map(convertSelect),
    all_system_except: null,
    all_user_except: []
  };
  const group_bys = compact(parts.groups || []).map(convertGroupBy);
  const order_bys = compact(parts.orders || []).map(convertOrderBy);
  const joins = parts.joins || [];
  const distinct = convertDistinct(parts.distinct || 'indistinct');

  return {
    selection,
    hints: parts.hints || [],
    from: parts.from || null,
    where: parts.where || null,
    group_bys,
    order_bys,
    joins,
    search: parts.search || null,
    having: parts.having || null,
    distinct,
    limit: parts.limit || null,
    offset: parts.offset || null,
    legacyWhereClause: parts.legacyWhereClause || null
  };
};

export const buildQueryAsBinaryTree = (parts: QueryParts | BinaryTree<QueryParts>): BinaryTree<UnAnalyzedAst> => {
  if (isBinaryTree<QueryParts>(parts, isQueryParts)) {
    return mapOverBinaryTree<QueryParts, UnAnalyzedAst>(parts, buildSimpleQuery);
  }
  return buildLeaf<UnAnalyzedAst>(buildSimpleQuery(parts));
};

export const buildQuery = (parts: QueryParts | BinaryTree<QueryParts>): UnAnalyzedAst | BinaryTree<UnAnalyzedAst> => {
  if (isBinaryTree<QueryParts>(parts, isQueryParts)) {
    return mapOverBinaryTree<QueryParts, UnAnalyzedAst>(parts, buildSimpleQuery);
  }
  return buildSimpleQuery(parts);
};
