import _ from 'lodash';
import { DateInput } from '@fullcalendar/core/datelib/env';

import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';

// Constants
import {
  DIVERGING_COLOR_PALETTES,
  SEQUENTIAL_COLOR_PALETTES,
  NUMERIC_COLUMN_TYPES,
  VIF_CONSTANTS,
  WITH_DIVERGING_COLOR_PALETTES_FOR_MAPS,
  BASE_COLOR_PALETTE_NAMES,
  DEFAULT_COLOR_PALETTE,
  COLOR_PALETTE_VALUES,
  DEFAULT_PRIMARY_COLOR
} from 'common/authoring_workflow/constants';
import {
  CALENDAR_END_DATE_COLUMN_SERIES_INDEX,
  CALENDAR_EVENT_TITLE_COLUMN_SERIES_INDEX,
  CALENDAR_START_DATE_COLUMN_SERIES_INDEX,
  DEFAULT_ROTATION_ANGLE,
  DEFAULT_ROTATION_ANGLE_BAR_CHART,
  SCATTER_CHART_COLOR_BY_SERIES_INDEX,
  SERIES_TYPE_AG_GRID_TABLE,
  SERIES_TYPE_BAR_CHART,
  SERIES_TYPE_PIE_CHART,
  SERIES_TYPE_SCATTER_CHART,
  SERIES_TYPE_HISTOGRAM,
  SERIES_TYPE_MAP,
  SERIES_TYPE_TABLE,
  SERIES_TYPE_DATA_TABLE,
  SERIES_TYPE_TIMELINE_CHART,
  SERIES_TYPE_COMBO_CHART_COLUMN,
  SERIES_TYPE_COMBO_CHART_LINE,
  SERIES_TYPE_FLYOUT
} from 'common/visualizations/views/SvgConstants';

import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import { getTodayDate } from 'common/dates';
import { AxisLabels, Drilldown, Series, Vif } from '../vif';
import { Filters } from 'common/components/SingleSourceFilterBar/types';
import { DataToRender, ReferenceLine } from '../views/BaseVisualization/types';
import { ViewColumn } from 'common/types/viewColumn';
import { getSiteAppearanceColorPaletteHexCodes } from 'common/visualizations/helpers/SiteAppearanceColors';

/* ------------------ Visualization type ------------------ */
const getVisualizationType = (vif: Vif): string => _.get(vif, 'series[0].type');
export const isBarVisualization = (vif: Vif): boolean => getVisualizationType(vif) === SERIES_TYPE_BAR_CHART;
export const isPieVisualization = (vif: Vif): boolean => getVisualizationType(vif) === SERIES_TYPE_PIE_CHART;
export const isComboVisualization = (vif: Vif): boolean =>
  getVisualizationType(vif) === SERIES_TYPE_COMBO_CHART_COLUMN ||
  getVisualizationType(vif) === SERIES_TYPE_COMBO_CHART_LINE;
export const isScatterVisualization = (vif: Vif): boolean =>
  getVisualizationType(vif) === SERIES_TYPE_SCATTER_CHART;
export const isTimelineVisualization = (vif: Vif): boolean =>
  getVisualizationType(vif) === SERIES_TYPE_TIMELINE_CHART;
export const isHistogramVisualization = (vif: Vif): boolean =>
  getVisualizationType(vif) === SERIES_TYPE_HISTOGRAM;
export const isMapVisualization = (vif: Vif): boolean => getVisualizationType(vif) === SERIES_TYPE_MAP;
export const isTableVisualization = (vif: Vif): boolean =>
  _.includes(
    [SERIES_TYPE_TABLE, SERIES_TYPE_DATA_TABLE, SERIES_TYPE_AG_GRID_TABLE],
    getVisualizationType(vif)
  );

/* ------------------ Feature flags ------------------ */
export const isTylerForgeVizLayoutEnabled = () => {
  return FeatureFlags.available() && FeatureFlags.value('enable_forge_layout_for_viz');
};

export const isTableRowStripeStyleEnabled = () => {
  return FeatureFlags.available() && FeatureFlags.valueOrDefault('enable_table_viz_row_striping', false);
};

export const newVizCardLayoutEnabled = (vif: Vif) => {
  const isAgTableVisualization = _.get(vif, 'series[0].type') === SERIES_TYPE_AG_GRID_TABLE;
  return isTylerForgeVizLayoutEnabled() || isAgTableVisualization;
};

export const isUpdatedConditionalFormattingDesignsEnabled = () => {
  return (
    FeatureFlags.available() && FeatureFlags.valueOrDefault('updated_conditional_formatting_designs', false)
  );
};

export const isComplexConditionalFormattingEnabled = (): boolean => {
  return (
    FeatureFlags.available() && FeatureFlags.valueOrDefault('enable_complex_conditional_formatting', false)
  );
};

export const isColumnStylingInTablesEnabled = () => {
  return FeatureFlags.available() && FeatureFlags.valueOrDefault('enable_column_styling_in_tables', false);
};

/* ------------------ Filters ------------------ */
export const getFilters = (vif: Vif): Filters => {
  return _.get(vif, 'series[0].dataSource.filters', []);
};

export const getNewFilters = (originalVif: Vif, newVif: Vif): Filters => {
  const originalVifFilters = getFilters(originalVif);
  const newVifFilters = getFilters(newVif);

  const drilldownFilters = _.filter(originalVifFilters, ['isDrilldown', true]);
  const normalFilters = _.filter(newVifFilters, (filterItem) => !filterItem.isDrilldown);

  return [...drilldownFilters, ...normalFilters];
};

/* ------------------ Drilldowns ------------------ */

export const getDrilldowns = (vif: Vif): Drilldown[] => {
  return _.get(vif, 'series[0].dataSource.dimension.drilldowns', []);
};

export const getCurrentDrilldownColumnName = (vif: Vif): string => {
  return _.get(vif, 'series[0].dataSource.dimension.currentDrilldownColumnName');
};

export const shouldDisableDrilldownResetButton = (originalVif: Vif, newVif: Vif): boolean => {
  const newVifCurrentDrilldownColumnName = getCurrentDrilldownColumnName(newVif);
  const originalVifCurrentDrilldownColumnName = getCurrentDrilldownColumnName(originalVif);
  const originalVifDrilldownFilters = _.filter(getFilters(originalVif), ['isDrilldown', true]);
  const newVifDrilldownFilters = _.filter(getFilters(newVif), ['isDrilldown', true]);

  return (
    _.isEqual(newVifCurrentDrilldownColumnName, originalVifCurrentDrilldownColumnName) &&
    _.isEqual(newVifDrilldownFilters, originalVifDrilldownFilters)
  );
};

export const shouldRenderDrillDown = (vif: Vif): boolean => {
  return !isGrouping(vif) && !_.isEmpty(getDrilldowns(vif));
};

/* ------------------ Styling ------------------ */

interface ShouldAnimateColumnOrBarOptions {
  vif: Vif;
  columnData: DataToRender;
  dimensionValue: number;
}
export const shouldAnimateColumnOrBar = ({
  vif,
  columnData,
  dimensionValue
}: ShouldAnimateColumnOrBarOptions): boolean => {
  const columnName = getCurrentDrilldownColumnName(vif);
  if (_.isNil(columnName)) {
    return false;
  }
  const columnDetails: ViewColumn = _.find(columnData.columnFormats, { fieldName: columnName }) as ViewColumn;
  const isNumericalColumn = _.includes(NUMERIC_COLUMN_TYPES, columnDetails?.renderTypeName);

  return !isNumericalColumn && !_.isNil(dimensionValue);
};

export const getMapColorPalettes = () => {
  return WITH_DIVERGING_COLOR_PALETTES_FOR_MAPS;
};

/**
 * Reset to default color palette when divergence color palette is unavailable
 * because its feature flag is turned off after it was set or
 * when changing the column to a categorical type after a linear color palette
 * was earlier set for a numerical column.
 */
export const getColorPaletteValue = (
  paletteValue: BASE_COLOR_PALETTE_NAMES | 'custom',
  isCategorical: boolean
): BASE_COLOR_PALETTE_NAMES | 'custom' => {
  if (isCategorical) {
    const linearColorPalettes = SEQUENTIAL_COLOR_PALETTES.concat(DIVERGING_COLOR_PALETTES);
    const linearColorPaletteValues = _.map(linearColorPalettes, 'value');
    return _.includes(linearColorPaletteValues, paletteValue)
      ? (DEFAULT_COLOR_PALETTE as BASE_COLOR_PALETTE_NAMES)
      : paletteValue;
  }
  return paletteValue;
};

export const getLineStylePoints = (vif: Vif): string => _.get(vif, 'series[0].lineStyle.points');
export const getShowLegend = (vif: Vif, defaultValue = VIF_CONSTANTS.DEFAULT_SHOW_LEGEND) =>
  _.get(vif, 'series[0].showLegend', defaultValue);
export const getShowLineValueLabels = (vif: Vif): boolean =>
  _.get(vif, 'configuration.showLineValueLabels', VIF_CONSTANTS.DEFAULT_SHOW_LINE_VALUE_LABEL);
export const getShowNullsAsFalse = (vif: Vif): boolean =>
  _.get(vif, 'configuration.showNullsAsFalse', VIF_CONSTANTS.DEFAULT_SHOW_NULLS_AS_FALSE);
export const getShowSlicePercentsInFlyouts = (vif: Vif): boolean =>
  _.get(vif, 'configuration.showSlicePercentsInFlyouts', VIF_CONSTANTS.DEFAULT_SHOW_SLICE_PERCENTS_VALUE);
export const getShowValueLabels = (vif: Vif): boolean =>
  _.get(vif, 'configuration.showValueLabels', VIF_CONSTANTS.DEFAULT_SHOW_VALUE_LABEL);
export const getShowValueLabelsAsPercent = (vif: Vif): boolean =>
  _.get(vif, 'configuration.showValueLabelsAsPercent', VIF_CONSTANTS.DEFAULT_SHOW_VALUE_LABEL_AS_PERCENT);
export const getWrapDimensionLabels = (vif: Vif): boolean =>
  _.get(vif, 'configuration.wrapDimensionLabels', VIF_CONSTANTS.DEFAULT_WRAP_DIMENSION_LABELS);

/* ------------------ Axes and References ------------------ */

export const getReferenceLinesWithValues = (vif: Vif): ReferenceLine[] => {
  return _.filter(_.get(vif, 'referenceLines', []), (referenceLine) => _.isFinite(referenceLine.value));
};

export const getAxisLabels = (vif: Vif): AxisLabels => _.get(vif, 'configuration.axisLabels', {});

export const getDimensionLabelRotationAngle = (vif: Vif): number => {
  const defaultAngle = isBarVisualization(vif) ? DEFAULT_ROTATION_ANGLE_BAR_CHART : DEFAULT_ROTATION_ANGLE;

  return _.get(vif, 'configuration.dimensionLabelRotationAngle', defaultAngle);
};

export const getMeasureAxisMaxValue = (vif: Vif): number => {
  const value = _.get(vif, 'configuration.measureAxisMaxValue', null);
  return validateAxisValue(value, 'measure_axis_max_value_should_be_numeric');
};

export const getMeasureAxisMinValue = (vif: Vif): number => {
  const value = _.get(vif, 'configuration.measureAxisMinValue', null);
  return validateAxisValue(value, 'measure_axis_min_value_should_be_numeric');
};

export const validateAxisValue = (value: any, key: string): number => {
  const check = isFinite(value) && parseFloat(value);

  if (value !== null && (check === false || isNaN(check))) {
    throw new Error(I18n.t('shared.visualizations.charts.common.validation.errors.' + key));
  } else {
    return value;
  }
};

export const getDimensionAxisMaxValue = (vif: Vif): number =>
  _.get(vif, 'configuration.dimensionAxisMaxValue', null);
export const getDimensionAxisMinValue = (vif: Vif): number =>
  _.get(vif, 'configuration.dimensionAxisMinValue', null);
export const getShowDimensionLabels = (vif: Vif): boolean =>
  _.get(vif, 'configuration.showDimensionLabels', VIF_CONSTANTS.DEFAULT_SHOW_DIMENSION_LABEL);
export const getMeasureAxisScale = (vif: Vif): string => _.get(vif, 'configuration.measureAxisScale');
export const getSecondaryMeasureAxisScale = (vif: Vif): string =>
  _.get(vif, 'configuration.secondaryMeasureAxisScale');
export const getYAxisScalingMode = (vif: Vif): string =>
  _.get(vif, 'configuration.yAxisScalingMode', 'showZero');
export const logScale = (vif: Vif) => _.get(vif, 'configuration.logarithmicScale');

/* ------------------ Series ------------------ */
export const isGrouping = (vif: Vif): boolean => {
  const dimensionGroupingColumnName = _.get(vif, 'series[0].dataSource.dimension.grouping.columnName', null);
  return !_.isEmpty(dimensionGroupingColumnName);
};

export const isGroupingOrHasMultipleNonFlyoutSeries = (vif: Vif): boolean => {
  return isGrouping(vif) || hasMultipleNonFlyoutSeries(vif);
};

export const hasMultipleNonFlyoutSeries = (vif: Vif): boolean => getNonFlyoutSeries(vif).length > 1;
export const getNonFlyoutSeries = (vif: Vif): Series[] => {
  const series = _.get(vif, 'series', []);
  return _.reject(series, (seriesItem) => seriesItem.type === SERIES_TYPE_FLYOUT);
};

export const hasFlyoutSeries = (vif: Vif): boolean => {
  const series = _.get(vif, 'series', []);
  return _.findIndex(series, (item: Series) => item.type === SERIES_TYPE_FLYOUT) != -1;
};

/* ------------------ Calculations and Data Source ------------------ */
export const isOneHundredStacked = (vif: Vif): boolean =>
  _.get(vif, 'series[0].stacked.oneHundredPercent', false);
export const isVifStacked = (vif: Vif): boolean => _.get(vif, 'series[0].stacked', false);

export function getDatasetUid(vif: Vif): string {
  return _.get(vif, 'series[0].dataSource.datasetUid');
}

export function getDomain(vif: Vif): string {
  return _.get(vif, 'series[0].dataSource.domain') || getDefaultDomain();
}

export function getParameterOverrides(vif: Vif): Map<string, any> {
  return _.get(vif, 'series[0].dataSource.parameterOverrides');
}

export const getGroupByColumnName = (vif: Vif): string => {
  const currentVizType = _.get(vif, 'series[0].type');
  let columnName;

  switch (currentVizType) {
    case SERIES_TYPE_PIE_CHART:
      columnName = _.get(vif, 'series[0].dataSource.dimension.columnName');
      break;
    case SERIES_TYPE_SCATTER_CHART:
      columnName = _.get(vif, `series[${SCATTER_CHART_COLOR_BY_SERIES_INDEX}].dataSource.measure.columnName`);
      break;
    default:
      columnName = _.get(vif, 'series[0].dataSource.dimension.grouping.columnName');
  }

  return columnName;
};

export const isMeasureCountOfRows = (vif: Vif): boolean =>
  _.get(vif, 'series[0].dataSource.measure.aggregationFunction') === 'count';

/* ------------------ Calendars ------------------ */
export const getCalendarDate = (vif: Vif): DateInput | undefined => {
  const currentCalendarDate = getCurrentDisplayDate(vif);

  if (currentCalendarDate === null || currentCalendarDate === undefined) {
    return getDefaultDisplayDate(vif);
  }

  return currentCalendarDate;
};

export const getEventTitleColumn = (vif: Vif): string => {
  return _.get(
    vif,
    `series[${CALENDAR_EVENT_TITLE_COLUMN_SERIES_INDEX}].dataSource.dimension.columnName`,
    ''
  );
};

export const getEndDateColumn = (vif: Vif): string => {
  return _.get(vif, `series[${CALENDAR_END_DATE_COLUMN_SERIES_INDEX}].dataSource.dimension.columnName`, '');
};

export const getStartDateColumn = (vif: Vif): string => {
  return _.get(vif, `series[${CALENDAR_START_DATE_COLUMN_SERIES_INDEX}].dataSource.dimension.columnName`, '');
};

export const getEventTextColor = (vif: Vif): string => {
  return _.get(vif, 'series[0].color.eventTextColor', VIF_CONSTANTS.DEFAULT_CALENDAR_TEXT_COLOR);
};

export const getEventBackgroundColor = (vif: Vif): string => {
  return _.get(vif, 'series[0].color.eventBackgroundColor', VIF_CONSTANTS.DEFAULT_CALENDAR_BACK_GROUND_COLOR);
};

export const getLockCalendarViewControl = (vif: Vif): boolean => {
  return _.get(
    vif,
    'configuration.lockCalendarViewControl',
    VIF_CONSTANTS.DEFAULT_LOCK_CALENDAR_VIEW_CONTROL
  );
};

export const getDefaultDisplayDate = (vif: Vif): DateInput => {
  // defaultDisplayDate is _always_ set in new calendars, but there may be existing calendars with no defaultDisplayDate.
  return vif.configuration?.defaultDisplayDate || getTodayDate();
};

/**
 * Current calendar date should be treated as local state.
 */
export const getCurrentDisplayDate = (vif: Vif): DateInput | undefined => {
  return _.get(vif, 'configuration.currentDisplayDate', undefined);
};

export const getEventOutlineColor = (vif: Vif): string => {
  return _.get(vif, 'series[0].color.eventOutlineColor', VIF_CONSTANTS.DEFAULT_CALENDAR_EVENT_OUTLINE_COLOR);
};

export const getSiteAppearanceColors = (paletteName: string) => {
  const hexCodes = getSiteAppearanceColorPaletteHexCodes(paletteName);
  const colors = [];

  for (let i = 0; i < 5; i++) {
    const color =
      _.get(hexCodes, i) || _.get(COLOR_PALETTE_VALUES[DEFAULT_COLOR_PALETTE], i) || DEFAULT_PRIMARY_COLOR;
    colors.push(color);
  }

  return colors;
};

/* ------------------ Tables ------------------ */

export const getTableHierarchies = (vif: Vif) => vif?.series?.[0]?.dataSource?.hierarchies;
