import _ from 'lodash';

import { Vif } from 'common/visualizations/vif';

import * as commonVifDecorator from './commonVifDecorator';
//@ts-expect-error
import * as lineMapVifDecorator from './lineMapVifDecorator';
//@ts-expect-error
import * as pointMapVifDecorator from './pointMapVifDecorator';
//@ts-expect-error
import * as regionMapVifDecorator from './regionMapVifDecorator';
//@ts-expect-error
import * as shapeMapVifDecorator from './shapeMapVifDecorator';

export function getDecoratedVif(vif: Vif): DecoratedVif {
  return _.merge(
    {},
    commonVifDecorator,
    lineMapVifDecorator,
    pointMapVifDecorator,
    regionMapVifDecorator,
    shapeMapVifDecorator,
    {
      cloneWithSingleSeries: function (seriesIndex: number) {
        const clonedVif = _.cloneDeep(this);
        const series = _.get(clonedVif, `series[${seriesIndex}]`);

        clonedVif.series = [series];

        return getDecoratedVif(clonedVif);
      }
    },
    vif
  );
}

type VifDecorations = Vif & typeof commonVifDecorator;
export interface DecoratedVif extends VifDecorations {
  cloneWithSingleSeries: (seriesIndex: number) => DecoratedVif;
  /* TODO: These are defined by the vif decorators included above. They should be moved to those files when they're converted to Typescript */
  getRangeBucketType: () => 'equalInterval' | 'jenks';
  isRegionMap: () => boolean;
}
