import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import type { View } from 'common/types/view';
import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import { Vif } from 'common/visualizations/vif';
import _ from 'lodash';
import { ColumnState } from '@ag-grid-community/core';

export interface ExportStateFunctions {
  setRowCountFiltered: (count: number) => void;
}

export const filterRowData = (view: View, exportFunctions: ExportStateFunctions, queryString: string) => {
  const domain = window.location.hostname;
  const datasetUid = view.id;
  if (domain && datasetUid) {
    const soqlDataProvider = new SoqlDataProvider({ domain, datasetUid }, true);
    soqlDataProvider.getRowCountForQuery(queryString).then((resp) => {
      exportFunctions.setRowCountFiltered(resp);
    });
  }
};

export const getTotalRowCount = (view: View, setRowCountAll: (count: number) => void) => {
  const domain = window.location.hostname;
  const datasetUid = view.id;
  if (domain && datasetUid) {
    const soqlDataProvider = new SoqlDataProvider({ domain, datasetUid }, true);
    soqlDataProvider.getRowCount('').then(function (resp) {
      setRowCountAll(resp);
    });
  }
};

// This will only work with a vif from an AG-TABLE otherwise will return a *
const getSelectFromVif = (vif: Vif): string => {
  const type = vif.series[0]?.type;
  const visibleColumns = vif.series[0]?.dataSource.dimension?.columns || [];

  if (type === 'agTable') {
    const selectedColumns = visibleColumns.map((column: any) => {
      if (!column.hide) {
        return column.fieldName;
      }
    });
    const columnClause = _.compact(selectedColumns).join(', ');
    return _.isEmpty(columnClause) ? '*' : columnClause;
  }
  return '*';
};

const getWhereFromVif = (vif: Vif) => {
  const filters = vif.series[0]?.dataSource?.filters || [];
  return filters.map(SoqlHelpers.filterToWhereClauseComponent).join(' AND ');
};

const convertAscending = (ascending: boolean) => (ascending ? 'ASC' : 'DESC');

export const getOrderByFromVif = (vif: Vif) => {
  const order = vif?.configuration?.order || [];
  // The vif seems to add :id when there is no sort.
  // This is problematic because its not an order by that a user has added,
  // so removing it from the array here if present.
  _.remove(order, (v) => v.columnName === ':id');

  if (!order || !order.length) return;

  const orderString = order.map((v) => `${v.columnName} ${convertAscending(!!v.ascending)}`).join(', ');

  return orderString;
};

const getSearchFromVif = (vif: Vif) => {
  //@ts-ignore search string is not on the vif type but its there
  const search = vif.series[0]?.dataSource?.searchString;
  // we want to return undefined and not an empty string when there is no search
  if (search) return search;
  return;
};

export const getQueryStringFromVif = (vif: Vif) => {
  const selectClause = getSelectFromVif(vif);
  const whereClause = getWhereFromVif(vif);
  const orderBy = getOrderByFromVif(vif);
  const searchClause = getSearchFromVif(vif);

  let queryString = `SELECT ${selectClause}`;
  if (whereClause) queryString += ` WHERE ${whereClause}`;
  if (orderBy) queryString += ` ORDER BY ${orderBy}`;
  if (searchClause) queryString += ` SEARCH "${searchClause}"`;
  return queryString;
};

const getNonSoqlOrderBy = (view: View) => {
  // this is where order bys are stored on default views
  const order = view.metadata?.jsonQuery?.order;
  if (!order || !order.length) return;

  const orderString = order
    .map((v: any) => `${v.columnFieldName} ${convertAscending(!!v.ascending)}`)
    .join(', ');
  return ` ORDER BY ${orderString}`;
};

// This will only get select + orderBy, no filters or search
// On default views this will be select + order. On derived views it will just be select
export const getQueryStringFromView = (view: View) => {
  let columns = view?.coreView?.columns;
  let queryString;
  if (columns) {
    // filter out hidden columns
    columns = _.filter(columns, (v) => !v.flags?.includes('hidden'));
    // extract field name and create string
    queryString = _.map(columns, (v) => v.fieldName).join(', ');

    // get order by
    const orderByString = getNonSoqlOrderBy(view);

    return `SELECT ${queryString}${orderByString || ''}`;
  }
};
