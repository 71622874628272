import React, { Component } from 'react';
import _ from 'lodash';
import InputRange from 'react-input-range';
import I18n from 'common/i18n';
import { hasProperty } from 'common/js_utils/typecheck';

import 'react-input-range/lib/css/index.css';
import './index.scss';

interface MinMax {
  min: number;
  max: number;
}

interface StartEnd {
  start: number;
  end: number;
}

interface Props {
  disabled: boolean;
  /** To format the labels */
  formatLabel: (label: number) => string;
  /** precision for _.round(#, precision) in formatLabel */
  precision: number;
  /** The minimum value selectable */
  rangeMin: number;
  /** The maximum value selectable */
  rangeMax: number;
  /** The increment that the user can move the handle(s) */
  step: number;
  /**
   * The value the slider should show. It comes in two flavors:
   * - The object flavor lets you select two values with the
   *   same slider. Renders two handles. (See the shape.)
   * - The number flavor is a single selection and only renders
   *   one handle.
   */
  value?: number | MinMax | StartEnd;
  /**
   * The change event is fired when the slider is dragged
   * or keyboard-navigated. The callback's value depends
   * on how props.value was set. If it was an object, you'll
   * get an object. If it was a number, you'll get a number.
   */
  onChange: (value: number | MinMax) => void;
}

interface States {
  value: number | MinMax;
}

export class Slider extends Component<Props, States> {
  static defaultProps = {
    disabled: false,
    precision: 1,
    step: 5
  };
  labelId: string;

  constructor(props: Props) {
    super(props);
    const {
      value,
      rangeMin,
      rangeMax
    } = props;
    this.labelId = `slider-label-${_.uniqueId()}`;
    this.state = {
      value: this.transformValue(value, rangeMin, rangeMax)
    };
  }

  transformValue = (value: undefined | number | MinMax | StartEnd, rangeMin: number, rangeMax: number): number | MinMax => {
    if (typeof value === 'object' && hasProperty(value, 'start')) {
      return {
        min: value.start,
        max: value.end
      };
    } else if (typeof value === 'number') {
      return _.clamp(value, rangeMin, rangeMax);
    } else {
      return rangeMax;
    }
  };

  onChange = (value: number | MinMax) => {
    this.setState({ value });
    this.props.onChange(value);
  };

  formatLabel = (label: number) => (_.round(label, this.props.precision));

  render() {
    const {
      disabled,
      formatLabel,
      rangeMax,
      rangeMin,
      step
    } = this.props;

    const labelFormatter = formatLabel || this.formatLabel;

    const inputRangeProps = {
      disabled,
      minValue: rangeMin,
      maxValue: rangeMax,
      step,
      value: this.state.value,
      onChange: this.onChange,
      ariaLabelledby: this.labelId,
      formatLabel: labelFormatter
    };

    return (
      <div className="input-range-slider">
        <label id={this.labelId} className="screenreader-only">{I18n.t('shared.components.filter_bar.slider')}</label>
        <InputRange {...inputRangeProps} />
      </div>
    );
  }
}

export default Slider;
