import { Expr, Scope, SoQLType, TypedExpr } from 'common/types/soql';
import * as _ from 'lodash';
import React from 'react';
import { ProjectionInfo, ViewColumnColumnRef } from '../lib/selectors';
import { none, Option, some } from 'ts-option';
import RemoveNode from './RemoveNode';
import KebabMenu from './visualNodes/KebabMenu';
import ExpressionEditor from './VisualExpressionEditor';
import ColumnPicker, { ColumnSubset } from './ColumnPicker';
import { PickableColumn } from '../lib/column-picker-helpers';
import { toTyped } from '../lib/soql-helpers';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { Eexpr } from '../types';

interface AddExprProps {
  columns: ViewColumnColumnRef[];
  parameters: ClientContextVariable[];
  columnSubset?: ColumnSubset;
  hasGroupOrAggregate?: boolean;
  onRemove: () => void;
  onUpdate: (expr: Expr | PickableColumn) => void;
  placeholderText: string;
  projectionInfo: ProjectionInfo;
  removable?: boolean;
  scope: Scope;
}

interface AddExprState {
  expr: Option<Expr>;
}

export default class AddExpr extends React.Component<AddExprProps, AddExprState> {
  constructor(props: AddExprProps) {
    super(props);
    this.state = { expr: none };
  }

  onUpdateType = (newExpr: Expr) => {
    if (newExpr.type === 'column_ref') {
      this.setState({ expr: none });
    } else if (newExpr.type === 'boolean_literal') {
      this.props.onUpdate(newExpr);
    } else {
      this.setState({ expr: some(newExpr) });
    }
  };

  // Why no dates here? Since 'Use date' is technically a function it was messing up the UI as it was double wrapping the function with two column-picker-containers
  //  and two kebabs. I failed to untangle it and since it wasn't a supported use case to make the initial filters when you add one a raw date value (you can still make it a date column or param)
  //  I just decided to remove the option from the kebab menu. This is something I would like to revisit eventually.
  isTypeAllowed = (type: SoQLType) => (type !== SoQLType.SoQLFloatingTimestampT && type !== SoQLType.SoQLFloatingTimestampAltT);

  render() {
    const {
      columns,
      parameters,
      hasGroupOrAggregate,
      onRemove,
      onUpdate,
      placeholderText,
      projectionInfo,
      removable,
      scope,
      columnSubset
    } = this.props;

    const eexpr = this.state.expr.map(e => ({
      typed: toTyped(e, scope, projectionInfo),
      untyped: e
    } as Eexpr<Expr, TypedExpr>));

    return (<div className="column-picker-container" data-testid="add-expr">
      {eexpr.match({
        some: e => <ExpressionEditor
          update={onUpdate}
          remove={onRemove}
          columns={columns}
          parameters={parameters}
          scope={scope}
          isTypeAllowed={t => true}
          hasGroupOrAggregate={hasGroupOrAggregate}
          eexpr={e}
          showRemove={false}
          projectionInfo={projectionInfo} />,
        none: () => <ColumnPicker
          className="btn btn-default add-expr-column-picker"
          prompt={placeholderText}
          columns={columns}
          projectionInfo={projectionInfo}
          selected={none}
          onSelect={onUpdate}
          columnSubset={columnSubset} />
      })}
      <KebabMenu
        columns={columns}
        parameters={parameters}
        isTypeAllowed={this.isTypeAllowed}
        scope={scope}
        update={this.onUpdateType}
      />
      {removable && (<RemoveNode onClick={onRemove} />)}
    </div>);
  }
}
