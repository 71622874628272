import _ from 'lodash';

import { assertHasProperty, assertIsOneOfTypes } from 'common/assertions';
import formatString from 'common/js_utils/formatString';
import DataProvider from './DataProvider';
import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import { appToken } from 'common/http';

function GeospaceDataProvider(config, useCache = false) {
  config.domain = config.domain || getDefaultDomain();

  _.extend(this, new DataProvider(config));

  assertHasProperty(config, 'domain');
  assertHasProperty(config, 'datasetUid');

  assertIsOneOfTypes(config.domain, 'string');
  assertIsOneOfTypes(config.datasetUid, 'string');

  if (useCache) {
    const cached = this.cachedInstance('GeospaceDataProvider');
    if (cached) {
      return cached;
    }
  }

  /**
   * Public methods
   */

  /**
   * Arguments:
   *  columnName          : api name of column for which to find the geometric extent
   *  ignoreInvalidLatLng : default false.
   *                        if true, will ignore geometries exceeding lat: 90 to -90
   *                        and lng: 180 to -180
   * whereClauseComponent  : The filter condition is added to the SoQL call when a filter is applied.
   */
  const featureExtentPromiseCache = {};
  this.getFeatureExtent = function(columnName, ignoreInvalidLatLng, whereClauseComponent = null) {

    let urlPattern = 'https://{0}/resource/{1}.json?$select=extent({2})';
    if (ignoreInvalidLatLng === true) {
      urlPattern += '&$where=within_box({2}, -90, -180, 90, 180)';
    }
    if (whereClauseComponent) {
     urlPattern += ` and ${whereClauseComponent}`;
    }
    urlPattern += '&$$read_from_nbe=true&$$version=2.1';

    const url = formatString(
      urlPattern,
      this.getConfigurationProperty('domain'),
      this.getConfigurationProperty('datasetUid'),
      columnName
    );
    const headers = {
      Accept: 'application/json',
      'X-App-Token': appToken()
    };

    const cacheKey = url;

    const cachedPromise = featureExtentPromiseCache[cacheKey];
    if (cachedPromise) {
      return cachedPromise;
    }

    const loadFeatureExtentPromise = new Promise(
      (resolve, reject) => {
        const xhr = new XMLHttpRequest();

        function onFail() {
          let error;

          try {
            error = JSON.parse(xhr.responseText);
          } catch (e) {
            console.log(e);
            error = xhr.statusText;
          }

          return reject({
            status: parseInt(xhr.status, 10),
            message: xhr.statusText,
            soqlError: error
          });
        }

        xhr.onload = function() {
          const status = parseInt(xhr.status, 10);

          if (status === 200) {
            try {
              const responseTextWithoutNewlines = xhr.
                responseText.
                replace(/\n/g, '');
              const coordinates = _.get(
                JSON.parse(responseTextWithoutNewlines),
                `[0].extent_${columnName}.coordinates[0][0]`
              );

              if (!_.isUndefined(coordinates)) {
                return resolve({
                  southwest: [coordinates[0][1], coordinates[0][0]],
                  northeast: [coordinates[2][1], coordinates[2][0]]
                });
              }
            } catch (e) {
              // Let this fall through to the `onFail()` below.
            }
          }

          onFail();
        };

        xhr.onabort = onFail;
        xhr.onerror = onFail;

        xhr.open('GET', url, true);

        // Set user-defined headers.
        _.each(headers, function(value, key) {
          xhr.setRequestHeader(key, value);
        });

        xhr.send();
      }
    );

    featureExtentPromiseCache[cacheKey] = loadFeatureExtentPromise;

    return loadFeatureExtentPromise;

  };

  const shapefilePromiseCache = {};
  this.getShapefile = function(extent) {

    const domain = this.getConfigurationProperty('domain');
    const datasetUid = this.getConfigurationProperty('datasetUid');

    let url = `https://${domain}/resource/${datasetUid}.geojson`;
    const headers = {
      Accept: 'application/json',
      'X-App-Token': appToken()
    };
    const extentQuery = extent ?
      "?$select=*&$where=intersects(the_geom, 'MULTIPOLYGON((({0})))')&$limit=5000" :
      '?$select=*&$limit=5000';
    const extentValidationErrorMessage = 'Argument `extent` must be an object ' +
      'with two keys: `southwest` and `northeast`; the value assigned to ' +
      'each key must be an array of two numbers in the following format: `[' +
      'latitude, longitude]`.';

    // Do not use a looser test for falsiness because if an invalid extent is
    // provided in any form we want to kick an error up to help with debugging.
    if (!_.isUndefined(extent)) {
      if (extentIsValid(extent)) {

        url += formatString(extentQuery, mapExtentToMultipolygon(extent));

      } else {

        return (
          new Promise(function(resolve, reject) {
            return reject({
              status: -1,
              message: extentValidationErrorMessage,
              soqlError: null
            });
          })
        );
      }
    }

    const cacheKey = url;

    const cachedPromise = shapefilePromiseCache[cacheKey];
    if (cachedPromise) {
      return cachedPromise;
    }

    const loadShapefilePromise = new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      function onFail() {
        let error;

        try {
          error = JSON.parse(xhr.responseText);
        } catch (e) {
          console.log(e);
          error = xhr.statusText;
        }

        return reject({
          status: parseInt(xhr.status, 10),
          message: xhr.statusText,
          soqlError: error
        });
      }

      xhr.onload = function() {
        const status = parseInt(xhr.status, 10);

        if (status === 200) {
          try {
            const responseTextWithoutNewlines = xhr.responseText.replace(/\n/g, '');
            resolve(JSON.parse(responseTextWithoutNewlines));
          } catch (e) {
            console.log(e);
            // Let this fall through to the `onFail()` below.
          }
        }

        onFail();
      };

      xhr.onabort = onFail;
      xhr.onerror = onFail;

      xhr.open('GET', url, true);

      // Set user-defined headers.
      _.each(headers, function(value, key) {
        xhr.setRequestHeader(key, value);
      });

      xhr.send();
    });

    shapefilePromiseCache[cacheKey] = loadShapefilePromise;

    return loadShapefilePromise;

  };

  function extentIsValid(extent) {

    return (
      // Validate that it is an object with northeast and
      // southwest properties.
      _.isObject(extent) &&
      // Next validate the northeast property.
      _.isArray(extent.northeast) &&
      extent.northeast.length === 2 &&
      _.every(extent.northeast, _.isNumber) &&
      // Then validate the southwest property.
      _.isArray(extent.southwest) &&
      extent.southwest.length === 2 &&
      _.every(extent.southwest, _.isNumber)
    );
  }

  /**
   * Multipolygon queries expect a polygon in clockwise order, starting from
   * the bottom left. Polygons are closed, meaning that the start and end
   * points must be identical.
   *
   * Example:
   *
   * 2----3
   * |    |
   * 1,5--4
   *
   * Where each pair is: longitude latitude
   */
  function mapExtentToMultipolygon(extent) {
    const lowerLeft = `${extent.southwest[1]} ${extent.southwest[0]}`;
    const upperLeft = `${extent.southwest[1]} ${extent.northeast[0]}`;
    const upperRight = `${extent.northeast[1]} ${extent.northeast[0]}`;
    const lowerRight = `${extent.northeast[1]} ${extent.southwest[0]}`;

    return `${lowerLeft},${upperLeft},${upperRight},${lowerRight},${lowerLeft}`;
  }
}

export default GeospaceDataProvider;
