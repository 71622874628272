import PropTypes from 'prop-types';
import React, { Component } from 'react';

import VisualizationRenderer from '../VisualizationRenderer';

class Visualization extends Component {
  static propTypes = {
    vif: PropTypes.object.isRequired,
    options: PropTypes.object,
    onVisualizationRenderError: PropTypes.func // optional function for errors
  }

  componentDidMount() {
    this.visualization = this.createVisualizationRenderer();
  }

  componentDidUpdate() {
    const { onVisualizationRenderError } = this.props;

    // if the visualization creation causes an error this variable will not be properly initialized
    // however, an update to this components props _could_ cause it to then be valid and it should be re-created
    if (!this.visualization) {
      this.visualization = this.createVisualizationRenderer();
    }

    try {
      this.visualization.update(this.props.vif, this.props.options);
    } catch (error) {
      // if we have a callback, call it
      // otherwise, actually throw the error
      if (onVisualizationRenderError) {
        onVisualizationRenderError(error);
      } else {
        throw error;
      }
    }
  }

  componentWillUnmount() {
    if (this.visualization) {
      this.visualization.destroy();
    }
  }

  // this.props.options contains localization information passed in from DatasetPreview
  // as temporary way localize the Table & Pager components until the mono-repo is complete.
  createVisualizationRenderer = () => {
    const { onVisualizationRenderError } = this.props;

    try {
      return new VisualizationRenderer(this.props.vif, this.element, this.props.options);
    } catch (error) {
      if (onVisualizationRenderError) {
        // if we have a callback, call it
        // otherwise, actually throw the error
        onVisualizationRenderError(error);
      } else {
        throw error;
      }
    }
  }

  render() {
    return (
      <div ref={(el) => this.element = el} />
    );
  }
}

export default Visualization;
