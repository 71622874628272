import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import I18n from 'common/i18n';
import SocrataIcon from '../SocrataIcon';
import Picklist from '../Picklist';
import { Key } from 'common/types/keyboard/key';
import ReactDOM from 'react-dom';

export class SearchablePicklist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValidating: false,
      isError: false,
      textEntered: false,
      offset: 0
    };

    _.bindAll(this, [
      'onChangeSearchTerm',
      'onClickSelectedOption',
      'onKeyPressSearch',
      'onKeyDownNavigation',
      'onSearch',
      'focusAndSelectSearchInput',
      'renderSearch',
      'renderSelectedOptionsPicklist',
      'renderPicklist',
      'renderError',
      'renderPrompt'
    ]);

    this.picklistRef = createRef();
  }

  componentDidMount() {
    this.mounted = true;

    if (this.search) {
      this.search.focus();
    }

    this.realignIfNeeded();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onChangeSearchTerm(event) {
    this.props.onChangeSearchTerm(event.target.value);
    this.setState({ isError: false });
    const textEntered = event.target.value !== '';
    this.setState({ textEntered });
  }

  onClickSelectedOption(selectedOption) {
    this.props.onClickSelectedOption(selectedOption);
  }

  onKeyPressSearch(event) {
    if (event.key === Key.Enter) {
      event.stopPropagation();
      event.preventDefault();
      this.onSearch(event);
    }
  }

  onKeyDownNavigation(event) {
    if (event.key === Key.ArrowUp || event.key === Key.ArrowDown) {
      this.picklistParent.querySelector('.picklist').focus();
    } else if (event.key === Key.Escape) {
      this.props.onBlur();
    }
  }

  onSearch(event) {
    const { canAddSearchTerm } = this.props;
    event.preventDefault();

    if (_.isFunction(canAddSearchTerm)) {
      this.setState({ isValidating: true });

      // This code runs asyncrhonously and potentially
      // after the component is removed. Make sure we're still
      // mounted.
      canAddSearchTerm(this.search.value).
        then(() => {
          if (this.mounted) {
            this.setState({ isValidating: false, textEntered: false });
          }
        }).
        catch(() => { // eslint-disable-line dot-notation
          if (this.mounted) {
            _.defer(this.focusAndSelectSearchInput);
            this.setState({ isError: true, isValidating: false });
          }
        });
    }
  }

  focusAndSelectSearchInput() {
    if (this.search) {
      this.search.focus();
      this.search.setSelectionRange(0, this.search.value.length);
    }
  }

  renderSearch() {
    const { hideSearchInput, value } = this.props;
    const { isValidating, isError, textEntered } = this.state;

    if (hideSearchInput) {
      return null;
    }

    const loadingSpinner = isValidating ? <span className="spinner-default"></span> : null;

    return (
      <div className="searchable-picklist-input-container">
        <span className="input-group">
          <SocrataIcon name="search" />
          <input
            className="searchable-picklist-input"
            type="text"
            aria-label={I18n.t('shared.components.filter_bar.search')}
            value={value || ''}
            ref={(el) => this.search = el}
            onKeyPress={this.onKeyPressSearch}
            onKeyDown={this.onKeyDownNavigation}
            onChange={this.onChangeSearchTerm}
            aria-invalid={isError}
            disabled={isValidating} />
          {loadingSpinner}
        </span>
      </div>
    );
  }

  renderSelectedOptionsPicklist() {
    const { selectedOptions, onBlur, size, value } = this.props;
    const { isValidating } = this.state;

    if (_.isEmpty(selectedOptions)) {
      return;
    }

    const picklistProps = {
      options: selectedOptions.map((selectedOption) => {
        return {
          group: I18n.t('shared.components.filter_bar.text_filter.selected_values'),
          ...selectedOption
        };
      }),
      onSelection: this.onClickSelectedOption,
      onBlur,
      disabled: isValidating,
      size,
      value
    };

    return (
      <div className="searchable-picklist-selected-options">
        <Picklist {...picklistProps} />
      </div>
    );
  }

  renderPicklist() {
    const {
      options,
      size,
      value,
      onSelection,
      onBlur,
      onArrowNavigationFromChild,
      noOptionsFoundText
    } = this.props;
    const { isValidating } = this.state;

    if (_.isEmpty(options)) {
      return (
        <div className="alert warning">
          {noOptionsFoundText || I18n.t('shared.components.filter_bar.no_options_found')}
        </div>
      );
    }

    const picklistProps = {
      options,
      size,
      value,
      onSelection,
      onBlur,
      onArrowNavigationFromChild,
      disabled: isValidating
    };

    return (
      <div className="searchable-picklist-suggested-options">
        <Picklist {...picklistProps}/>
      </div>
    );
  }

  renderError() {
    return this.state.isError ?
      <div className="alert warning">{I18n.t('shared.components.filter_bar.text_filter.keyword_not_found')}</div> :
      null;
  }

  renderPrompt() {
    const { hideExactMatchPrompt } = this.props;
    const { isError, textEntered } = this.state;

    if (hideExactMatchPrompt) {
      return null;
    }

    return (textEntered && !isError) ?
      <div className="alert info">
        {I18n.t('shared.components.filter_bar.text_filter.exact_search_prompt_main')}
      </div> :
      null;
  }

  realignIfNeeded() {
    if (!this.props.shouldReposition) {
      return;
    }
    // position the picklist after showing it
    const plNode = ReactDOM.findDOMNode(this.picklistRef.current);
    if (plNode !== null) {
      const scrollOffset = _.isFunction(this.props.getRepositionScrollOffset) ? this.props.getRepositionScrollOffset() : 0;
      const picklistBottomPosition = plNode.getBoundingClientRect().bottom + window.scrollY;
      const bodyHeight = document.body.offsetHeight;
      const initOffset = (plNode.parentElement.parentElement ? plNode.parentElement.parentElement.getBoundingClientRect().height : 42);
      const offset = initOffset + scrollOffset;
      if (bodyHeight < (picklistBottomPosition - scrollOffset)) {
        this.setState({ shouldBeTopAligned: true, offset: offset });
      } else {
        this.setState({ offset: scrollOffset });
      }
    }
  }

  render() {
    const transform = this.state.shouldBeTopAligned ? 'translateY(calc(-100% - ' + this.state.offset + 'px))' : 'translateY(-' + this.state.offset + 'px)';
    const style = this.props.shouldReposition ? { transform: transform } : {};

    return (
      <div ref={this.picklistRef} className={'searchable-picklist'} style={style}>
        {this.renderSearch()}
        {this.renderError()}
        {this.renderPrompt()}
        <div className="searchable-picklist-options" ref={(el) => this.picklistParent = el}>
          {this.renderSelectedOptionsPicklist()}
          {this.renderPicklist()}
        </div>
      </div>
    );
  }
}

SearchablePicklist.propTypes = {
  canAddSearchTerm: PropTypes.func,
  hideExactMatchPrompt: PropTypes.bool,
  hideSearchInput: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onBlur: PropTypes.func.isRequired,
  onChangeSearchTerm: PropTypes.func.isRequired,
  onClickSelectedOption: PropTypes.func,
  onSelection: PropTypes.func.isRequired,
  onArrowNavigationFromChild: PropTypes.func,
  noOptionsFoundText: PropTypes.string,
  shouldReposition: PropTypes.bool,
  getRepositionScrollOffset: PropTypes.func
};

export default SearchablePicklist;
