import { find, get, includes, map, merge, isNil, values } from 'lodash';
import { DataProvider, FilterBarColumn, FilterDataType } from '../../types';
import { FILTER_FUNCTION, NoopFilter, SoqlFilter } from '../../SoqlFilter';
import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import { POINT_COLUMN_TYPES } from 'common/authoring_workflow/constants';
import { isRegionComputedColumn } from 'common/column/utils';
import { ViewColumn } from 'common/types/viewColumn';

export interface FilterColumnsMap {
  /** Call getAllColumnStats to transform your ViewColumn's to FilterBarColumn's */
  [datasetUid: string]: FilterBarColumn | ViewColumn;
}

/** Fetch column stats for a single dataset. */
async function getColumnStats(
  dataProviderConfig: DataProvider,
  datasetUid: string,
  column: FilterBarColumn | ViewColumn
): Promise<FilterBarColumn> {
  const soqlDataProvider = new SoqlDataProvider(dataProviderConfig, true);
  const columnStats: FilterBarColumn[] = await soqlDataProvider.getColumnStats([column], datasetUid);
  return (isNil(columnStats[0]) ? {} : columnStats[0]) as FilterBarColumn;
}

/**
 * Fetch column stats and annotate the filter columns with them
 */
export async function getAllColumnStats(
  dataProviderConfigs: DataProvider[],
  columns: FilterColumnsMap
): Promise<FilterColumnsMap> {
  const datasetUids = Object.keys(columns);
  for (const datasetUid of datasetUids) {
    const dataProviderConfig = find(dataProviderConfigs, { datasetUid }) ?? { datasetUid };
    const columnStats = await getColumnStats(dataProviderConfig, datasetUid, columns[datasetUid]);
    columns[datasetUid] = { ...columns[datasetUid], ...columnStats };
  }
  return columns;
}

export function getFormattingColumn(columns: FilterColumnsMap) {
  return values(columns)[0];
}

export function getFilterName(config: SoqlFilter, columnMetaData: FilterColumnsMap) {
  if (!config.displayName) {
    const { datasetUid, fieldName } = config.columns[0];
    const viewColumn = columnMetaData[datasetUid];
    return viewColumn?.name ?? viewColumn?.fieldName ?? fieldName ?? '';
  }
  return config.displayName;
}

export function getRelevantColumnMetadata(
  filter: SoqlFilter,
  datasetMetadata: {
    [datasetUid: string]: ViewColumn[];
  },
  computedColumns: ViewColumn[] = []
) {
  const filterColumns = {};
  // This is where we make our datasetUid => column object for the filter object
  filter.columns?.forEach(({ datasetUid, fieldName: columnName }) => {
    const column =
      find(datasetMetadata[datasetUid] || [], { fieldName: columnName }) ||
      find(computedColumns || [], { fieldName: columnName });
    if (column) {
      filterColumns[datasetUid] = column;
    }
  });

  return filterColumns;
}

export function isColumnStatRequired(filter: SoqlFilter): boolean {
  const columnStatsFilterTypes = [FilterDataType.DATE, FilterDataType.NUMBER];
  return includes(columnStatsFilterTypes, filter.dataTypeName);
}

export function reset<Filter extends SoqlFilter>(
  config: Filter,
  columns: FilterColumnsMap,
  resetOnlyVisibleFilters = false
): NoopFilter | Filter {
  const { isHidden, isOverridden } = config;
  const orderBy = get(config, 'orderBy');
  const singleSelect = get(config, 'singleSelect');
  if ((resetOnlyVisibleFilters && isHidden) || isOverridden) {
    return config;
  }

  const defaultFilterForColumn = getNoopFilter(columns, config.isDrilldown, config.displayName);

  const resetConfig = merge({}, defaultFilterForColumn, {
    isHidden,
    singleSelect: singleSelect ?? undefined,
    orderBy: orderBy ?? undefined
  });

  return resetConfig as NoopFilter;
}

export function getNoopFilter(
  columns: FilterColumnsMap,
  isDrilldown = false,
  displayName?: string
): SoqlFilter {
  return {
    dataTypeName: getFilterDataType(values(columns)[0]),
    displayName: displayName,
    function: FILTER_FUNCTION.NOOP,
    arguments: null,
    isDrilldown,
    isHidden: false,
    columns: map(columns, (column: Pick<FilterBarColumn, 'fieldName'>, datasetUid: string) => ({
      datasetUid,
      fieldName: column.fieldName
    }))
  };
}

export function getFilterDataType(column: Pick<FilterBarColumn, 'renderTypeName'>): FilterDataType {
  if (!column?.renderTypeName) {
    return FilterDataType.UNKNOWN;
  }

  // Since a column can be computed regardless of data type, we must check whether it is computed first
  if (isRegionComputedColumn(column as ViewColumn)) {
    return FilterDataType.COMPUTED;
  } else if (includes(['calendar_date', 'date'], column.renderTypeName)) {
    return FilterDataType.DATE;
  } else if (column.renderTypeName === 'number') {
    return FilterDataType.NUMBER;
  } else if (column.renderTypeName === 'text') {
    return FilterDataType.TEXT;
  } else if (column.renderTypeName === 'checkbox') {
    return FilterDataType.CHECKBOX;
  } else if (includes(POINT_COLUMN_TYPES, column.renderTypeName)) {
    return FilterDataType.RADIUS;
  }

  return FilterDataType.UNKNOWN;
}

export function getColumnsForFilter(columnName: string, datasetUid: string) {
  return {
    columns: [{ fieldName: columnName, datasetUid }]
  };
}
