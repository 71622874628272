import $ from 'jquery';
import _ from 'lodash';

import mapboxgl from '@socrata/mapbox-gl';
import MapHelper from '../../helpers/MapHelper';

import airbrake from 'common/airbrake';
import VifBasemap from './VifBasemap';
import VifMapControls from './VifMapControls';
import VifMapInteractionHandler from './VifMapInteractionHandler';
import { DEFAULT_MAP_CENTER, SMALL_SIZE_MAP_WIDTH } from 'common/visualizations/views/mapConstants';

import GeospaceDataProvider from 'common/visualizations/dataProviders/GeospaceDataProvider';
import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';

export default class MapFactory {
  // Instantiates a mapboxgl map with given vif.
  // Case 1: Vif contains zoom and center
  //    Load map with the given zoom and center
  // Case 2: Vif does not contain zoom and center
  //    It fetches the feature bounds for the given datasource,
  //    instantiates the map at the center of the bounds and
  //    zooms in/out to fit the feature bounds.
  static async build(element, vif) {
    let map;
    let mapObject;
    const mapElement = initializeAndGetMapElement(element);
    const mapOptions = getMapOptions(mapElement, vif);

    if (mapOptions.zoom && mapOptions.center) {
      map = new mapboxgl.Map(mapOptions);
      mapObject = { map: map };
    } else {
      const { center, featureBounds } = await MapFactory.getFeatureBounds(vif);
      mapOptions.zoom = 10;
      mapOptions.center = center;

      map = new mapboxgl.Map(mapOptions);
      if (featureBounds !== null) {
        map.fitBounds(featureBounds, { animate: false });
      }
      mapObject = { map, featureBounds };
    }

    map.on('resize', () => {
      const mapContainer = $(this._container);
      if (mapContainer.width() < SMALL_SIZE_MAP_WIDTH) {
        if (!mapContainer.hasClass('small-size-map')) {
          mapContainer.addClass('small-size-map');
        }
      }
    });
    return mapObject;
  }

  // Fetches the feature bounds for the given vif datasource,
  // and returns it as mapboxgl.LatLngBounds.
  static async getFeatureBounds(vif, whereClauseComponent) {
    try {
      const extentPromises = _.chain(0)
        .range(_.get(vif, 'series.length', 0))
        .map(async (seriesIndex) => {
          const slicedVif = vif.cloneWithSingleSeries(seriesIndex);

          const datasetUid = slicedVif.isRegionMap()
            ? slicedVif.getShapeDatasetUid()
            : slicedVif.getDatasetUid();
          const columnName = slicedVif.isRegionMap()
            ? slicedVif.getShapeGeometryColumn(await slicedVif.getShapeDatasetMetadata())
            : slicedVif.getColumnName();
          const domain = slicedVif.getDomain();
          // Only the primary layer has filters, the whereClause is only valid for that.
          const primaryOnlyWhereClause = _.get(slicedVif, 'series[0].primary')
            ? whereClauseComponent
            : undefined;

          return await new GeospaceDataProvider({ domain, datasetUid }, false)
            .getFeatureExtent(columnName, true, primaryOnlyWhereClause)
            .catch(async (result) => {
              if (result.status === 200) {
                // The dataset has only one point or all the points are on the same location or in
                // a straight line. In that case getting extent will return empty response since
                // soql can't form a rectangle containing the points. In that case, we take the
                // first point in the dataset and use as the center.
                const pointResponse = await new SoqlDataProvider({ domain, datasetUid }, false).rawQuery(
                  `SELECT ${SoqlHelpers.escapeColumnName(columnName)} LIMIT 1`
                );

                const point = _.get(pointResponse, ['0', columnName, 'coordinates']);
                return { point };
              } else {
                console.warn('Getting extent for dataset failed', domain, datasetUid);
              }
            });
        })
        .value();

      const extents = await Promise.all(extentPromises);
      const lats = [];
      const lngs = [];

      _.each(extents, (extent) => {
        if (!extent) return;
        if (extent.southwest && extent.northeast) {
          lats.push(extent.southwest[0]);
          lats.push(extent.northeast[0]);
          lngs.push(extent.southwest[1]);
          lngs.push(extent.northeast[1]);
        } else if (extent.point) {
          lats.push(extent.point[1]);
          lngs.push(extent.point[0]);
        }
      });

      if (_.isEmpty(lats) || _.isEmpty(lngs)) {
        return { center: new mapboxgl.LngLat(...DEFAULT_MAP_CENTER), featureBounds: null };
      }

      const maxLat = _.max(lats);
      const maxLng = _.max(lngs);
      const minLat = _.min(lats);
      const minLng = _.min(lngs);
      const featureBounds = new mapboxgl.LngLatBounds([minLng, minLat], [maxLng, maxLat]);

      if (minLat === maxLat || minLng === maxLng) {
        return { center: featureBounds.getCenter(), featureBounds: null };
      }

      return { center: featureBounds.getCenter(), featureBounds };
    } catch (err) {
      airbrake.notify({
        error: `Error while finding center and feature bounds ${err}`,
        context: { component: 'UnifiedMap' }
      });
      return { center: new mapboxgl.LngLat(...DEFAULT_MAP_CENTER), featureBounds: null };
    }
  }
}

function getMapOptions(mapElement, vif) {
  let mapOptions = {
    container: mapElement,
    attributionControl: false,
    maxZoom: 18,
    preserveDrawingBuffer: true,
    transformRequest: (tileUrl) => {
      const transformedUrl = MapHelper.substituteSoqlParams(tileUrl);
      // Every call made by mapbox library to fetch
      //    - base map tile data
      //    - style configurations
      //    - sprites
      // can be transformed using the `transformRequest` option. Mapbox tile calls are in the format
      // https://a.domain.com/tiles/{z}/{x}/{y} where `z` is zoom, `x`|`y` are coordinates.
      // Based on the zoom and coordinates, in the tile data SOQL calls, we convert it into
      // a SOQL query that uses within_box to fetch data within the coordinates. If the url is not
      // soql tile call but base map tile call or sprites call, we do not transform the url.
      const isSoqlTileRequest = tileUrl !== transformedUrl;

      return {
        url: transformedUrl,
        headers: isSoqlTileRequest ? { 'X-Socrata-Federation': 'Honey Badger' } : {}
      };
    }
  };

  return _.merge(
    mapOptions,
    VifBasemap.getMapInitOptions(vif),
    VifMapControls.getMapInitOptions(vif),
    VifMapInteractionHandler.getMapInitOptions(vif)
  );
}

function initializeAndGetMapElement(element) {
  const vizContainer = element.find('.socrata-visualization-container');
  const mapElement = $('<div>', { class: 'unified-map-instance' });

  if ($(element).width() < SMALL_SIZE_MAP_WIDTH) {
    $(mapElement).addClass('small-size-map');
  }
  vizContainer.append(mapElement);

  return mapElement[0];
}
