
export const regexMatchPatterns =  {
  FOUR_BY_FOUR_PATTERN: /^\w{4}-\w{4}$/,

  // Obtained email regex from Strings.java in core-misc
  // eslint-disable-next-line max-len
  VALID_EMAIL_PATTERN:
    /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,
};

// the maximum size url that will load a frontend page (smaller than the rows requests to Core)
export const MAX_FRONTEND_URL_SIZE = 4113;
// the size after which we need to use POST instead of GET when querying for rows
export const MAX_URL_SIZE = 10000;

export default regexMatchPatterns;
