import { RELATIVE_FILTERS, RELATIVE_FILTER_TYPES, FILTER_TYPES } from 'common/dates';
import { FilterDataType } from './types';

export enum OPERATOR {
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'does_not_contain',
  EQUALS = '=',
  NOT_EQUAL = '!=',
  STARTS_WITH = 'starts_with',
  GREATER_THAN = '>',
  GREATER_THAN_EQUAL_TO = '>=',
  LESS_THAN = '<',
  LESS_THAN_EQUAL_TO = '<=',
  NULL = 'IS NULL',
  NOT_NULL = 'IS NOT NULL',
  ENDS_WITH = 'ends_with'
}

export enum FILTER_FUNCTION {
  NOOP = 'noop',
  BINARY_OPERATOR = 'binaryOperator',
  BINARY_OPERATOR_COMPUTED_GEOREGION = 'binaryComputedGeoregionOperator',
  EQUALS = '=',
  NOT_EQUAL = '!=',
  IN = 'in',
  NOT_IN = 'not_in',
  GREATER_THAN = '>',
  GREATER_THAN_EQUAL_TO = '>=',
  LESS_THAN = '<',
  LESS_THAN_EQUAL_TO = '<=',
  EXCLUDE_NULL = 'excludeNull',
  VALUE_RANGE = 'valueRange',
  RANGE_INCLUSIVE = 'rangeInclusive',
  RANGE_EXCLUSIVE = 'rangeExclusive',
  TIME_RANGE = 'timeRange',
  RELATIVE_DATE_RANGE = 'relativeDateRange',
  WITHIN_CIRCLE = 'withinCircle'
}

export enum JOIN_FUNCTION {
  AND = 'AND',
  OR = 'OR'
}

export type FilterValue = string | number | boolean | null | undefined;
export interface FilterOrderBy {
  parameter: string;
  sort: string;
}

interface SingleSelectWithPeriod {
  /** Time period length to select */
  by: string;
}

interface SoqlFilterBase {
  /** Used to determine what type of filter editor to display */
  dataTypeName?: FilterDataType;
  /** Name to display to the user. */
  displayName?: string;
  /** A list of all columns associated with this filter */
  columns: DataSourceColumn[];
  // TODO EN-63868: Remove this when removing references to `columnName`
  columnName?: string;
  /** Whether the filter is hidden when the filter bar is in read only mode. The filter will still be visible in edit mode. */
  isHidden?: boolean;
  /** Whether the filter was generated by using a chart's hierarchy/drilldown. Typically only included when set to true by drilldowns. */
  isDrilldown?: boolean;
  /** Whether the filter can have one value or multiple. */
  singleSelect?: boolean | SingleSelectWithPeriod;
  /** Whether the filter is overridden */
  isOverridden?: boolean;
  orderBy?: FilterOrderBy;
}

export interface DataSourceColumn {
  /** The API field name of the column this filter is for. */
  fieldName: string;
  /** The 4x4 for the tabular view this column is associated with */
  datasetUid: string;
}

interface BinaryOperatorBase extends SoqlFilterBase {
  arguments: FilterArgument[];
  joinOn?: JOIN_FUNCTION;
  singleSelect?: boolean;
}

/** Similar to BinaryOperator, but BinaryOperator's arguments are type FilterArgument
 * which is limited to just operand/operator/label, when I have need of
 * all the various filter types (including ranges, etc)
 * do not save this type to view's vif, or attempt to pass it to viz can filtering
 * only used by unsave-able primer filtering
 */
interface AndOrFilter extends SoqlFilterBase {
  arguments: SoqlFilter[];
  function: JOIN_FUNCTION;
}

export interface BinaryOperator extends BinaryOperatorBase {
  function: FILTER_FUNCTION.BINARY_OPERATOR;
}

interface SetSoqlFilter extends SoqlFilterBase {
  function: FILTER_FUNCTION.IN | FILTER_FUNCTION.NOT_IN;
  arguments: FilterValue[];
}

interface BinaryGeoregionOperator extends BinaryOperatorBase {
  function: FILTER_FUNCTION.BINARY_OPERATOR_COMPUTED_GEOREGION;
  computedColumnName: string;
}

export interface NumberSoqlFilter extends SoqlFilterBase {
  function:
    | FILTER_FUNCTION.EQUALS
    | FILTER_FUNCTION.EXCLUDE_NULL
    | FILTER_FUNCTION.GREATER_THAN
    | FILTER_FUNCTION.GREATER_THAN_EQUAL_TO
    | FILTER_FUNCTION.LESS_THAN
    | FILTER_FUNCTION.LESS_THAN_EQUAL_TO
    | FILTER_FUNCTION.NOT_EQUAL
    | FILTER_FUNCTION.RANGE_EXCLUSIVE
    | FILTER_FUNCTION.RANGE_INCLUSIVE
    /** This type is deprecated */
    | FILTER_FUNCTION.VALUE_RANGE;
  arguments: NumberFilterArgument;
  singleSelect?: boolean;
}

export interface NoopFilter extends SoqlFilterBase {
  function: FILTER_FUNCTION.NOOP;
  arguments: null;
  joinOn?: 'OR' | 'AND';
}

export interface TimeRangeFilter extends SoqlFilterBase {
  function: FILTER_FUNCTION.TIME_RANGE;
  arguments: DateFilterArgument;
  singleSelect?: SingleSelectWithPeriod;
}

export interface RelativeDateFilter extends SoqlFilterBase {
  function: FILTER_FUNCTION.RELATIVE_DATE_RANGE;
  arguments: DateFilterArgument;
  /** This will only ever be single select by day, relative to today */
  singleSelect?: SingleSelectWithPeriod;
}

export interface RadiusSoqlFilter extends SoqlFilterBase {
  function: FILTER_FUNCTION.WITHIN_CIRCLE;
  arguments: {
    center: number[];
    humanReadableLocation: unknown;
    radius: number;
    units: string;
  }[];
}

export interface FilterArgument {
  operator: OPERATOR;
  operand?: FilterValue;
  operandLabel?: string;
}

export interface DateFilterArgument {
  calendarDateFilterType: FILTER_TYPES.RELATIVE | FILTER_TYPES.RANGE;
  /** Length of the relative time period */
  period?: string;
  /** Something like this_week, etc... find the constant */
  type?: RELATIVE_FILTER_TYPES;
  /** Typically the number of periods (ex: for the last 3 weeks, this should be 3) */
  value?: number | string;
  /** Moment.js-compatible date string */
  start?: string | typeof RELATIVE_FILTERS.TODAY | typeof RELATIVE_FILTERS.YESTERDAY;
  /** Moment.js-compatible date string */
  end?: string;
}

export interface NumberFilterArgument {
  start?: string | number;
  end?: string | number;
  includeNullValues?: boolean;
  value?: string;
}

export type SoqlFilter =
  | AndOrFilter
  | SetSoqlFilter
  | BinaryOperator
  | BinaryGeoregionOperator
  | NoopFilter
  | NumberSoqlFilter
  | RadiusSoqlFilter
  | TimeRangeFilter
  | RelativeDateFilter;
