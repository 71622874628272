import _ from 'lodash';

import {
  MAP_TYPES,
  SHAPE_GEOMETRY_COLUMN,
  SHAPE_GEOMETRY_SECONDARY_COLUMN,
  SHAPE_GEOMETRY_VALID_DATA_TYPES
} from 'common/visualizations/views/mapConstants';
import { POINT_AGGREGATIONS, RANGE_BUCKET_TYPES } from 'common/authoring_workflow/constants';
import ChoroplethMapUtils from 'common/visualizations/views/ChoroplethMapUtils';

export function getRangeBucketType() {
  return _.get(this, 'series[0].mapOptions.rangeBucketType', RANGE_BUCKET_TYPES.jenks.value);
}

export function getShapeGeometryColumn(shapeDatasetMetadata) {
  const columns = _.get(shapeDatasetMetadata, 'columns', []);

  const primaryColumn = _.find(columns, { fieldName: SHAPE_GEOMETRY_COLUMN });
  const secondaryColumn = _.find(columns, { fieldName: SHAPE_GEOMETRY_SECONDARY_COLUMN });
  const firstValidShapeColumn = _.find(columns, (column) => {
    return _.includes(SHAPE_GEOMETRY_VALID_DATA_TYPES, column.dataTypeName);
  });

  if (_.includes(SHAPE_GEOMETRY_VALID_DATA_TYPES, _.get(primaryColumn, 'dataTypeName'))) {
    return _.get(primaryColumn, 'fieldName');
  } else if (_.includes(SHAPE_GEOMETRY_VALID_DATA_TYPES, _.get(secondaryColumn, 'dataTypeName'))) {
    return _.get(secondaryColumn, 'fieldName');
  } else if (!_.isUndefined(firstValidShapeColumn)) {
    return _.get(firstValidShapeColumn, 'fieldName');
  }

  throw new Error('No valid shape column in the given shape dataset');
}

export function getShapeGeometryLabelColumn() {
  return _.get(this, 'series[0].shapefile.geometryLabel', null);
}

export function isRegionMap() {
  return this.getMapType() === MAP_TYPES.POINT_MAP &&
    this.getPointAggregation() === POINT_AGGREGATIONS.REGION_MAP &&
    !_.isUndefined(this.getShapeDatasetUid()) &&
    !_.isUndefined(this.getMeasureForeignKey()) &&
    this.getMeasureForeignKey() !== null;
}
